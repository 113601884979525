<div class="container-fluid ticc-detail-container">

    <div class="row">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb" [routerLink]="'/' + attachmentType"><span class="single-breadcrumb-link">{{attachmentType == 'home' ? 'Dashboard' : attachmentType | titlecase}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb" *ngIf="attachmentType != 'home'" [routerLink]="'/' + attachmentType + '/' + checkDetails?.ticcAttachmentUuid"><span class="single-breadcrumb-link">{{checkDetails?.ticcAttachmentName}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb" [routerLink]="'/ticcs/' + checkDetails?.ticcUuid"> <span class="single-breadcrumb-link">{{checkDetails?.ticcTypeName}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb">Check Overview</div>
        </div>
    </div>

    <div class="col-12 view-entry">
        <div class="row">
            <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <div class="title">
                    Check Overview
                </div>

                <hr>

                <div class="subtitle">
                    {{checkDetails?.ticcTypeName}}
                </div>
            </div>
        </div>
    </div>

    <div class="row p-3 m-0">

        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0 d-flex align-items-center">
                    <button [routerLink]="['/ticcs/' + checkDetails?.ticcUuid]" mat-button class="me-3"><mat-icon>arrow_back_ios_new</mat-icon></button>
                    <span>TICC Details</span>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 pb-3 d-flex information-content">

                <p class="information-ticc-type mt-4">{{checkDetails?.ticcTypeName}}</p>

                <div class="col-12">
                    <div class="row mx-0 my-2">
                        <div class="col-4 col-md-2 col-lg-1 parameter parameter-definition">Status</div>
                        <div class="col-8 col-md-10 col-lg-11 parameter">{{checkDetails?.status}}</div>
                    </div>

                    <div class="row mx-0 my-2">
                        <div class="col-4 col-md-2 col-lg-1 parameter parameter-definition">For {{checkDetails?.ticcAttachmentType}}</div>
                        <div class="col-8 col-md-10 col-lg-11 parameter">{{checkDetails?.ticcAttachmentName}}</div>
                    </div>

                    <div class="row mx-0 my-2">
                        <div class="col-4 col-md-2 col-lg-1 parameter parameter-definition">Ticc</div>
                        <div class="col-8 col-md-10 col-lg-11 parameter">{{checkDetails?.ticcUuid}}</div>
                    </div>

                    <div class="row mx-0 my-2">
                        <div class="col-4 col-md-2 col-lg-1 parameter parameter-definition">Checked By</div>
                        <div class="col-8 col-md-10 col-lg-11 parameter">{{checkDetails?.completedBy?.fullName}}</div>
                    </div>

                    <div class="row mx-0 my-2">
                        <div class="col-4 col-md-2 col-lg-1 parameter parameter-definition">Checked At</div>
                        <div class="col-8 col-md-10 col-lg-11 parameter">{{checkDetails?.completedDate | date: 'dd-MM-YYYY'}}</div>
                    </div>

                    <div class="row mx-0 my-2">
                        <div class="col-12 parameter-notes">
                            <span class="parameter parameter-definition">Notes</span>&nbsp;{{checkDetails?.notes}}
                        </div>
                    </div>

                </div>

            </mat-card-content>
        </mat-card>



        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Observations
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 py-3 d-flex observations-content">
                <mat-list role="list" *ngFor="let observation of checkDetails?.checkObservationsData">
                    <mat-list-item role="listitem">
                        <div class="w-100 d-flex align-items-center justify-content-between flex-wrap">

                            <span>{{observation.text}}</span>
                            <span>
                                <button mat-button *ngIf="observation.isChecked" disabled>
                                    <!-- (click)="observation.isChecked = !observation.isChecked" -->
                                    <mat-icon class="icon-blue">check_box</mat-icon>
                                </button>

                                <button mat-button *ngIf="!observation.isChecked" disabled>
                                    <!-- (click)="observation.isChecked = !observation.isChecked" -->
                                    <mat-icon class="icon-red">disabled_by_default</mat-icon>
                                </button>
                            </span>
                        </div>

                    </mat-list-item>

                </mat-list>
            </mat-card-content>
        </mat-card>



        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Attachments 
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 py-3 d-flex attachments-container">
                <mat-list role="list" *ngFor="let file of attachmentFileList; let i = index">
                    <mat-list-item class="mb-2" role="listitem">
                        <div class="row m-0 py-2 w-100">
                            <div class="col-1 attachments-image-container d-flex align-items-center">

                                <img *ngIf="isImg(file.contentType)" class="attachments-image"
                                    src="data:{{file.contentType}};base64,{{file.file}}" alt="">
                                <mat-icon *ngIf="!isImg(file.contentType)" class="icon-blue">description</mat-icon>
                            </div>

                            <div class="col-9 d-flex align-items-center">
                                {{ file.name }}
                            </div>

                            <div class="col-2 d-flex align-items-center">
                                <a mat-button class="w-100 h-100 d-flex align-items-center justify-content-center"
                                    [href]="getDownloadUrl(file.file)" download="{{file.name}}">
                                    <mat-icon>download</mat-icon>
                                </a>
                            </div>
                        </div>

                    </mat-list-item>
                </mat-list>
                <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                    <mat-spinner class="my-auto"></mat-spinner>
                </div>
            </mat-card-content>

           
        </mat-card>

        <div class="col-12 m-0 p-0">
            <button class="w-100 red-button" mat-button (click)="openCheckDeleteDialog(checkDetails)"><mat-icon>delete</mat-icon>&nbsp;Delete</button>
        </div>


    </div>
</div>