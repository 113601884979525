<mat-toolbar color="secondary">
	<div class="container">
		<div class="row">
			<div class="col-1 menu-logotype-box">
				<img
					src="../web-assets/logotype/Logo-01.png"
					class="menu-logotype"
					alt="" />
			</div>

			<div class="col-4 d-flex justify-content-between">
				<button class="menu-link" mat-button [matMenuTriggerFor]="userArea">
					User Area
				</button>

				<mat-menu #userArea="matMenu">
					<button routerLink="home" mat-menu-item><mat-icon>speed</mat-icon> Dashboard</button>
					<button routerLink="sites" mat-menu-item><mat-icon>corporate_fare</mat-icon> Sites</button>
					<button routerLink="boards" mat-menu-item><mat-icon>assignment</mat-icon> Boards</button>
					<button routerLink="assets" mat-menu-item><mat-icon>image</mat-icon> Assets</button>
					<button routerLink="reports" mat-menu-item><mat-icon>report</mat-icon> Reports</button>
					<button routerLink="my-files" mat-menu-item><mat-icon>folder_open</mat-icon> My Files</button>
					<button routerLink="users" mat-menu-item><mat-icon>group</mat-icon> Users</button>
				</mat-menu>

				<button class="menu-link" mat-button [matMenuTriggerFor]="administrationArea">
					Administration Area
				</button>

				<mat-menu #administrationArea="matMenu">
					<button routerLink="admin/site-management" mat-menu-item><mat-icon>corporate_fare</mat-icon> Sites Managemenet</button>
					<button routerLink="admin/user-management" mat-menu-item><mat-icon>manage_accounts</mat-icon> User Managemenet</button>
					<button routerLink="admin/asset-types" mat-menu-item><mat-icon>perm_media</mat-icon> Asset Types</button>
					<button routerLink="admin/ticc-types" mat-menu-item><mat-icon>done_all</mat-icon> TICC Types</button>
					<button routerLink="admin/modules" mat-menu-item><mat-icon>layers</mat-icon> Modules</button>
					<button routerLink="admin/tags" mat-menu-item><mat-icon>qr_code</mat-icon> TAGs</button>
				</mat-menu>


				<!-- <div class="d-flex">
					<button class="menu-dropdowns selectors" mat-button>
						Companies
					</button>
					<mat-menu #companies="matMenu">
						<button
							*ngFor="let companies of sitesList$ | async"
							mat-menu-item
							>
							{{ companies.company }}
						</button>
					</mat-menu>

					<button
						class="menu-dropdowns selectors"
						mat-button
						[matMenuTriggerFor]="sites">
							{{ (currentSite$ | async)!.name || "Sites" }}
					</button>
					<mat-menu #sites="matMenu">
						<button
							*ngFor="let site of sitesList$ | async"
							mat-menu-item
							(click)="changeSite(site)"
							class="main-menu-selector"
							>
							<span class="main-menu-selector-sitename">{{ site.name }}</span>
							<br class="my-2">
							<div *ngIf="site.city">
								<mat-icon class="mx-0 main-menu-selector-city">pin_drop</mat-icon>
								{{site.city}}
							</div>
						</button>
					</mat-menu>
				</div> -->

			</div>

			<div class="col-2"></div>

			<div class="col-5 d-flex align-middle justify-content-between">
					<button class="col-2 header-action-button" mat-button>
						<mat-icon>notifications</mat-icon>
					</button>

					<button (click)="toggleModules()" class="col-2 header-action-button" mat-button>
						<mat-icon>apps</mat-icon>
					</button>

					<button class="col-5 header-action-button header-action-button-user" [matMenuTriggerFor]="user" mat-button>
						<div class="col-4">
							<img class="user-icon" [src]="userPhoto || 'web-assets/images/default/default-user.png'">
						</div>
						<div class="col-7 header-action-button-user-text">
							<span class="col-12 user-name" *ngIf="!(newUserName | async) as name">{{currentUserData?.name}}&nbsp;{{currentUserData?.surname}}</span>
							<span class="col-12 user-name" *ngIf="newUserName | async as name">{{name}}</span>
							<span class="col-12 user-email">{{currentUserData?.email}}</span>
						</div>
					</button>

					<mat-menu #user="matMenu">
                        <button [routerLink]="['/users/' + currentUserData?.userUUId]" mat-menu-item><mat-icon>account_circle</mat-icon>&nbsp;View Account</button>
                        <button (click)="logout()" mat-menu-item><mat-icon>logout</mat-icon>&nbsp;Logout</button>
                    </mat-menu>

			</div>
		</div>
	</div>
</mat-toolbar>

<app-admin-modules-carrousel *ngIf="areModulesDisplayed"></app-admin-modules-carrousel>

<!-- <app-breadcrumb></app-breadcrumb> -->
