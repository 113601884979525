import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, of, pipe } from 'rxjs';
import { IExtractedUserFromJWT, IUser, User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {

  currentUserSource = new BehaviorSubject<IExtractedUserFromJWT | null>(null);
  
  currentUser$: Observable<IUser | null> = this.currentUserSource.pipe(
    map((jwtUser: IExtractedUserFromJWT | null) => {
        if (jwtUser !== null) {
          
          return new User(jwtUser);
        }

        return null;
      }
    ));

  isLoggedIn$ = this.currentUserSource.pipe(map(Boolean));

  isUserAdmin = this.currentUser$.pipe(
    map((user: IUser | null) => user?.role === 'administrator')
  )


  getCurrentSiteRole(currentSiteUuid: number) {
    return this.currentUser$.pipe(
      map(x => x?.siteRoles.find(element => element.SiteId === currentSiteUuid))
    )
  }
  
  userHasAccess(currentSiteUuid: number) {
    return this.getCurrentSiteRole(currentSiteUuid).pipe(
      mergeMap((res) => {
        if (res?.RoleId === 2 || res?.RoleId == 3) {
          return of(true);
        }
        return of(false);
      })
    )
  }


  constructor() {

  }

}
