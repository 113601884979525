import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { concatMap } from 'rxjs';
import { AuthService } from 'src/app/services/api/auth.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';

@Component({
  selector: 'app-admin-menu-mobile',
  templateUrl: './admin-menu-mobile.component.html',
  styleUrls: ['./admin-menu-mobile.component.scss']
})
export class AdminMenuMobileComponent {

  areModulesDisplayed: boolean = false;

	currentSite$ = this.siteStateService.currentUserSite$;
  
  constructor(
		private siteStateService: SiteStateService,
		private sitesService: SitesService,
  ) {  }
  
  @Output() togglerEvent = new EventEmitter();

	changeSite(site: { id: number }) {

		this.sitesService.setCurrentSite(site.id).pipe(
			concatMap(() => {
				return this.sitesService.getCurrentSite()
			})
		).subscribe((res) => {
			console.log("Current Site", res);
			this.siteStateService.currentUserSiteSource.next(res);
		})

	}

  toggleModules() {
    this.areModulesDisplayed = !this.areModulesDisplayed;
  }

  toggleSidebar() {
    this.togglerEvent.emit()
  }
}
