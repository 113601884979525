<h1 class="title mx-2 mb-3">Delete Dialog</h1>

<div class="main-text mx-2 mb-3">
    You are going to delete <br>
    <span class="italic data">"{{data?.assetName}}"</span> <br>
    Are you sure?
</div>

<div mat-dialog-actions class="row mx-2 d-flex justify-content-center action-buttons">
  <button class="col-4" mat-button mat-dialog-close>CANCEL</button>
    <button class="col-4" mat-button [matDialogClose]="data?.assetUuid">DELETE</button><!-- (click)="deleteAsset(data.assetUUID)" -->
</div>