import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { QrService } from 'src/app/services/api/qr.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { TagsService } from 'src/app/services/api/tags.service';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QRComponent {

  tagUuid = this.route.snapshot.paramMap.get('qr');
  qrDetails$: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private qrService: QrService,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorHandlerService,
    private tagsService: TagsService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    // this.qrService.getQR(this.siteUuid).subscribe((res) => {
    //   this.qrDetails$ = (res as any).url;
    //   console.log("res",res)
    // })

    this.route.queryParamMap.subscribe((queryParams) => {
      console.log(queryParams.get('scanner'));
      if (queryParams.get('scanner') == 'false') {
        if (this.tagUuid) {
          this.tagsService.getQRCode(this.tagUuid).subscribe({
            next: (blobImage) => {

              let parsedBlobImage = URL.createObjectURL(blobImage);

              let res = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
              this.qrDetails$ = res;
              return res;
            },
            error: (error: HttpErrorResponse) => {

              if (error.status === 400) {
                return EMPTY
              }

              this.errorHandler.handleError(error);

              return throwError(error);
            }
          });
        }
      } else {
        this.qrService.scanQRCode(this.tagUuid as string).subscribe((res: any) => this.router.navigate([res.url]));
      }
    });

  }
}
