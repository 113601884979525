<div class="page-top-breadcrumbs">
    USER EDIT
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
    User Edit
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="container-fluid">

    <mat-card class="p-0">
        <mat-card-header class="p-3">
            <mat-card-title class="m-0">Information</mat-card-title>
        </mat-card-header>

        <mat-card-content class="p-3">
            <div class="row">
                <div class="col-12 col-md-1 user-image-container">
                    <label for="photo">
                        <img [src]=" userPhoto || avatarConvert(userDetails?.userPhoto) || 'web-assets/images/default/default-user.png'"
                        class="user-image" alt="">
                    </label>
                    <input type="file" style="display: none;"  id="photo" (change)="uploadFile($event)" />   
                </div>

                <div class="col-12 col-md-11">
                    <mat-form class="row" [formGroup]="formUserEdit">
                        <div
                            class="col-12 col-md-4 bordered-no-padding d-flex justify-content-between align-items-center">
                            <div class="col-10 internal-padding d-flex justify-content-between flex-wrap">
                                <span class="italic ligth">Name:</span>&nbsp;
                                <input (change)="updateButtonEnabled = true" form #nameInput (focus)="nameOnFocus = !nameOnFocus"
                                    (focusout)="nameOnFocus = !nameOnFocus" matInput class="strong"
                                    formControlName="userFirstName" [value]="userDetails?.userFirstName">
                            </div>

                            <div (click)="nameInput.focus()" *ngIf="!nameOnFocus; else inputSelected"
                                class="col-2 user-edit-icon-box"><mat-icon>edit</mat-icon></div>

                            <ng-template #inputSelected>
                                <div class="col-2 user-check-icon-box"><mat-icon>task_alt</mat-icon></div>
                            </ng-template>
                        </div>
                        <div
                            class="col-12 col-md-4 bordered-no-padding d-flex justify-content-between align-items-center">
                            <div class="col-10 internal-padding d-flex justify-content-between flex-wrap">
                                <span class="italic ligth">Name:</span>&nbsp;
                                <input (change)="updateButtonEnabled = true" #surnameInput (focus)="surnameOnFocus = !surnameOnFocus"
                                    (focusout)="surnameOnFocus = !surnameOnFocus" matInput class="strong"
                                    formControlName="userLastName" [value]="userDetails?.userLastName">
                            </div>

                            <div (click)="surnameInput.focus()" *ngIf="!surnameOnFocus; else inputSelected"
                                class="col-2 user-edit-icon-box"><mat-icon>edit</mat-icon></div>

                            <ng-template #inputSelected>
                                <div class="col-2 user-check-icon-box"><mat-icon>task_alt</mat-icon></div>
                            </ng-template>
                        </div>
                        <div class="col-12 col-md-4 bordered">
                            <span class="italic ligth">Email:</span>&nbsp;<span class="strong">{{userDetails?.userEmail
                                || "Email not added"}}</span>
                        </div>
                        <div class="col-12 col-md-3 bordered">
                            <span class="italic ligth">Company:</span>&nbsp;<span
                                class="strong">{{userDetails?.userCompany?.companyName || "Company not added"}}</span>
                        </div>
                        <div class="col-12 col-md-3 bordered">
                            <span class="italic ligth">System Role:</span>&nbsp;<span
                                class="strong">{{userDetails?.userSystemRole || "Company not added"}}</span>
                        </div>
                        <div class="col-12 col-md-3 bordered">
                            <span class="italic ligth">Last Login:</span>&nbsp;<span
                                class="strong">{{userDetails?.userLastLogin || "User doesen't have last login data"}}
                            </span>
                        </div>
                        <div class="col-12 col-md-3 bordered">
                            <span class="italic ligth">Timezone:</span>&nbsp;<span
                                class="strong">{{userDetails?.userLastTimezone || "Timezone not added"}}</span>
                        </div>
                    </mat-form>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="col-12 action-buttons-container">
        <div class="row d-flex justify-content-between">

            <div class="col-6 col-md-5 col-xl-3">
                <button mat-button type="submit" [routerLink]="'/users/' + userUuid" class="action-buttons cancel w-100">CANCEL</button>
            </div>

            <div class="col-6 col-md-5 col-xl-3">
                <button (click)="editUser()" mat-button type="submit" [disabled]="!updateButtonEnabled"
                    class="action-buttons submit w-100">UPDATE</button>
            </div>
        </div>
    </div>
</div>