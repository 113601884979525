
<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="users-page-container d-none d-md-flex justify-content-end">
    <input (keyup)="searchUser();" [formControl]="searchControl" class="fixed-input" placeholder="Search User">
</div>

<div class="page-top-breadcrumbs">
    USERS MANAGEMENT
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
    Admin - Users Management
</h1>


<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->

<cdk-virtual-scroll-viewport itemSize="10"  class="container-fluid scrollbar-box">
    <div *cdkVirtualFor="let user of obs | async, let i=index;">
        <div class="row table-row" [ngClass]="{
                'table-row-normal': user.userIsActive,
                'table-row-disabled': !user.userIsActive
            }">
            <div class="col-1" [ngClass]="{'d-none': isMobile}">
                <div *ngIf="!user.userIsActive" class="disabled-user d-flex flex-wrap justify-content-center">
                    <mat-icon class="w-100 disabled-user-box disabled-user-box-color">disabled_visible</mat-icon>
                    <span class="disabled-user-text disabled-user-box-color">DISABLED</span>
                </div>
            </div>

            <div class="col-12 col-md-10 py-3" [ngClass]="{'inactive-user-table-content': !user.userIsActive}">
                <div class="row first-table-row">

                    <div class="d-block d-md-none col-12 mt-2 mb-4">
                        <div *ngIf="!user.userIsActive" class="disabled-user d-flex flex-wrap justify-content-center">
                            <mat-icon class="w-100 disabled-user-box disabled-user-box-color">disabled_visible</mat-icon>
                            <span class="disabled-user-text-mobile disabled-user-box-color">DISABLED</span>
                        </div>
                    </div>
                    
                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">First Name:&nbsp;</span> <span
                            class="strong">{{user.userFirstName}}</span></div>
                            <hr class="my-2 d-block d-md-none">

                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">Last Name:&nbsp;</span> <span
                            class="strong">{{user.userLastName}}</span></div>
                            <hr class="my-2 d-block d-lg-none">

                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">Email:&nbsp;</span> <span
                            class="strong">{{user.userEmail}}</span></div>
                            <hr class="my-2 d-block d-md-none">


                    <div *ngIf="user.userEmail !== 'admin@admin.com'; else mainAdmin"
                        [mat-menu-trigger-for]="changeRole" class="col-12 col-md-6 col-lg-3 cell"
                        [ngSwitch]="user.userSystemRole?.systemRoleTitle">
                        <span class="italic">System Role:&nbsp;&nbsp;</span>

                        <mat-icon *ngSwitchCase="'user'">person</mat-icon>
                        <mat-icon *ngSwitchCase="'administrator'">manage_accounts</mat-icon>
                        <mat-icon *ngSwitchCase="'company manager'">supervised_user_circle</mat-icon>

                        <span [ngClass]="{
                                'text-color-user': user.userSystemRole?.systemRoleTitle === 'user',
                                'text-color-administrator': user.userSystemRole?.systemRoleTitle === 'administrator',
                                'text-color-company-manager': user.userSystemRole?.systemRoleTitle === 'company manager'
                            }">
                            &nbsp;{{user.userSystemRole?.systemRoleTitle | titlecase}}
                        </span>
                    </div>

                    <ng-template #mainAdmin>
                        <div class="col-12 col-md-6 col-lg-3 cell">
                            <span class="italic">System Role:&nbsp;&nbsp;</span>
                            <mat-icon>manage_accounts</mat-icon>
                            <span class="text-color-administrator">
                                &nbsp;{{user.userSystemRole?.systemRoleTitle | titlecase}}
                            </span>
                        </div>
                    </ng-template>


                    <mat-menu #changeRole="matMenu">
                        <button mat-menu-item
                            (click)="changeSystemRole('user', user.userSystemRole?.systemRoleTitle, user)">
                            <mat-icon>person</mat-icon>
                            <span>User</span>
                        </button>

                        <button mat-menu-item
                            (click)="changeSystemRole('company manager', user.userSystemRole?.systemRoleTitle, user)">
                            <mat-icon>supervised_user_circle</mat-icon>
                            <span>Company Manager</span>
                        </button>

                        <button mat-menu-item
                            (click)="changeSystemRole('administrator', user.userSystemRole?.systemRoleTitle, user)">
                            <mat-icon>manage_accounts</mat-icon>
                            <span>Administrator</span>
                        </button>
                    </mat-menu>
                </div>
                <hr class="my-2">
                <div class="row second-table-row">
                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">User Sites:&nbsp;</span> <span
                            class="strong">{{user.userSitesCount}}</span></div>
                            <hr class="my-2 d-block d-md-none">
                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">Invitation Pending:&nbsp;</span>
                        <span class="strong">{{user.userInvitationPending}}</span></div>
                            <hr class="my-2 d-block d-lg-none">

                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">Last Login:&nbsp;</span> <span
                            class="strong">{{user.userLastLoginAt}}</span></div>
                            <hr class="my-2 d-block d-md-none">

                    <div class="col-12 col-md-6 col-lg-3 cell"><span class="italic">Company:&nbsp;</span> <span
                            class="strong">{{user.userCompany?.companyName}}</span></div>
                            <hr class="my-2 d-block d-md-none">

                </div>
            </div>

            <div class="col-12 my-3 my-md-0 col-md-1 action-buttons">
                <div class="col-6 col-md-12">
                    <button [routerLink]="['/users/' + user.userUUID]"  [queryParams]="{ lastUrl: 'admin-user-manager' }" mat-button
                        class="w-100 justify-content-center icon-box view p-0 d-flex flex-wrap" style="min-width: 0">
                        <mat-icon class="w-100 action-buttons-icon"
                            [ngClass]="{'inactive-user-action-buttons': !user.userIsActive}">visibility</mat-icon>
                        <div class="w-100 action-buttons-line-height"
                            [ngClass]="{'inactive-user-action-buttons': !user.userIsActive}">VIEW</div>
                    </button>
                </div>

                <div class="col-6 col-md-12">
                    <button [matMenuTriggerFor]="moreOptions" mat-button
                        class="w-100 justify-content-center icon-box more p-0 d-flex flex-wrap" style="min-width: 0">
                        <mat-icon class="w-100 action-buttons-icon"
                            [ngClass]="{'inactive-user-action-buttons': !user.userIsActive}">more_horiz</mat-icon>
                        <div class="w-100 action-buttons-line-height"
                            [ngClass]="{'inactive-user-action-buttons': !user.userIsActive}">MORE</div>
                    </button>
                </div>

                <mat-menu #moreOptions="matMenu">

                    <button *ngIf="user.userIsActive; else userDisabled" mat-menu-item
                        (click)="enableOrDisableUser(user.userUUID, 0)">
                        <mat-icon class="disabled-color">do_not_disturb_on</mat-icon>
                        <span class="disabled-color">Disable</span>
                    </button>

                    <ng-template #userDisabled>
                        <button mat-menu-item (click)="enableOrDisableUser(user.userUUID, 1)">
                            <mat-icon class="disabled-color">do_not_disturb_off</mat-icon>
                            <span class="disabled-color">Enable</span>
                        </button>
                    </ng-template>

                    <button mat-menu-item (click)="openChangePasswordDialog(user)">
                        <mat-icon class="change-password-color">password</mat-icon>
                        <span class="change-password-color">Change Password</span>
                    </button>

                    <button mat-menu-item (click)="openDeleteDialog(user)">
                        <mat-icon class="delete-color">delete</mat-icon>
                        <span class="delete-color">Delete</span>
                    </button>

                </mat-menu>
            </div>
        </div>
    </div>

    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[]" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>
</cdk-virtual-scroll-viewport>


<!-- ---------------------------------------------------------- ABSOLUTE ---------------------------------------------------------- -->
<div class="asset-page-container d-flex d-md-none justify-content-center">
    <input (keyup)="searchUser();" [formControl]="searchControl" class="fixed-input" placeholder="Search User">
</div>