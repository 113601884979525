import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './views/login/login.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from './material.module';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { environment } from 'src/environments/environment';
import { BottomSitesDialogSelector, DialogDisassociateSiteCompany, SitesComponent } from './views/sites/sites.component';
import { BoardsComponent, DialogBoardDelete } from './views/boards/boards.component';
import { AssetsComponent, DialogDeleteConfirmation } from './views/assets/assets.component';
import { ModulesComponent } from './views/modules/modules.component';
import { ReportsComponent } from './views/reports/reports.component';
import { MyFilesComponent } from './views/my-files/my-files.component';
import { ModulesCarrouselComponent } from './views/header/modules-carrousel/modules-carrousel.component';
import { MenuDesktopComponent } from './views/header/menu-desktop/menu-desktop.component';
import { MenuMobileComponent } from './views/header/menu-mobile/menu-mobile.component';
import { SidenavComponent } from './views/header/sidenav/sidenav.component';
import { SwiperModule } from "node_modules/swiper/angular";
import { ModalDashboardComponent } from './views/dashboard/modal-dashboard/modal-dashboard.component';

import { AsyncPipe, CommonModule } from '@angular/common';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AdminMenuDesktopComponent } from './views/header-admin/admin-menu-desktop/admin-menu-desktop.component';
import { AdminMenuMobileComponent } from './views/header-admin/admin-menu-mobile/admin-menu-mobile.component';
import { AdminSidenavComponent } from './views/header-admin/admin-sidenav/admin-sidenav.component';
import { AdminModulesCarrouselComponent } from './views/header-admin/admin-modules-carrousel/admin-modules-carrousel.component';
import { IsAdministratorDirective } from './directives/is-administrator.directive';
import { AssetTypesComponent, DialogDeleteAssetTypeCompanyConfirmation } from './views/admin/asset-types/asset-types.component';
import { TiccTypesComponent } from './views/admin/ticc-types/ticc-types.component';
import { AdminSitesManagementComponent, DialogSiteDelete } from './views/admin/admin-sites-management/admin-sites-management.component';
import { AdminUserManagementComponent, DialogChangePassword, DialogCompanySelector, DialogSystemRole, DialogUserDelete } from './views/admin/admin-user-management/admin-user-management.component';
import { AdminModulesComponent } from './views/admin/admin-modules/admin-modules.component';
import { AdminTagsComponent, DialogAddNewTags } from './views/admin/admin-tags/admin-tags.component';
import { NewTiccTypeComponent } from './views/admin/ticc-types/new-ticc-type/new-ticc-type.component';
import { SitesCreationComponent } from './views/sites/sites-creation/sites-creation.component';
import { SitesDetailComponent } from './views/sites/sites-detail/sites-detail.component';
import { DialogRenameCompanyConfirmation, SitesEditComponent } from './views/sites/sites-edit/sites-edit.component';
import { DialogRecipientsSelector, TiccsCreateComponent } from './views/ticcs/ticcs-create/ticcs-create.component';
import { TiccsDetailComponent } from './views/ticcs/ticcs-detail/ticcs-detail.component';
import { TiccsEditComponent } from './views/ticcs/ticcs-edit/ticcs-edit.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AssetCreateComponent } from './views/assets/asset-create/asset-create.component';
import { UserDetailComponent } from './views/users/user-detail/user-detail.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './views/new-password/new-password.component';
import { DatePipe } from '@angular/common';
import { BottomDialogSelector, DialogAddNewUsersBulk, DialogDisassociateUserCompany, DialogInviteToSite, DialogRemoveUserFromSite, DialogRemoveUsersFromSiteBulk, DialogUserDeactivate, UsersComponent } from './views/users/users.component';
import { MatBottomSheet, MatBottomSheetModule, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserEditComponent } from './views/users/user-detail/user-edit/user-edit.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DialogTiccDelete, TiccsListComponent } from './views/ticcs/ticcs-list/ticcs-list.component';
import { BreadcrumbComponent } from './services/helpers/breadcrumb/breadcrumb.service';
import { CheckDetailComponent, DialogCheckDelete } from './views/ticcs/check-detail/check-detail.component';
import { CheckCreateComponent } from './views/ticcs/check-create/check-create.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannerComponent } from './views/scanner/scanner.component';
import { AssetsDetailComponent } from './views/assets/assets-detail/assets-detail.component';
import { AssetCloneComponent } from './views/assets/asset-clone/asset-clone.component';
import { BoardCreateComponent } from './views/boards/board-create/board-create.component';
import { TagAssociationComponent, TagAssociationForAssetComponent, TagAssociationForBoardComponent, TagAssociationForSiteComponent, TagAssociationForUserComponent } from './views/tags/tag-association/tag-association.component';
import { InviteToSiteComponent } from './views/users/invite-to-site/invite-to-site.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { AssetEditComponent } from './views/assets/asset-edit/asset-edit.component';
import { QRComponent } from './views/qr/qr.component';
import { CircuitCreateComponent } from './views/boards/circuits/circuit-create/circuit-create.component';
import { BoardDetailComponent } from './views/boards/board-detail/board-detail.component';
import { CircuitEditComponent } from './views/boards/circuits/circuit-edit/circuit-edit.component';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { BoardEditComponent } from './views/boards/board-edit/board-edit.component';
import { AssetTypeEditComponent } from './views/admin/asset-types/asset-type-edit/asset-type-edit.component';
import { DialogAsignToCompanyComponent } from './views/dialogs/dialog-asign-to-company/dialog-asign-to-company.component';
import { AssetTypeCreateComponent } from './views/admin/asset-types/asset-type-create/asset-type-create.component';
import { DialogCircuitDeleteConfirmation } from './views/boards/circuits/dialog-circuit-delete-confirmation/dialog-circuit-delete-confirmation';
import { DialogRemoveTiccType } from './views/admin/ticc-types/dialog-remove-ticc-type/dialog-remove-ticc-type';
import { ExceptionHandlingService } from './services/logging/exception-handling.service';
import { OrderByPipe } from './pipes/order-by.pipe';



export function tokenGetter() {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,

    DashboardComponent,
    SitesComponent,
    SitesDetailComponent,
    SitesCreationComponent,
    SitesEditComponent,

    BoardsComponent,
    BoardDetailComponent,
    BoardEditComponent,

    CircuitEditComponent,

    BoardCreateComponent,


    CircuitCreateComponent,

    AssetsComponent,
    AssetCreateComponent,
    AssetsDetailComponent,
    AssetEditComponent,
    AssetCloneComponent,

    ModulesComponent,
    ReportsComponent,
    MyFilesComponent,

    ModulesCarrouselComponent,
    MenuDesktopComponent,
    MenuMobileComponent,
    SidenavComponent,

    ModalDashboardComponent,

    TiccsDetailComponent,
    TiccsCreateComponent,
    TiccsEditComponent,
    CheckDetailComponent,
    CheckCreateComponent,


    IsAdministratorDirective,

    AdminMenuDesktopComponent,
    AdminMenuMobileComponent,
    AdminSidenavComponent,
    AdminModulesCarrouselComponent,


    AdminSitesManagementComponent,
    AdminUserManagementComponent,

    AssetTypesComponent,
    AssetTypeEditComponent,

    AssetTypeCreateComponent,

    TiccTypesComponent,
    AdminModulesComponent,
    AdminTagsComponent,
    DialogAddNewTags,

    UsersComponent,
    UserDetailComponent,
    UserEditComponent,

    NewTiccTypeComponent,

    DialogDeleteConfirmation,
    DialogSiteDelete,
    DialogUserDelete,
    DialogCompanySelector,
    DialogSystemRole,
    DialogUserDeactivate,
    DialogRenameCompanyConfirmation,
    DialogRemoveUserFromSite,
    DialogRemoveUsersFromSiteBulk,
    DialogInviteToSite,
    DialogDisassociateUserCompany,
    DialogDisassociateSiteCompany,
    DialogAddNewUsersBulk,
    DialogRecipientsSelector,
    DialogTiccDelete,
    DialogCheckDelete,
    DialogBoardDelete,
    DialogDeleteAssetTypeCompanyConfirmation,
    DialogCircuitDeleteConfirmation,
    DialogRemoveTiccType,
    DialogChangePassword,

    BottomDialogSelector,
    BottomSitesDialogSelector,
    TiccsListComponent,
    BreadcrumbComponent,
    TagAssociationComponent,
    TagAssociationForSiteComponent,
    TagAssociationForUserComponent,
    TagAssociationForAssetComponent,
    TagAssociationForBoardComponent,
    ScannerComponent,
    InviteToSiteComponent,
    QRComponent,
    DialogAsignToCompanyComponent,

    OrderByPipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    SwiperModule,
    FormsModule,
    CommonModule,
    MatDialogModule,
    MatBottomSheetModule,
    ScrollingModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ProgressBarModule,
    SliderModule,
    TagModule,
    ZXingScannerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.allowedDomain],
      },
    }),
  ],

  providers: [
    DatePipe,
    AsyncPipe,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
       multi: true
    },
    {
      provide: ErrorHandler, useClass: ExceptionHandlingService
    }
    /* { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} } */
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
