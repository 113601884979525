import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormBuilder, UntypedFormGroup, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, filter, mergeMap, Observable, tap, of } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PageInfoOfUsersAdminViewModel, SystemRoles, UsersAdminViewModel } from 'src/app/models/backend-generated-models';
import { IExtractedUserFromJWT, IUser } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/api/admin.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';


export interface temp {
  name: string,
  id: number
}

@Component({
  selector: 'app-admin-user-management',
  templateUrl: './admin-user-management.component.html',
  styleUrls: ['./admin-user-management.component.scss']
})
export class AdminUserManagementComponent implements OnInit {

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  search = "";

  displayedColumns: string[] = ['id', 'firstname', 'lastname', 'email', 'reg_date'];
  dataSource: MatTableDataSource<UsersAdminViewModel> | undefined;

  obs!: Observable<any>;

  searchControl = new UntypedFormControl();
  allUsers?: UsersAdminViewModel[];

  isMobile = this.appcomponent.isMobile

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private adminService: AdminService,
    private appcomponent: AppComponent,

    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.refreshUsers();
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.refreshUsers();
  }

  openDeleteDialog(user: UsersAdminViewModel) {
    this.dialog.open(DialogUserDelete, {
      width: '800px',
      data: user,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((userUUID: string) => {
          return this.adminService.deleteUser(userUUID)
        })
      ).subscribe(
        (res: any) => {
          this.obs = this.obs.pipe(map(e=> e.filter((e: any) => e.userUUID !== res.userUUID)));
        }
      )
  }

  openChangePasswordDialog(user: UsersAdminViewModel) {
    this.dialog.open(DialogChangePassword, {
      width: '800px',
      data: user,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((data) => {
          if (!data) return of(null);

          return this.adminService.changeUserPassword(user.userUUID, data);
        })
      ).subscribe(
        (res: any) => {
          this.obs = this.obs.pipe(map(e=> e.map((e: any) => {
            if (res != null && e.userUUID === res.uuid) {
              e.userEmail = res.email;
            }
            return e;
          })));
        }
      )
  }

  refreshUsers() {
    this.adminService.getPagedUsers(this.currentPage, this.search).subscribe((res) => {

      this.dataSource = new MatTableDataSource<UsersAdminViewModel>(res.usersAdminViewModel);

      this.allUsers = res.usersAdminViewModel;

      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.pageInfo?.totalCount;
      });

      this.changeDetectorRef.detectChanges();
      this.obs = this.dataSource.connect();

      this.obs.subscribe((res) => {
        console.log(res)
      })

    })
  }

  enableOrDisableUser(userUUID: string, action: number) {
    this.adminService.enableOrDisableUser(userUUID, action).subscribe((res) => {
      console.log("res", res);
      this.refreshUsers();
    })
  }

  changeSystemRole(selectedRole: string, currentRole: string, user: UsersAdminViewModel) {


    if (selectedRole === currentRole) return
    if (user.userEmail === "admin@admin.com") return


    console.log("mail", user.userEmail)
    console.log("this selected role", selectedRole)
    console.log("this current role", currentRole)
    console.log("equal", selectedRole === "administrator")


    // Are you sure you want to ...
    if (selectedRole === "administrator") {
      this.dialog.open(DialogSystemRole, {
        width: '800px',
        data: {
          role: 'administrator',
          user: user
        },
        panelClass: 'dialog-box'
      })
          .afterClosed()
          .subscribe((res: boolean) => {
            console.log("res", user.userUUID)
            if (res && user.userUUID) this.adminService.changeSystemRole(user.userUUID, { roleId: SystemRoles.Administrator }).subscribe((res) => {
              console.log(res);
              this.refreshUsers();
            });
          })
    }

    // No persmission to downgrade admin
    /* else if (currentRole === "administrator" && (selectedRole === "user" || selectedRole === "company manager")) {
      this.dialog.open(DialogSystemRole, {
        width: '800px',
        data: {
          role: 'administrator-downgrade',
        },
        panelClass: 'dialog-box'
      })
    } */

    // Upgrade role
    else if (selectedRole === "company manager" && (currentRole === "user" || currentRole === "administrator" || !currentRole)) {

      // This dialog should open to select company for this Company Manager

      /* this.dialog.open(DialogCompanySelector, {
        width: '800px',
      },)
      .afterClosed() */

      this.dialog.open(DialogCompanySelector, {
        width: '800px',
        data: user.userUUID,
        /* data: {
          role: 'company manager'
        }, */
        panelClass: 'dialog-box'
      })
        .afterClosed()
        .subscribe((res: boolean) => {
          /* console.log("res", user.userUUID)
          if (res && user.userUUID) this.adminService.changeSystemRole(user.userUUID, {  role: 3  }).subscribe((res) => {
            console.log(res)
            this.refreshUsers();
          }); */
          this.refreshUsers();
        })
    }

    // Downgrade role
    else if (selectedRole === "user" && (currentRole === "company manager" || currentRole === "administrator" || !currentRole)) {
      this.dialog.open(DialogSystemRole, {
        width: '800px',
        data: {
          role: currentRole + "-downgrade"
        },
        panelClass: 'dialog-box'
      })
        .afterClosed()
        .subscribe((res: boolean) => {
          console.log("res", user.userUUID)
          if (res && user.userUUID) this.adminService.changeSystemRole(user.userUUID, { roleId: SystemRoles.User }).subscribe((res) => {
            console.log(res)
            this.refreshUsers();
          });
        })
    }
    /* else if (selectedRole === "company manager" && currentRole === "administrator") {
      this.dialog.open(DialogSystemRole, {
        width: '800px',
        data: {
          role: 'user'
        },
        panelClass: 'dialog-box'
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        console.log("res", user.userUUID)
        if (res && user.userUUID) this.adminService.changeSystemRole(user.userUUID, {role: 3}).subscribe((res) => {
          console.log(res)
          this.refreshUsers();
        });
      })
    }  */

  }

  searchUser() {
    this.search = this.searchControl.value;
    this.currentPage = 0;
    this.refreshUsers();
  }
}



@Component({
  selector: 'dialog-system-role',
  templateUrl: 'dialog-system-role.html',
  styleUrls: ['./admin-user-management.component.scss']

})
export class DialogSystemRole {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogSystemRole>
  ) { }
}
@Component({
  selector: 'dialog-user-delete',
  templateUrl: 'dialog-user-delete.html',
  styleUrls: ['./admin-user-management.component.scss']

})
export class DialogUserDelete {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogUserDelete>
  ) { }
}
@Component({
  selector: 'dialog-company-selector',
  templateUrl: 'dialog-company-selector.html',
  styleUrls: ['./admin-user-management.component.scss']

})
export class DialogCompanySelector {

  switchCompany: boolean = true;

  isUserAdmin$ = this.userStateService.isUserAdmin;

  parentCompaniesList$ = this.adminService.getParentCompaniesList();

  formParentCompany = this.fb.group({
    companyName: this.fb.control('', Validators.required)
  })


  constructor(
    @Inject(MAT_DIALOG_DATA) private userUUID: string,

    private sitesService: SitesService,
    private adminService: AdminService,
    private userStateService: UserStateService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogCompanySelector>
  ) { }

  submit() {
    if (!this.formParentCompany.valid) return;

    if (this.switchCompany == false) {

      this.adminService.createParentCompany(this.formParentCompany.value).subscribe((res) => {


        this.adminService.changeSystemRole(
          this.userUUID,
          {
            roleId: SystemRoles.CompanyManager/* CompanyManager ID */,
            metadata: {CompanyId: res}
          }
        ).subscribe(() => {
          this.dialogRef.close()
        })
      })

    } else {

      this.adminService.changeSystemRole(
        this.userUUID,
        {
          roleId: SystemRoles.CompanyManager/* CompanyManager ID */,
          metadata: {CompanyId: this.formParentCompany.get('companyName')?.value}
        }
      ).subscribe(() => {
        this.dialogRef.close()
      })

    }
  }

}

@Component({
  selector: 'dialog-change-password',
  templateUrl: 'dialog-change-password.html',
  styleUrls: ['./admin-user-management.component.scss']
})
export class DialogChangePassword {

  regexPassword: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  formResetPassword = this.fb.group({
    email: this.fb.control('', [Validators.required, Validators.email]),
    newPassword: this.fb.control('', [Validators.required, Validators.pattern(this.regexPassword)]),
    newConfirmPassword: this.fb.control('', [Validators.required, Validators.pattern(this.regexPassword)]),

  }, {
    validators: [
      this.matchValidator("newPassword", "newConfirmPassword")/*  */
    ]
  },)

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogChangePassword>
  ) { }

  ngOnInit(): void {
    this.formResetPassword.get('email')?.setValue(this.data.userEmail);
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSubmit() {
    if (!this.formResetPassword.valid) return;

    this.dialogRef.close(this.formResetPassword.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private matchValidator(source: string, target: string): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;

    };
  }
}
