<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="page-top-breadcrumbs">
    TAG MANAGER
  </div>

<div class="create-user-button-container d-flex justify-content-end">
    <!-- (click)="" -->
    <button (click)="openAddTags()" mat-button class="create-user-button d-flex"><mat-icon>add_circle</mat-icon>
        <div class="d-none d-md-block">&nbsp;&nbsp;NEW BATCH</div>
    </button>
</div>

<h1 class="page-top-title">
    TAG Management
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->



<mat-drawer-container class="sidenav-general-container" autosize>

    <div class="container-fluid">
        <mat-accordion class="main-card ticc-types-accordion">
            <div *ngFor="let company of tags$; first as isFirst">

                <mat-expansion-panel hideToggle class="mt-3" [expanded]="isFirst" (opened)="getQRCodeByArray(company[1], company[0])">

                    <mat-expansion-panel-header class="d-flex p-0">
                        <div class="p-2 expansion-header-center-title">
                            <p style="display:inline; font-size: 21px;">Batch #{{company[0]}} </p><p style="display:inline; font-size: 16px;">count:{{company[1].length}}</p>
                        </div>

                        <div class="d-flex">
                            <mat-icon class="p-2 icon-40" (click)="downloadBatchCSV(company)">download</mat-icon>
                            <mat-icon class="p-2 icon-40">expand_more</mat-icon>
                        </div>
                    </mat-expansion-panel-header>

                    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="row p-0 m-0 content">

                        <div class="col-12 d-flex justify-content-center">
                            <mat-spinner *ngIf="isLoading$ | async"></mat-spinner>
                        </div>
                        
                        <div [class.not-display] = "(isLoading$ | async)" [class.display] = "!(isLoading$ | async)">
                            <div class="row card-title detail-panel-title">
                                <div class="pt-2 pb-2 col-md-1">TAG</div>
                                <div class="row col-md-11">
                                    <div class="row col-md-4 align-items-center">
                                        <div class="pt-2 pb-2 col-12">UUID: </div>
                                    </div>
                                    <div class="row col-md-3 align-items-center">
                                        <div class="pt-2 pb-2 col-12">Attached to: </div>
                                    </div>
                                    <div class="row col-md-5 align-items-center">
                                        <div class="pt-2 pb-2 col-12">Attached id: </div>
                                    </div>
                                </div>
                            </div>

                            <div *cdkVirtualFor="let tag of company[1]; let i = index" class="col-12 detail-panel">

                                <div class="row">
                                    <div class="col-3 col-md-1 delete-margin">
                                        <img [routerLink]="['/qr/' + tag.tagsModel?.tagsUUID]" [queryParams]="{scanner:  false }" [src]="getQRCodeImage(tag.tagsModel?.tagsUUID)" class="w-100 img-fluid rounded-top" alt="">                               
                                    </div>
                                    <div class="row col-9 col-md-11 align-items-center">
                                        <div class="row col-12 col-md-4 align-items-center">
                                            <div class="col-4 col-md-0 delete-margin align-self-start tag-title">UUID: </div>
                                            <div class="col-8 col-md-12 delete-margin tag-text">{{tag.tagsModel?.tagsUUID}}</div>
                                        </div>
                                        <div class="row col-12 col-md-3 align-items-center">
                                            <div class="col-4 col-md-0 delete-margin align-self-start tag-title">Attached to: </div>
                                            <div class="col-8 col-md-12 delete-margin tag-text">{{tag.tagsModel?.tagAssociatedType}}</div>
                                        </div>
                                        <div class="row col-12 col-md-5 align-items-center">
                                            <div class="col-4 col-md-0 delete-margin align-self-start tag-title">Attached id: </div>
                                            <div [routerLink]="['/' + getAttachmentRoute(tag.tagsModel?.tagAssociatedType) + '/' + tag.tagsModel?.tagAssociatedUUID]" class="col-8 col-md-12 delete-margin attached-id">{{tag.tagsModel?.tagAssociatedUUID}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </cdk-virtual-scroll-viewport>
                </mat-expansion-panel>
            </div>
        </mat-accordion>

    </div>
</mat-drawer-container>