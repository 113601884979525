<div>
  <mat-tab-group mat-align-tabs="start">

    <mat-tab label="Site TICCs">
      <p-table #dt1 [value]="siteTableItems" [columns]="exportColumnsSite" dataKey="Id" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['siteName', 'ticcName', 'checkedBy']">
        <ng-template pTemplate="caption">
          <div class="flex" style="display: flex; justify-content: right;">
            <mat-button-toggle type="button" pButton pRipple icon="pi pi-file" (click)="dt1.exportCSV()" class="mr-2"
              pTooltip="CSV" tooltipPosition="bottom">Export
              CSV</mat-button-toggle>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Site name
                <p-columnFilter type="text" field="siteName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                TICC name
                <p-columnFilter type="text" field="ticcName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Deadline
                <p-columnFilter type="date" field="checkDeadline" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked At
                <p-columnFilter type="date" field="checkedAt" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked By
                <p-columnFilter type="text" field="checkedBy" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Notes
                <p-columnFilter type="text" field="checkNotes" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Status
                <p-columnFilter type="text" field="checkStatus" display="menu"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              {{ item.siteName }}
            </td>
            <td>
              {{ item.ticcName }}
            </td>
            <td>
              {{ item.checkDeadline | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedAt | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedBy }}
            </td>
            <td>
              {{ item.checkNotes }}
            </td>
            <td>
              <span *ngIf="item.checkStatus == 'Compliant'">
                <mat-icon class="color-green">check_circle</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'NonCompliant'">
                <mat-icon class="color-red">error</mat-icon>
                Non Compliant
              </span>
              <span *ngIf="item.checkStatus == 'Missed'">
                <mat-icon class="color-grey">cancel</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'Pending'">
                <mat-icon class="color-blue">help</mat-icon>
                {{ item.checkStatus }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No TICC events found.</td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>

    <mat-tab label="UsersTICCs">
      <p-table #dt2 [value]="usersTableItems" [columns]="exportColumnsUsers" dataKey="Id" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['siteName', 'ticcName', 'checkedBy']">
        <ng-template pTemplate="caption">
          <div class="flex" style="display: flex; justify-content: right;">
            <mat-button-toggle type="button" pButton pRipple icon="pi pi-file" (click)="dt2.exportCSV()" class="mr-2"
              pTooltip="CSV" tooltipPosition="bottom">Export
              CSV</mat-button-toggle>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Site name
                <p-columnFilter type="text" field="siteName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                User name
                <p-columnFilter type="text" field="userName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                TICC name
                <p-columnFilter type="text" field="ticcName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Deadline
                <p-columnFilter type="date" field="checkDeadline" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked At
                <p-columnFilter type="date" field="checkedAt" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked By
                <p-columnFilter type="text" field="checkedBy" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Notes
                <p-columnFilter type="text" field="checkNotes" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Status
                <p-columnFilter type="text" field="checkStatus" display="menu"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              {{ item.siteName }}
            </td>
            <td>
              {{ item.userName }}
            </td>
            <td>
              {{ item.ticcName }}
            </td>
            <td>
              {{ item.checkDeadline | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedAt | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedBy }}
            </td>
            <td>
              {{ item.checkNotes }}
            </td>
            <td>
              <span *ngIf="item.checkStatus == 'Compliant'">
                <mat-icon class="color-green">check_circle</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'NonCompliant'">
                <mat-icon class="color-red">error</mat-icon>
                Non Compliant
              </span>
              <span *ngIf="item.checkStatus == 'Missed'">
                <mat-icon class="color-grey">cancel</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'Pending'">
                <mat-icon class="color-blue">help</mat-icon>
                {{ item.checkStatus }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No TICC events found.</td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>

    <mat-tab label="Asset TICCs">
      <p-table #dt3 [value]="assetsTableItems" [columns]="exportColumnsAssets" dataKey="Id" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['siteName', 'ticcName', 'checkedBy']">
        <ng-template pTemplate="caption">
          <div class="flex" style="display: flex; justify-content: right;">
            <mat-button-toggle type="button" pButton pRipple icon="pi pi-file" (click)="dt3.exportCSV()" class="mr-2"
              pTooltip="CSV" tooltipPosition="bottom">Export
              CSV</mat-button-toggle>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Site name
                <p-columnFilter type="text" field="siteName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Asset name
                <p-columnFilter type="text" field="assetName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Asset type
                <p-columnFilter type="text" field="assetType" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                TICC name
                <p-columnFilter type="text" field="ticcName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Deadline
                <p-columnFilter type="date" field="checkDeadline" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked At
                <p-columnFilter type="date" field="checkedAt" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked By
                <p-columnFilter type="text" field="checkedBy" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Notes
                <p-columnFilter type="text" field="checkNotes" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Status
                <p-columnFilter type="text" field="checkStatus" display="menu"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              {{ item.siteName }}
            </td>
            <td>
              {{ item.assetName }}
            </td>
            <td>
              {{ item.assetType }}
            </td>
            <td>
              {{ item.ticcName }}
            </td>
            <td>
              {{ item.checkDeadline | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedAt | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedBy }}
            </td>
            <td>
              {{ item.checkNotes }}
            </td>
            <td>
              <span *ngIf="item.checkStatus == 'Compliant'">
                <mat-icon class="color-green">check_circle</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'NonCompliant'">
                <mat-icon class="color-red">error</mat-icon>
                Non Compliant
              </span>
              <span *ngIf="item.checkStatus == 'Missed'">
                <mat-icon class="color-grey">cancel</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'Pending'">
                <mat-icon class="color-blue">help</mat-icon>
                {{ item.checkStatus }}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>

    <mat-tab label="Boards TICCs">
      <p-table #dt4 [value]="boardsTableItems" [columns]="exportColumnsBoard" dataKey="Id" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['siteName', 'ticcName', 'checkedBy']">
        <ng-template pTemplate="caption">
          <div class="flex" style="display: flex; justify-content: right;">
            <mat-button-toggle type="button" pButton pRipple icon="pi pi-file" (click)="dt4.exportCSV()" class="mr-2"
              pTooltip="CSV" tooltipPosition="bottom">Export
              CSV</mat-button-toggle>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Site name
                <p-columnFilter type="text" field="siteName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                Board name
                <p-columnFilter type="text" field="boardName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem">
              <div class="flex align-items-center">
                TICC name
                <p-columnFilter type="text" field="ticcName" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Deadline
                <p-columnFilter type="date" field="checkDeadline" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked At
                <p-columnFilter type="date" field="checkedAt" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Checked By
                <p-columnFilter type="text" field="checkedBy" display="menu"></p-columnFilter>
              </div>
            </th>

            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Notes
                <p-columnFilter type="text" field="checkNotes" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem">
              <div class="flex align-items-center">
                Check Status
                <p-columnFilter type="text" field="checkStatus" display="menu"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              {{ item.siteName }}
            </td>
            <td>
              {{ item.boardName }}
            </td>
            <td>
              {{ item.ticcName }}
            </td>
            <td>
              {{ item.checkDeadline | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedAt | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ item.checkedBy }}
            </td>
            <td>
              {{ item.checkNotes }}
            </td>
            <td>
              <span *ngIf="item.checkStatus == 'Compliant'">
                <mat-icon class="color-green">check_circle</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'NonCompliant'">
                <mat-icon class="color-red">error</mat-icon>
                Non Compliant
              </span>
              <span *ngIf="item.checkStatus == 'Missed'">
                <mat-icon class="color-grey">cancel</mat-icon>
                {{ item.checkStatus }}
              </span>
              <span *ngIf="item.checkStatus == 'Pending'">
                <mat-icon class="color-blue">help</mat-icon>
                {{ item.checkStatus }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No TICC events found.</td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>

  </mat-tab-group>
</div>