import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyFilesService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getOrCreateSiteFolderSharedUrl(siteId: string) {
    return this.http
    .get<any>(`${environment.apiUrl}OneDrive/get-or-create-site-folder-shared-url/${siteId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }

  getToken() {
    return this.http
    .get<any>(`${environment.apiUrl}OneDrive/token`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }

  getPickerData() {
    return this.http
    .get<any>(`${environment.apiUrl}OneDrive/get-data`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }
}