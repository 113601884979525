import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrService {
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  scanQRCode(tagUuid: string | undefined) {
    return this.http
    .get(`${environment.apiUrl}qr/${tagUuid}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }
}
