<swiper [config]="swiperConfiguration" class="mySwiper">

    <ng-template swiperSlide *ngFor="let item of (modulesList$ | async)">

        <button mat-button class="mx-3 modules-button d-flex justify-content-center">

            <div class="row pt-2">
                <div class="col-12 d-flex justify-content-center">
                    <div class="circle">
                        <mat-icon class="circle-icon" mat-icon>
                            {{checkIcon(item.name) || "image"}}
                        </mat-icon>
                    </div>
                </div>

                <div class="col-12">
                    <mat-divider inset></mat-divider>
                </div>

                <div class="col-12">
                    <button mat-button class="slider-element-text">{{item.name}}</button>
                </div>
            </div>

        </button>
        
    </ng-template>

</swiper>