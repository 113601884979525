import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { BoardDetails, BoardEditModel, BoardsAndFilters, CircuitResumeModel, CreateBoardModel, CreateCircuitModel, TagResponseModel, TiccViewModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BoardsService {
  errorHandler: any;

  constructor(
    private http: HttpClient
  ) { }


  createCircuit(boardUuid: string, body: CreateCircuitModel) {
    return this.http
      .post(`${environment.apiUrl}Boards/${boardUuid}/circuits`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  createBoard(body: CreateBoardModel) {
    return this.http
      .post(`${environment.apiUrl}Boards`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  editBoard(boardUuid: string, body: BoardEditModel) {
    return this.http
      .put(`${environment.apiUrl}Boards/${boardUuid}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getBoardsList(params?: HttpParams) {
    params = params?.append("IncludeNullCompanies", false).append("Order", "desc")

    return this.http
    .get<BoardsAndFilters>(`${environment.apiUrl}Boards`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getBoard(boardUUID: string | null) {
    return this.http
    .get<BoardDetails>(`${environment.apiUrl}Boards/${boardUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getCircuits(boardUUID: string | null) {
    return this.http
    .get<CircuitResumeModel[]>(`${environment.apiUrl}Boards/${boardUUID}/circuits`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getTiccs(boardUUID: string | null) {
    return this.http
    .get<TiccViewModel[]>(`${environment.apiUrl}Boards/${boardUUID}/ticcs`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getBoardTagInformation(boardUUID: string | null) {
    return this.http
    .get<TagResponseModel>(`${environment.apiUrl}Boards/${boardUUID}/tag`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getCurrentTagImage(param: string | undefined) {
    console.log("param", `${environment.apiUrl}Tags/${param}/qr`)
    return this.http
      .get(`${environment.apiUrl}Tags/${param}/qr`, { responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  deleteTagAssociation(param: string /* tagUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Tags/${param}/association`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  deleteCircuit(boardUUID: string /* boardUUID */, circuitUUID: string /* circuitUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Boards/${boardUUID}/circuits/${circuitUUID}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  deleteBoard(boardUUID: string /* boardUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Boards/${boardUUID}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }
}


