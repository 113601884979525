import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { AuthenticatedResponse } from '../models/backend-generated-models';
import { IExtractedUserFromJWT } from '../models/user.model';
import { AuthService } from '../services/api/auth.service';
import { UserStateService } from '../services/state/user-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private userStateService: UserStateService,
    private authService: AuthService) { }

  async canActivate() {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.userStateService.currentUserSource.next(this.jwtHelper.decodeToken(token) as IExtractedUserFromJWT);

      return true;
    }

    const isRefreshSuccess = await this.tryRefreshingTokens(token);

    if (!isRefreshSuccess) {
      this.router.navigate(["login"]);
    }

    if (isRefreshSuccess) {
      window.location.href = window.location.href;
    }

    return false;
  }

  public async tryRefreshingTokens(token: string | null): Promise<boolean> {
    const refreshToken = localStorage.getItem("refreshToken") as string;

    if (!token || !refreshToken) {
      return false;
    }

    var credentials: AuthenticatedResponse = {
      refreshToken: refreshToken,
      token: token
    };

    let isRefreshSuccess: boolean;
    const refreshRes = await new Promise<AuthenticatedResponse>((resolve, reject) => {
      this.authService.refreshingTokens(credentials).subscribe({
        next: (res: AuthenticatedResponse) => resolve(res),
        error: (_) => { reject; isRefreshSuccess = false; }
      });
    });

    localStorage.setItem("jwt", refreshRes.token);
    localStorage.setItem("refreshToken", refreshRes.refreshToken);
    isRefreshSuccess = true;

    return isRefreshSuccess;
  }
}
