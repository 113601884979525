import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetSelectedSiteModel, SiteUserModel } from 'src/app/models/backend-generated-models';
import { AssetsService } from 'src/app/services/api/assets.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { TagsService } from 'src/app/services/api/tags.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
  selector: 'app-tag-association',
  templateUrl: './tag-association.component.html',
  styleUrls: ['./tag-association.component.scss']
})
export class TagAssociationComponent {

  selected: string | undefined;
  isAsset: boolean = false;
  isBoard: boolean = false;
  tagUUID = this.route.snapshot.paramMap.get('tagUUID');
  radionSelect: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  choiseAssociation(selected: string | undefined) {
    this.selected = selected;
  }

  submit() {
    switch (this.selected) {
      case 'site': this.router.navigate(['tags/' + this.tagUUID + '/association/site']);
        break;
      case 'asset': if (this.radionSelect == 'create') this.router.navigate(['assets/create'], { queryParams: { associationTo: this.tagUUID } });
                    else this.router.navigate(['tags/' + this.tagUUID + '/association/asset']);
        break;
      case 'board': if (this.radionSelect == 'create') this.router.navigate(['boards/create'], { queryParams: { associationTo: this.tagUUID } });
                    else this.router.navigate(['tags/' + this.tagUUID + '/association/board']);
        break;
      case 'user': this.router.navigate(['tags/' + this.tagUUID + '/association/user']);
        break;
    }
  }
}


@Component({
  selector: 'tag-association-site',
  templateUrl: 'tag-association-site.html',
  styleUrls: ['./tag-association.component.scss']
})
export class TagAssociationForSiteComponent implements OnInit {

  tagUUID = this.route.snapshot.paramMap.get('tagUUID');
  sites: any[] | undefined;
  selectedSite: any;
  constructor(
    private route: ActivatedRoute,
    private userStateService: UserStateService,
    private userService: UserService,
    private tagsService: TagsService,
    private router: Router) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userService.getUserSites({ userUuids: [res?.userUUId as string] }).subscribe(res => {
        this.sites = res.userUUID.flatMap((val: any) => val.userSites.map((site: any) => ({ ...site })));
      });
    });
  }

  submit() {
    this.tagsService.setAssociationForTag(this.tagUUID, { tagableType: 'App\\Models\\Site', tagableUuid: this.selectedSite }).subscribe(() =>
      this.router.navigate(['/sites', this.selectedSite])
    )
  }
}

@Component({
  selector: 'tag-association-user',
  templateUrl: 'tag-association-user.html',
  styleUrls: ['./tag-association.component.scss']
})
export class TagAssociationForUserComponent implements OnInit {

  tagUUID = this.route.snapshot.paramMap.get('tagUUID');
  sites: any[] | undefined;
  users: SiteUserModel[] | undefined;
  selectedSite: any;
  selectedUser: any;

  constructor(
    private route: ActivatedRoute,
    private userStateService: UserStateService,
    private userService: UserService,
    private tagsService: TagsService,
    private sitesService: SitesService,
    private router: Router) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userService.getUserSites({ userUuids: [res?.userUUId as string] }).subscribe(res => {
        this.sites = res.userUUID.flatMap((val: any) => val.userSites.map((site: any) => ({ ...site })));
      });
    });
  }

  getUsersForSelectSite(siteUUId: string | undefined) {
    this.sitesService.getSiteUsersList(siteUUId).subscribe(res => {
      this.users = res;
    });
  }

  submit() {
    this.tagsService.setAssociationForTag(this.tagUUID, { tagableType: 'App\\Models\\Auth\\User', tagableUuid: this.selectedUser }).subscribe(() =>
      this.router.navigate(['/users', this.selectedUser]));
  }
}

@Component({
  selector: 'tag-association-asset',
  templateUrl: 'tag-association-asset.html',
  styleUrls: ['./tag-association.component.scss']
})
export class TagAssociationForAssetComponent implements OnInit {

  tagUUID = this.route.snapshot.paramMap.get('tagUUID');
  sites: any[] | undefined;
  assets: AssetSelectedSiteModel[] | undefined;
  selectedSite: any;
  selectedAsset: any;

  constructor(
    private route: ActivatedRoute,
    private userStateService: UserStateService,
    private userService: UserService,
    private tagsService: TagsService,
    private assetsService: AssetsService,
    private router: Router) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userService.getUserSites({ userUuids: [res?.userUUId as string] }).subscribe(res => {
        this.sites = res.userUUID.flatMap((val: any) => val.userSites.map((site: any) => ({ ...site })));
      });
    });
  }

  getAssetsForSelectSite(siteUUId: string | undefined) {
    this.assetsService.getAssetForSiteList(siteUUId).subscribe(res => {
      this.assets = res;
    });
  }

  submit() {
    this.tagsService.setAssociationForTag(this.tagUUID, { tagableType: 'App\\Models\\Asset', tagableUuid: this.selectedAsset, assetCloneEnabled: true }).subscribe(() =>
      this.router.navigate(['/assets', this.selectedAsset]));
  }
}

@Component({
  selector: 'tag-association-board',
  templateUrl: 'tag-association-board.html',
  styleUrls: ['./tag-association.component.scss']
})
export class TagAssociationForBoardComponent implements OnInit {

  tagUUID = this.route.snapshot.paramMap.get('tagUUID');
  sites: any[] | undefined;
  boards: AssetSelectedSiteModel[] | undefined;
  selectedSite: any;
  selectedBoard: any;

  constructor(
    private route: ActivatedRoute,
    private userStateService: UserStateService,
    private userService: UserService,
    private tagsService: TagsService,
    private assetsService: AssetsService,
    private router: Router) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userService.getUserSites({ userUuids: [res?.userUUId as string] }).subscribe(res => {
        this.sites = res.userUUID.flatMap((val: any) => val.userSites.map((site: any) => ({ ...site })));
      });
    });
  }

  getBoardsForSelectSite(siteUUId: string | undefined) {
    this.assetsService.getAssetForSiteList(siteUUId).subscribe(res => {
      this.boards = res;
    });
  }

  submit() {
    this.tagsService.setAssociationForTag(this.tagUUID, { tagableType: 'App\\Models\\Board', tagableUuid: this.selectedBoard }).subscribe(() =>
      this.router.navigate(['/boards', this.selectedBoard]));
  }
}
