import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { CompaniesViewModel, CreateCompanyRequest } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  errorHandler: any;

  constructor(
    private http: HttpClient
  ) { }


  getCompaniesList() {
    return this.http
    .get<CompaniesViewModel[]>(`${environment.apiUrl}Companies`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }

  createCompany(body: CreateCompanyRequest) {
    return this.http
    .post(`${environment.apiUrl}Companies`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
}