import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { concatMap, map, Subject } from 'rxjs';
import { IExtractedUserFromJWT, IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/api/auth.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
	selector: 'app-menu-desktop',
	templateUrl: './menu-desktop.component.html',
	styleUrls: ['./menu-desktop.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDesktopComponent implements OnInit{
	areModulesDisplayed: boolean = false;
	newUserName = new Subject<string>();

	currentSite$ = this.siteStateService.currentUserSite$;

	currentUserData: IUser | null | undefined;

	currenUserRole$ = this.userStateService.currentUser$.pipe(map(x => x?.role))

	constructor(
		private siteStateService: SiteStateService,
		private sitesService: SitesService,
		private auth: AuthService,
		private userStateService: UserStateService,
		private jwtHelper: JwtHelperService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.userStateService.currentUser$.subscribe(
			res => this.currentUserData = res
		)
		this.userService.getUserName().subscribe(name => {
			this.newUserName.next(name);
		  });
		this.checkSiteRoles();
	}

	checkSiteRoles() {
		const token = localStorage.getItem('jwt')

		if (!token || this.jwtHelper.isTokenExpired(token)) return false
		
		let decodedToken = this.jwtHelper.decodeToken(token) as IExtractedUserFromJWT
		
		if (decodedToken.SiteRoles.includes("Site Owner") || decodedToken.SiteRoles.includes("Site Manager")) {
			return true;
		}

		return false;
	}

	changeSite(site: { id: number }) {

		this.sitesService.setCurrentSite(site.id).pipe(
			concatMap(() => {
				return this.sitesService.getCurrentSite()
			})
		).subscribe((res) => {
			console.log("Current Site", res);
			this.siteStateService.currentUserSiteSource.next(res);
		})

	}

	toggleModules() {
		this.areModulesDisplayed = !this.areModulesDisplayed;
	}

	logout() {
		this.auth.logout();
	}
}
