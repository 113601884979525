import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CircuitRatings } from 'src/app/models/backend-generated-models';
import { BoardsService } from 'src/app/services/api/boards.service';

@Component({
  selector: 'app-circuit-create',
  templateUrl: './circuit-create.component.html',
  styleUrls: ['./circuit-create.component.scss']
})
export class CircuitCreateComponent implements OnInit {

  circuitRating = CircuitRatings;

  boardUuid = this.route.snapshot.paramMap.get('uuid');

  formNewCircuit = this.fb.group({
    referenceName: this.fb.control('', Validators.required),
    protectiveDevice: this.fb.control(''),
    rating: this.fb.control('', Validators.required),
    description: this.fb.control(''),
    comments: this.fb.control('')
  });

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private boardsService: BoardsService,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.formNewCircuit.markAllAsTouched();
    if (!this.formNewCircuit.valid) return;
    
    this.boardsService.createCircuit(this.boardUuid!, this.formNewCircuit.value).subscribe((res) => {
      this.router.navigate(['/boards/' + this.boardUuid])
    })
  }
}
