import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs';
import { SitesService } from 'src/app/services/api/sites.service';
import { TiccsService } from 'src/app/services/api/ticcs.service';
import { BasicTiccTypeViewModel, SiteUserModel, TiccScheduleType } from '../../../models/backend-generated-models';
import { DialogRecipientsSelector, ScheduleCheckWeekdays } from '../ticcs-create/ticcs-create.component';
import { LoadingService } from 'src/app/services/helpers/loading.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-ticcs-edit',
  templateUrl: './ticcs-edit.component.html',
  styleUrls: ['./ticcs-edit.component.scss']
})
export class TiccsEditComponent implements OnInit {
  isLoading$ = this.loadingService.isLoading$;


  ticcUuid = this.route.snapshot.paramMap.get('uuid');

  ticcTypeName: string | undefined;

  enumScheduleType = TiccScheduleType;

  siteUuid: string | undefined;
  checkWeekdays: ScheduleCheckWeekdays | undefined;
  pTimePerion: number | undefined;
  pEndTime: string | null = null;
  nonpEndTime: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private ticcsService: TiccsService,
    private fb: UntypedFormBuilder,
    private sitesService: SitesService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private snackbar: MatSnackBar,
    private datepipe: DatePipe,
    private router: Router
  ) {
    this.maxDaysMonth = 0;

  }

  formTiccEdit = new UntypedFormGroup({
    scheduleType: this.fb.control(null),
    schedule: this.fb.group({
      startTime: this.fb.control(null),
      endTime: this.fb.control(null),
      repeatEvery: this.fb.control(null),
      weekdays: this.fb.group({
        sunday: this.fb.control(null),
        monday: this.fb.control(null),
        tuesday: this.fb.control(null),
        wednesday: this.fb.control(null),
        thursday: this.fb.control(null),
        friday: this.fb.control(null),
        saturday: this.fb.control(null)
      }),
      checkDate: this.fb.control(null),
    }),

    ticcObservationsEnabled: this.fb.control(null),
    ticcObservations: this.fb.array([]),

    ticcAssignedToEnabled: this.fb.control(null),
    ticcAssignedTo: this.fb.array([]),

    ticcTagScanRequiredEnabled: this.fb.control(null),

    notificationsEnabled: this.fb.control(null),
    notificationsData: this.fb.group({
      sendReminder: this.fb.control(null),
      sendCheckComplete: this.fb.control(null),
      sendCheckNotComplete: this.fb.control(null),
      sendNotesFilled: this.fb.control(null),

      reminderRules: this.fb.array([]),
      recipientsForReminder: this.fb.array([]),
      recipientsForCheckComplete: this.fb.array([]),
      recipientsForCheckNotComplete: this.fb.array([]),
      recipientsForNotesFilled: this.fb.array([]),
    })
  })


  ngOnInit(): void {

    /* START FORM */
    this.ticcsService.getTiccInformation(this.ticcUuid).subscribe((ticc) => {
      this.pTimePerion = ticc.scheduleType;
      if (ticc.scheduleType == 6) {
        this.nonpEndTime = ticc.schedule?.endTime ? ticc.schedule?.endTime : null;
      } else {
        this.pEndTime = ticc.schedule?.endTime ? ticc.schedule?.endTime : null;
      }

      console.log("ticc information", ticc)

      if (!ticc.schedule) {
        this.snackbar.open('Please set up Schedule', 'ok', {
          panelClass: 'custom-snackbar-error',
          politeness: 'polite',
          duration: 4000
        });
      }

      this.siteUuid = ticc.siteUuid;

      this.formTiccEdit = new UntypedFormGroup({

        scheduleType: this.fb.control(ticc.scheduleType),
        schedule: this.fb.group({
          startTime: this.fb.control(ticc.schedule?.startTime),
          endTime: this.fb.control(ticc.schedule?.endTime),
          repeatEvery: this.fb.control(ticc.schedule?.repeatEvery),
          weekdays: this.fb.group({
            sunday: this.fb.control(ticc.schedule?.weekdays?.sunday),
            monday: this.fb.control(ticc.schedule?.weekdays?.monday),
            tuesday: this.fb.control(ticc.schedule?.weekdays?.tuesday),
            wednesday: this.fb.control(ticc.schedule?.weekdays?.wednesday),
            thursday: this.fb.control(ticc.schedule?.weekdays?.thursday),
            friday: this.fb.control(ticc.schedule?.weekdays?.friday),
            saturday: this.fb.control(ticc.schedule?.weekdays?.saturday)
          }),
          checkDate: this.fb.control(this.datepipe.transform(ticc.schedule?.checkDate, 'yyyy-MM-dd')),
        }),

        ticcObservationsEnabled: this.fb.control(ticc.ticcObservationsEnabled),
        ticcObservations: this.fb.array([]),

        ticcAssignedToEnabled: this.fb.control(ticc.ticcAssignedToEnabled),
        ticcAssignedTo: this.fb.array([]),

        ticcTagScanRequiredEnabled: this.fb.control(ticc.ticcTagScanRequiredEnabled),

        notificationsEnabled: this.fb.control(ticc.notificationsEnabled),
        notificationsData: this.fb.group({
          sendReminder: this.fb.control(ticc.notificationsData?.sendReminder),
          sendCheckComplete: this.fb.control(ticc.notificationsData?.sendCheckComplete),
          sendCheckNotComplete: this.fb.control(ticc.notificationsData?.sendCheckNotComplete),
          sendNotesFilled: this.fb.control(ticc.notificationsData?.sendNotesFilled),

          reminderRules: this.fb.array([]),
          recipientsForReminder: this.fb.array([]),
          recipientsForCheckComplete: this.fb.array([]),
          recipientsForCheckNotComplete: this.fb.array([]),
          recipientsForNotesFilled: this.fb.array([]),
        })
      })

      this.checkWeekdays = {
        name: 'All',
        completed: false,
        days: [
          { name: 'Sunday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('sunday')?.value },
          { name: 'Monday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('monday')?.value },
          { name: 'Tuesday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('tuesday')?.value },
          { name: 'Wednesday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('wednesday')?.value },
          { name: 'Thursday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('thursday')?.value },
          { name: 'Friday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('friday')?.value },
          { name: 'Saturday', completed: this.formTiccEdit?.get('schedule')?.get('weekdays')?.get('saturday')?.value },
        ],
      };

      if (this.scheduleType.value == 3) {
        this.formSelectedWeekday.patchValue(this.checkWeekdays?.days.find(x => x.completed)?.name)
      }

      ticc.observarions?.forEach(observation => {
        (this.formTiccEdit.get('ticcObservations') as UntypedFormArray).push(this.fb.group({
          uuid: this.fb.control(observation.uuid),
          text: this.fb.control(observation.text),
        }))
      });

      ticc.assignedToUsers?.forEach(user => {
        (this.formTiccEdit.get('ticcAssignedTo') as UntypedFormArray).push(this.fb.group({
          userUuid: this.fb.control(user.uuid)
        }))
      })

      ticc.notificationsData?.reminderRules?.forEach(rules => {
        (this.formTiccEdit.get('notificationsData')?.get('reminderRules') as UntypedFormArray).push(this.fb.group({
          unitOfTime: this.fb.control(rules.unitOfTime),
          aforehandReminderTimePeriodInUnits: this.fb.control(rules.aforehandReminderTimePeriodInUnits),
        }))
      })

      ticc.notificationsData?.recipientsForReminder?.forEach(recipient => {
        (this.formTiccEdit.get('notificationsData')?.get('recipientsForReminder') as UntypedFormArray).push(this.fb.group({
          uuid: this.fb.control(recipient.uuid),
          enabled: this.fb.control(recipient.enabled),
        }))
      })

      ticc.notificationsData?.recipientsForCheckComplete?.forEach(recipient => {
        (this.formTiccEdit.get('notificationsData')?.get('recipientsForCheckComplete') as UntypedFormArray).push(this.fb.group({
          uuid: this.fb.control(recipient.uuid),
          enabled: this.fb.control(recipient.enabled),
        }))
      })

      ticc.notificationsData?.recipientsForCheckNotComplete?.forEach(recipient => {
        (this.formTiccEdit.get('notificationsData')?.get('recipientsForCheckNotComplete') as UntypedFormArray).push(this.fb.group({
          uuid: this.fb.control(recipient.uuid),
          enabled: this.fb.control(recipient.enabled),
        }))
      })

      ticc.notificationsData?.recipientsForNotesFilled?.forEach(recipient => {
        (this.formTiccEdit.get('notificationsData')?.get('recipientsForNotesFilled') as UntypedFormArray).push(this.fb.group({
          uuid: this.fb.control(recipient.uuid),
          enabled: this.fb.control(recipient.enabled),
        }))
      })

      this.ticcTypeName = ticc.ticcTypeName;



      this.setTiccTypeValue(ticc.ticcTypeId);

      this.sitesService.getSiteUsersList(ticc.siteUuid).subscribe((res) => {

        res.forEach(user => {
          const obj = ticc.assignedToUsers?.find(obj => obj.fullName == user.userFullName);
          this.selectedUsersList?.push(
          {
            user,
            enabled: !!obj
          })
        })
      })


    });
    /* END FORM */
  }



  requirements = {
    legal: true,
    certificateRequired: true,
    responsiblePerson: true,
    competentPerson: false
  }

  timePeriods = [
    "Minutes",
    "Hours",
    "Days",
    "Weeks",
    "Months",
    "Years",
  ];

  months = [
    { month: "January", days: 31 },
    { month: "February", days: 28 },
    { month: "March", days: 31 },
    { month: "April", days: 30 },
    { month: "May", days: 31 },
    { month: "June", days: 30 },
    { month: "July", days: 31 },
    { month: "August", days: 31 },
    { month: "September", days: 30 },
    { month: "October", days: 31 },
    { month: "November", days: 30 },
    { month: "December", days: 31 }
  ]

  changeTimePeriod(timePerion: number) {
    this.pTimePerion = timePerion;
  }

  changePeriodicEndTime(event: any) {
    this.pEndTime = event.target.value;
  }

  changeNonPeriodicEndTime(event: any) {
    this.nonpEndTime = event.target.value;
  }

  resetTiccCreationForm() {
    this.formTiccEdit.controls['schedule'].patchValue(
      this.fb.group(
        {
          endTime: this.fb.control(null),
          startTime: this.fb.control('00:00:00'),
          repeatEvery: this.fb.control(undefined),
          weekdays: this.fb.group({
            sunday: this.fb.control(false),
            monday: this.fb.control(false),
            tuesday: this.fb.control(false),
            wednesday: this.fb.control(false),
            thursday: this.fb.control(false),
            friday: this.fb.control(false),
            saturday: this.fb.control(false),
          }),
          checkDate: this.fb.control(null)
        }
      )
    );

    if (!this.scheduleButtonToggled) {
      this.formTiccEdit.get('scheduleType')?.patchValue(this.pTimePerion);
      this.formTiccEdit.get('schedule')?.get('endTime')?.patchValue(this.pEndTime);
    } else {
      this.formTiccEdit.get('schedule')?.get('endTime')?.patchValue(this.nonpEndTime);
    }
  }



  formSchedule = this.fb.group({
    timePeriod: this.fb.control(''),
    repetition: this.fb.control(null),
    startTime: this.fb.control(null),
    endTime: this.fb.control(null),
    weekdays: this.fb.control('')
  })

  formScheduleNonPeriodic = this.fb.group({
    date: this.fb.control(''),
    endTime: this.fb.control(null),
  })


  formSelectedWeekday = this.fb.control('');


  maxDaysMonth: number | 0;

  selectedUsersList: { user: SiteUserModel; enabled: boolean; }[] = []


  addReminder(aforehandReminderTimePeriodInUnits: HTMLInputElement, unitOfTime: MatSelect) {
    console.log("bef", aforehandReminderTimePeriodInUnits.value)

    if (!unitOfTime.value || !aforehandReminderTimePeriodInUnits.value) return

    // console.log("enum", )
    console.log("enum value", unitOfTime.value, TiccScheduleType[unitOfTime.value as keyof typeof TiccScheduleType])

    const reminders = this.fb.group({
      unitOfTime: this.fb.control(TiccScheduleType[unitOfTime.value as keyof typeof TiccScheduleType]),
      aforehandReminderTimePeriodInUnits: this.fb.control(parseInt(aforehandReminderTimePeriodInUnits.value))
    })

    this.reminderRulesList.push(
      reminders
    );

    unitOfTime.value = "";
    aforehandReminderTimePeriodInUnits.value = "";

    console.log("rem", this.reminderRulesList)
  }

  deleteReminder(reminderIndex: number) {
    this.reminderRulesList.removeAt(reminderIndex);
  }


  resetNotificationsValue(notificationType: string) {
    (this.formTiccEdit.get('notificationsData')?.get(notificationType.toString()) as UntypedFormArray)
      .clear();
  }

  openDialogRecipients(notificationType: string, title: string) {
    this.dialog.open(DialogRecipientsSelector, {
      width: '600px',
      data: {
        siteUuid: this.siteUuid,
        recipientsForCheckComplete: this.formTiccEdit.get('notificationsData')?.get(notificationType)?.value,
        title: title
      }
    })
      .afterClosed()
      .subscribe(
        (recipients: { uuid: string; enabled: boolean; }[]) => {
          if (!recipients) return

          this.resetNotificationsValue(notificationType);
          recipients.forEach((recipient) => {
            (this.formTiccEdit.get('notificationsData')?.get(notificationType.toString()) as UntypedFormArray).push(
              this.fb.group({
                uuid: this.fb.control(recipient.uuid),
                enabled: this.fb.control(recipient.enabled)
              })
            );
          });

          console.log("form", this.formTiccEdit.value)
        })
  }


  selectedTiccType: BasicTiccTypeViewModel | undefined;

  setTiccTypeValue(e: number | undefined) {
    this.ticcTypesList$.pipe(map(x => x.find(x => x.id == e))).subscribe((res) => { this.selectedTiccType = res; })
  }

  formDayRepeat = this.fb.control('');


  get scheduleType() {
    return this.formTiccEdit.controls['scheduleType'] as UntypedFormControl;
  }

  get ticcObservations() {
    return this.formTiccEdit.controls["ticcObservations"] as UntypedFormArray;
  }

  get reminderRulesList() {
    return this.formTiccEdit.get('notificationsData')?.get('reminderRules') as UntypedFormArray;
  }

  minDate = moment(new Date()).format('YYYY-MM-DD')


  scheduleButtonToggled = false;

  allComplete: boolean = false;


  ticcTypesList$ = this.ticcsService.getTiccTypesList();

  /* OBSERVATIONS */

  addObservation(lastObservation: HTMLInputElement) {
    if (!lastObservation.value || this.ticcObservations.controls.some(x => x.value == lastObservation.value)) return

    this.ticcObservations.push(new UntypedFormGroup({ text: new UntypedFormControl(lastObservation.value) }));

    lastObservation.value = "";
  }

  deleteObservation(observationIndex: number) {
    this.ticcObservations.removeAt(observationIndex);
  }

  /* END - OBSERVATIONS */


  /* CHECKBOX */

  updateAllComplete() {
    this.allComplete = this.checkWeekdays?.days != null && this.checkWeekdays.days.every((t: { completed: any; }) => t.completed);
  }

  someComplete(): boolean {
    if (this.checkWeekdays?.days == null) {
      return false;
    }
    return this.checkWeekdays.days.filter((t: { completed: any; }) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.checkWeekdays?.days == null) {
      return;
    }
    this.checkWeekdays.days.forEach((t: { completed: boolean; }) => (t.completed = completed));
  }

  /* END - CHECKBOX */


  submit() {
    if (!this.scheduleButtonToggled && !this.formTiccEdit.get('scheduleType')?.value && this.formTiccEdit.get('scheduleType')?.value != 0) {
      this.snackbar.open('Time Period is required', 'ok', {
        panelClass: 'custom-snackbar-error',
        politeness: 'polite',
        duration: 4000
      });
      return;
    }

    /* TIME PERDIOD SET FOR NON PERIODIC TASKS */
    if (this.scheduleButtonToggled) {
      this.formTiccEdit.get('scheduleType')?.patchValue(6)
    }

    /* ADD SCHEDULE WEEKDAYS CHECKBOX TO POST MODEL */
    (Object.keys((this.formTiccEdit.get('schedule')?.get('weekdays') as UntypedFormGroup).value).forEach((v: any, i) => {
      this.formTiccEdit.get('schedule')?.get('weekdays')?.get(v)?.patchValue(this.checkWeekdays?.days[i].completed)
    }));

    /* FULFILL WITH SELECTED WEEKDAY - ONLY FOR WEEKS TIME PERIOD */

    if (this.scheduleType.value == 3) {
      let selectedWeekday = this.checkWeekdays?.days.find(x => x.name == this.formSelectedWeekday.value);
      
      (Object.keys((this.formTiccEdit.get('schedule')?.get('weekdays') as UntypedFormGroup).value).forEach((v: any, i) => {
        this.formTiccEdit.get('schedule')?.get('weekdays')?.get(v)?.patchValue(this.checkWeekdays?.days[i].name == selectedWeekday?.name)
      }));
    }


    let weekdays = this.checkWeekdays?.days.filter(x => x.completed).map(x => x.name);

    this.formSchedule.controls['weekdays'].patchValue(weekdays)

    /* STORE SELECTED USERS IN ASSIGNMENTS */
    if (this.formTiccEdit.get('ticcAssignedToEnabled')?.value)
      this.selectedUsersList.filter(((x) => x.enabled == true)).map(x => x.user.userUUID).forEach(user => {
        (this.formTiccEdit.get('ticcAssignedTo') as UntypedFormArray).push(new UntypedFormControl({ userUuid: user }))
      });


    // set to checkDate time from endTime or startTime to fix bug with utc time
    if (this.formTiccEdit?.get('schedule')?.get('checkDate')?.value != null) {
      var date = this.formTiccEdit?.get('schedule')?.get('checkDate')?.value as string;

      var time = this.formTiccEdit?.get('schedule')?.get('endTime')?.value 
        ?? this.formTiccEdit?.get('schedule')?.get('startTime')?.value;

      if (time == null) 
        time = "00:00:00";

      var checkDate = date.slice(0, 10) + "T" + time;

      this.formTiccEdit?.get('schedule')?.get('checkDate')?.patchValue(checkDate);
    }

    this.ticcsService.editTicc(this.ticcUuid, this.formTiccEdit.value).subscribe((res) => {
      console.log("ticc edited successfully", res);
      this.cancel();
    })
  }

  cancel() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (!queryParams.get('lastUrl') || !queryParams.get('uuid')) return history.back();

      this.router.navigate([queryParams.get('lastUrl'), queryParams.get('uuid')])
    });
  }
}
