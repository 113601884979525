import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { concatMap, EMPTY, throwError } from 'rxjs';
import { AssetResponseModel, TagResponseModel } from 'src/app/models/backend-generated-models';
import { AssetsService } from 'src/app/services/api/assets.service';
import { TagsService } from 'src/app/services/api/tags.service';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';
import { TiccsListComponent } from '../../ticcs/ticcs-list/ticcs-list.component';

@Component({
  selector: 'app-assets-detail',
  templateUrl: './assets-detail.component.html',
  styleUrls: ['./assets-detail.component.scss']
})
export class AssetsDetailComponent implements OnInit {
  breadcrumbExist = false;
  breadcrumbTitle: string = "";
  breadcrumbUrl: string = "";
  selectedSite: string | null = "";
  assetUUID = this.route.snapshot.paramMap.get('uuid');
  assetDetails$: AssetResponseModel | undefined;

  @ViewChild(TiccsListComponent) ticcsComponent: TiccsListComponent | undefined;

  ticcs$ = this.assetsService.getAssetTiccsInformation(this.assetUUID);

  tag$: TagResponseModel | undefined
  tagImage$!: string;
  image: SafeUrl | undefined;
  tagStatusError = false;
  tagUUID: string | undefined;

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorHandlerService,
    private tagsService: TagsService
  ) { }

  ngOnInit(): void {

    this.assetsService.getAssetByUUID(this.assetUUID).subscribe((res) => {
      this.assetDetails$ = res;
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      const previousUrl = queryParams.get('lastUrl');

      if (previousUrl === "assets") {
        this.breadcrumbExist = true;
        this.breadcrumbTitle = "Assets"
        this.breadcrumbUrl = "/assets"
      } else {
        this.breadcrumbExist = true;
        this.breadcrumbTitle = "Assets"
        this.breadcrumbUrl = "/assets"
      }

    });

    this.assetsService.getAssetTagInformation(this.assetUUID)
      .pipe(
        concatMap((tag) => {
          this.tag$ = tag;
          return this.assetsService.getCurrentTagImage(tag.tagUuid);
        }
        )
      )
      .subscribe(
        {
          next: (blobImage) => {
            this.tagStatusError = false;

            let parsedBlobImage = URL.createObjectURL(blobImage);

            this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          },
          error: (error: HttpErrorResponse) => {

            this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }

            this.errorHandler.handleError(error);

            return throwError(error);
          }
        }
      )
  }

  disassociateTag() {
    if (this.tag$?.tagUuid) {
      this.assetsService.deleteTagAssociation(this.tag$.tagUuid).subscribe(
        () => {
          this.image = undefined;
          this.tagStatusError = true
          this.tag$ = undefined;
        }
      )
    }
  }

  associationToTag() {
    this.tagsService.setAssociationForTag(this.tagUUID as string, { tagableType: 'App\\Models\\Asset', tagableUuid: this.assetUUID as string }).pipe(
      concatMap((tag) => {
        this.tag$ = tag;
        return this.assetsService.getCurrentTagImage(tag);
      }
      )).subscribe(
        {
          next: (blobImage) => {
            this.tagStatusError = false;

            let parsedBlobImage = URL.createObjectURL(blobImage);

            this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          },
          error: (error: HttpErrorResponse) => {

            this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }

            this.errorHandler.handleError(error);

            return throwError(error);
          }
        }
      )
  }

}
