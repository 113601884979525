import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TiccTypesAdminViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';
import { DialogRemoveTiccType } from './dialog-remove-ticc-type/dialog-remove-ticc-type';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { catchError, mergeMap, of } from 'rxjs';

@Component({
  selector: 'app-ticc-types',
  templateUrl: './ticc-types.component.html',
  styleUrls: ['./ticc-types.component.scss']
})
export class TiccTypesComponent implements OnInit {

  ticcTypes$: [string, [any]][] | undefined;
  newTiccType: string | undefined;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.adminService.getTiccTypes().subscribe(
      res => {
        this.ticcTypes$ = Object.entries(this.groupByProperty(res, 'ticcTypeModule'));
      }
    )

    this.route.params.subscribe(res => {
      this.newTiccType = res['ticc_type'];
    })

  }

  groupByProperty(arr: TiccTypesAdminViewModel[], ticcTypeModule: string) {
    return arr.reduce(
      (previousVal: any, currentVal: any) => {
        if (!previousVal[currentVal[ticcTypeModule]]) {
          previousVal[currentVal[ticcTypeModule]] = [];
        }
        previousVal[currentVal[ticcTypeModule]].push(currentVal);
        return previousVal;
      }, {});
  }

  parseDate(date: Date) {
    return date.toLocaleString().split('T')[0];
  }

  isNewTiccType(item: [any]) {

    let isNew = false;
    if (!this.newTiccType) return false;

    item.forEach(ticcType => {
      (ticcType.ticcTypeName.toLowerCase().replace(/\s/g, "")).includes(this.newTiccType?.toLowerCase().replace(/\s/g, "")) ? isNew = true : null;
    })

    return isNew;
  }

  deleteTiccType(ticcType: any) {
    console.log(ticcType);
    console.log(this.ticcTypes$);

    if (!ticcType) return;

    if (ticcType.isDeleted) return;

    ticcType.isDeleted = true;

    this.dialog.open(DialogRemoveTiccType, {
      width: '800px',
      data: ticcType,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((res) => {
          if (res) return this.adminService.deleteTiccType(ticcType.ticcTypeId);

          ticcType.isDeleted = false;
          return of(false)
        }),
        catchError((error) => {
          return error
        })
      ).subscribe(
        (res: any) => {
          if (res === false) return;

          var groupIndex = this.ticcTypes$?.findIndex(item => item[0] === ticcType.ticcTypeModule);
          if (groupIndex === -1) return;
          
          var index = this.ticcTypes$![groupIndex!][1].findIndex((item: any) => item.ticcTypeId === ticcType.ticcTypeId);
          if (index === -1) return;

          this.ticcTypes$![groupIndex!][1].splice(index, 1);
        }
      )
  }
}
