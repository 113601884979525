<div class="dialog">
    <div class="row px-3 m-0 justify-content-between">
        <h1 class="col-10 p-0 m-0 dialog-title">
            <p class="m-0 dialog-title-text">TAG Management&nbsp;</p>
            <p class="m-0 dialog-title-text-small">create TAG Batch</p>
        </h1>
        <mat-icon class="col-2 p-0" mat-dialog-close>close</mat-icon>
    </div>

    <hr class="mb-3" color="#C4C4C4">

    <div class="dialog-main-text mx-2">
        <div class="row px-2 m-0">

            <div class="col-12 p-0 m-0 add-users-form" [formGroup]="newTagsForm">

                <div class="col-12" style="font-size: 16px">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                        <mat-label class="pl-2 d-flex align-items-center">
                            TAG Quantity
                        </mat-label>
                        <input formControlName="tagsCount" class="dialog-input" type="number"
                            placeholder="TAG Quantity" matInput>
                        <mat-error *ngIf="newTagsForm.controls['tagsCount'].errors 
                            && newTagsForm.controls['tagsCount'].errors?.['min'] 
                            && newTagsForm.controls['tagsCount'].touched">
                                Typed number is less than 1
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="row p-0 mx-3 d-flex justify-content-between dialog-action-buttons">
        <button class="m-0 dialog-red-action-button cancel-button" style="width: 49%;" mat-button mat-dialog-close>
            Cancel
        </button>
        <button class="m-0 dialog-blue-action-button submit-button" style="width: 49%;" (click)="submit()" mat-button>
            Create
        </button>
    </div>

</div>