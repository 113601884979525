import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardsService } from 'src/app/services/api/boards.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-board-create',
  templateUrl: './board-create.component.html',
  styleUrls: ['./board-create.component.scss']
})
export class BoardCreateComponent implements OnInit {

  formNewBoard = this.fb.group({
    siteUuid: this.fb.control("", Validators.required),
    name: this.fb.control("", Validators.required),
    description: this.fb.control("", Validators.required)
  });

  sites$ = this.sitesService.getList();

  previousUrl?: string | null;

  constructor(
    private fb: UntypedFormBuilder,
    private sitesService: SitesService,
    private boardsService: BoardsService,
    private route: ActivatedRoute,
    private router: Router,
    private tagsService: TagsService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.previousUrl = queryParams.get('associationTo');
    });
  }

  submit() {
    console.log("form", this.formNewBoard)
    this.formNewBoard.markAllAsTouched()

    if (!this.formNewBoard.valid) return;

    this.boardsService.createBoard(this.formNewBoard.value).subscribe((res: any) => {
      if (this.previousUrl) {
        this.tagsService.setAssociationForTag(this.previousUrl, { tagableType: 'App\\Models\\Board', tagableUuid: res?.boardUuid }).subscribe();
      }
      this.router.navigate(['/boards'])
    })
  }
}
