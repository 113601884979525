<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->

<div *ngIf="breadcrumbExist" class="page-top-breadcrumbs">
    <a [routerLink]="breadcrumbUrl">{{breadcrumbTitle}}</a> > {{assetDetails$?.assetName}}
</div>
<div class="fixed-button-container d-flex justify-content-end" >
    <button routerLink="edit" mat-button class="edit-button fixed-button d-flex"><mat-icon>edit</mat-icon><div class="d-none d-md-block">&nbsp;&nbsp;EDIT ASSET</div></button>
</div>

<h1 class="page-top-title">
    Asset Detail
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="container-fluid">

    <mat-card class="p-0">
        <mat-card-header class="p-3">
          <mat-card-title class="m-0">Overview</mat-card-title>
        </mat-card-header>

        <mat-card-content class="p-3">
            <div class="row">
                <div class="col-12 col-md-11 row">
                    <div class="col-12 col-md-6 row">
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Name:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetName ? assetDetails$?.assetName : "Name not added"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Type:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetTypeName || "Type not added"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Site:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetSiteName || "Site not added"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 row">
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Description:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetDescription || "Description not added" | titlecase}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Created At:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetCreatedAt || "No value"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Updated At:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{assetDetails$?.assetUpdatedAt || "No value"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 row">
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4 col-md-2">
                                <span class="ligth break-word">Modules:</span>
                            </div>
                            <div class="col-8 col-md-10">
                                <span *ngFor="let module of assetDetails$?.assetTypeModulesModel" class="module-background strong break-word">{{module.moduleName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-tab-group mat-align-tabs="start">

        <mat-tab label="TICCs">
            <app-ticcs-list [ticcsArray$]="ticcs$" [pageType]="'assets'" [uuid]="assetDetails$?.assetUUID!" [siteUuid]="assetDetails$?.assetSiteUuid"></app-ticcs-list>
        </mat-tab>

        <mat-tab label="TAG">
            <div class="row m-0 p-0">
                <div class="col-12" [ngClass]="{'col-12': !image, 'col-12 col-md-8': image}">
                    <mat-card class="p-0">
                        <mat-card-header class="p-3">
                          <mat-card-title class="m-0">QR Code UUID</mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="p-3" *ngIf="!tagStatusError; else noTag">
                            <div class="w-100" style="text-align: center; margin-bottom: 1rem; font-size: 1.1rem;">
                                {{tag$?.tagUuid}}
                            </div>
                            <button (click)="disassociateTag()" class="button-center button-disassociate w-100"
                                mat-button style="text-align: center;">
                                <img src="web-assets/svg/qrcode.svg">REMOVE QR
                            </button>
                        </mat-card-content>

                        <ng-template #noTag>
                            <mat-card-content class="p-3" style="text-align: center !important; font-size: 1.3rem;">
                                <p style="color: #F61010;">No TAG associated</p>
                                <p style="font-size: 20px;">Add TAG by UUID</p>
                                <mat-form-field class="col-12" appearance="outline">
                                    <mat-label>TAG UUID</mat-label>
                                    <input type="text" [(ngModel)]="tagUUID" matInput>
                                </mat-form-field>
                                <button mat-button type="submit" class="action-buttons submit w-100" [disabled]="tagUUID == undefined" (click)="associationToTag()" >Add TAG</button>
                                <p style="font-size: 20px;">Add TAG form QR scan</p>
                                <button mat-button routerLink="/scanner" [queryParams]="{ lastUrl: 'users' , uuid:  assetUUID }" class="action-buttons scan w-100"><img
                                        src="web-assets/svg/qrcode.svg"> Scan QR code</button>
                            </mat-card-content>
                        </ng-template>
                    </mat-card>
                </div>

                <div class="col-12 col-md-4 tag-image" [ngClass]="{'d-none': !image, 'd-block': image}">
                    <img [src]="image" class="w-100 img-fluid rounded-top" alt="">
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>

</div>
