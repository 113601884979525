import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SiteBasicViewModel, SystemRoles, UsersViewModel } from 'src/app/models/backend-generated-models';
import { SiteOwner, getListUserSiteRoles } from 'src/app/models/user.model';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { PhotoConvertService } from 'src/app/services/helpers/photo-convert';

@Component({
  selector: 'app-invite-to-site',
  templateUrl: './invite-to-site.component.html',
  styleUrls: ['./invite-to-site.component.scss']
})
export class InviteToSiteComponent implements OnInit {
  //can't invite site owner
  roles = getListUserSiteRoles().filter(x => x !== SiteOwner);

  selectedSite?: number;
  sites: SiteBasicViewModel[] | undefined;
  users: any[] | undefined;
  breadcrumbExist = false;
  breadcrumbTitle: string = "";
  breadcrumbUrl: string = "";
  usersSites: { userUUID: any[] } | undefined;

  inputFilled: any;

  sendInvite_loading : boolean = false;

  constructor(
    private siteService: SitesService,
    private userService: UserService,
    private photoConvert: PhotoConvertService,
    private router: Router) {
    this.breadcrumbExist = true;
    this.breadcrumbTitle = "Users";
    this.breadcrumbUrl = "/users";
    
  }

  ngOnInit(): void {
    this.users = this.userService.getUsersForInvite();
    this.users?.forEach(val => {
      val.isCopyTiccs = false
    })

    this.siteService.getList("manage").subscribe(res => {
      this.sites = res
    });

    this.userService.getUsersSites(this.users?.map(x => x.userUUID) as string[]).subscribe(res => {
      this.users?.forEach(user => {
        user.sites = res.userUUID.filter((site: any) => site.userName === user?.userFullName)
          .flatMap((val: any) => val.userSites.map((site: any) => ({ ...site })))
      })
    });

    this.inputFilled = (this.users?.length === this.siteUuidList.length) && (this.users?.length === this.siteRoleList.length) && (Object.values(this.siteUuidList).length === this.siteUuidList.length) && (Object.values(this.siteRoleList).length === this.siteUuidList.length);
console.log(this.users)
  }

  avatarConvert(photo: any) {
    if (photo == null) {
      return null;
    }
    return this.photoConvert.photoConvert(photo);
  }

  toggleCopyTicc(user: any) {
    user.isCopyTiccs = !user.isCopyTiccs;
  }

  siteUuidList: string[] = [];
  siteRoleList: number[] = [];


  setSiteUUID(siteUUID: string, i: number, event: any) {
    if (event.isUserInput) {
      this.siteUuidList[i] = siteUUID;
      this.inputFilled = (this.users?.length === this.siteUuidList.length) && (this.users?.length === this.siteRoleList.length) && (Object.values(this.siteUuidList).length === this.siteUuidList.length) && (Object.values(this.siteRoleList).length === this.siteUuidList.length);
    }
  }

  canInvite() {

    if (!this.selectedSite || !this.users) 
      return false;

    if (this.users?.length === 0)
      return false;

    if (this.users?.some(val => val.userSiteRole?.value === undefined || val.userSiteRole?.value === ""))
      return false;

    if (this.users?.some(val => val.isCopyTiccs && (val.userSiteUUIdForCopyTiccs === undefined || val.userSiteUUIdForCopyTiccs === "")))
      return false;

    return true;
  }

  sendInvite() {
    if (this.sendInvite_loading) 
      return;

    if (!this.canInvite())
      return;

    this.sendInvite_loading = true;

    let body  =       {
      siteId: this.selectedSite,
      invites: this.users?.map(val => {return {
        userEmail: val.userEmail,
        userSiteRole: val.userSiteRole.value.replace("_"," "),
        userSiteUUIdForCopyTiccs: val.userSiteUUIdForCopyTiccs
      }})
    }

    this.userService.inivteUsersToSiteBulk(body).subscribe(()=> this.router.navigate(['/users']), 
      null, 
      () => this.sendInvite_loading = false);
  }

  onSelectSite(siteId: number) {
    this.selectedSite = siteId;
  }

  onSelectSiteForCopyTicc(site: any, user: any) {
    user.userSiteUUIdForCopyTicc = site;
  }

}
