import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concatMap, EMPTY, take, throwError } from 'rxjs';
import { SiteResponseModel, SiteTagResponseModel } from 'src/app/models/backend-generated-models';
import { SitesService } from 'src/app/services/api/sites.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-sites-detail',
  templateUrl: './sites-detail.component.html',
  styleUrls: ['./sites-detail.component.scss']
})
export class SitesDetailComponent implements OnInit {

  tagStatusError = false;

  siteUuid = this.route.snapshot.paramMap.get('siteUuid');

  siteDetail$: SiteResponseModel | undefined;

  tag$: SiteTagResponseModel | undefined

  tagImage$!: string;

  image: SafeUrl | undefined;

  tagUUID: string | undefined;

  ticcs$ = this.sitesService.getTiccInformation(this.siteUuid)

  isLoadingSiteDetails: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sitesService: SitesService,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorHandlerService,
    private tagsService: TagsService
  ) { }

  ngOnInit(): void {
    this.isLoadingSiteDetails = true;
    this.sitesService.getSiteDetailByUUID(this.siteUuid).subscribe((res) => {
      this.siteDetail$ = res;
      console.log("res",res)
      this.isLoadingSiteDetails = false;
    });

    this.sitesService.getCurrentTagBySiteUUID(this.siteUuid)
    .pipe(
      concatMap((tag) => {
        this.tag$ = tag;
        return this.sitesService.getCurrentTagImage(tag.tagUUID);
 
      }
      )
    )
    .subscribe(
      {
        next: (blobImage) => {
          this.tagStatusError = false;

          let parsedBlobImage = URL.createObjectURL(blobImage);

          this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
        },
        error: (error: HttpErrorResponse) => {
          
          this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }
    
            this.errorHandler.handleError(error);
    
            return throwError(error);
        }
      }
    )
  }

  disassociateTag() {
    if (this.tag$?.tagUUID) {
      this.sitesService.deleteTagAssociation(this.tag$.tagUUID).subscribe(
        () => {
          this.image = undefined;
          this.tagStatusError = true
          this.tag$ = undefined;
        }
      )
    }
  }

  associationToTag() {
    this.tagsService.setAssociationForTag(this.tagUUID as string, { tagableType: 'App\\Models\\Site', tagableUuid: this.siteUuid as string }).pipe(
      concatMap((tag) => {
        this.tag$ = tag;
        return this.sitesService.getCurrentTagImage(tag);
      }
    )).subscribe(
      {
        next: (blobImage) => {
          this.tagStatusError = false;

          let parsedBlobImage = URL.createObjectURL(blobImage);

          this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
        },
        error: (error: HttpErrorResponse) => {
          
          this.tagStatusError = true;

          if (error.status === 400) {
            return EMPTY
          }

          this.errorHandler.handleError(error);

          return throwError(error);
        }
      }
    )
  }
}
