<div class="page-top-breadcrumbs">
    SITES
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
    Companies - Sites
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->

<div class="container-fluid">    
    <mat-accordion class="main-card ticc-types-accordion">
        <div *ngFor="let company of sites$; first as isFirst">

            <mat-expansion-panel hideToggle class="mt-3" [expanded]="isFirst">
                <mat-expansion-panel-header class="d-flex p-0">

                    <mat-icon class="p-2">circle</mat-icon>

                    <div class="p-2 expansion-header-center-title" *ngIf="company[0] != 'null' && company[0] != 'undefined'">{{company[0]}}</div>
                    <div class="p-2 expansion-header-center-title"*ngIf="company[0] == 'null' || company[0] == 'undefined'">Default</div>

                    <div class="d-flex">
                        <div class="site-box d-flex p-2 expansion-header-rigth">
                            <div class="site">{{company[1].length}} sites</div>
                        </div>
                        <mat-icon class="p-2">expand_more</mat-icon>
                    </div>
                </mat-expansion-panel-header>


                <!-- SITES CREATED - WHITHOUT STATUS -->
                <div *ngFor="let site of filterBySitesNew(company[1])" class="col-12 col-xl-6 detail-panel new">
                    <div class="row">
                        <div class="col-11">
                            
                            <div class="top-row row p-3">
                                <div class="col-12 col-xl-4 py-2 d-flex align-center">
                                    <span>{{site.siteName}}</span>
                                </div>

                                <div class="col-12 col-xl-8 py-2">
                                    <div class="row">
                                        <div class="col-6 col-sm-3 d-flex align-center">
                                            <mat-icon>image</mat-icon>&nbsp;
                                            <span>{{site.assetsCount}}</span>&nbsp;
                                            <span class="strong">assets</span>
                                        </div>
                                        <div class="col-6 col-sm-3 d-flex align-center">
                                            <mat-icon>assignment</mat-icon>&nbsp;
                                            <span>{{site.boardCount}}</span>&nbsp;
                                            <span class="strong">boards</span>
                                        </div>
                                        <div class="col-6 col-sm-3 d-flex align-center">
                                            <mat-icon>group</mat-icon>&nbsp;
                                            <span>{{site.MembersCount}}</span>&nbsp;
                                            <span class="strong">users</span>
                                        </div>
                                        <div class="col-6 col-sm-3 d-flex align-center">
                                            <mat-icon>task_alt</mat-icon>&nbsp;
                                            <span>{{site.ticcsCount}}</span>&nbsp;
                                            <span class="strong">ticcs</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="bottom-row row p-3">
                                <div class="col-6 col-sm-4 py-2 text-wrap">
                                    <span class="italic">Site Owner:</span>&nbsp;<br class="d-block d-xl-none">
                                    <span class="strong text-wrap">{{site.owner}}</span>
                                </div>
                                <div class="col-6 col-sm-4 py-2">
                                    <span class="italic">Created At:</span>&nbsp;<br class="d-block d-xl-none">
                                    <span class="date strong">{{parseDate(site.createdAt) | date: 'dd-MM-yyyy'}}</span>
                                </div>
                                <div class="col-6 col-sm-4 py-2">
                                    <span class="italic">Updated At:</span>&nbsp;<br class="d-block d-xl-none">
                                    <span class="date strong">{{parseDate(site.updatedAt) | date: 'dd-MM-yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="action-buttons col-1 p-0 d-flex flex-wrap align-center" *ngIf=" site.enabled === null && site.toBeDeleted === null">
                            <button [routerLink]="['/sites/' + site?.uuid]" mat-button class="icon-box p-0 w-100 justify-content-center view">
                                <mat-icon class="w-100">visibility</mat-icon>
                                <div class="w-100 action-buttons-text">VIEW</div>
                            </button>

                            <button (click)="newSiteApprove(site)" mat-button class="icon-box p-0 w-100 justify-content-center activate-site">
                                <mat-icon class="w-100">done</mat-icon>
                                <div class="w-100 action-buttons-text">APPROVE</div>
                            </button>

                            <button (click)="newSiteReject(site)" mat-button class="icon-box p-0 w-100 justify-content-center delete">
                                <mat-icon class="w-100">close</mat-icon>
                                <div class="w-100 action-buttons-text">REJECT</div>
                            </button>

                        </div>
                        
                    </div>
                </div>
                

                <!-- SITES ENABLED -->
                <div class="row p-0 m-0 content">
                    <div *ngFor="let site of filterBySitesEnabled(company[1])" class="col-12 col-xl-6 detail-panel enabled">
                        <div class="row">
                            <div class="col-11">
                                
                                <div class="top-row row p-3">
                                    <div class="col-12 col-xl-4 py-2 d-flex align-center">
                                        <span>{{site.siteName}}</span>
                                    </div>

                                    <div class="col-12 col-xl-8 py-2">
                                        <div class="row">
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>image</mat-icon>&nbsp;
                                                <span>{{site.assetsCount}}</span>&nbsp;
                                                <span class="strong">assets</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>assignment</mat-icon>&nbsp;
                                                <span>{{site.boardCount}}</span>&nbsp;
                                                <span class="strong">boards</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>group</mat-icon>&nbsp;
                                                <span>{{site.MembersCount}}</span>&nbsp;
                                                <span class="strong">users</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>task_alt</mat-icon>&nbsp;
                                                <span>{{site.ticcsCount}}</span>&nbsp;
                                                <span class="strong">ticcs</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bottom-row row p-3">
                                    <div class="col-6 col-sm-4 py-2 text-wrap">
                                        <span class="italic">Site Owner:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="strong text-wrap">{{site.owner}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Created At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.createdAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Updated At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.updatedAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="action-buttons col-1 p-0 d-flex flex-wrap align-center">
                                <button [routerLink]="['/sites/' + site?.uuid]" mat-button class="icon-box p-0 w-100 justify-content-center view">
                                    <mat-icon class="w-100">visibility</mat-icon>
                                    <div class="w-100 action-buttons-text">VIEW</div>
                                </button>

                                <button (click)="siteToggle(site)" mat-button class="icon-box p-0 w-100 justify-content-center deactivate-site">
                                    <mat-icon class="w-100">power_settings_new</mat-icon>
                                    <div class="w-100 action-buttons-text">DEACTIVATE</div>
                                </button>

                                <button (click)="deleteSite(site)" mat-button class="icon-box p-0 w-100 justify-content-center delete">
                                    <mat-icon class="w-100">delete</mat-icon>
                                    <div class="w-100 action-buttons-text">DELETE</div>
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- SITES DISABLED -->
                    <div *ngFor="let site of filterBySitesDisabled(company[1])" class="col-12 col-xl-6 detail-panel disabled">
                        <div class="row">
                            <div class="col-11">
                                
                                <div class="top-row row p-3">
                                    <div class="col-12 col-xl-4 py-2 d-flex align-center">
                                        <span>{{site.siteName}}</span>
                                    </div>
    
                                    <div class="col-12 col-xl-8 py-2">
                                        <div class="row">
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>image</mat-icon>&nbsp;
                                                <span>{{site.assetsCount}}</span>&nbsp;
                                                <span class="strong">assets</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>assignment</mat-icon>&nbsp;
                                                <span>{{site.boardCount}}</span>&nbsp;
                                                <span class="strong">boards</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>group</mat-icon>&nbsp;
                                                <span>{{site.MembersCount}}</span>&nbsp;
                                                <span class="strong">users</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>task_alt</mat-icon>&nbsp;
                                                <span>{{site.ticcsCount}}</span>&nbsp;
                                                <span class="strong">ticcs</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bottom-row row p-3">
                                    <div class="col-6 col-sm-4 py-2 text-wrap">
                                        <span class="italic">Site Owner:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="strong text-wrap">{{site.owner}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Created At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.createdAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Updated At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.updatedAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="action-buttons col-1 p-0 d-flex flex-wrap align-center">
                                <button [routerLink]="['/sites/' + site?.uuid]" mat-button class="icon-box p-0 w-100 justify-content-center view">
                                    <mat-icon class="w-100">visibility</mat-icon>
                                    <div class="w-100 action-buttons-text">VIEW</div>
                                </button>
                                
                                <button (click)="siteToggle(site)" mat-button class="icon-box p-0 w-100 justify-content-center activate-site">
                                    <mat-icon class="w-100">power_settings_new</mat-icon>
                                    <div class="w-100 action-buttons-text">ACTIVATE</div>
                                </button>

                                <button (click)="deleteSite(site)" mat-button class="icon-box p-0 w-100 justify-content-center delete">
                                    <mat-icon class="w-100">delete</mat-icon>
                                    <div class="w-100 action-buttons-text">DELETE</div>
                                </button>
                            </div>

                        </div>
                    </div>
                    
                    
                    <!-- DELETE SITES SEPARATOR -->
                    <div class="col-12 delete-sites-separator">
                        SITES REQUESTED FOR DELETE
                    </div>


                    <!-- SITES FOR DELETE -->
                    <div *ngFor="let site of filterBySitesToBeDeleted(company[1])" class="col-12 col-xl-6 detail-panel for-delete">
                        <div class="row">
                            <div class="col-11">
                                
                                <div class="top-row row p-3">
                                    <div class="col-12 col-xl-4 py-2 d-flex align-center">
                                        <span>{{site.siteName}}</span>
                                    </div>
    
                                    <div class="col-12 col-xl-8 py-2">
                                        <div class="row">
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>image</mat-icon>&nbsp;
                                                <span>{{site.assetsCount}}</span>&nbsp;
                                                <span class="strong">assets</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>assignment</mat-icon>&nbsp;
                                                <span>{{site.boardCount}}</span>&nbsp;
                                                <span class="strong">boards</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>group</mat-icon>&nbsp;
                                                <span>{{site.MembersCount}}</span>&nbsp;
                                                <span class="strong">users</span>
                                            </div>
                                            <div class="col-6 col-sm-3 d-flex align-center">
                                                <mat-icon>task_alt</mat-icon>&nbsp;
                                                <span>{{site.ticcsCount}}</span>&nbsp;
                                                <span class="strong">ticcs</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bottom-row row p-3">
                                    <div class="col-6 col-sm-4 py-2 text-wrap">
                                        <span class="italic">Site Owner:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="strong text-wrap">{{site.owner}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Created At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.createdAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 py-2">
                                        <span class="italic">Updated At:</span>&nbsp;<br class="d-block d-xl-none">
                                        <span class="date strong">{{parseDate(site.updatedAt) | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="action-buttons col-1 p-0 d-flex flex-wrap align-center">
                                <button [routerLink]="['/sites/' + site?.uuid]" mat-button class="icon-box p-0 w-100 justify-content-center view">
                                    <mat-icon class="w-100">visibility</mat-icon>
                                    <div class="w-100 action-buttons-text">VIEW</div>
                                </button>

                                <button (click)="reactivateSite(site)" mat-button class="icon-box p-0 w-100 justify-content-center activate-site">
                                    <mat-icon class="w-100">power_settings_new</mat-icon>
                                    <div class="w-100 action-buttons-text">REACTIVATE</div>
                                </button>

                                <button mat-button class="icon-box p-0 w-100 justify-content-center days-left" disabled>
                                    <span class="number-days-left">{{deletedSiteTimeLeftParse(site.toBeDeleted)}}</span><br>
                                    <span class="text-days-left action-buttons-text">DAYS LEFT</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</div>
