import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetResponseModel } from 'src/app/models/backend-generated-models';
import { AssetsService } from 'src/app/services/api/assets.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.scss']
})
export class AssetEditComponent implements OnInit {
  assetTypeList$ = this.assetsService.getAssetTypeList();

  sitesList$ = this.sitesService.getSiteFullList();

  assetUUID = this.route.snapshot.paramMap.get('uuid');

  assetDetails$: AssetResponseModel | undefined;

  formAssetEdit = new UntypedFormGroup({
    assetSiteName: this.fb.control(null),
    showedSiteName: this.fb.control(null),
    assetName: this.fb.control(null),
    assetDescription: this.fb.control(null),
    assetTypeId: this.fb.control(null),
    assetTypeName: this.fb.control(null),
  });

  assetName: string | undefined;
  siteName: string | undefined;
  assetTypeId: number | undefined;

  constructor(
    private sitesService: SitesService,
    private assetsService: AssetsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.assetsService.getAssetByUUID(this.assetUUID).subscribe((res) => {
      this.assetName = res.assetName;
      this.siteName = res.assetSiteName;
      this.assetTypeId = res.assetTypeID;

      this.formAssetEdit = new UntypedFormGroup({
        assetName: this.fb.control(res.assetName),
        assetDescription: this.fb.control(res.assetDescription),
        assetTypeId: this.fb.control(res.assetTypeID),
        assetSiteName: this.fb.control(res.assetSiteName),
        showedSiteName: this.fb.control({value: res.assetSiteName, disabled: true}),
        assetTypeName: this.fb.control({value: res.assetTypeName, disabled: true})
      });
    });
  }

  submit() {
    this.formAssetEdit.markAllAsTouched();

    if (!this.formAssetEdit.valid) return;

    this.assetsService.editAsset(this.assetUUID as string,this.formAssetEdit.value).subscribe((res: any) => {
      this.router.navigate(['assets/', this.assetUUID]);
    })
  }
}
