import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppComponent } from 'src/app/app.component';
import { CompaniesViewModel, UsersViewModel } from 'src/app/models/backend-generated-models';
import { IExtractedUserFromJWT } from 'src/app/models/user.model';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
  selector: 'app-sites-creation',
  templateUrl: './sites-creation.component.html',
  styleUrls: ['./sites-creation.component.scss']
})
export class SitesCreationComponent implements OnInit {

  userSystemRole: string | undefined;

  isMobile = this.appComponent.isMobile;
  currentParentCompanyCompanies: CompaniesViewModel[] | undefined;
  companiesNested: CompaniesViewModel[] | undefined;
  selectedParanetId?: number;

  switchCompany: boolean = true;

  formSiteCreation: UntypedFormGroup = new UntypedFormGroup({
    siteName: new UntypedFormControl('', Validators.required),
    siteCity: new UntypedFormControl('', Validators.required),
    siteAddress: new UntypedFormControl('', Validators.required),
    sitePostCode: new UntypedFormControl('', Validators.required),
    siteCompany: new UntypedFormGroup(
      {
        companyId: new UntypedFormControl(0),
        companyName: new UntypedFormControl(''),
        parentCompanyId: new UntypedFormControl(0)
      }
    ),
    siteNotes: new UntypedFormControl('', Validators.required),
  });

  isUserAdmin$ = this.userStateService.isUserAdmin;

  user: UsersViewModel | undefined

  parentCompaniesList: CompaniesViewModel[] | undefined;
  userCompany: string | undefined;
  userParentCompanyId: number | undefined;

  siteCreating_loading: boolean = false;

  constructor(
    private router: Router,
    private sitesService: SitesService,
    private usersService: UserService,
    private userStateService: UserStateService,
    private companiesService: CompaniesService,
    private appComponent: AppComponent,
    private jwtHelper: JwtHelperService,
  ) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userSystemRole = res?.role;
    })


    Promise.all([
      this.usersService.getUserInformation((this.jwtHelper.decodeToken(localStorage.getItem("jwt")!) as IExtractedUserFromJWT).UUId).toPromise(),
      this.companiesService.getCompaniesList().toPromise(),
    ]).then((res) => {
      this.initUserInformation(res[0]);
      this.initCompaniesList(res[1] as CompaniesViewModel[]);
    })

  }

  setCurrentParentCompanyCompanies() {
    this.formSiteCreation.get('siteCompany')?.get('parentCompanyId')?.patchValue(this.selectedParanetId);
    this.currentParentCompanyCompanies = [];
    this.companiesNested?.forEach(element => {
      if (element.parentCompanyId == this.selectedParanetId) {
        this.currentParentCompanyCompanies?.push(element);
      }
    });
  }

  submit() {
    if (!this.formSiteCreation.valid || this.siteCreating_loading) return

    this.siteCreating_loading = true;

    if (this.switchCompany == true) {
      this.createSite();
    } else {
      const newCompany =  { 
        companyName: this.formSiteCreation.get('siteCompany')?.get('companyName')?.value, 
        parentCompanyId: this.formSiteCreation.get('siteCompany')?.get('parentCompanyId')?.value 
      }

      this.companiesService.createCompany(newCompany).subscribe((res: any) => {
        this.formSiteCreation.value.siteCompany.companyId = res;
        this.createSite();
      },(err) =>  {
        console.log("company create failed", err);
        this.siteCreating_loading = false;
      }, () => this.siteCreating_loading = false)
    }
  }

  private createSite(){
    this.sitesService.createNewSite(this.formSiteCreation.value).subscribe(() => {
      this.router.navigate(['/sites']);
    }, (err) =>  {
      console.log("Site create failed", err);
      this.siteCreating_loading = false;
    }, () => this.siteCreating_loading = false);
  }

  private initUserInformation(userInformation: any) {
    this.user = userInformation;
    this.userCompany = userInformation.userCompany?.companyName;
    this.userParentCompanyId = userInformation.userCompany?.parentCompanyId || userInformation.userCompany?.companyId;
    this.formSiteCreation.get('siteCompany')?.get('parentCompanyId')?.patchValue(this.userParentCompanyId);
    this.selectedParanetId = this.userParentCompanyId;
  }

  private initCompaniesList(conpanies: CompaniesViewModel[]){

    this.companiesNested = conpanies;
    this.parentCompaniesList = this.companiesNested?.filter(x => x.parentCompanyId == null && x.parentCompanyName == null);
    if (this.selectedParanetId) {
      this.setCurrentParentCompanyCompanies();
    }
  }
}
