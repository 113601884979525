import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { concatMap, EMPTY, Observable, throwError } from 'rxjs';
import { TagsBaseViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';
import { TagsService } from 'src/app/services/api/tags.service';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';
import { LoadingService } from 'src/app/services/helpers/loading.service';
import { MessagesService } from 'src/app/services/helpers/messages-service.service';
import { DialogAddNewUsersBulk } from '../../users/users.component';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/helpers/export.service';

@Component({
  selector: 'app-admin-tags',
  templateUrl: './admin-tags.component.html',
  styleUrls: ['./admin-tags.component.scss']
})
export class AdminTagsComponent implements OnInit {

  isLoading$ = this.loadingService.isLoading$;
  models$: TagsBaseViewModel[] | undefined;
  tagsModels$: any | undefined;
  tags$: [string, [any]][] | undefined;
  nameSort: string;
  imageArray: {tagUuid: string, image: SafeUrl | undefined}[] | undefined = [];

  constructor(
    private tagsService: TagsService,
    private adminService: AdminService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private errorHandler: ErrorHandlerService,
    public dialog: MatDialog,
    private exportService: ExportService,
  ) { this.nameSort = "tagBatchId"; }

  ngOnInit(): void {
    this.adminService.getAllTags().subscribe(res => {
      this.models$ = res;
      this.tagsModels$ = res.map(p => {
        return {
          userEmail: p.tagBatchId,
          isTagAssociated: p.tagsModel?.isTagAssociated,
          tagAssociatedType: p.tagsModel?.tagAssociatedType,
          tagAssociatedUUID: p.tagsModel?.tagAssociatedUUID,
          tagsUUID: p.tagsModel?.tagsUUID,
        }
      })

      this.tags$ = Object.entries(this.groupByProperty(this.models$, this.nameSort));
    })
  }

  groupByProperty(arr: TagsBaseViewModel[], sortBy: string) {
    return arr.reduce(
      (previousVal: any, currentVal: any) => {
        const key = currentVal[sortBy] ?? "Default";
        if (!previousVal[key]) {
          previousVal[key] = [];
        }
        previousVal[key].push(currentVal);
        return previousVal;
      }, {});
  }

  getQRCodeByArray(array: [any], batch: string){
    this.imageArray = [];

    array.forEach(e => {
      this.tagsService.getQRCode(e.tagsModel?.tagsUUID).subscribe({
        next: (blobImage) => {
  
          let parsedBlobImage = URL.createObjectURL(blobImage);
          
          let res = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          this.imageArray?.push({tagUuid: e.tagsModel?.tagsUUID, image: res});
          return res;
        },
        error: (error: HttpErrorResponse) => {

          if (error.status === 400) {
            return EMPTY
          }

          this.errorHandler.handleError(error);

          return throwError(error);
        }
      });

    })
  }

  getQRCodeImage(tagUuid: string): SafeUrl | undefined{
    return this.imageArray?.find(x => x.tagUuid === tagUuid)?.image
  }

  getAttachmentRoute(type: string): string{
    if(type == "Site"){
      return "sites";
    }
    if(type == "User"){
      return "users";
    }
    if(type == "Asset"){
      return "assets";
    }
    if(type == "Board"){
      return "boards";
    }
    return "";
  }

  openAddTags() {
    this.dialog.open(DialogAddNewTags, {
      height: '240px',
      width: '600px',
      panelClass: 'dialog-box-tags',
    })
  }

  downloadBatchCSV(company: any) {
    console.log(company);
    let json: any[] = [];

    company[1].forEach((e: any) => {
      let exportRow : any = {};
      exportRow["url"] =`${environment.url}qr/${e.tagsModel?.tagsUUID}`
      json.push(exportRow);
    })

    const fileName = `Batch #${company[0]} count:${company[1].length}`;
    const showHeaders = false;
    this.exportService.exportAsCSVFile(json, fileName, showHeaders);
  }
}

@Component({
  selector: 'dialog-add-new-tags',
  templateUrl: 'dialog-add-new-tags.html',
  styleUrls: ['./admin-tags.component.scss']
})
export class DialogAddNewTags implements OnInit {

  /* TODO: */
  newTagsForm = new UntypedFormGroup(
    {
      tagsCount: new UntypedFormControl('', [Validators.required, Validators.min(1)])
    }
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    private adminService: AdminService,
    private messagesService: MessagesService,
    public dialogRef: MatDialogRef<DialogAddNewUsersBulk>
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.newTagsForm.valid) {

      this.adminService.createTags(this.newTagsForm.value).subscribe((res) => {
        this.messagesService.tagsSuccessfullyCreated();
      });

      this.dialogRef.close()
    }

  }
}


