<div class="container-fluid">
    <mat-card class="p-0">
        <mat-card-header class="p-3">
            <mat-card-title class="m-0">Association (Clone Asset)</mat-card-title>
        </mat-card-header>
        <mat-card-content class="p-3">
            <div class="row">
                <div class="col-12 m-0 p-0">
                    <mat-form-field class="w-100">
                        <mat-label class="pl-2 d-flex align-items-center">
                           From Site
                        </mat-label>
                        <mat-select  [(value)]="selectedSite" class="invite-to-site-select" (selectionChange)="getAssetsForSelectSite(selectedSite)">
                            <mat-option *ngFor="let site of sites"
                            [value]="site.siteUUID">
                            {{site.siteName}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-label class="pl-2 d-flex align-items-center">
                           Asset
                        </mat-label>
                        <mat-select  [(value)]="selectedAsset" class="invite-to-site-select">
                            <mat-option *ngFor="let asset of assets"
                            [value]="asset.assetUUID">
                            {{asset.assetName}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="col-12 action-buttons-container">
        <div class="row d-flex justify-content-between">
            <div class="col-6 col-md-5 col-xl-3">
                <button mat-button type="submit" [routerLink]="['/tags', tagUUID, 'association']" class="action-buttons cancel w-100">Cancel</button>
            </div>
            <div class="col-6 col-md-5 col-xl-3">
                <button mat-button type="submit" (click)="submit()" [disabled]="selectedSite == undefined" class="action-buttons submit w-100">Associate</button>
            </div>
        </div>
    </div>
</div>