import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable, Subscription, catchError, map, mergeMap, of } from 'rxjs';
import { CompanyFilterModel, SiteFilterModel, SiteRoleFilterModel, SystemRoles, UserFilters, UsersAndFilters, UsersViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService, UserSitesViewModel, UsersWithSelectedSites } from 'src/app/services/api/user.service';
import { DialogUserDelete } from '../admin/admin-user-management/admin-user-management.component';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SiteOwner, UserSiteRoles, getListUserSiteRoles } from 'src/app/models/user.model';
import { MessagesService } from 'src/app/services/helpers/messages-service.service';
import { UserStateService } from 'src/app/services/state/user-state.service';
import { PhotoConvertService } from 'src/app/services/helpers/photo-convert';
import { Router } from '@angular/router';
import { DialogAsignToCompanyComponent } from '../dialogs/dialog-asign-to-company/dialog-asign-to-company.component';
import { LoadingService } from 'src/app/services/helpers/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  isLoading$ = this.loadingService.isLoading$;
  currenUserRole$ = this.userStateService.currentUser$.pipe(map(x => x?.role))

  usersBulkArr: UsersViewModel[] = [];
  usersBulkArrCount = this.usersBulkArr.length;
  buttonForBulkOperation = false;

  // here we can return only the user group name instead of companyId

  globalFilter: HttpParams | undefined;

  selectedGroup: "Company" | "Site" | "Role" = "Site";
  sortType: "asc" | "desc" | null = null;

  companiesGroupList: { companyName: string; companyId: number; count: number }[] = [];
  sitesGroupList: { siteName: string; siteUuid: string; count: number; }[] = [];
  rolesGroupList: { roleName: string; roleId: number; count: number; }[] = [];

  companiesUsersList: { companyId: number; users: Observable<UsersViewModel[]> }[] = [];
  sitesUsersList: { siteUuid: string; users: Observable<UsersViewModel[]> }[] = [];
  rolesUsersList: { roleId: number; users: Observable<UsersViewModel[]> }[] = [];

  isFilter = false;
  filter$: UserFilters | undefined;

  selectionCompanies = new SelectionModel<number | undefined>(true, []);
  selectionSites = new SelectionModel<string | undefined>(true, []);
  selectionRoles = new SelectionModel<number | undefined>(true, []);


  /* ==================================================== COMPANIES ==================================================== */
  getCompaniesUsersList(companyId: number) {
    return this.companiesUsersList.filter(x => x.companyId == companyId).map(x => x.users);
  }

  setCompaniesUsersList(companyId: number) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.companiesUsersList.map(x => x.companyId).includes(companyId)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5);
    
    if (!companyId) filterParams = filterParams?.append("ReturnOnlyGroupWithNullCompanyId", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithCompanyId", companyId!);

    this.companiesUsersList.push({ companyId: companyId, users: this.usersService.getUsersListByGroup(filterParams).pipe(map(x => x!)) })
  }

  setCompaniesUsersListWithPagination(companyId: number, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize)

    if (!companyId) filterParams = filterParams?.append("ReturnOnlyGroupWithNullCompanyId", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithCompanyId", companyId!);

    this.companiesUsersList[this.companiesUsersList.map(x => x.companyId).indexOf(companyId)] = {
      companyId: companyId,
      users: this.usersService.getUsersListByGroup(filterParams).pipe(
        map(x => x!)
      )
    }
  }


  /* ==================================================== SITES ==================================================== */
  getSitesUsersList(siteUuid: string) {
    return this.sitesUsersList.filter(x => x.siteUuid == siteUuid).map(x => x.users);
  }

  setSitesUsersList(siteUuid: string) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.sitesUsersList.map(x => x.siteUuid).includes(siteUuid)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5);

    if (!siteUuid || siteUuid! == "00000000-0000-0000-0000-000000000000") filterParams = filterParams?.append("ReturnOnlyGroupWithNullSiteUuid", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithSiteUuid", siteUuid!);

    this.sitesUsersList.push({ siteUuid: siteUuid, users: this.usersService.getUsersListByGroup(filterParams).pipe(map(x => x!)) })
  }

  setSitesUsersListWithPagination(siteUuid: string, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize)

    if (!siteUuid || siteUuid! == "00000000-0000-0000-0000-000000000000") filterParams = filterParams?.append("ReturnOnlyGroupWithNullSiteUuid", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithSiteUuid", siteUuid!);

    this.sitesUsersList[this.sitesUsersList.map(x => x.siteUuid).indexOf(siteUuid)] = {
      siteUuid: siteUuid,
      users: this.usersService.getUsersListByGroup(filterParams).pipe(
        map(x => x!)
      )
    }
  }

  /* ==================================================== Role ==================================================== */
  getRolesUsersList(roleId: number) {
    return this.rolesUsersList.filter(x => x.roleId == roleId).map(x => x.users);
  }

  setRolesUsersList(roleId: number) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.rolesUsersList.map(x => x.roleId).includes(roleId)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5);

    if (!roleId) filterParams = filterParams?.append("ReturnOnlyGroupWithNullSiteRoleId", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithSiteRoleId", roleId!);

    this.rolesUsersList.push({ roleId: roleId, users: this.usersService.getUsersListByGroup(filterParams).pipe(map(x => x!)) })
  }

  setRolesUsersListWithPagination(roleId: number, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize)

    if (!roleId) filterParams = filterParams?.append("ReturnOnlyGroupWithNullSiteRoleId", true);
    else filterParams = filterParams?.append("ReturnOnlyGroupWithSiteRoleId", roleId!);

    this.rolesUsersList[this.rolesUsersList.map(x => x.roleId).indexOf(roleId)] = {
      roleId: roleId,
      users: this.usersService.getUsersListByGroup(filterParams).pipe(
        map(x => x!)
      )
    }
  }

  constructor(
    private usersService: UserService,
    private adminService: AdminService,
    private bottomSheet: MatBottomSheet,
    private photoConvert: PhotoConvertService,
    private userStateService: UserStateService,
    private loadingService: LoadingService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
  ) {  }

  exec(event: PageEvent) {
    console.log("changed", event)

    let params = new HttpParams();

    params = params.append("PageIndex", event.pageIndex)
    params = params.append("PageSize", event.pageSize)

    this.selectionCompanies.selected.forEach(element => {
      params = params.append("FilteredCompaniesIds", element!);
    });
    this.selectionSites.selected.forEach(element => {
      params = params.append("FilteredSitesUuids", element!);
    });
    this.selectionRoles.selected.forEach(element => {
      params = params.append("FilteredSiteRolesIds", element!);
    });

    // this.usersService.getUsersList(params).subscribe((res) => {
    //   console.log("after change", res)
    //   this.users = res;
    // })
  }

  @ViewChildren('users') things: QueryList<any> | undefined;

  users: UsersAndFilters | undefined;
  paginatorLength = 100; 

  search: string | undefined; 

  currentSiteId: number | undefined;

  currentDialog: Subscription | undefined;

  ngOnInit(): void {
    if (!this.globalFilter) this.globalFilter = new HttpParams();
    this.usersService.getUsersListFilter().subscribe((res) => {

      res?.filteredCompanies?.forEach(element => {
        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count!/* .pipe(map(x => x.users)) */ })
      });

      res?.filteredSites?.forEach(element => {
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
      })

      res?.siteRoleFilterModels?.forEach(element => {
        this.rolesGroupList.push({ roleName: element.name!, roleId: element.id!, count: element.count! })
      })

      this.filter$ = res;

      this.sortType = "asc";
    })

    let params = new HttpParams()

    params = params.append("PageIndex", 0).append("PageSize", 5)

    // this.usersService.getUsersList(
    //   params
    // ).subscribe((res) => {
    //   console.log("response user list", res)

    //   if (res.pageInfo?.totalCount) this.paginatorLength = res.pageInfo?.totalCount;
    //   this.users = res;
    //   this.filter$ = res.updatedFilters;

    //   console.log("filter", res.updatedFilters)
    //   console.log("filter", this.filter$)
    // });
  }

  ngAfterViewInit() {
    if (this.things) {
      this.things.changes.subscribe(() => {
        this.ngForRendered();
      })
    }
  }

  ngForRendered() {
    let userScrollPositionItem = localStorage.getItem('userScrollPosition');
    if (userScrollPositionItem !== null) {
      this.document.documentElement.scrollTop = parseInt(userScrollPositionItem);
      localStorage.removeItem('userScrollPosition')
    }
  }

  ngOnDestroy(): void {
    localStorage.setItem('userScrollPosition', (this.document.documentElement.scrollTop).toString());
    this.search ? localStorage.setItem('userSearch', this.search) : localStorage.setItem('userSearch', "")
  }

  getMappedCompanies(companies: CompanyFilterModel[]) {
    return companies.filter(x => x !== null).map(x => x.id);
  }

  getMappedSites(sites: SiteFilterModel[]) {
    return sites.filter(x => x !== null).map(x => x.uuid);
  }

  getMappedRoles(roles: SiteRoleFilterModel[]) {
    return roles.filter(x => x !== null).map(x => x.id);
  }

  avatarConvert(photo: any) {
    if (photo == null) return null;
    
    return this.photoConvert.photoConvert(photo);
  }

  parseDate(date: Date | undefined) {
    return date?.toLocaleString().split('T')[0]
  }

  changeUserRole(user: UsersViewModel, role: string) {
    this.usersService.setUserRole(user.userUUID, role).subscribe(() => {
      // return user.userSiteRole = {
      //   siteRoleTitle: role
      // }
    })
  }

  filter() {
    this.companiesGroupList = [];
    this.sitesGroupList = [];
    this.rolesGroupList = [];

    this.companiesUsersList = [];
    this.sitesUsersList = [];
    this.rolesUsersList = [];

    // if (this.selectionCompanies.selected.length == 0
    //   && this.selectionSites.selected.length == 0
    //   && this.selectionRoles.selected.length == 0) {

    //   this.usersService.getUsersListFilter().subscribe((res) => {

    //     res?.filteredCompanies?.forEach(element => {
    //       this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
    //     });

    //     res?.filteredSites?.forEach(element => {
    //       this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
    //     })

    //     res?.siteRoleFilterModels?.forEach(element => {
    //       this.rolesGroupList.push({ roleName: element.name!, roleId: element.id!, count: element.count! })
    //     })
    //   })
    // }

    this.globalFilter = new HttpParams();

    this.selectionCompanies.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredCompaniesIds", element!);
    });
    this.selectionSites.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredSitesUuids", element!);
    });
    this.selectionRoles.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredSiteRolesIds", element!);
    });

    this.isFilter = true;

    this.usersService.getUsersListFilter(this.globalFilter).subscribe((res) => {

      res?.filteredCompanies?.forEach(element => {
        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
      });

      res?.filteredSites?.forEach(element => {
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
      })

      res?.siteRoleFilterModels?.forEach(element => {
        this.rolesGroupList.push({ roleName: element.name!, roleId: element.id!, count: element.count! })
      })
    })

    // let params = new HttpParams();

    // params = params.append("PageIndex", 0)
    // params = params.append("PageSize", 5)

    // this.selectionCompanies.selected.forEach(element => {
    //   params = params.append("FilteredCompaniesIds", element!);
    // });
    // this.selectionSites.selected.forEach(element => {
    //   params = params.append("FilteredSitesUuids", element!);
    // });
    // this.selectionRoles.selected.forEach(element => {
    //   params = params.append("FilteredSiteRolesIds", element!);
    // });

    // this.usersService.getUsersList(
    //   params
    // ).subscribe((res) => {
    //   if (res.pageInfo?.totalCount) this.paginatorLength = res.pageInfo?.totalCount;
    //   this.users = res;
    // })
  }

  openActivateOrDeactivateDialog(user: UsersViewModel, i: number) {
    this.dialog.open(DialogUserDeactivate, {
      width: '800px',
      data: user,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((userUUID) => {
          if (userUUID) return this.usersService.activateOrDeactivateUser(user.userUUID, 1-(user.userIsActive ?? 0))
          return of(userUUID)
        }),
        catchError((error) => {
          return error
        })
      ).subscribe(
        (res) => {
          // let user = this.usersModels$?.find((x: { userUUID: any; }) => x.userUUID == res.userUUID);
          // if (user) {
          //   user.userIsActive = 1 - user.userIsActive;
          // }
          
          if (res) user.userIsActive = 1 - (user.userIsActive ?? 0);
        }
      )
  }

  openDeleteDialog(user: UsersViewModel, i: number) {

    this.dialog.open(DialogUserDelete, {
      width: '800px',
      data: user,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((userUUID) => {
          if (userUUID) return this.adminService.deleteUser(user.userUUID)
          return of(userUUID)
        }),
        catchError((error) => {
          return error
        })
      ).subscribe(
        (res) => {
          // let element = this.usersModels$?.find((x: { userUUID: any; }) => x.userUUID == res.userUUID);
          // if (element) {
          //   this.users$?.forEach(x => {
          //     let i = x[1].findIndex(e => e.userUUID === user.userUUID);
          //     x[1].splice(i, 1);
          //   })
          // }

          user.userIsDeleted = true;

          // if (this.selectedGroup == "Company") {
          //   let companyGroupList = this.companiesGroupList.find(x => (x?.companyId ?? 0)== user.userCompany?.companyId);
          //   if (companyGroupList) companyGroupList.count -= 1;
          // }

          // if (this.selectedGroup == "Site") {
          //   let siteGroupList = this.sitesGroupList.find(x => user.userSites?.some(u => u.id == x.siteUuid));
          //   if (siteGroupList) siteGroupList.count -= 1;
          // }

          // if (this.selectedGroup == "Role") {
          //   let roleGroupList = this.rolesGroupList.find(x => user.userSites?.some(u => u.role == x.roleName));
          //   if (roleGroupList) roleGroupList.count -= 1;
          // }
        }
      )
  }

  removeUserFromSite(user: UsersViewModel, i: number) {
    const singleUser = [user];
    this.openBulkOperation(singleUser);
  }

  clearSelectionFilters() {
    this.selectionCompanies.clear();
    this.selectionSites.clear();
    this.selectionRoles.clear();
  }  

  sotrByRoleDescendingOrder() {
    this.selectedGroup = "Role"; 
    this.sortType = "desc";
  }
  sotrByCompanyDescendingOrder() {
    this.selectedGroup = "Company";
    this.sortType = "desc";
  }
  sotrBySiteDescendingOrder() {
    this.selectedGroup = "Site";
    this.sortType = "desc";
  }
  sotrByCompanyrAscendingOrder() {
    this.selectedGroup = "Company";
    this.sortType = "asc";
  }
  sotrBySiteAscendingOrder() {
    this.selectedGroup = "Site";
    this.sortType = "asc";
  }
  sotrByRoleAscendingOrder() {
    this.selectedGroup = "Role";
    this.sortType = "asc";
  }

  markForBulkOperation(user: UsersViewModel) {
    let index = this.usersBulkArr.indexOf(user, 0)

    if (index > -1) {
      this.usersBulkArr.splice(index, 1);
    } else {
      this.usersBulkArr.push(user);
    }

    this.usersBulkArr.length > 0 ? this.buttonForBulkOperation = true : this.buttonForBulkOperation = false;

    this.usersBulkArrCount = this.usersBulkArr.length

  }

  openBulkOperation(singleUser: UsersViewModel[] = []) {
    this.dialog.open(DialogRemoveUsersFromSiteBulk, {
      width: '800px',
      panelClass: 'dialog-box',
      data: singleUser.length > 0 ? singleUser : this.usersBulkArr
    }).afterClosed().subscribe((res: { site: UserSitesViewModel | undefined, userUUID: string | undefined }[]) => {
      if (!res) return;

      let users = res.map(obj => { return { siteUuid: obj.site?.siteUUID, userUuid: obj.userUUID } });
      const usersBySite = users.reduce((acc: any, curr: any) => {
        if (!acc[curr.siteUuid]) {
          acc[curr.siteUuid] = [];
        }
        acc[curr.siteUuid].push(curr.userUuid);
        return acc;
      }, {});

      for (const siteUUID in usersBySite) {
        const users = usersBySite[siteUUID];
        this.usersService.removeUsersFromSitesBulk(users, siteUUID).subscribe(res => console.log("successfully removed"));
      }
    });
  }




  openBulkInviteToSite() {
    this.usersService.setUsersForInvite(this.usersBulkArr)
    this.router.navigate(['/users/invite']);
  }

  openDialogDisassociateFromCompanyBulk() {
    this.dialog.open(DialogDisassociateUserCompany, {
      width: '600px',
      panelClass: 'dialog-box',
      data: this.usersBulkArr
    })
  }

  openBulkAddUsers() {
    this.dialog.open(DialogAddNewUsersBulk, {
      height: '600px',
      width: '800px',
      panelClass: 'dialog-box',
      data: this.usersBulkArr
    })
  }

  openDialogAssignToCompanyBulk() {
    let maxWidth: string;
    if(window.innerWidth > 600){
      maxWidth = '80vw'
    } else {
      maxWidth = '100vw'
    }

    this.dialog.open(DialogAsignToCompanyComponent, {
      width: '100%',
      maxWidth: maxWidth,
      panelClass: 'dialog-box-mobile',
      data: {type: 'users', array: this.usersBulkArr.map(x => x.userUUID)},
    })/* .afterClosed().subscribe(() => window.location.reload()) */
  }

  openBottomSheetDialogSelector(selectedSitesCount: number) {
    this.bottomSheet.open(BottomDialogSelector, {
      panelClass: 'bottom-sheet-dialog',
      data: selectedSitesCount
    }).afterDismissed().subscribe(res => {
      /* DIALOGS WILL OPEN FROM HERE */
      // console.log("bottom sheet res", res)

      switch (res) {
        case 'invite-to-site':
          this.openBulkInviteToSite();
          break;
        case 'remove-from-site':
          this.openBulkOperation();
          break;

        case 'associate-with-company':
          this.openDialogAssignToCompanyBulk();
          break;

        case 'disassociate-from-company':
          this.openDialogDisassociateFromCompanyBulk();
          break;

        default:
          break;
      }
    })
  }
}


@Component({
  selector: 'dialog-add-new-users-bulk',
  templateUrl: 'dialog-add-new-users-bulk.html',
  styleUrls: ['../admin/admin-user-management/admin-user-management.component.scss', './users.component.scss']

})
export class DialogAddNewUsersBulk implements OnInit {

  newUsersForm = new UntypedFormGroup(
    {
      activateNewUsersImmediately: new UntypedFormControl(false, Validators.required),
      users: new UntypedFormArray([
        new UntypedFormGroup({
          userFirsName: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
          userLastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
          usersEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
          userSiteUUID: new UntypedFormControl('', Validators.required),
          userSiteRole: new UntypedFormControl('', Validators.required)
        })
      ])
    }
  )

  sitesList$ = this.sitesService.getSiteFullList();

  //can't invite site owner
  roles = getListUserSiteRoles().filter(x => x !== SiteOwner);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    private sitesService: SitesService,
    private userService: UserService,
    private messagesService: MessagesService,
    public dialogRef: MatDialogRef<DialogAddNewUsersBulk>
  ) { }

  ngOnInit(): void {

  }

  get users() {
    return this.newUsersForm.controls["users"] as UntypedFormArray;
  }

  addNewUser() {
    this.users.push(
      new UntypedFormGroup({
        userFirsName: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
        userLastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
        usersEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
        userSiteUUID: new UntypedFormControl('', Validators.required),
        userSiteRole: new UntypedFormControl('', Validators.required)
      })
    )
  }

  removeUser(i: number) {
    this.users.removeAt(i);
  }

  submit() {
    if (this.newUsersForm.valid) {
      this.userService.addNewUsers(this.newUsersForm.value).subscribe((res) => {
        // console.log("successfully saved", res)
        this.messagesService.usersSuccessfullyCreated();
      });

      this.dialogRef.close()
    }



    console.log("valid", this.newUsersForm.valid)

    console.log("user form", this.newUsersForm.value)
  }
}

@Component({
  selector: 'dialog-user-deactivate',
  templateUrl: 'dialog-user-deactivate.html',
  styleUrls: ['../admin/admin-user-management/admin-user-management.component.scss']
})
export class DialogUserDeactivate {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogUserDelete>
  ) { }
}

@Component({
  selector: 'dialog-remove-user-from-site',
  templateUrl: 'dialog-remove-user-from-site.html',
  styleUrls: ['../admin/admin-user-management/admin-user-management.component.scss']
})
export class DialogRemoveUserFromSite {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogRemoveUserFromSite>
  ) { }
}

@Component({
  selector: 'dialog-invite-to-site',
  templateUrl: 'dialog-invite-to-site.html',
  styleUrls: ['../admin/admin-user-management/admin-user-management.component.scss', './users.component.scss']
})
export class DialogInviteToSite {

  roles = Object.keys(SystemRoles).slice(Object.keys(SystemRoles).length / 2);
  usersSites: { userUUID: any[] } | undefined;

  @ViewChild('select') el: ElementRef | undefined;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UsersViewModel[] | undefined,
    private userService: UserService,

    public dialogRef: MatDialogRef<DialogInviteToSite>
  ) {
    this.userService.getUsersSites(this.data?.map(x => x.userUUID) as string[]).subscribe(res => {
      this.usersSites = res;
    })
  }

  siteUuidList: string[] = [];
  siteRoleList: number[] = [];

  inputFilled = (this.data?.length === this.siteUuidList.length) && (this.data?.length === this.siteRoleList.length) && (Object.values(this.siteUuidList).length === this.siteUuidList.length) && (Object.values(this.siteRoleList).length === this.siteUuidList.length);

  setSiteUUID(siteUUID: string, i: number, event: any) {
    if (event.isUserInput) {
      this.siteUuidList[i] = siteUUID;

      this.inputFilled = (this.data?.length === this.siteUuidList.length) && (this.data?.length === this.siteRoleList.length) && (Object.values(this.siteUuidList).length === this.siteUuidList.length) && (Object.values(this.siteRoleList).length === this.siteUuidList.length);
    }
  }

  setSiteRole(role: any, i: number, event: any) {
    if (event.isUserInput) {
      this.siteRoleList[i] = parseInt(SystemRoles[role as keyof typeof SystemRoles]);

      this.inputFilled = (this.data?.length === this.siteUuidList.length) && (this.data?.length === this.siteRoleList.length) && (Object.values(this.siteUuidList).length === this.siteUuidList.length) && (Object.values(this.siteRoleList).length === this.siteUuidList.length);
    }
  }

  // "userEmail": "miden28778@ezgiant.com",
  // "siteUUId": "16511f92-caac-4b2e-98c1-e9b63650bd1a",
  // "userSiteRoleId": 1

  submit() {

    // console.log("el", this.el?.nativeElement);

    /* for (let i = 0; i < this.data!.length; i++) {
      this.userService.inivteUsersToSiteBulk(
        {
          userEmail: this.data![i].userEmail,
          siteUUId: this.siteUuidList[i],
          userSiteRoleId: this.siteRoleList[i]
        }
      ).subscribe(
        res => console.log("invite result")
      )

    } */
  }

}


@Component({
  selector: 'dialog-disassociate-user-company',
  templateUrl: 'dialog-disassociate-user-company.html',
  styleUrls: ['./users.component.scss']
})
export class DialogDisassociateUserCompany {
  constructor(
    @Inject(MAT_DIALOG_DATA) public usersBulkArr: UsersViewModel[] | undefined,

    private userService: UserService,
    public dialogRef: MatDialogRef<DialogDisassociateUserCompany>
  ) { }

  submit() {
    this.userService.disassociateUsersFromCompany({
      usersToDissociate: this.usersBulkArr?.map(x => x.userUUID) as string[]
    }).subscribe()
  }

}

@Component({
  selector: 'dialog-remove-users-from-site-bulk',
  templateUrl: 'dialog-remove-users-from-site-bulk.html',
  styleUrls: ['../admin/admin-user-management/admin-user-management.component.scss']

})
export class DialogRemoveUsersFromSiteBulk {

  removeFromSiteUsers: UsersWithSelectedSites[] | undefined;

  userSitesForRemoveArr: { site: UserSitesViewModel | undefined, userUUID: string | undefined }[] = [];

  removeSiteForUser(site: UserSitesViewModel | undefined, user: UsersWithSelectedSites) {
    this.removeFromSiteUsers!.find(u => u.userUuid == user.userUuid)!.userSites = this.removeFromSiteUsers?.find(u => u.userUuid == user.userUuid)?.userSites?.filter((s) => s.siteUUID != site?.siteUUID);

    this.userSitesForRemoveArr = this.userSitesForRemoveArr.filter((obj) => !(obj.site?.siteUUID == site?.siteUUID && obj.userUUID == user.userUuid));
  }

  remove() {
    this.dialogRef.close(this.userSitesForRemoveArr);
  }

  cancel() {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UsersViewModel[],
    private userService: UserService,


    public dialogRef: MatDialogRef<DialogRemoveUsersFromSiteBulk>
  ) {

    // console.log("map to string", data.map(x => x.userUUID).toString())

    userService.getUserSites({ "userUuids": data.map(x => x.userUUID) as Array<string> }).subscribe((res) => {
      // console.log("response data for remove from site", res)

      this.removeFromSiteUsers = res.userUUID;

      this.removeFromSiteUsers?.forEach(element => {

        element.userSites = element.userSites?.filter(site => site.siteRole != SiteOwner);

        element.userSites?.forEach(site => {
          if (site) {
            this.userSitesForRemoveArr.push({
              site,
              userUUID: this.data.find(user => user.userFullName === element.userName)?.userUUID
            })
          }
        });
      });

      // console.log(this.removeFromSiteUsers, "removed")
    })

  }


}


@Component({
  selector: 'bottom-dialog-selector',
  templateUrl: 'bottom-dialog-selector.html',
  styleUrls: ['./users.component.scss']
})
export class BottomDialogSelector implements OnInit {

  userSystemRole: string | undefined;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: number | undefined,
    public bottomSheetRef: MatBottomSheetRef<BottomDialogSelector>,
    private userStateService: UserStateService,
  ) { }

  ngOnInit(): void {
    this.userStateService.currentUser$.subscribe((res) => {
      this.userSystemRole = res?.role;
      console.log(this.userSystemRole)
    })
  }


  closeBottomSheet(data: string) {
    this.bottomSheetRef.dismiss(data);
  }
}
