import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AddAssetTypeModule, ModulesAdminViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';

@Component({
  selector: 'app-asset-type-create',
  templateUrl: './asset-type-create.component.html',
  styleUrls: ['./asset-type-create.component.scss']
})
export class AssetTypeCreateComponent implements OnInit {
  newAssetType = new UntypedFormGroup({
    assetTypeName: this.fb.control(null, Validators.required),
    assetTypeModules: this.fb.array([])
  });

  selectedCategories: ModulesAdminViewModel[] | undefined = [];
  modules: ModulesAdminViewModel[] | undefined;

  get assetTypeModules() {
    return this.newAssetType.controls["assetTypeModules"] as UntypedFormArray;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private adminSevice: AdminService
  ) { }

  ngOnInit(): void {
    this.adminSevice.getModules().subscribe(res => this.modules = res);
  }

  onChange($event: any, category: ModulesAdminViewModel) {
    if ($event.checked) {
      this.selectedCategories?.push(category);
    } else {
      let index = this.selectedCategories?.findIndex(c => c.modileId == category.modileId)!;
      this.selectedCategories?.splice(index, 1);
    }
  }

  submit() {
    this.newAssetType.markAllAsTouched();

    if (!this.newAssetType.valid) return;
   
    this.selectedCategories?.forEach(category => {
      let assetTypeModule = {
        moduleId: category.modileId
      }

      this.assetTypeModules.push(this.fb.control(assetTypeModule));
    });
    
    this.adminSevice.createAssetType(this.newAssetType.value).subscribe(() => {
      this.router.navigate(['admin/asset-types']);
    });
  }
}
