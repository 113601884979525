import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private snackBar: MatSnackBar) {}

  forgotPasswordLinkSend() {
    this.snackBar.open(
      "We have sent you an email with a reset password link",
      "Ok", 
      {
        duration: 10000,
        panelClass: ['main-color-snackbar']
      }
    );
  }

  passwordSuccessfullyReset() {
    this.snackBar.open(
      "The password has been changed successfully",
      "Ok", 
      {
        duration: 5000,
        panelClass: ['main-color-snackbar']
      }
    );
  }

  successfullyRegistered() {
    this.snackBar.open(
      "Check your email to confirm registration",
      "Ok", 
      {
        duration: 5000,
        panelClass: ['main-color-snackbar']
      }
    );
  }

  assetSuccessfullyDeleted() {
    this.snackBar.open(
      "Asset successfully deleted",
      "Close", 
      {
        duration: 5000,
        panelClass: ['main-color-snackbar']
      }
    );
  }

  usersSuccessfullyCreated() {
    this.snackBar.open(
      "Users successfully created",
      "Ok", 
      {
        duration: 5000,
        panelClass: ['main-color-snackbar']
      }
    );
  }

  tagsSuccessfullyCreated() {
    this.snackBar.open(
      "Tags successfully created",
      "Ok", 
      {
        duration: 5000,
        panelClass: ['main-color-snackbar']
      }
    );
  }
}
