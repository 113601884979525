<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="fixed-button-container d-flex justify-content-end">
  <button routerLink="/admin/asset-types/create" mat-button class="fixed-button d-flex"><mat-icon>add_circle</mat-icon><div class="d-none d-md-block">&nbsp;&nbsp;NEW ASSET TYPE</div></button>
</div>

<div class="page-top-breadcrumbs">
  ADMIN - ASSET TYPES
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
  Asset Types
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->

<div class="container-fluid">
  <cdk-virtual-scroll-viewport itemSize="10" class="scrollbar-box-asset-types">
    <table mat-table [dataSource]="assetTypeData$" class="mat-elevation-z8">

      <ng-container matColumnDef="assetTypeName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" class="asset-name"> {{element.assetTypeName}} </td>
      </ng-container>
    
      <ng-container matColumnDef="assetTypeModules">
        <th class="modules" mat-header-cell *matHeaderCellDef>Modules</th>
        <td class="modules d-flex d-md-table-cell flex-wrap" mat-cell *matCellDef="let element">
          <span class="col-12 d-block d-md-none modules">Modules: <br></span>
          <mat-chip-list class="col-12 p-1">
            <cdk-virtual-scroll-viewport itemSize="10" class="scrollbar-box">
              <mat-chip *cdkVirtualFor="let module of element.assetTypeModules">{{module.moduleName}}</mat-chip>
            </cdk-virtual-scroll-viewport>
            </mat-chip-list>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="assetCount">
        <th mat-header-cell *matHeaderCellDef>Count</th>
        <td mat-cell *matCellDef="let element">
           <div class="d-flex align-items-center"><mat-icon>image</mat-icon>&nbsp;&nbsp;<span class="italic">Assets:</span>&nbsp;&nbsp;<span class="strong">{{element.assetCount}}</span></div>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let element"><span class="italic">Created:</span>&nbsp;&nbsp;<span class="strong">{{parseDate(element.createdAt)}}</span></td>
      </ng-container>
    
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef>Updated</th>
        <td mat-cell *matCellDef="let element"><span class="italic">Updated:</span>&nbsp;&nbsp;<span class="strong">{{parseDate(element.updatedAt)}}</span></td>
      </ng-container>
    
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action-buttons-cusstom-width"></th>
        <td mat-cell *matCellDef="let element" class="action-buttons-cusstom-width">
    
          <div class="d-flex">
            <button [routerLink]="[element.assetTypeUuid,'edit']" mat-button class="icon-box edit px-1 px-xs-2 px-md-3 d-flex flex-wrap" style="text-align: center;">
              <mat-icon class="w-100">edit</mat-icon>
              <div class="w-100">EDIT</div>
            </button>
    
            <button (click)="deleteAssetType(element, element.assetTypeUuid)" mat-button class="icon-box delete px-1 px-xs-2 px-md-3 d-flex flex-wrap" style="text-align: center;">
              <mat-icon class="w-100">delete</mat-icon>
              <div class="w-100">DELETE</div>
            </button>
          </div>
    
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>