import { Injectable } from '@angular/core';
import * as alasql from 'alasql';
const XLSX = require('xlsx');
alasql.setXLSX(XLSX);

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportAsExcelFile(json: any[], excelFileName: string, showHeaders: boolean = true): void {
    const options = {
      headers: showHeaders
    };

    alasql(`SELECT * INTO XLSX('${excelFileName}', ?) FROM ?`, [options, json]);
  }

  exportAsCSVFile(json: any[], csvFileName: string, showHeaders: boolean = true): void {
    const options = {
      headers: showHeaders
    };

    alasql(`SELECT * INTO CSV('${csvFileName}', ?) FROM ?`, [options, json]);
  }
}
