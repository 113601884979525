import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs';
import { UserBasicViewModel } from 'src/app/models/backend-generated-models';
import { IExtractedUserFromJWT, IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/api/auth.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  options = this.formBuilder.group({
    bottom: 0,
    fixed: false,
    top: 0,
  });

  userList!: [UserBasicViewModel] | any;

	currentUserData: IUser | null | undefined

  currenUserRole$ = this.userStateService.currentUser$.pipe(map(x => x?.role))

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private userStateService: UserStateService,
    private jwtHelper: JwtHelperService,
  ) {
    this.userService.getModulesList().subscribe(data => {
      this.userList = data as [UserBasicViewModel];
      console.log("user list", this.userList)
    })
  }

  ngOnInit(): void {
		this.userStateService.currentUser$.subscribe(
			res => this.currentUserData = res
		)
  }

  checkSiteRoles() {
		const token = localStorage.getItem('jwt')

		if (!token || this.jwtHelper.isTokenExpired(token)) return false
		
		let decodedToken = this.jwtHelper.decodeToken(token) as IExtractedUserFromJWT
		
		if (decodedToken.SiteRoles.includes("Site Owner") || decodedToken.SiteRoles.includes("Site Manager")) {
			return true;
		}

		return false;
	}

  togglerEventHandler(sidenav: any) {
    sidenav.toggle()
  }

  logout() {
    this.auth.logout();
  }
}
