import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ddialog-remove-ticc-type',
  templateUrl: 'dialog-remove-ticc-type.html'
})
export class DialogRemoveTiccType {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogRemoveTiccType>
  ) { }
}