<div class="dialog">
    <h1 class="dialog-title mx-2 mb-3">Invite to Site</h1>

    <div class="dialog-main-text mx-2 mb-3">
        <!-- Are you sure you want to remove user <span class="italic data">"{{data[0].userFullName}}"</span> <br> from site
        <div *ngFor="let item of data">
            {{item.userFullName}}
        </div> -->
        <div class="row">
            <div class="col-12 dialog-data italic">
                {{usersSites?.userUUID?.length}} users selected
            </div>

            <div class="col-12">
                <cdk-virtual-scroll-viewport itemSize="50" class="scrollbar-box">
                    <!-- <div *ngFor="let item of usersSites$.userUUID | async">
                        {{item.userName}}
                    </div> -->
                    <!-- <div *ngFor="let item of usersSites?.userUUID">
                        {{item?.userName}}
                    </div> -->

                    <div *cdkVirtualFor="let user of usersSites?.userUUID; let i = index" class="scrollbar-items">
                        <div class="row">
                            <!-- {{user.userImage}} -->
                            <!-- <div class="col-3 user-image">
                                user iamge
                            </div> -->
                            <div class="col-3 col-sm-2 col-lg-1 col-xl-1">
                                <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
                                    class="user-image" alt="">
                            </div>

                            <div class="col-9">
                                <div class="col-12 user-name">{{user.userName}}</div>
                                <!-- <div class="col-12 d-flex">
                                    <div class="col-7 current-sites-text">Current Sites</div>
                                </div> -->
                            </div>

                        </div>

                        <div class="row mt-3">
                            <!-- <mat-form-field appearance="fill"> -->

                            <!-- </mat-form-field> -->
                            <!-- <div class="col-5 site-box-items">Site Example 1</div>
                            <div class="col-3 site-box-items"><mat-icon>pin_drop</mat-icon> London</div>
                            <div class="col-3 site-box-items"><mat-icon>account_circle</mat-icon> Employee</div>
                            <div class="col-1 site-box-items"><mat-icon>disabled_by_default</mat-icon></div> -->

                            <div class="col-6">
                                <mat-form-field class="w-100" floatLabel="always">

                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>apartment</mat-icon>&nbsp;
                                        Site
                                    </mat-label>
                                    <mat-select class="invite-to-site-select" placeholder="Select Site Name">
                                        <mat-option *ngFor="let site of user.userSites"
                                            (onSelectionChange)="setSiteUUID(site.siteUUID, i, $event)"
                                            [value]="site.siteName">
                                            {{site.siteName}}
                                        </mat-option>
                                        <!-- <mat-option *ngFor="let role of roles | keyvalue" [value]="role">
                                            {{role.key}}
                                        </mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="w-100" floatLabel="always">

                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>group</mat-icon>&nbsp;
                                        System Role
                                    </mat-label>
                                    <mat-select class="invite-to-site-select" placeholder="Select System Role">
                                        <mat-option *ngFor="let role of roles | keyvalue"
                                            (onSelectionChange)="setSiteRole(role.value, i, $event)" [value]="role">
                                            {{role.value}}
                                            <!-- {{role.key}} -->
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- {{item}} -->

                        <div class="col-12 d-flex justify-content-center">
                            <hr class="user-bottom-line">
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>

        </div>

    </div>

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button class="col-4 dialog-blue-action-button" (click)="submit()" [matDialogClose]="true"
            mat-button [disabled]="!inputFilled">INVITE</button>
    </div>

</div>