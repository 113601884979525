<mat-toolbar color="secondary">
	<div class="container">
		<div class="row">
			<div class="col-1 menu-logotype-box">
				<img
					src="../web-assets/logotype/Logo-01.png"
					class="menu-logotype"
					alt="" />
			</div>

			<div class="col-6 d-flex align-middle">
				<button class="menu-link" mat-button routerLink="home">
					Dashboard
				</button>
				<button class="menu-link" mat-button routerLink="sites">
					Sites
				</button>
				<button class="menu-link" mat-button routerLink="boards">
					Boards
				</button>
				<button class="menu-link" mat-button routerLink="assets">
					Assets
				</button>
				<button class="menu-link" mat-button routerLink="reports">
					Reports
				</button>
				<button class="menu-link" mat-button routerLink="my-files">
					My Files
				</button>
				<button *ngIf="(currenUserRole$ | async) === 'company manager' || checkSiteRoles()" class="menu-link" mat-button routerLink="users">
					Users
				</button>
			</div>

			<div class="col-5 d-flex align-middle justify-content-between">
				<button class="col-2 header-action-button" mat-button>
					<mat-icon>notifications</mat-icon>
				</button>

				<button (click)="toggleModules()" class="col-2 header-action-button" mat-button>
					<mat-icon>apps</mat-icon>
				</button>

				<button class="col-5 header-action-button header-action-button-user" [matMenuTriggerFor]="user" mat-button>
					<div class="col-4 user-icon"></div>
					<div class="col-7 header-action-button-user-text">
						<span class="col-12 user-name" *ngIf="!(newUserName | async) as name">{{currentUserData?.name}}&nbsp;{{currentUserData?.surname}}</span>
						<span class="col-12 user-name" *ngIf="newUserName | async as name">{{name}}</span>
						<span class="col-12 user-email">{{currentUserData?.email}}</span>
					</div>
				</button>

				<mat-menu #user="matMenu">
					<button [routerLink]="['/users/' + currentUserData?.userUUId]" mat-menu-item><mat-icon>account_circle</mat-icon>&nbsp;View Account</button>
					<button (click)="logout()" mat-menu-item><mat-icon>logout</mat-icon>&nbsp;Logout</button>
				</mat-menu>
				
				<!-- <button class="util-icons" mat-button (click)="toggleModules()">
					<mat-icon>apps</mat-icon>
				</button>

				<button class="util-icons" mat-button>
					<mat-icon>notifications</mat-icon>
				</button>

				<button class="util-icons" mat-button (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button> -->
			</div>

			<!-- <div class="col-2 d-flex align-middle">
				<button class="util-icons" mat-button (click)="toggleModules()">
					<mat-icon>apps</mat-icon>
				</button>

				<button class="util-icons" mat-button>
					<mat-icon>notifications</mat-icon>
				</button>

				<button class="util-icons" mat-button (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button>
			</div> -->
		</div>
	</div>
</mat-toolbar>

<app-modules-carrousel *ngIf="areModulesDisplayed"></app-modules-carrousel>
