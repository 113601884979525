import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnDestroy {
  qrResultString: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    localStorage.removeItem("ticcUuid");
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
  }

  clearResult(): void {
    this.qrResultString = undefined;
  }

  submit() {
    let splitedUrlElements = this.qrResultString?.split('/');
    let qrUuid = splitedUrlElements?.[splitedUrlElements.length - 1];
    if (qrUuid)
      this.router.navigate(['/qr', qrUuid]);
  }

  cancel() {
    const ticcUuid = localStorage.getItem('ticcUuid');
    if (ticcUuid)
      this.router.navigateByUrl(`/ticcs/${ticcUuid}`);
    else
      this.router.navigateByUrl('/');
  }
}
