<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="create-user-button-container d-flex justify-content-end">
    <button (click)="openBulkAddUsers()" mat-button class="create-user-button d-flex"><mat-icon>add_circle</mat-icon>
        <div class="d-none d-md-block">&nbsp;&nbsp;NEW USERS</div>
    </button>
</div>


<mat-drawer #drawer class="sidenav-content-container d-flex flex-wrap zindex-1000" mode="over">

    <div class="col-12 d-flex align-items-center filter-header">
        <div class="col-8 offset-1 filters-title">
            Apply Filters
        </div>

        <div class="col-2 filters-count-container d-flex flex-wrap">
            <span class="filters-count">{{selectionCompanies.selected.length + selectionRoles.selected.length +
                selectionSites.selected.length}}</span>
            <span class="filters-text w-100">FILTERS</span>
        </div>
    </div>


    <div class="col-12">
        <mat-accordion class="filters-accordion" multi>
            <mat-expansion-panel class="w-100">

                <mat-expansion-panel-header>
                    <div class="col-10 d-flex align-items-center">
                        <mat-panel-title class="user-title">
                            <mat-icon>apartment</mat-icon>&nbsp;
                            Companies
                        </mat-panel-title>
                    </div>
                    <div class="col-2">
                        <div class="count-expansion-pannel">
                            {{selectionCompanies.selected.length}}/{{filter$?.filteredCompanies?.length}}
                        </div>
                    </div>
                </mat-expansion-panel-header>


                <div class="row" *ngFor=" let company of filter$?.filteredCompanies| orderBy : 'name'; let i = index">
                    <div class="col-12 py-2">
                        <mat-checkbox class="example-margin" 
                        (change)="selectionCompanies.toggle(getMappedCompanies(filter$?.filteredCompanies!)[i])"
                        [checked]="selectionCompanies.isSelected(getMappedCompanies(filter$?.filteredCompanies!)[i])"
                        >{{company?.name}}</mat-checkbox>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="col-12">
        <mat-accordion class="filters-accordion" multi>
            <mat-expansion-panel class="w-100">

                <mat-expansion-panel-header>
                    <div class="col-10 d-flex align-items-center">
                        <mat-panel-title class="user-title">
                            <mat-icon>pin_drop</mat-icon>&nbsp;
                            Sites
                        </mat-panel-title>
                    </div>
                    <div class="col-2">
                        <div class="count-expansion-pannel">
                            {{selectionSites.selected.length}}/{{filter$?.filteredSites?.length}}
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="row" *ngFor=" let city of filter$?.filteredSites | orderBy : 'name'; let i = index">
                    <div class="col-12 py-2">
                        <mat-checkbox class="example-margin" 
                            (change)="selectionSites.toggle(getMappedSites(filter$?.filteredSites!)[i])"
                            [checked]="selectionSites.isSelected(getMappedSites(filter$?.filteredSites!)[i])">
                                {{city?.name}}
                            </mat-checkbox>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="col-12">
        <mat-accordion class="filters-accordion" multi>
            <mat-expansion-panel class="w-100">

                <mat-expansion-panel-header>
                    <div class="col-10 d-flex align-items-center">
                        <mat-panel-title class="user-title">
                            <mat-icon>groups</mat-icon>&nbsp;
                            Roles
                        </mat-panel-title>
                    </div>
                    <div class="col-2">
                        <div class="count-expansion-pannel">
                            {{selectionRoles.selected.length}}/{{filter$?.siteRoleFilterModels?.length}}
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="row" *ngFor=" let owner of filter$?.siteRoleFilterModels | orderBy : 'name'; let i = index">
                    <div class="col-12 py-2">
                        <mat-checkbox class="example-margin" 
                            (change)="selectionRoles.toggle(getMappedRoles(filter$?.siteRoleFilterModels!)[i])"
                            [checked]="selectionRoles.isSelected(getMappedRoles(filter$?.siteRoleFilterModels!)[i])">
                            {{owner?.name}}
                        </mat-checkbox>
                    </div>
                </div>


            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="row">
        <div class="col-6 filter-button-container">
            <button mat-button class="filter-button cancel-filter-button"
                (click)="drawer.toggle();clearSelectionFilters();">
                CANCEL
            </button>
        </div>
        <div class="col-6 filter-button-container">
            <button mat-button class="filter-button apply-filter-button" (click)="filter();drawer.toggle();">
                APPLY FILTERS
            </button>
        </div>
    </div>


</mat-drawer>

<h1 class="page-top-title">
    Users
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="row d-flex justify-content-center w-100 m-0">
    <div class="col-6 col-md-4 col-lg-4 filter-button-container">
        <button (click)="drawer.toggle()" mat-button class="open-filter-button open-filtering">
            <mat-icon>tune</mat-icon>&nbsp;
            Filter
        </button>
    </div>
    <div class="col-6 col-md-4 col-lg-4 filter-button-container">
        <button mat-button class="open-filter-button open-grouping" [matMenuTriggerFor]="grouping">
            <mat-icon>sort</mat-icon>&nbsp;
            Group By {{selectedGroup}}({{sortType}}) 
        </button>
        <mat-menu #grouping="matMenu">
            <button mat-menu-item
                (click)="sotrByCompanyrAscendingOrder()"><mat-icon>trending_up</mat-icon>A-Z Company
                name
            </button>
            <button mat-menu-item (click)="sotrBySiteAscendingOrder()"><mat-icon>trending_up</mat-icon>A-Z
                Site name
            </button>
            <button mat-menu-item
                (click)="sotrByRoleAscendingOrder()"><mat-icon>trending_up</mat-icon>A-Z Role name
            </button>
            <button mat-menu-item
                (click)="sotrByCompanyDescendingOrder()"><mat-icon>trending_down</mat-icon>Z-A Company
                name
            </button>
            <button mat-menu-item (click)="sotrBySiteDescendingOrder()"><mat-icon>trending_down</mat-icon>Z-A
                Site name
            </button>
            <button mat-menu-item
                (click)="sotrByRoleDescendingOrder()"><mat-icon>trending_down</mat-icon>Z-A Role name
            </button>
        </mat-menu>
    </div>
</div>

<mat-drawer-container class="sidenav-general-container" autosize>
    <div class="container-fluid">

        <div *ngIf="selectedGroup == 'Company'" class="row p-0 m-0 content w-100">
            <mat-accordion class="users-container">
                <mat-expansion-panel (opened)="setCompaniesUsersList(company.companyId)" hideToggle class="mt-3"
                    *ngFor="let company of (companiesGroupList | orderBy : 'companyName' : sortType); first as isFirst" [expanded]="isFirst"> 
                    <mat-expansion-panel-header *ngIf="company.companyId != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>
                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{company.companyName}}</div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{company.count}} users</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getCompaniesUsersList(company.companyId)">
                        <ng-container *ngFor="let user of item | async; let i = index">
                            <ng-container  *ngTemplateOutlet="userView; context: {user: user, index: i}"></ng-container>
                        </ng-container>
                    </div>

                    <mat-paginator [length]="company.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page" (page)="setCompaniesUsersListWithPagination(company.companyId, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="selectedGroup == 'Site'" class="row p-0 m-0 content w-100">
            <mat-accordion class="users-container">
                <mat-expansion-panel (opened)="setSitesUsersList(site.siteUuid)" hideToggle class="mt-3"
                    *ngFor="let site of (sitesGroupList | orderBy : 'siteName' : sortType); first as isFirst" [expanded]="isFirst"> 
                    <mat-expansion-panel-header *ngIf="site.siteUuid != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>
                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{site.siteName}}</div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{site.count}} users</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getSitesUsersList(site.siteUuid)">
                        <ng-container *ngFor="let user of item | async; let i = index">
                            <ng-container  *ngTemplateOutlet="userView; context: {user: user, index: i}"></ng-container>
                        </ng-container>
                    </div>

                    <mat-paginator [length]="site.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page" (page)="setSitesUsersListWithPagination(site.siteUuid, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="selectedGroup == 'Role'" class="row p-0 m-0 content w-100">
            <mat-accordion class="users-container">
                <mat-expansion-panel (opened)="setRolesUsersList(role.roleId)" hideToggle class="mt-3"
                    *ngFor="let role of (rolesGroupList | orderBy : 'roleName' : sortType); first as isFirst" [expanded]="isFirst"> 
                    <mat-expansion-panel-header *ngIf="role.roleId != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>
                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{role.roleName}}</div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{role.count}} users</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getRolesUsersList(role.roleId)">
                        <ng-container *ngFor="let user of item | async; let i = index">
                            <ng-container  *ngTemplateOutlet="userView; context: {user: user, index: i}"></ng-container>
                        </ng-container>
                    </div>

                    <mat-paginator [length]="role.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page" (page)="setRolesUsersListWithPagination(role.roleId, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>
        </div>


        <div class="choose-action-container d-flex justify-content-center">
            <button mat-button *ngIf="buttonForBulkOperation" (click)="openBottomSheetDialogSelector(2)"
                class="fixed-button d-flex flex-wrap">
                <span>CHOOSE ACTION</span><br>
            </button>
        </div>

    </div>
</mat-drawer-container>


<ng-template #userView let-user="user" let-index="index">
    <div *ngIf="!user.userIsDeleted" class="col-12 py-2 detail-panel" [class.not-display]="isLoading$ | async" [ngClass]="user.userIsActive === 0 ? 'userIsActive' : ''">
        <div class="row">
            <div class="col-10 col-lg-11 d-flex py-3 px-3">
                <div class="col-12 d-flex flex-wrap user">
                    <div class="col-2 col-lg-1">
                        <img [src]="avatarConvert(user.userPhoto) || 'web-assets/images/default/default-user.png'"
                            class="user-image" alt="">
                    </div>
                    <div class="col-9 col-lg-6 d-flex flex-wrap d-flex align-items-center">
                        <div class="col-12 col-lg-6 user-name py-2 no-wrap ">
                            <mat-checkbox [color]="'primary'"
                                (change)="markForBulkOperation(user)"></mat-checkbox>&nbsp;
                            {{user.userFullName}} 
                            
                            {{user.userIsDeleted ? '(Deleted)' : ''}} 
                        </div>
                        <div class="col-12 col-lg-6 user-email no-wrap">{{user.userEmail}}</div>
                    </div>

                    <div class="col-7 col-lg-3 d-flex flex-wrap d-flex align-items-center">
                        <div class="user-last-login no-wrap">
                            Last Login: <span class="strong">{{parseDate(user.userLastLogIn) | date:
                                'dd-MM-yyyy'}}</span>
                        </div>
                    </div>

                    <div class="col-5 col-lg-2 d-flex align-items-center user">
                        <mat-icon class="user-ticc-count-icon">check_circle</mat-icon>
                        <span class="user-ticc-count">{{user.userTiccsCount}} ticcs</span>
                    </div>

                </div>
            </div>

            <div class="action-buttons col-2 col-lg-1 p-0 d-flex flex-wrap align-center">

                <button routerLink="/users/{{user.userUUID}}" [queryParams]="{ lastUrl: 'users' }"
                    mat-button class="icon-box view p-0 w-100 justify-content-center">
                    <mat-icon class="w-100">visibility</mat-icon>
                    <div class="w-100">VIEW</div>
                </button>
                <button [matMenuTriggerFor]="menu" mat-button
                    class="icon-box edit p-0 w-100 justify-content-center">
                    <mat-icon class="w-100">more_horiz</mat-icon>
                    <div class="w-100">MORE</div>
                </button>

                <mat-menu class="users-page-menu" #menu="matMenu">
                    <!-- ONLY FOR ADMIN or Company Manager -->
                    <button *ngIf="(currenUserRole$ | async) === 'administrator' || (currenUserRole$ | async) === 'company manager'"
                        (click)="openActivateOrDeactivateDialog(user, index)"
                        class="enable-or-disable" mat-menu-item>
                        <mat-icon class="enable-or-disable">do_not_disturb_on</mat-icon>
                        <span *ngIf="user.userIsActive === 0">Activate</span>
                        <span *ngIf="user.userIsActive === 1">Deactivate</span>
                    </button>

                    <button (click)="removeUserFromSite(user, index)" class="remove-from-site"
                        mat-menu-item>
                        <mat-icon class="remove-from-site">account_circle</mat-icon>
                        <span>Remove from Site</span>
                    </button>

                    <!-- ONLY FOR ADMIN -->
                    <button *ngIf="(currenUserRole$ | async) === 'administrator'"
                        (click)="openDeleteDialog(user, index)" class="delete" mat-menu-item>
                        <mat-icon class="delete">delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-template>
