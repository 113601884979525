import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { TagAssociationModel, TagsBaseViewModel } from 'src/app/models/backend-generated-models';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  setAssociationForTag(tagUUID: string | null, body: TagAssociationModel) {
    return this.http
      .put<any>(`${environment.apiUrl}Tags/${tagUUID}/association`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      ); 
  }

  getQRCode(tagUuid: string) {
    return this.http
    .get(`${environment.apiUrl}Tags/${tagUuid}/qr`, { responseType: 'blob' })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
}
