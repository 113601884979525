import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { ReportDataCollectionViewModelOfSiteTiccDataItem } from 'src/app/models/backend-generated-models';
import { ReportDataCollectionViewModelOfUsersTiccDataItem } from 'src/app/models/backend-generated-models';
import { ReportDataCollectionViewModelOfBoardTiccDataItem } from 'src/app/models/backend-generated-models';
import { ReportDataCollectionViewModelOfAssetTiccDataItem } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
    ) { }

  GetSitesReport(params: HttpParams) {
    let request = this.http.get<ReportDataCollectionViewModelOfSiteTiccDataItem>(`${environment.apiUrl}reports/site-ticcs`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );

    return request;
  }

  GetUsersReport(params: HttpParams) {
    let request = this.http.get<ReportDataCollectionViewModelOfUsersTiccDataItem>(`${environment.apiUrl}reports/user-ticcs`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );

    return request;
  }

  GetBoardsReport(params: HttpParams) {
    let request = this.http.get<ReportDataCollectionViewModelOfBoardTiccDataItem>(`${environment.apiUrl}reports/board-ticcs`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );

    return request;
  }

  GetAssetsReport(params: HttpParams) {
    let request = this.http.get<ReportDataCollectionViewModelOfAssetTiccDataItem>(`${environment.apiUrl}reports/asset-ticcs`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );

    return request;
  }
}
