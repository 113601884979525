<div [ngSwitch]="data.role" class="dialog">

    <div *ngSwitchCase="'administrator-downgrade'">
        <h1 class="dialog-title mx-2 mb-3">System Role Downgrade</h1>

        <div class="dialog-main-text mx-2 mb-3">
            You are going to change "Administrator" system role to "User" system role. Are you sure you want to perform this action?
        </div>
        
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
            <button class="col-4 dialog-blue-action-button" [matDialogClose]="true" mat-button>YES</button>
        </div>
    </div>

    <div *ngSwitchCase="'company manager-downgrade'">
        <h1 class="dialog-title mx-2 mb-3">System Role Downgrade</h1>

        <div class="dialog-main-text mx-2 mb-3">
            You are going to change "Company Manager" system role to "User" system role. Are you sure you want to perform this action?
        </div>
        
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
            <button class="col-4 dialog-blue-action-button" [matDialogClose]="true" mat-button>YES</button>
        </div>
    </div>


    <div *ngSwitchCase="'administrator'">
        <h1 class="dialog-title mx-2 mb-3">Administrator Management</h1>

        <div class="dialog-main-text mx-2 mb-3">
            Are you sure you want to give this user “Administrator” system role?
        </div>
        
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
            <button class="col-4 dialog-blue-action-button" [matDialogClose]="true" mat-button>YES</button>
        </div>
    </div>



    <!-- <div *ngSwitchCase="'administrator-downgrade'">
        <h1 class="dialog-title mx-2 mb-3">Administrator Management</h1>

        <div class="dialog-main-text mx-2 mb-3">
            You do not have permission to change “Administrator” system role.
        </div>
        
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CLOSE</button>
        </div>
    </div> -->



    <div *ngSwitchCase="'company manager'">
        <h1 class="dialog-title mx-2 mb-3">Company Manager Management</h1>

        <div class="dialog-main-text mx-2 mb-3">
            Are you sure you want to give this user Company Manager system role?
        </div>
        
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
            <button class="col-4 dialog-blue-action-button" [matDialogClose]="true" mat-button>YES</button>
        </div>
    </div>



</div>


