


<div class="container-fluid ticc-detail-container">

    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb" [routerLink]="'/' + attachmentType"><span class="single-breadcrumb-link">{{attachmentType == 'home' ? 'Dashboard' : attachmentType | titlecase}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb" *ngIf="attachmentType != 'home'" [routerLink]="'/' + attachmentType + '/' + ticcInformation?.attachmentUuid"><span class="single-breadcrumb-link">{{ticcInformation?.attachmentName}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb"><span>{{ticcInformation?.ticcTypeName}}</span></div>
        </div>
    </div>

    <div class="col-12 view-entry">
        <div class="row m-0">
            <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <div class="title">
                    TICC Overview
                </div>

                <hr>

                <div class="subtitle">
                    {{ticcInformation?.ticcTypeName}}
                </div>
            </div>
        </div>
    </div>


    <div class="row p-3 m-0">

        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    TICC Information
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 pb-3 d-flex information-content">

                <p class="information-ticc-type mt-4">{{ticcInformation?.ticcTypeName}}</p>
                <hr class="information-separator">
                <div class="col-12">
                    <p class="parameter"><span class="parameter-definition">{{ticcInformation?.attachmentType}}&nbsp;</span>{{ticcInformation?.attachmentName}}</p>
                    <p class="parameter"><span class="parameter-definition">Last Checked
                            At&nbsp;</span>{{ticcInformation?.lastCheckedDate | date:'dd - MMMM - YYYY'}}</p>
                    <p class="parameter"><span class="parameter-definition">Requirements&nbsp;</span></p>
                </div>

                <div class="col-12">
                    <mat-chip-list class="row information-modules-chiplist">

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.legal}">
                            <mat-icon>gavel</mat-icon>
                            &nbsp;Legal</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.certificate}">
                            <mat-icon>history_edu</mat-icon>
                            &nbsp;Certificate Required</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.responsiblePerson}">
                            <mat-icon>supervisor_account</mat-icon>
                            &nbsp;Responsible Person</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.competentPerson}">
                            <mat-icon>engineering</mat-icon>
                            &nbsp;Competent Person</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.tagScan}">
                            <mat-icon>qr_code</mat-icon>
                            &nbsp;QR/TAG Require</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': ticcInformation?.ticcRequirements?.observations}">
                            <mat-icon>receipt_long</mat-icon>
                            &nbsp;Observations</mat-chip>
                    </mat-chip-list>
                </div>

            </mat-card-content>

        </mat-card>

        <div class="col-12">
            <button *ngIf="ticcInformation?.checkNotAllowed_ScanRequired && ticcInformation?.ticcRequirements?.tagScan" (click)="onScanClick()" mat-button class="green-button mb-4">Scan QR/TAG</button>
        </div>

        <div class="col-12 warnig-message" *ngIf="ticcInformation?.checkNotAllowed_ScanRequired && ticcInformation?.ticcRequirements?.tagScan" >
            <img src="web-assets/svg/warnig.svg">Please scan QR TAG first
        </div>

        <div class="col-12 col-md-6 p-0">
            <button routerLink="checks/create" [disabled]="ticcInformation?.checkNotAllowed_NoPendingChecks || ticcInformation?.checkNotAllowed_AssignmentRequired || (ticcInformation?.checkNotAllowed_ScanRequired && ticcInformation?.ticcRequirements?.tagScan)" mat-button class="green-button mb-4">New TICC Check</button>
        </div>

        <div class="col-12 col-md-6 p-0">
            <button [disabled]="!ticcInformation?.enableEditButton" routerLink="edit" mat-button class="white-button">Edit TiCC</button>
        </div>



        <!-- <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    TICC Information
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 d-flex information-content">

                <p class="information-ticc-type mt-4">General Waste Collector ...</p>
                <p class="information-ticc-guidance">General Waste Collector ...</p>
                <hr class="information-separator">
                <div class="col-12">
                    <p class="parameter"><span class="parameter-definition">Site&nbsp;</span></p>
                    <p class="parameter"><span class="parameter-definition">Last Checked At&nbsp;</span> 03. Sep ...</p>
                    <p class="parameter"><span class="parameter-definition">Requirements&nbsp;</span></p>
                </div>

                <div class="col-12">
                    <mat-chip-list class="row information-modules-chiplist">

                        <mat-chip
                            class="col-6">
                            <mat-icon>gavel</mat-icon>
                            &nbsp;Legal</mat-chip>

                        <mat-chip
                            class="col-6">
                            <mat-icon>history_edu</mat-icon>
                            &nbsp;Certificate Required</mat-chip>

                        <mat-chip
                            class="col-6">
                            <mat-icon>supervisor_account</mat-icon>
                            &nbsp;Responsible Person</mat-chip>

                        <mat-chip
                            class="col-6">
                            <mat-icon>engineering</mat-icon>
                            &nbsp;Competent Person</mat-chip>
                    </mat-chip-list>
                </div>

            </mat-card-content>

        </mat-card> -->


        <mat-accordion class="accordion px-0 ticc-detail-accordion">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Details:
                    </mat-panel-title>
                </mat-expansion-panel-header>



                <mat-card class="accordion-card col-12 p-0">

                    <mat-card-content class="row m-0 py-3 d-flex information-content">

                        <p class="parameter"><span class="parameter-definition">Created
                                At:&nbsp;</span>{{ticcInformation?.createdAt | date:'dd - MMMM - YYYY'}}</p>
                        <p class="parameter"><span class="parameter-definition">Updated
                                At:&nbsp;</span>{{ticcInformation?.updatedAt | date:'dd - MMMM - YYYY'}}</p>
                        <p class="parameter-notes"><span
                                class="parameter-definition">Guidance:&nbsp;</span>{{ticcInformation?.ticcTypeGuidance}}
                        </p>

                    </mat-card-content>

                </mat-card>


            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion class="accordion px-0 ticc-detail-accordion">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Assigned to:
                    </mat-panel-title>
                </mat-expansion-panel-header>



                <mat-card class="accordion-card col-12 p-0">

                    <mat-card-content class="row m-0 py-3 d-flex assigned-to-content">

                        <div class="col-12 my-2 d-flex justify-content-between" *ngFor="let user of ticcInformation?.assignedToUsers">
                            <span class="asignation-name">{{user.fullName}}</span>
                            <span class="asignation-role">{{user.siteRole}}</span>
                        </div>

                    </mat-card-content>

                </mat-card>


            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="ticcInformation?.ticcObservationsEnabled" class="accordion px-0 ticc-detail-accordion">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Observations
                    </mat-panel-title>
                </mat-expansion-panel-header>



                <mat-card class="accordion-card col-12 p-0">

                    <mat-card-content class="row m-0 py-3 d-flex observations-content">
                        <mat-list role="list" *ngFor="let observation of ticcInformation?.observarions">
                            <mat-list-item role="listitem">
                                {{observation.text}}
                            </mat-list-item>
                          </mat-list>
                    </mat-card-content>

                </mat-card>


            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion class="accordion px-0 ticc-detail-accordion">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        History
                    </mat-panel-title>
                </mat-expansion-panel-header>



                <mat-card class="accordion-card col-12 p-0">

                    <mat-card-content *ngIf="checkHistory !== []; else checkUndefined"
                        class="row m-0 py-3 d-flex history-content">

                        <div class="row m-0 px-0 py-3 history-box" *ngFor="let check of checkHistory">

                            <div class="col-12">
                                <p class="parameter" *ngIf="check.checkCompletedBy?.userFullName">
                                    <span class="parameter-definition">Checked By:&nbsp;</span>
                                    {{check.checkCompletedBy?.userFullName}}
                                </p>

                                <p class="parameter" *ngIf="check.checkCreatedAt">
                                    <span class="parameter-definition">Checked At:&nbsp;</span>
                                    {{check.checkCreatedAt | date}}
                                </p>

                                <!-- <p class="parameter" *ngIf="check.checkEventTime">
                                    <span class="parameter-definition">Checked At:&nbsp;</span>
                                    {{check.checkEventTime | date}}
                                </p> -->

                                <p class="parameter-notes" *ngIf="check.checkNotes">
                                    <span class="parameter-definition">Notes:&nbsp;</span>
                                    {{check.checkNotes}}
                                </p>
                            </div>


                            <div class="col-12">
                                <div class="row p-0 m-0">
                                        <button mat-button class="blue-button"
                                            [routerLink]="['checks', check.checkUuid]">
                                            <mat-icon>visibility</mat-icon>&nbsp;
                                            View
                                        </button>
                                </div>
                            </div>


                        </div>

                    </mat-card-content>

                    <ng-template #checkUndefined>
                        <mat-card-content class="row m-0 py-3 d-flex history-content">
                            asd
                            <div class="row m-0 px-0 py-3 history-box">
                                <div class="col-12">
                                    tasdfdsfa
                                </div>
                            </div>

                        </mat-card-content>
                    </ng-template>

                </mat-card>


            </mat-expansion-panel>
        </mat-accordion>


    </div>
</div>
