import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root',
})
export class PhotoConvertService {
    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    photoConvert(blobImage: any) {
        return 'data:image/png;base64,' + blobImage;
    }
}
