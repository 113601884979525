import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { filter, find, map } from 'rxjs';
import { PermissionGuard } from 'src/app/guards/permission.guard';
import { CompaniesViewModel, SiteFullInfoViewModel, UserInfoViewModel, UsersViewModel } from 'src/app/models/backend-generated-models';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserStateService } from 'src/app/services/state/user-state.service';
import { FormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdminService } from 'src/app/services/api/admin.service';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IExtractedUserFromJWT } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/api/user.service';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { DialogAsignToCompanyComponent } from '../dialogs/dialog-asign-to-company/dialog-asign-to-company.component';
import { LoadingService } from 'src/app/services/helpers/loading.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  isLoading$ = this.loadingService.isLoading$;

  sites$: [string, [any]][] | undefined;
  sitesModels$: SiteFullInfoViewModel[] | undefined;
  filter$: any[] | undefined;
  showFiller = false;

  selectionCompanies = new SelectionModel<any[]>(true, []);
  selectionCities = new SelectionModel<any[]>(true, []);
  selectionOwners = new SelectionModel<any[]>(true, []);

  sitesBulkArr: string[] = [];
  sitesBulkArrCount = this.sitesBulkArr.length

  buttonForBulkOperation: boolean = false;
  isFilter = false;
  nameSort: string;
  allCompanies: CompaniesViewModel[] | undefined;

  constructor(
    private sitesService: SitesService,
    private userStateService: UserStateService,
    private bottomSheet: MatBottomSheet,
    private loadingService: LoadingService,
    private companiesService: CompaniesService,
    public dialog: MatDialog,
  ) { this.nameSort = "siteCompanyName"; }

  ngOnInit(): void {
    this.companiesService.getCompaniesList().subscribe((res: CompaniesViewModel[]) => {
      this.allCompanies = res;
    });
    this.sitesService.getSiteFullList().subscribe(
      (res: SiteFullInfoViewModel[]) => {
        this.sitesModels$ = res.map((p: SiteFullInfoViewModel) => {
          return {
            siteAssetsCount: p.siteAssetsCount,
            siteBoardsCount: p.siteBoardsCount,
            siteCity: p.siteCity,
            siteCompanyName: this.getComposedCompanyLabel(p),
            siteCreatedAt: p.siteCreatedAt,
            siteId: p.siteId,
            siteLastCheckAt: p.siteLastCheckAt,
            siteMembersCount: p.siteMembersCount,
            siteName: p.siteName,
            siteOwner: p.siteOwner,
            siteTiccsCount: p.siteTiccsCount,
            siteUUID: p.siteUUID,
            siteUpdatedAt: p.siteUpdatedAt,
            siteUserRole: p.siteUserRole,
            enableEditButoon: p.enableEditButoon
          }
        });

        this.sites$ = Object.entries(this.groupByProperty(this.sitesModels$, this.nameSort));
      }
    )

    this.sitesService.filterForSite().subscribe(res => { this.filter$ = res })
  }

  private getComposedCompanyLabel(site: SiteFullInfoViewModel) : string | undefined
  {
    let searchingParentId = site.siteCompany?.parentCompanyId;
    if(searchingParentId != undefined)
    {
      let parent = this.allCompanies?.find(x =>
        x.companyId === searchingParentId
      );
      if (parent) {
        return parent.companyName+" / "+site.siteCompany?.companyName;
      }
    }
    return site.siteCompany?.companyName
  }

  isUserAdmin$ = this.userStateService.isUserAdmin;

  groupByProperty(arr: SiteFullInfoViewModel[], ticcTypeModule: string) {
    return arr.reduce(
      (previousVal: any, currentVal: any) => {
        const key = currentVal[ticcTypeModule] ?? "Default";
        if (!previousVal[key]) {
          previousVal[key] = [];
        }
        previousVal[key].push(currentVal);
        return previousVal;
      }, {});
  }

  parseDate(date: Date) {
    return date?.toLocaleString().split('T')[0]
  }

  clearSelectionFilters() {
    this.selectionCompanies.clear();
    this.selectionCities.clear();
    this.selectionOwners.clear();
    this.returnListOfSite();
  }

  filter() {
    if (this.selectionOwners.selected.length == 0
      && this.selectionCities.selected.length == 0
      && this.selectionCompanies.selected.length == 0) {
      return this.returnListOfSite();
    }

    let filterList = this.sitesModels$;
    this.isFilter = true;

    if (this.selectionCompanies.selected.length != 0) {
      filterList = filterList?.filter(owner => this.selectionCompanies.selected.includes(owner.siteCompany as any));
    }

    if (this.selectionCities.selected.length != 0) {
      filterList = filterList?.filter(city => this.selectionCities.selected.includes(city.siteCity as any));
    }

    if (this.selectionOwners.selected.length != 0) {
      filterList = filterList?.filter(owner => this.selectionOwners.selected.includes(owner.siteOwner as any));
    }
    return this.sites$ = Object.entries(this.groupByProperty(filterList as SiteFullInfoViewModel[], this.nameSort));
  }

  private returnListOfSite() {
    this.isFilter = false;
    this.getSitesBySort(this.nameSort, this.isFilter);
  }

  sotrBySiteOwnerDescendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
    this.descendingOrder();
  }

  sotrByCompanyDescendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
    this.descendingOrder();
  }

  sotrByCityDescendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
    this.descendingOrder();
  }

  sotrByCompanyrAscendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
  }

  sotrByCityAscendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
  }

  sotrBySiteOwnerAscendingOrder(sotr: string) {
    this.getSitesBySort(sotr, this.isFilter);
  }

  private getSitesBySort(sotr: string, isFilter: boolean) {
    this.nameSort = sotr;

    let sites = isFilter ? this.sites$ : this.sitesModels$;

    if (sites && sites.length > 0) {
      sites = sites.map((site: SiteFullInfoViewModel | [string, [any]] | undefined) => {
        return Array.isArray(site) ? site[1] : site;
      }) as [string, [any]][];
      sites.sort((a: any, b: any) => a[sotr]?.toLowerCase().localeCompare(b[sotr]?.toLowerCase(), 'en'));
      this.sites$ = Object.entries(this.groupByProperty(sites as SiteFullInfoViewModel[], sotr));
    }
  }

  private descendingOrder() {
    this.sites$?.sort((a, b) => (a?.[0] > b[0] ? -1 : 1));
  }

  markForBulkOperation(site: string) {

    let index = this.sitesBulkArr.indexOf(site, 0)

    if (index > -1) {
      this.sitesBulkArr.splice(index, 1);
    } else {
      this.sitesBulkArr.push(site);
    }

    this.sitesBulkArr.length > 0 ? this.buttonForBulkOperation = true : this.buttonForBulkOperation = false;

    this.sitesBulkArrCount = this.sitesBulkArr.length

  }

  openDialogAssignToCompanyBulk() {
    let maxWidth: string;
    if(window.innerWidth > 600){
      maxWidth = '80vw'
    } else {
      maxWidth = '100vw'
    }

    this.dialog.open(DialogAsignToCompanyComponent, {
      width: '100%',
      maxWidth: maxWidth,
      panelClass: 'dialog-box-mobile',
      data: {type: 'sites', array: this.sitesBulkArr}
    })/* .afterClosed().subscribe(() => window.location.reload()) */
  }

  openDialogDisassociateFromCompanyBulk() {
    this.dialog.open(DialogDisassociateSiteCompany, {
      width: '600px',
      panelClass: 'dialog-box',
      data: this.sitesBulkArr
    })/* .afterClosed().subscribe(() => window.location.reload()) */
  }


  openBottomSheetDialogSelector(selectedSitesCount: number) {
    this.bottomSheet.open(BottomSitesDialogSelector, {
      panelClass: 'bottom-sheet-dialog',
      data: selectedSitesCount
    }).afterDismissed().subscribe(res => {
      /* DIALOGS WILL OPEN FROM HERE */

      switch (res) {
        case 'assign-to-company':
          this.openDialogAssignToCompanyBulk();
          break;
        case 'disassociate-from-company':
          this.openDialogDisassociateFromCompanyBulk();
          break;

        default:
          break;
      }

    })
  }

  truncateText(name: string) {
    const element = document.createElement('div');
    element.innerText = name;
    let truncated = '';

    if (element) {
      truncated = element.innerText.substr(0, 30);
      if (element.innerText.length > 30) {
        truncated += '...';
      }
    }
    return truncated;
  }
}

@Component({
  selector: 'dialog-disassociate-site-company',
  templateUrl: 'dialog-disassociate-site-company.html',
  styleUrls: ['./sites.component.scss']
})
export class DialogDisassociateSiteCompany {
  constructor(
    @Inject(MAT_DIALOG_DATA) public sitesBulkArr: string[] | undefined,

    private sitesService: SitesService,
    public dialogRef: MatDialogRef<DialogDisassociateSiteCompany>
  ) { }

  submit() {

    this.sitesService.disassociateCompanyFromSite(
      {
        "sitesToDissociate": this.sitesBulkArr
      }
    ).subscribe()

  }

}

@Component({
  selector: 'bottom-sites-dialog-selector',
  templateUrl: 'bottom-sites-dialog-selector.html',
  styleUrls: ['./sites.component.scss']
})
export class BottomSitesDialogSelector {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: number | undefined,
    public bottomSheetRef: MatBottomSheetRef<BottomSitesDialogSelector>,
  ) { }

  closeBottomSheet(data: string) {
    this.bottomSheetRef.dismiss(data);
  }
}
