import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map } from 'rxjs';
import { UserStateService } from '../services/state/user-state.service';

@Injectable({
	providedIn: 'root',
})
export class AdministratorGuard implements CanActivate {
	constructor(private userStateService: UserStateService) {}

	canActivate() {
		return this.userStateService.currentUser$.pipe(
			map(user => {
				return user?.role === 'administrator';
			})
		);
	}
}
