<div class="dialog">
    <mat-card class="p-0 card">
        <mat-card-header class="p-3 site-creation-company">
            <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                <span class="w-100 sites-dialog-title">Company Manager</span>
            </mat-card-title>
        </mat-card-header>
    
        <mat-card-content class="p-3">
            <div class="row">
                <div class="col-12 assign-to-company">
                    <div class="row">
                        <div class="col-12 assign-to-company-options my-3 px-2 d-flex justify-content-center">
                            <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING COMPANY</span>
                            &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany" class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                            <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW COMPANY</span>
                        </div>
                    </div>
            
                    <div [formGroup]="formParentCompany" class="col-12">


                        <div *ngIf="switchCompany; else newCompany">
                            <mat-card class="my-3">
                                <mat-card-header>
                                    <mat-card-title>
                                        Parent Company
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content><!-- TODO: [ngClass]="{'select-not-admin': isUserAdmin$}" -->
                                    <mat-form-field  class="page-form-field w-100 custom-input" appearance="fill">
                                        <mat-label style="color: black">Select Parent Company</mat-label>
                            
                                        <span matPrefix>
                                            <mat-icon class="icon-color">
                                                description
                                            </mat-icon>
                                        </span>
                            
                                        <mat-select formControlName="companyName">
                                            <mat-option *ngFor="let parentCompany of parentCompaniesList$ | async" [value]="parentCompany.id">
                                                {{parentCompany.name}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </div>

                
                        <ng-template #newCompany>

                            <mat-card  class="my-3" >
                                <mat-card-header>
                                    <mat-card-title>
                                        Parent Company
                                    </mat-card-title>
                                </mat-card-header>
    
                                <mat-card-content><!-- TODO: [ngClass]="{'select-not-admin': isUserAdmin$}" -->
                                    <mat-form-field  class="page-form-field w-100 custom-input" appearance="fill">
                                        <mat-label style="color: black">Create new Parent Company</mat-label>
                            
                                        <span matPrefix>
                                            <mat-icon class="icon-color">
                                                description
                                            </mat-icon>
                                        </span>
                                        <input formControlName="companyName" type="text" matInput>

                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
    
                        </ng-template>
                    </div>
    
                    <div class="col-12 py-3 dialog-text">
                        With this action you are going to give this user <span class="italic dialog-data">Company Manager</span> system role
                    </div>
                </div>
            </div>
        </mat-card-content>
    
    
        <div class="col-12">
            <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
                <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
                <button class="col-4 dialog-blue-action-button" (click)="submit()" mat-button>ASSIGN</button>
            </div>
        </div>
    
    
    </mat-card>
    
</div>
