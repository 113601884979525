
<div class="container p-0 dashboard-container">
    <div *ngIf="(sitesList)?.length == 0">

        <h1 class="page-top-title">
            There is no site available
        </h1>

        <div class="row mx-5 px-3 py-5  mt-3 d-flex justify-content-center no-sites-container">
            <span class="col-12 my-3 no-site-text">Please create</span>
            <button routerLink="/sites/create" class="col-12 offset-md-3 col-md-6 new-site-button" mat-button>NEW SITE</button>
            <span class="col-12 my-3 no-site-text italic">or</span>
            <span class="col-12 my-1 no-site-text">Ask your <span class="strong">site manager</span> for invitation</span>

        </div>
    </div>
</div>


<!-- <ng-template #dashboard> -->
    <div *ngIf="(sitesList)?.length! > 0" class="container-fluid p-0 dashboard-container">

        <h1 class="page-top-title">
            Dashboard
        </h1>

        <div class="row p-3 m-0" #dashboard>

            <mat-form-field class="col-12 m-0 p-0" appearance="outline">
                <mat-label class="label-color">Select Site</mat-label>


                <mat-select>
                    <mat-option *ngFor="let site of sitesList"
                        (onSelectionChange)="siteChanged(site.uuid!, $event)" [value]="site.name">
                        {{site.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <!-- TODAY TICCS -->
            <mat-card class="card col-12 mt-0">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0 d-flex justify-content-between w-100" style="word-break: normal;">
                        Todays TICCs
                        <mat-checkbox style="word-break: normal;" (change)="todayTiccsChangeNearestTiccsCheckbox();"
                            [checked]="todayNearestTiccsEnabled" class="dashboard-checkbox" color="primary"
                            [disabled]="!selectedSiteUuid">See Only Nearest Ticcs</mat-checkbox>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="row m-0 d-flex information-content">

                    <div *ngFor="let check of dashboardTodayList"
                        class="col-12 d-flex flex-wrap pt-3 pb-3 ticc-check-container"
                        [ngClass]="{'ticc-check-past': !check.firstPendingEventForThisTicc}">

                        <div class="col-12 col-md-6 col-lg-3 d-flex flex-wrap mb-3" style="word-break: normal;">
                            <div class="col-4 col-md-3 col-xl-3 check-deadline parameter-definition ticc-header-text"
                                [ngClass]="{'check-deadline-incoming': check.firstPendingEventForThisTicc, 'check-deadline': !check.firstPendingEventForThisTicc}"
                                style="white-space: nowrap;">
                                {{check.checkEventTime | date:'HH:mm'}} |
                            </div>
                            <div class="col-8 col-md-9 col-xl-9 check-ticc-type d-flex align-items-center">

                                <!-- CHECK COPMLETED <<NOT COMPLIANT>> -->
                                <span
                                    *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndCompleted && check.checkStatus == 'NonCompliant'"
                                    class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                    style="color: #004973;"
                                        class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                        class="color-red" style="transform: scale(1.1); overflow: initial;">error</mat-icon></span>

                                <!-- CHECK COMPLETED <<COMPLIANT>> -->
                                <span
                                    *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndCompleted && check.checkStatus == 'Compliant'"
                                    class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                    style="color: #004973;"
                                        class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                        class="color-green" style="transform: scale(1.1); overflow: initial;">check_circle</mat-icon></span>

                                <!-- CHECK MISSED -->
                                <span *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndMissed"
                                    class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                        class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                        class="color-grey" style="transform: scale(1.1); overflow: initial;">cancel</mat-icon></span>

                                <!-- CHECK PENDING -->
                                <span *ngIf="!check.eventIsDoneNotPending" class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                        class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                        class="color-blue" style="transform: scale(1.1); overflow: initial;">help</mat-icon></span>

                            </div>
                        </div>

                            <div class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-board">
                                <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">For
                                    {{check.ticcInfo?.ticcAttachmentType}}:</div>
                                <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcAttachmentName === null || check.ticcInfo?.ticcAttachmentName === '' ? '-' : check.ticcInfo?.ticcAttachmentName}}</div>
                            </div>

                           <div *ngIf="!(check.checkCreatedAt === null || check.checkCreatedAt === undefined) && (!check.firstPendingEventForThisTicc || (check.eventIsDoneNotPending && check.eventIsDoneAndCompleted))"
                                class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-update-time">
                                <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">Checked At:</div>
                                <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text d-flex" style="word-break: normal;">
                                    <div style="width: 80px;">
                                        {{check.checkCreatedAt === null || check.checkCreatedAt === undefined ? '-' : check.checkCreatedAt | date:'HH:mm'}}
                                    </div>
                                    <div class="check-view-mobile" (click)="onCheckVisibilityClick(check?.checkUuid!)">
                                        <mat-icon class="w-100 view">visibility</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!(check.checkCompletedBy === null || check.checkCompletedBy?.userFullName === undefined || check.checkCompletedBy?.userFullName === '' || check.checkCompletedBy?.userFullName === ' ') && (!check.firstPendingEventForThisTicc || (check.eventIsDoneNotPending && check.eventIsDoneAndCompleted))"
                                class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-update-user">
                                <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">Checked By:</div>
                                <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text d-flex justify-content-between" style="word-break: normal;">
                                    <div>
                                        {{check.checkCompletedBy === null || check.checkCompletedBy?.userFullName === undefined || check.checkCompletedBy?.userFullName === '' || check.checkCompletedBy?.userFullName === ' ' ? '-' :check.checkCompletedBy?.userFullName}}
                                    </div>
                                    <div class="mr-40 check-view" (click)="onCheckVisibilityClick(check?.checkUuid!)">
                                        <mat-icon class="w-100 view">visibility</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="check.firstPendingEventForThisTicc && !check.eventIsDoneNotPending && !check.eventIsDoneAndCompleted" class="col-12 col-lg-6 d-flex flex-wrap">
                                <div class="col-12">
                                    <button (click)="createCheck(check)" mat-button class="green-button mt-2">New TICC
                                        Check</button>
                                </div>
                            </div>
                        </div>

                    <mat-paginator [length]="dashboardTodayPageLength" [pageSize]="5"
                        [pageSizeOptions]="[5, 10, 20, 50]" (page)="todayTiccsChangeIndex($event)"
                        aria-label="Select page">
                    </mat-paginator>

                </mat-card-content>

            </mat-card>


            <!-- FUTURE TICCS TOGGLE BUTTON -->
            <div class="col-12 p-0 d-flex justify-content-center">
                <div class="col-12 col-md-8 col-lg-6 col-xl-4 p-0">
                    <button *ngIf="!showFutureTiccs; else hideFutureTiccs" mat-button
                        (click)="showFutureTiccs = !showFutureTiccs; loadFutureTiccs()" class="white-button mb-4"
                        [disabled]="!selectedSiteUuid">See future TICCs</button>

                    <ng-template #hideFutureTiccs>
                        <button mat-button (click)="showFutureTiccs = !showFutureTiccs" class="white-button mb-4">Hide
                            future
                            TICCs</button>
                    </ng-template>
                </div>
            </div>


            <!-- FUTURE TICCS -->
            <mat-accordion *ngIf="showFutureTiccs" class="accordion px-0 ticc-detail-accordion">

                <div class="col-12 mb-3 d-flex justify-content-center">
                    <mat-checkbox (change)="futureTiccsChangeNearestTiccsCheckbox();"
                        [checked]="futureNearestTiccsEnabled" class="dashboard-checkbox" color="primary"
                        [disabled]="!selectedSiteUuid">See Only Nearest Future
                        Ticcs</mat-checkbox>
                </div>

                <div class="col-12 mb-3 d-flex justify-content-center">
                    <mat-spinner *ngIf="isLoading$ | async"></mat-spinner>
                </div>

                <div *ngIf="!(isLoading$ | async)">
                    <mat-expansion-panel class="col-12 my-1" *ngFor="let dashboardFutureTiccList of dashboardFutureList">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{dashboardFutureTiccList.checkDate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-card class="card col-12 mt-0 mb-0">

                            <mat-card-content class="row m-0 d-flex information-content">

                                <div *ngFor="let check of sortByCheckTimeAscendant(dashboardFutureTiccList.eventList)"
                                    class="col-12 d-flex flex-wrap pt-3 pb-3 ticc-check-container"
                                    [ngClass]="{'ticc-check-past': !check.firstPendingEventForThisTicc}">

                                    <div class="col-12 col-md-6 col-lg-3 d-flex flex-wrap mb-3">
                                        <div class="col-4 col-md-2 col-xl-3 check-deadline parameter-definition ticc-header-text"
                                            [ngClass]="{'check-deadline-incoming': check.firstPendingEventForThisTicc, 'check-deadline': !check.firstPendingEventForThisTicc}"
                                            style="white-space: nowrap;">
                                            {{check.checkEventTime | date:'HH:mm'}} |
                                        </div>
                                        <div class="col-8 col-md-10 col-xl-9 check-ticc-type d-flex align-items-center">

                                            <!-- CHECK COPMLETED <<NOT COMPLIANT>> -->
                                            <span
                                                *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndCompleted && check.checkStatus == 'NonCompliant'"
                                                class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                                style="color: #004973;"
                                                    class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                                    class="color-red" style="transform: scale(1.1); overflow: initial;">error</mat-icon></span>

                                            <!-- CHECK COMPLETED <<COMPLIANT>> -->
                                            <span
                                                *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndCompleted && check.checkStatus == 'Compliant'"
                                                class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                                style="color: #004973;"
                                                    class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                                    class="color-green" style="transform: scale(1.1); overflow: initial;">check_circle</mat-icon></span>

                                            <!-- CHECK MISSED -->
                                            <span *ngIf="check.eventIsDoneNotPending && check.eventIsDoneAndMissed"
                                                class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                                    class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                                    class="color-grey" style="transform: scale(1.1); overflow: initial;">cancel</mat-icon></span>

                                            <!-- CHECK PENDING -->
                                            <span *ngIf="!check.eventIsDoneNotPending"
                                                class="d-flex align-items-center"><span (click)="onTitleClick(check.ticcInfo?.ticcUuid!)"
                                                    class="parameter ticc-header-text" style="word-break: normal;">{{check.ticcInfo?.ticcTypeName}}&nbsp;</span><mat-icon
                                                    class="color-blue" style="transform: scale(1.1); overflow: initial;">help</mat-icon></span>

                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-board">
                                        <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">For
                                            {{check.ticcInfo?.ticcAttachmentType}}:</div>
                                        <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text" style="word-break: normal;">
                                            {{check.ticcInfo?.ticcAttachmentName === null || check.ticcInfo?.ticcAttachmentName === '' ? '-' : check.ticcInfo?.ticcAttachmentName}}
                                        </div>
                                    </div>

                                    <div *ngIf="!(check.checkCreatedAt === null || check.checkCreatedAt === undefined) && (!check.firstPendingEventForThisTicc || (check.eventIsDoneNotPending && check.eventIsDoneAndCompleted))"
                                        class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-update-time">
                                        <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">Checked At:
                                        </div>
                                        <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text d-flex" style="word-break: normal;">
                                            <div style="width: 80px;">
                                                {{check.checkCreatedAt === null || check.checkCreatedAt === undefined ? '-' : check.checkCreatedAt | date:'HH:mm'}}
                                            </div>
                                            <div class="check-view-mobile" (click)="onCheckVisibilityClick(check?.checkUuid!)">
                                                <mat-icon class="w-100 view">visibility</mat-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!(check.checkCompletedBy === null || check.checkCompletedBy?.userFullName === undefined || check.checkCompletedBy?.userFullName === '' || check.checkCompletedBy?.userFullName === ' ') && (!check.firstPendingEventForThisTicc || (check.eventIsDoneNotPending && check.eventIsDoneAndCompleted))"
                                        class="col-12 col-md-6 col-lg-3 d-flex flex-wrap my-1 check-update-user">
                                        <div class="parameter parameter-definition col-4 col-lg-5 col-xl-3 ticc-text" style="word-break: normal;">Checked By:
                                        </div>
                                        <div class="parameter col-8 col-lg-7 col-xl-9 ticc-header-text d-flex justify-content-between" style="word-break: normal;">
                                            <div>
                                                {{check.checkCompletedBy === null || check.checkCompletedBy?.userFullName === undefined || check.checkCompletedBy?.userFullName === '' || check.checkCompletedBy?.userFullName === ' ' ? '-' :check.checkCompletedBy?.userFullName}}
                                            </div>
                                            <div class="mr-40 check-view" (click)="onCheckVisibilityClick(check?.checkUuid!)">
                                                <mat-icon class="w-100 view">visibility</mat-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="check.firstPendingEventForThisTicc && !check.eventIsDoneNotPending && !check.eventIsDoneAndCompleted"
                                        class="col-12 col-lg-6 d-flex flex-wrap">
                                        <div class="col-12">
                                            <button (click)="createCheck(check)" mat-button class="green-button mt-2">New
                                                TICC
                                                Check</button>

                                        </div>
                                    </div>
                                </div>

                            </mat-card-content>
                        </mat-card>

                    </mat-expansion-panel>
                </div>

            </mat-accordion>

        </div>
    </div>
<!-- </ng-template> -->
