<div class="container-fluid ticc-check-creation-container">

    <div class="row m-0">
        <div class="col-10 breadcrumbs-container">
            <div class="single-breadcrumb" [routerLink]="'/' + attachmentType">
                <span class="single-breadcrumb-link">{{attachmentType == 'home' ? 'Dashboard' : attachmentType | titlecase}}</span> <mat-icon>chevron_right</mat-icon>
            </div>
            <div class="single-breadcrumb" *ngIf="attachmentType != 'home'" [routerLink]="'/' + attachmentType + '/' + ticcInformationTable?.attachmentUuid"><span class="single-breadcrumb-link">{{ticcInformationTable?.attachmentName}}</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb" [routerLink]="'/ticcs/' + ticcInformationTable?.uuid">
                <span class="single-breadcrumb-link">{{ticcInformationTable?.ticcTypeName}}</span> <mat-icon>chevron_right</mat-icon>
            </div>
            <div class="single-breadcrumb"><span>Create Check</span></div>

        </div>
    </div>



    <div class="col-12 view-entry">
        <div class="row m-0">
            <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                <div class="title">
                    Create check for
                </div>

                <hr>

                <div class="subtitle">
                    {{ticcInformation?.ticcTypeName}}
                </div>
            </div>
        </div>
    </div>




    <div class="row p-3 m-0">

        <div class="col-12 col-md-6">
            <mat-card class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0">
                        Status
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="p-3">


                    <div class="row m-0 px-1 py-3 schedule-buttons-container">

                        <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Status</mat-label>
                            <!-- <mat-select formControlName="scheduleType" (opened)="resetTiccCreationForm();">
                                <mat-option *ngFor="let timePeriod of timePeriods; let i = index" [value]="i">
                                    {{timePeriod}}
                                </mat-option>
                            </mat-select> -->

                            <mat-select [(value)]="selected">
                                <mat-option *ngFor="let option of availableOptions" [value]="option"
                                    style="color: black;">
                                    {{getCheckStatusLabel(option)}}
                                </mat-option>
                            </mat-select>

                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                    </div>

                </mat-card-content>

            </mat-card>






            <mat-card class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0">
                        Attachments
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="p-3 attachment-container">
                    <mat-radio-group
                        class="radio-group px-3 pb-3" [(ngModel)]="attachmentsSelector">
                        <mat-radio-button value="myFiles" class="mx-3">My Files</mat-radio-button>
                        <mat-radio-button value="uploadFile"class="mx-3">Uploda File</mat-radio-button>
                    </mat-radio-group>

                    <div class="input-group mt-3 px-3 mb-2">
                        <input *ngIf="attachmentsSelector == 'uploadFile'"
                            #files
                            mat
                            type="file"
                            id="upload-files-id"
                            multiple
                            [disabled]="creatingCheck"
                            (change)="fileSelectionChanged($event)">

                        <!-- <mat-card *ngFor="let file of selectedFiles">
                            <mat-card-content>
                                <div class="container">
                                    <div class="row" style="padding-left: 0 !important; padding-right: 0 !important">
                                        <div class="col-11 px-0" style="word-wrap: break-word">
                                            {{file}}
                                        </div>
                                    </div>
                                </div>

                            </mat-card-content>
                        </mat-card> -->
                        <button  *ngIf="attachmentsSelector == 'myFiles'"
                            [disabled]="!site || creatingCheck"
                            (click)="launchPicker()"
                            id="pickedFiles">
                            Launch Picker
                        </button>

                        <div class="observations-container px-2 py-1 mt-2 w-100" *ngFor="let file of selectedFiles; let i = index">
                            <span>{{file}}</span>
                            <button [disabled]="creatingCheck" mat-icon-button (click)="removeFile(i)">
                                <mat-icon class="delete">delete</mat-icon>
                            </button>
                        </div>
                        <div class="observations-container px-2 py-1 mt-2 w-100" *ngFor="let item of pickedFiles; let i = index">
                            <span>{{item.name}}</span>
                            <button [disabled]="creatingCheck" mat-icon-button (click)="removeLaunchPickerFile(i)">
                                <mat-icon class="delete">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>






            <mat-card *ngIf="ticcInformationTable?.ticcRequirements?.observations" class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0">
                        Observations
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="row m-0 py-3 d-flex observations-content">
                    <mat-list role="list" *ngFor="let observation of observations">
                        <mat-list-item role="listitem">
                            <div class="w-100 d-flex align-items-center justify-content-between flex-wrap">

                                <span>{{observation.text}}</span>
                                <span>
                                    <button [disabled]="creatingCheck" mat-button *ngIf="observation.isChecked"
                                        (click)="observation.isChecked = !observation.isChecked">
                                        <mat-icon class="icon-blue">check_box</mat-icon>
                                    </button>

                                    <button [disabled]="creatingCheck" mat-button *ngIf="!observation.isChecked"
                                        (click)="observation.isChecked = !observation.isChecked">
                                        <mat-icon class="icon-red">disabled_by_default</mat-icon>
                                    </button>
                                </span>
                            </div>

                        </mat-list-item>

                    </mat-list>
                </mat-card-content>
            </mat-card>


            <mat-card [formGroup]="newCheck" class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0">
                        <mat-slide-toggle (toggleChange)="notesEnabled = !notesEnabled;">
                            Notes
                        </mat-slide-toggle>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content *ngIf="notesEnabled" class="p-3">
                    <mat-form-field class="w-100" appearance="outline">

                        <mat-label style="color: black">Add Notes</mat-label>

                        <!-- <span matPrefix>
                            <mat-icon class="icon-color">
                                description
                            </mat-icon>
                        </span> -->

                        <textarea matInput formControlName="notes" rows="1"></textarea>
                    </mat-form-field>

                </mat-card-content>
            </mat-card>


            <mat-card class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title>

                        <mat-slide-toggle (toggleChange)="isConfirmed = !isConfirmed;">
                            Confirm
                        </mat-slide-toggle>

                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="p-3">
                    {{ticcInformation?.ticcTypeConfirmationText}}
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-12 col-md-6">


            <mat-card class="card col-12">
                <mat-card-header class="p-3">
                    <mat-card-title class="m-0">
                        TICC Information
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content class="row m-0 pb-3 d-flex information-content">

                    <ng-container *ngIf="site && ticcInformationTable">
                        <p class="information-ticc-type mt-4">{{ticcInformationTable.ticcTypeName}}</p>
                        <!-- <p class="information-ticc-guidance">{{site?.siteName}}</p> -->
                        <hr class="information-separator">
                        <div class="col-12">
                            <p class="parameter"><span class="parameter-definition">{{ticcInformationTable?.attachmentType}}&nbsp;</span>{{ticcInformationTable?.attachmentName}}</p>
                            <p class="parameter"><span class="parameter-definition">Last Checked
                                    At&nbsp;</span>{{ticcInformationTable.lastCheckedDate | date:'dd - MMMM - YYYY'}}</p>
                            <p class="parameter"><span class="parameter-definition">Requirements&nbsp;</span></p>
                        </div>
                    </ng-container>

                    <div *ngIf="!(site && ticcInformationTable)" class="col-12 h-100 d-flex justify-content-center py-4">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div class="col-12">
                        <mat-chip-list class="row information-modules-chiplist">

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.legal}">
                                <mat-icon>gavel</mat-icon>
                                &nbsp;Legal</mat-chip>

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.certificate}">
                                <mat-icon>history_edu</mat-icon>
                                &nbsp;Certificate Required</mat-chip>

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.responsiblePerson}">
                                <mat-icon>supervisor_account</mat-icon>
                                &nbsp;Responsible Person</mat-chip>

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.competentPerson}">
                                <mat-icon>engineering</mat-icon>
                                &nbsp;Competent Person</mat-chip>

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.tagScan}">
                                <mat-icon>qr_code</mat-icon>
                                &nbsp;QR/TAG Require</mat-chip>

                            <mat-chip class="col-12"
                                [ngClass]="{'active-requirement': ticcInformationTable?.ticcRequirements?.observations}">
                                <mat-icon>receipt_long</mat-icon>
                                &nbsp;Observations</mat-chip>
                        </mat-chip-list>
                    </div>

                </mat-card-content>

            </mat-card>

            <div class="col-12 warnig-message" *ngIf="isEventsWarnig"><img src="web-assets/svg/warnig.svg"> {{ eventsWarnigMessage }}</div>
            <div class="row m-0">
                <div class="col-6">
                    <button (click)="goBack()" [disabled]="creatingCheck" mat-button class="w-100 red-button">CANCEL</button>
                </div>
                <div class="col-6">
                    <button (click)="submit()"
                        [disabled]="!canCreateCheck"
                        mat-button
                        class="w-100 green-button">
                            <mat-spinner *ngIf="creatingCheck" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
                            {{creatingCheck ? '' : 'CREATE CHECK'}}
                        </button>
                </div>
            </div>

        </div>


        <!--         <div class="col-5 col-md-4 col-lg-3 col-xl-2">
            <button class="always-display-buttons button-cancel" mat-button>CANCEL</button>
        </div>

        <div class="col-5 col-md-4 col-lg-3 col-xl-2 offset-2 offset-md-4 offset-lg-6 offset-xl-8">
            <button (click)="submit()" class="always-display-buttons button-create" [disabled]="!isConfirmed"
                mat-button>CREATE CHECK</button>
        </div> -->




















    </div>

</div>




















<!-- <div class="container-fluid">
    <div class="row">

        <div class="col-4 py-3 col-md-3 col-lg-2 top-info">
            NEW CHECK
        </div>
        <div class="col-4 py-3 col-md-3 col-lg-2 offset-4 offset-md-6 offset-lg-8 top-info" style="text-align: right;">
            10 hours left
        </div>

        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        Create check for
                    </div>

                    <hr>

                    <div class="subtitle">
                        {{ticcInformation?.ticcTypeName}}
                    </div>
                </div>
            </div>
        </div>

        <form [formGroup]="newCheck" (ngSubmit)="submit()" class="row">
            <div class="col-12 col-lg-6">

                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Status</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">

                        <mat-form-field appearance="fill" class="form-fields">
                            <mat-label>Select an option</mat-label>
                            <mat-select [(value)]="selected">
                                <mat-option *ngFor="let option of availableOptions" [value]="option"
                                    style="color: black;">
                                    {{getCheckStatusLabel(option)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>

                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Attachments</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">

                        <input class="mb-4" type="file" id="upload-files-id" multiple
                            (change)="fileSelectionChanged($event)">

                        <mat-card *ngFor="let file of selectedFiles">
                            <mat-card-content>
                                <div class="container">
                                    <div class="row" style="padding-left: 0 !important; padding-right: 0 !important">
                                        <div class="col-11 px-0" style="word-wrap: break-word">
                                            {{file}}
                                        </div>
                                    </div>
                                </div>

                            </mat-card-content>
                        </mat-card>
                    </mat-card-content>
                </mat-card>




                <mat-card class="p-0">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">
                            <mat-slide-toggle (toggleChange)="notesEnabled = !notesEnabled;">
                                Notes
                            </mat-slide-toggle>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content *ngIf="notesEnabled" class="p-3">
                        <mat-form-field class="w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Notes</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <textarea matInput formControlName="notes" rows="1"></textarea>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>


                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Observations</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-card class="observations-elements" *ngFor="let observation of observationData.questions">
                            <mat-card-content>
                                <div class="container">
                                    <div class="row">

                                        <div class="col-1">
                                            <mat-select placeholder="-" [(value)]="observation.value">
                                                <mat-option value="On" style="color: black;">On</mat-option>
                                                <mat-option value="Off" style="color: black;">Off</mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-8">
                                            {{observation.key}}
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>

                    </mat-card-content>
                </mat-card>

                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title>

                            <mat-slide-toggle (toggleChange)="isConfirmed = !isConfirmed;">
                                Confirm
                            </mat-slide-toggle>

                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3 confirm-text">
                        {{ticcInformation?.ticcTypeConfirmationText}}
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12 col-lg-6">
                <hr class="d-block d-lg-none">
                <mat-card class="p-0 card">
                    <mat-card-header class="pt-3 px-3">
                        <mat-card-title class="m-0">
                            {{ticcInformation?.ticcTypeName}}
                        </mat-card-title>
                    </mat-card-header>

                    <hr class="card-separator">
                    <mat-card-subtitle>
                        <div class="container">
                            <div class="row px-3 align-items-center">
                                <div class="col-6 ticc-attachment-name">{{ticcInformation?.ticcAttachmentName}}</div>
                                <div class="col-6 d-flex justify-content-end">
                                    <mat-chip-list aria-label="Fish selection">

                                        <mat-chip color="primary" selected>
                                            {{ticcInformation?.ticcModule}}
                                        </mat-chip>

                                    </mat-chip-list>
                                </div>
                            </div>

                        </div>
                    </mat-card-subtitle>

                    <mat-card-content class="p-3">
                        <p>{{ticcInformation?.ticcTypeGuidanceText}}</p>

                        <hr>

                        <div class="col-12">
                            <table class="mat-table w-100">

                                <tr class="mat-header-row">
                                    <th class="mat-header-cell">Required</th>
                                    <th class="mat-header-cell">Type</th>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon *ngIf="ticcInformation?.ticcRequirement?.legal; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Legal</td>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon
                                            *ngIf="ticcInformation?.ticcRequirement?.certificate; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Certificate</td>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon
                                            *ngIf="ticcInformation?.ticcRequirement?.responsiblePerson; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Responsible Person</td>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon
                                            *ngIf="ticcInformation?.ticcRequirement?.competentPerson; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Competent Person</td>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon
                                            *ngIf="ticcInformation?.ticcRequirement?.observationCheckList; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Observations</td>
                                </tr>

                                <tr class="mat-row">
                                    <td class="mat-cell">
                                        <mat-icon *ngIf="ticcInformation?.ticcRequirement?.tagScans; else templateName"
                                            class="required-true">check_circle</mat-icon>
                                        <ng-template #templateName>
                                            <mat-icon class="required-false" #templateName>cancel</mat-icon>
                                        </ng-template>
                                    </td>
                                    <td class="mat-cell">Tag Scan</td>
                                </tr>

                            </table>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </form>

    </div>

    <div class="row always-display">

        <div class="col-5 col-md-4 col-lg-3 col-xl-2">
            <button class="always-display-buttons button-cancel" mat-button>CANCEL</button>
        </div>

        <div class="col-5 col-md-4 col-lg-3 col-xl-2 offset-2 offset-md-4 offset-lg-6 offset-xl-8">
            <button (click)="submit()" class="always-display-buttons button-create" [disabled]="!isConfirmed"
                mat-button>CREATE CHECK</button>
        </div>

    </div>
</div> -->
