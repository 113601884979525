<div class="container-fluid">
    <div class="row">

        <div class="col-4 py-3 col-md-3 col-lg-2 top-info">
            Site - {{siteName}} - Edit
        </div>

        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        Edit Site
                    </div>
                </div>
            </div>
        </div>



        <form class="row" (submit)="submit()" [formGroup]="formSiteEdit">


            <div class="col-12 p-0">
                <div class="row" [ngSwitch]="userState$ | async">

                    <div class="col-12 col-xl-6 ml-0 pl-0">
                        <div class="col-12">
                            <mat-card class="p-0 card">
                                <mat-card-header class="p-3">
                                    <mat-card-title class="m-0">
                                        Site Name
                                    </mat-card-title>
                                </mat-card-header>

                                <mat-card-content class="p-3">
                                    <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                        <mat-label style="color: black">Add Site</mat-label>

                                        <span matPrefix>
                                            <mat-icon class="icon-color">
                                                description
                                            </mat-icon>
                                        </span>

                                        <input matInput type="text" formControlName="siteName">

                                    </mat-form-field>
                                </mat-card-content>

                            </mat-card>
                        </div>

                        <div class="col-12">
                            <mat-card class="p-0 card">
                                <mat-card-header class="p-3">
                                    <mat-card-title class="m-0">Address</mat-card-title>
                                </mat-card-header>

                                <mat-card-content class="p-3">
                                    <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                        <mat-label style="color: black">Add Site Address</mat-label>

                                        <span matPrefix>
                                            <mat-icon class="icon-color">
                                                description
                                            </mat-icon>
                                        </span>

                                        <input matInput type="text" formControlName="siteAddress">
                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </div>

                    </div>




















                    <!-- ADMINISTRATOR -->
                    <!-- {{userState$ | async}} -->
                    <div class="col-12 col-xl-6" *ngSwitchCase="'administrator'">
                        <div class="col-12 extended-company-field-col">
                            <mat-card class="p-0 extended-company-field-col card">
                                <mat-card-header class="p-3 site-edit-company">
                                    <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                                        <div>
                                            Company
                                        </div>
                                        <div>
                                            <span class="company"
                                                [ngClass]="{'selected-company': useExistingCompany}">EXISTING COMPANY</span>
                                            &nbsp;&nbsp;<mat-slide-toggle
                                                (toggleChange)="OnSwitchCompanyUseOrCreate()"
                                                class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                            <span class="company" [ngClass]="{'selected-company': !useExistingCompany}">NEW
                                                COMPANY</span>
                                        </div>
                                    </mat-card-title>
                                </mat-card-header>


                                <mat-card-content formGroupName="siteCompany" class="p-3 extended-company-field-card-content"
                                    *ngIf="useExistingCompany; else newCompany">

                                    <div class="pt-2 mb-1">Parent Company</div>
                                    <mat-form-field appearance="fill" class="page-form-field select form-fields">

                                        <mat-label>Select Parent Company</mat-label>

                                        <mat-select #parentSelect [(value)]="selectedParanetId" (valueChange)="confirmParentCompanyChange($event);">
                                            <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                                [value]="parentCompany.companyId">
                                                {{parentCompany.companyName}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                    <div *ngIf="currentParentCompanyCompanies?.length">
                                    <div class="pt-4 mb-1">Company</div>
                                    <mat-form-field appearance="fill" class="page-form-field select form-fields">

                                        <mat-label>Select Company</mat-label>

                                        <mat-select formControlName="companyId">
                                          <mat-option *ngFor="let company of currentParentCompanyCompanies"
                                              [value]="company.companyId">
                                              {{company.companyName}}
                                          </mat-option>
                                      </mat-select>

                                    </mat-form-field>
                                  </div>
                                </mat-card-content>

                                <ng-template #newCompany>
                                    <mat-card-content formGroupName="siteCompany" class="p-3 extended-company-field-card-content">

                                        <div class="pt-2 mb-1">Parent Company</div>
                                        <mat-form-field appearance="fill" class="page-form-field select form-fields">

                                            <mat-label>Select Parent Company</mat-label>

                                            <mat-select #parentSelect [(value)]="selectedParanetId" (valueChange)="confirmParentCompanyChange($event);">
                                                <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                                    [value]="parentCompany.companyId">
                                                    {{parentCompany.companyName}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>

                                        <div class="pt-4 mb-1">Company</div>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                            <mat-label style="color: black">Add Company Name</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <input matInput type="text" formControlName="newCompanyName">

                                        </mat-form-field>

                                    </mat-card-content>
                                </ng-template>
                            </mat-card>
                        </div>
                    </div>











                    <!-- COMPANY MANAGER -->
                    <div class="col-6" *ngSwitchCase="'company manager'">
                        <div class="col-12 extended-company-field-col">
                            <mat-card class="p-0 extended-company-field-col card">
                                <mat-card-header class="p-3 site-edit-company">
                                    <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                                        <div>
                                            Company
                                        </div>
                                        <div>
                                            <span class="company"
                                                [ngClass]="{'selected-company': useExistingCompany}">EXISTING COMPANY</span>
                                            &nbsp;&nbsp;<mat-slide-toggle
                                                (toggleChange)="OnSwitchCompanyUseOrCreate()"
                                                class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                            <span class="company" [ngClass]="{'selected-company': !useExistingCompany}">NEW
                                                COMPANY</span>
                                        </div>
                                    </mat-card-title>
                                </mat-card-header>


                                <mat-card-content formGroupName="siteCompany" class="p-3 extended-company-field-card-content"
                                    *ngIf="useExistingCompany; else newCompany">

                                    <div class="pt-2 mb-1">Parent Company</div>
                                    <mat-form-field appearance="fill" class="disabled page-form-field select form-fields">

                                        <mat-label>{{siteParentCompanyName}}</mat-label>

                                        <mat-select disabled></mat-select>

                                    </mat-form-field>

                                    <div class="pt-4 mb-1">Company</div>
                                    <mat-form-field appearance="fill" class="page-form-field select form-fields">

                                        <mat-label>Select Company</mat-label>

                                        <mat-select formControlName="companyId">
                                            <mat-option *ngFor="let company of currentParentCompanyCompanies"
                                                [value]="company.companyId">
                                                {{company.companyName}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>

                                </mat-card-content>

                                <ng-template #newCompany>
                                    <mat-card-content formGroupName="siteCompany" class="p-3 extended-company-field-card-content">

                                        <div class="pt-2 mb-1">Parent Company</div>
                                        <mat-form-field appearance="fill" class="disabled page-form-field select form-fields">

                                            <mat-label>{{siteParentCompanyName}}</mat-label>

                                            <mat-select disabled></mat-select>

                                        </mat-form-field>

                                        <div class="pt-4 mb-1">Company</div>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                            <mat-label style="color: black">Add Company Name</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <input matInput type="text" formControlName="newCompanyName">

                                        </mat-form-field>

                                    </mat-card-content>
                                </ng-template>
                            </mat-card>
                        </div>
                    </div>











                    <!-- USER -->
                    <div class="col-6" *ngSwitchCase="'user'" [formGroup]="formSiteEdit">
                        <div class="col-12 extended-company-field-col">
                            <mat-card class="p-0 extended-company-field-col card">
                                <mat-card-header class="p-3 site-edit-company">
                                    <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                                        <div>
                                            Company
                                        </div>
                                    </mat-card-title>
                                </mat-card-header>


                                <mat-card-content class="p-3 extended-company-field-card-content">

                                    <div class="pt-2 mb-1">Parent Company</div>
                                    <mat-form-field appearance="fill" class="disabled page-form-field select form-fields">
    
                                        <mat-label>{{siteParentCompanyName}}</mat-label>
    
                                        <mat-select disabled></mat-select>
    
                                    </mat-form-field>

                                    <div class="pt-4 mb-1">Company</div>
                                    <mat-form-field appearance="fill" class="page-form-field select form-fields">

                                        <mat-label>{{formSiteEdit.get('siteCompany')?.get('companyName')?.value || "No Company Associated"}}</mat-label>

                                        <mat-select disabled></mat-select>

                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>




















                </div>
            </div>

            <div class="col-12 col-md-6">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Post Code</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site Post Code</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="sitePostCode">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12 col-md-6">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">City</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site City</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="siteCity">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0 p-0">
                            Notes
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Notes</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <textarea matInput type="text" formControlName="siteNotes"></textarea>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>
            </div>





            <div class="col-12 action-buttons-container">
                <div class="row d-flex justify-content-between">

                    <div class="col-6 col-md-5 col-xl-3">
                        <button mat-button type="submit" routerLink="/sites"
                            class="action-buttons cancel w-100">CANCEL</button>
                    </div>

                    <div class="col-6 col-md-5 col-xl-3">
                        <button (click)="submit()" mat-button type="submit" class="action-buttons submit w-100" [disabled]="!formSiteEdit.valid || siteEditing_loading">
                            <mat-spinner *ngIf="siteEditing_loading" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
                            {{siteEditing_loading ? '' : 'UPDATE'}}
                        </button>
                    </div>
                </div>
            </div>
        </form>



    </div>
</div>
