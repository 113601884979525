import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { now } from 'moment';
import { mergeMap, tap } from 'rxjs';
import { SiteAdminViewModel, SiteFullInfoViewModel, SiteModel } from 'src/app/models/backend-generated-models';
import { User } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/api/admin.service';
import { SitesService } from 'src/app/services/api/sites.service';

@Component({
  selector: 'app-admin-sites-management',
  templateUrl: './admin-sites-management.component.html',
  styleUrls: ['./admin-sites-management.component.scss']
})
export class AdminSitesManagementComponent implements OnInit {

  sites$: [string, [any]][] | undefined;
  sitesModels$: SiteModel[] | undefined;
  siteDeletePeriodHours?: number;

  constructor(
    private adminService: AdminService,
    private datePipe: DatePipe,

    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.adminService.getSites().subscribe(
      res => {
        this.sitesModels$ = res.sites?.map(p => {
          return {
            id: p.id,
            uuid: p.uuid,
            siteName: p.siteName,
            membersCount: p.membersCount,
            assetsCount: p.assetsCount,
            boardsCount: p.boardsCount,
            ticcsCount: p.ticcsCount,
            createdAt: p.createdAt,
            updatedAt: p.updatedAt,
            owner: p.owner,
            enabled: p.enabled,
            toBeDeleted: p.toBeDeleted,
            siteCompanyName: p.siteCompany?.companyName
          }
        })
        this.sites$ = Object.entries(this.groupByProperty(this.sitesModels$ as [], 'siteCompanyName'));
        this.siteDeletePeriodHours = res.siteDeletePeriodHours;
        console.log(this.sites$)
      }
    )
  }

  filterBySitesNew(sites: any) {
    return sites.filter((x: { enabled: boolean; toBeDeleted: null; }) => x.enabled === null && x.toBeDeleted === null);
  }
  filterBySitesEnabled(sites: any) {
    return sites.filter((x: { enabled: boolean; toBeDeleted: null; }) => x.enabled == true && x.toBeDeleted === null);
  }
  filterBySitesDisabled(sites: any) {
    return sites.filter((x: { enabled: boolean; toBeDeleted: null; }) => !x.enabled && x.toBeDeleted === null);
  }
  filterBySitesToBeDeleted(sites: any) {
    return sites.filter((x: { enabled: boolean; toBeDeleted: null; }) => x.toBeDeleted !== null);
  }


  groupByProperty(arr: SiteModel[], ticcTypeModule: string) {
    return arr.reduce(
      (previousVal: any, currentVal: any) => {
        if (!previousVal[currentVal[ticcTypeModule]]) {
          previousVal[currentVal[ticcTypeModule]] = [];
        }
        previousVal[currentVal[ticcTypeModule]].push(currentVal);
        return previousVal;
      }, {});
  }

  parseDate(date: Date) {
    return date?.toLocaleString().split('T')[0]
  }

  siteToggle(site: SiteModel) {
    this.adminService.setSitesEnabledOrDisabled(site.enabled == false ?? true, site.uuid as string).subscribe((respDate => {
      return site.enabled = respDate as boolean;
    }));
  }

  newSiteApprove({ site }: { site: SiteModel; }) {
    this.adminService.setSitesEnabledOrDisabled(true, site.uuid as string).subscribe((responseData => {
      return site.enabled = responseData as boolean;
    }))
  }
  newSiteReject(site: SiteModel) {
    this.adminService.setSitesEnabledOrDisabled(false, site.uuid as string).subscribe((responseData => {
      return site.enabled = responseData as boolean;
    }))
  }

  deletedSiteTimeLeftParse(timeLeft: string) {
    let now = new Date(this.datePipe.transform(new Date(), "MM/dd/yyyy")!);
    let time = new Date(this.datePipe.transform(new Date(timeLeft), "MM/dd/yyyy")!);

    const diffTime = Math.abs(+now! - +time!);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  reactivateSite(site: SiteModel) {
    this.adminService.reactivateSite(site.uuid as string).subscribe((res) => {
      site.enabled = res as boolean;
      return site.toBeDeleted = null as any;
    })
  }

  deleteSite(site: SiteModel) {
    this.dialog.open(DialogSiteDelete, {
      width: '800px',
      data: {
        name: site.siteName,
        time: this.siteDeletePeriodHours
      },
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .subscribe(res => {
        res ? this.adminService.deleteSite(site.uuid).subscribe(() => { return site.toBeDeleted = new Date(); }) : null
      })
  }

}

@Component({
  selector: 'dialog-site-delete',
  templateUrl: 'dialog-site-delete.html',
  styleUrls: ['./admin-sites-management.component.scss']

})
export class DialogSiteDelete {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string | undefined, time: number | undefined },
    public dialogRef: MatDialogRef<DialogSiteDelete>
  ) { }

  parseDate(time: number | undefined) {
    if(time == 1) {
      return time;
    }
    return Math.floor(time as number / 24);
  }
}
