import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardsService } from 'src/app/services/api/boards.service';

@Component({
  selector: 'app-board-edit',
  templateUrl: './board-edit.component.html',
  styleUrls: ['./board-edit.component.scss']
})
export class BoardEditComponent {
  formEditBoard = this.fb.group({
    boardName: this.fb.control(null, Validators.required),
    boardDescription: this.fb.control(null, Validators.required),
    boardSiteName: this.fb.control(null),
  });

  previousUrl?: string | null;

  boardUuid = this.route.snapshot.paramMap.get('uuid');
  boardName: string | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private boardsService: BoardsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.boardsService.getBoard(this.boardUuid).subscribe((res) => {
      this.boardName = res.name;

      this.formEditBoard = new UntypedFormGroup({
        boardName: this.fb.control(res.name),
        boardDescription: this.fb.control(res.description),
        boardSiteName: this.fb.control({value: res.siteName, disabled: true})
      });
    });
  }

  submit() {
    this.formEditBoard.markAllAsTouched();

    if (!this.formEditBoard.valid) return;

    this.boardsService.editBoard(this.boardUuid as string, this.formEditBoard.value).subscribe((res: any) => {
      this.router.navigate(['boards/', this.boardUuid])
    })
  }
}
