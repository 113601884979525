<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="create-site-button-container d-flex justify-content-end">
  <button routerLink="/sites/create" mat-button class="create-site-button d-flex">
    <mat-icon>add_circle</mat-icon>
    <div class="d-none d-md-block">&nbsp;&nbsp;REQUEST SITE CREATION</div>
  </button>
</div>

<mat-drawer #drawer class="sidenav-content-container d-flex flex-wrap zindex-1000" mode="over">
  <div class="col-12 d-flex align-items-center filter-header">
    <div class="col-8 offset-1 filters-title">
      Apply Filters
    </div>

    <div class="col-2 filters-count-container d-flex flex-wrap">
      <span class="filters-count">{{selectionCompanies.selected.length + selectionOwners.selected.length +
        selectionCities.selected.length}}</span>
      <span class="filters-text w-100">FILTERS</span>
    </div>
  </div>

  <div class="col-12">
    <mat-accordion class="filters-accordion" multi>
      <mat-expansion-panel class="w-100">

        <mat-expansion-panel-header>
          <div class="col-10 d-flex align-items-center">
            <mat-panel-title class="user-title">
              <mat-icon>apartment</mat-icon>&nbsp;
              Companies
            </mat-panel-title>
          </div>
          <div class="col-2">
            <div class="count-expansion-pannel">
              {{selectionCompanies.selected.length}}
            </div>
          </div>
        </mat-expansion-panel-header>

        <div class="row" *ngFor=" let company of filter$?.[0].companies">
          <!-- <div class="col-12 py-2">
                          <mat-checkbox class="example-margin" [ngClass]="{'strong': true}">Parent Company</mat-checkbox>
                      </div> -->
          <div class="col-12 py-2">
            <mat-checkbox class="example-margin" (change)="selectionCompanies.toggle(company)"
              [checked]="selectionCompanies.isSelected(company)">{{company}}</mat-checkbox>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="col-12">
    <mat-accordion class="filters-accordion" multi>
      <mat-expansion-panel class="w-100">

        <mat-expansion-panel-header>
          <div class="col-10 d-flex align-items-center">
            <mat-panel-title class="user-title">
              <mat-icon>pin_drop</mat-icon>&nbsp;
              Cities
            </mat-panel-title>
          </div>
          <div class="col-2">
            <div class="count-expansion-pannel">
              {{selectionCities.selected.length}}
            </div>
          </div>
        </mat-expansion-panel-header>

        <div class="row" *ngFor=" let city of filter$?.[1].cities">
          <div class="col-12 py-2">
            <mat-checkbox class="example-margin" (change)="selectionCities.toggle(city)"
              [checked]="selectionCities.isSelected(city)">{{city}}</mat-checkbox>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="col-12">
    <mat-accordion class="filters-accordion" multi>
      <mat-expansion-panel class="w-100">

        <mat-expansion-panel-header>
          <div class="col-10 d-flex align-items-center">
            <mat-panel-title class="user-title">
              <mat-icon>groups</mat-icon>&nbsp;
              Site Owners
            </mat-panel-title>
          </div>
          <div class="col-2">
            <div class="count-expansion-pannel">
              {{selectionOwners.selected.length}}
            </div>
          </div>
        </mat-expansion-panel-header>

        <div class="row" *ngFor=" let owner of filter$?.[2].owners">
          <div class="col-12 py-2">
            <mat-checkbox class="example-margin" (change)="selectionOwners.toggle(owner)"
              [checked]="selectionOwners.isSelected(owner)">{{owner}}</mat-checkbox>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- <div class="col-6">
              <div class="fixed-button-container d-flex justify-content-end">
                  <button routerLink="/sites/create" mat-button class="fixed-button d-flex"><mat-icon>add_circle</mat-icon><div class="d-none d-md-block">&nbsp;&nbsp;REQUEST SITE CREATION</div></button>
              </div>
          </div> -->

  <div class="row">
    <div class="col-6 filter-button-container">
      <button mat-button class="filter-button cancel-filter-button" (click)="drawer.toggle();clearSelectionFilters();">
        CANCEL
      </button>
    </div>
    <div class="col-6 filter-button-container" (click)="filter();drawer.toggle();">
      <button mat-button class="filter-button apply-filter-button">
        APPLY FILTERS
      </button>
    </div>
  </div>
</mat-drawer>

<div class="page-top-breadcrumbs">
  SITES
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
  Companies - Sites
</h1>
<!-- Is user admin {{isUserAdmin$ | async}} -->
<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="row d-flex justify-content-center w-100 m-0">
  <div class="col-6 col-md-4 col-lg-2 filter-button-container">
    <button (click)="drawer.toggle()" mat-button class="open-filter-button open-filtering">
      <mat-icon>tune</mat-icon>&nbsp;
      Filter
    </button>
  </div>
  <div class="col-6 col-md-4 col-lg-2 filter-button-container">
    <button mat-button class="open-filter-button open-grouping" [matMenuTriggerFor]="grouping">
      <mat-icon>sort</mat-icon>&nbsp;
      Group By
    </button>
    <mat-menu #grouping="matMenu">
      <button mat-menu-item
        (click)="sotrByCompanyrAscendingOrder('siteCompanyName')"><mat-icon>trending_up</mat-icon>A-Z Company
        name</button>
      <button mat-menu-item (click)="sotrByCityAscendingOrder('siteCity')"><mat-icon>trending_up</mat-icon>A-Z City
        name</button>
      <button mat-menu-item (click)="sotrBySiteOwnerAscendingOrder('siteOwner')"><mat-icon>trending_up</mat-icon>A-Z
        Site Owner name</button>
      <button mat-menu-item
        (click)="sotrByCompanyDescendingOrder('siteCompanyName')"><mat-icon>trending_down</mat-icon>Z-A Company
        name</button>
      <button mat-menu-item (click)="sotrByCityDescendingOrder('siteCity')"><mat-icon>trending_down</mat-icon>Z-A City
        name</button>
      <button mat-menu-item (click)="sotrBySiteOwnerDescendingOrder('siteOwner')"><mat-icon>trending_down</mat-icon>Z-A
        Site Owner
        name</button>
    </mat-menu>
  </div>
</div>

<mat-drawer-container class="sidenav-general-container" autosize>
  <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
    <mat-spinner class="my-auto"></mat-spinner>
  </div>

  <div *ngIf="!(isLoading$ | async)" class="container-fluid d-flex justify-content-center flex-wrap">
    <mat-accordion class="w-100 main-card ticc-types-accordion">
      <div *ngFor="let company of sites$; first as isFirst">

        <mat-expansion-panel hideToggle class="mt-3" [expanded]="isFirst">
          <mat-expansion-panel-header class="d-flex p-0">

            <mat-icon class="p-2">circle</mat-icon>
            <div class="p-2 expansion-header-center-title">{{company[0]}}</div>
            <div class="d-flex">
              <div class="site-box d-flex p-2 expansion-header-rigth">
                <div class="site">{{company[1].length}} sites</div>
              </div>
              <mat-icon class="p-2">expand_more</mat-icon>
            </div>
          </mat-expansion-panel-header>

          <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="row p-0 m-0 content">
            <div *cdkVirtualFor="let site of company[1]" class="col-12 col-xxl-6 detail-panel">
              <div class="row">
                <div class="col-10 col-sm-11">

                  <div class="top-row row p-3">
                    <div class="col-12 col-lg-3 py-2 d-flex align-center">
                      <mat-checkbox [color]="'primary'"
                        (change)="markForBulkOperation(site.siteUUID)"></mat-checkbox>&nbsp;
                      <span class="text-wrap">{{site.siteName}}</span>
                    </div>

                    <div class="col-12 col-lg-9 py-2">
                      <div class="row">
                        <div class="col-6 col-sm-3 d-flex align-center">
                          <mat-icon>image</mat-icon>&nbsp;
                          <span>{{site.siteAssetsCount}}</span>&nbsp;
                          <span class="strong">assets</span>
                        </div>
                        <div class="col-6 col-sm-3 d-flex align-center">
                          <mat-icon>assignment</mat-icon>&nbsp;
                          <span>{{site.siteBoardsCount}}</span>&nbsp;
                          <span class="strong">boards</span>
                        </div>
                        <div class="col-6 col-sm-3 d-flex align-center">
                          <mat-icon>group</mat-icon>&nbsp;
                          <span>{{site.siteMembersCount}}</span>&nbsp;
                          <span class="strong">users</span>
                        </div>
                        <div class="col-6 col-sm-3 d-flex align-center">
                          <mat-icon>task_alt</mat-icon>&nbsp;
                          <span>{{site.siteTiccsCount}}</span>&nbsp;
                          <span class="strong">ticcs</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bottom-row row p-3">
                    <div class="col-6 col-sm-3 py-2 text-wrap">
                      <span class="italic">Site Owner:</span>&nbsp;<br class="d-block d-xl-none">
                      <span class="strong text-wrap">{{site.siteOwner}}</span>
                    </div>
                    <div class="col-6 col-sm-3 py-2">
                      <span class="italic">Last Check:</span>&nbsp;<br class="d-block d-xl-none">
                      <span class="date strong">
                        {{
                        parseDate(site.siteLastCheckAt) | date:
                        'dd-MM-yyyy'
                        }}
                      </span>
                    </div>
                    <div class="col-6 col-sm-3 py-2">
                      <span class="italic">Created At:</span>&nbsp;<br class="d-block d-xl-none">
                      <span class="date strong">
                        {{
                        parseDate(site.siteCreatedAt) | date:
                        'dd-MM-yyyy'
                        }}
                      </span>
                    </div>
                    <div class="col-6 col-sm-3 py-2">
                      <span class="italic">Updated At:</span>&nbsp;<br class="d-block d-xl-none">
                      <span class="date strong">
                        {{
                        parseDate(site.siteUpdatedAt) | date:
                        'dd-MM-yyyy'
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="action-buttons col-2 col-sm-1 p-0 d-flex flex-wrap align-center">
                  <button routerLink="/sites/{{site.siteUUID}}" mat-button
                    class="icon-box view p-0 w-100 justify-content-center">
                    <mat-icon class="w-100">visibility</mat-icon>
                    <div class="w-100">VIEW</div>
                  </button>
                  <button [disabled]="!site.enableEditButoon" routerLink="/sites/{{site.siteUUID}}/edit" mat-button
                    class="icon-box edit p-0 w-100 justify-content-center">
                    <mat-icon class="w-100">edit</mat-icon>
                    <div class="w-100">EDIT</div>
                  </button>
                </div>

              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </mat-expansion-panel>
      </div>
    </mat-accordion>


    <div class="sites-page-container d-flex justify-content-center">
      <button mat-button *ngIf="buttonForBulkOperation" (click)="openBottomSheetDialogSelector(sitesBulkArr.length)"
        class="fixed-button d-flex flex-wrap">
        <span>CHOOSE ACTION</span><br>
      </button>
    </div>
  </div>
</mat-drawer-container>
