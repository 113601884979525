import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { UserInfoViewModel } from 'src/app/models/backend-generated-models';
import { UserService } from 'src/app/services/api/user.service';
import { PhotoConvertService } from 'src/app/services/helpers/photo-convert';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  formUserEdit: UntypedFormGroup = new UntypedFormGroup({
    userFirstName: new UntypedFormControl("", Validators.required),
    userLastName: new UntypedFormControl("", Validators.required)
  })

  userDetails: UserInfoViewModel | undefined;

  nameOnFocus: boolean = false;
  surnameOnFocus: boolean = false;
  formData = new FormData();

  userUuid = this.route.snapshot.paramMap.get('userUUID');

  updateButtonEnabled: boolean = false;
  userPhoto: SafeUrl | undefined;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private photoConvert: PhotoConvertService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.userService.getUserInformation(this.route.snapshot.paramMap.get('userUUID')).subscribe((res) => {
      this.userDetails = res;
      this.formUserEdit.get('userFirstName')?.patchValue(res.userFirstName);
      this.formUserEdit.get('userLastName')?.patchValue(res.userLastName);
    })
  }

  editUser() {
    console.log(this.formUserEdit.value)
    if (!this.formUserEdit.valid) return;

    this.userService.editUser(this.userUuid, this.formUserEdit.value).subscribe((res) => {
      console.log("successfuly edited", res);
      this.userService.setNewUserName(this.formUserEdit.get('userFirstName')?.value + " " + this.formUserEdit.get('userLastName')?.value);
      this.userService.upload(this.formData, this.userUuid as string).subscribe({
        next: (blobImage) => {

          let parsedBlobImage = URL.createObjectURL(blobImage);

          this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          this.router.navigate(['/users/' + this.userUuid])
        }
      });
    })
  }


  uploadFile(event: any): void {
    this.updateButtonEnabled = true;
    const file: File = event.target.files[0];
    this.formData.append("file", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const url = reader.result as string;
      this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(url);
    };
  }

  avatarConvert(photo: any) {
    if (photo == null) {
      return null;
    }
    return this.photoConvert.photoConvert(photo);
  }
}
