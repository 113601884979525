<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div class="fixed-button-container d-flex justify-content-end">
    <button routerLink="/admin/ticc-types/create" mat-button class="fixed-button d-flex"><mat-icon>add_circle</mat-icon><div class="d-none d-md-block">&nbsp;&nbsp;NEW TICC TYPE</div></button>
</div>


<div class="page-top-breadcrumbs">
    ADMIN - TICC TYPES
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
    TICC Types
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->

<div class="container-fluid">
    <mat-accordion class="main-card ticc-types-accordion">
        <div *ngFor="let item of ticcTypes$; first as isFirst">

            <mat-expansion-panel hideToggle class="mt-3" [expanded]="isFirst || isNewTiccType(item[1])">
                <mat-expansion-panel-header class="d-flex p-0">

                    <mat-icon class="p-2">circle</mat-icon>

                    <div class="p-2 expansion-header-center-title">&nbsp;{{item[0]}}</div>

                    <div class="d-flex">
                        <div class="site-box d-flex p-2 expansion-header-rigth">
                            <div class="site-count">{{item[1].length}}&nbsp;</div>
                            <div class="site">Ticc Types</div>
                        </div>
                        <mat-icon class="p-2">expand_more</mat-icon>
                    </div>
                </mat-expansion-panel-header>

                <div class="row p-0 m-0 content">
                    <div *ngFor="let item of item[1]" class="col-12 col-lg-6 pt-3 detail-panel">
                        <div class="row">
                            <div class="col-12" style="border-bottom: solid 1px;">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center">

                                                <ng-container
                                                    *ngIf="item.ticcTypeRequirements[0].legal; else notComnpetent">
                                                    <div class="circle-done">
                                                        <mat-icon>done</mat-icon>
                                                    </div>
                                                </ng-container>

                                                <ng-template #notComnpetent>
                                                    <div class="circle-close">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </ng-template>

                                            </div>
                                            <div class="col-12 d-flex justify-content-center">
                                                Legal
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center">
                                                <div class="circle-done">

                                                    <ng-container
                                                        *ngIf="item.ticcTypeRequirements[0].attachedFiles; else notComnpetent">
                                                        <div class="circle-done">
                                                            <mat-icon>done</mat-icon>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #notComnpetent>
                                                        <div class="circle-close">
                                                            <mat-icon>close</mat-icon>
                                                        </div>
                                                    </ng-template>

                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center">
                                                Certificate
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center">
                                                <div class="circle-close">

                                                    <ng-container
                                                        *ngIf="item.ticcTypeRequirements[0].responsiblePerson; else notComnpetent">
                                                        <div class="circle-done">
                                                            <mat-icon>done</mat-icon>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #notComnpetent>
                                                        <div class="circle-close">
                                                            <mat-icon>close</mat-icon>
                                                        </div>
                                                    </ng-template>

                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center">
                                                Responsible Person
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center">
                                                <div class="circle-done">

                                                    <ng-container
                                                        *ngIf="item.ticcTypeRequirements[0].competentPerson; else notComnpetent">
                                                        <div class="circle-done">
                                                            <mat-icon>done</mat-icon>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #notComnpetent>
                                                        <div class="circle-close">
                                                            <mat-icon>close</mat-icon>
                                                        </div>
                                                    </ng-template>

                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center">
                                                Competent Person
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-8 p-0 px-2">
                                <div class="col-12 d-flex site-title-panel">
                                    <mat-icon>radio_button_unchecked</mat-icon>&nbsp;{{item.ticcTypeName}}
                                </div>

                                <div class="col-12 d-flex attachment-types-panel">
                                    <div class="italic">Attachment types:&nbsp;</div>

                                    <mat-chip-list>
                                        <mat-chip *ngFor="let attachmentTypes of item.ticcAttachmentType">&nbsp;{{attachmentTypes.ticcAttachmentTypeName}}</mat-chip>
                                    </mat-chip-list>
                                </div>
                            </div>

                            <div class="col-3 p-0 px-2 d-flex date-panel">
                                <div class="col-12 d-flex align-items-center strong">
                                    <mat-icon class="standard-color">check_circle</mat-icon>&nbsp;{{item.ticcsCount}} TiCCs
                                </div>
                                <div class="col-12">
                                    <div class="italic">Created At:</div>
                                    <div class="strong">&nbsp;{{parseDate(item.createdAt)}}</div>
                                </div>
                                <div class="col-12">
                                    <div class="italic">Updated At:</div>
                                    <div class="strong">&nbsp;{{parseDate(item.updatedAt)}}</div>
                                </div>
                            </div>

                            <div class="col-1 p-0 d-flex flex-wrap">
                                <button mat-button [disabled]="true" class="icon-box edit px-1 px-xs-2 px-md-3 d-flex flex-wrap w-100 justify-content-center">
                                    <mat-icon class="w-100">edit</mat-icon>
                                    <div class="w-100">EDIT</div>
                                </button>

                                <button mat-button (click)="deleteTiccType(item)" [disabled]="item.isDeleted" class="icon-box delete px-1 px-xs-2 px-md-3 d-flex flex-wrap w-100 justify-content-center">
                                    <mat-icon class="w-100">delete</mat-icon>
                                    <div class="w-100">DELETE</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</div>
