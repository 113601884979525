<div class="background"><!-- background-image -->
    <div class="container-fluid">
        <div class="row my-0 mx-0">

            <div class="d-sm-inline d-none col-12 col-sm-7 py-5 logotype-container">
                <div class="col-12 col-sm-8 offset-sm-2">
                    <img class="logotype" src="../../../web-assets/logotype/Logo-01.png" alt="">
                </div>
            </div><!-- logotype_left-side -->

            <div class="col-12 col-sm-5 py-5 darker-background">

                <span class="d-sm-none">
                    <img class="mb-4 logotype" src="../../../web-assets/logotype/Logo-01.png" alt="">
                </span><!-- logotype-mobile -->

                <div class="col-12 d-flex justify-content-center">
                    <u class="mat-headline header-color">USER LOGIN</u>
                </div>

                <div>
                    <div class="col-12 d-flex justify-content-center">
                        <mat-spinner *ngIf="isLoginInProces$ | async"></mat-spinner>
                    </div>
                </div>
                
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" [ngClass]="{ blur: (isLoginInProces$ | async) }">

                    <mat-form-field class="my-3 col-12" appearance="fill">
                        <mat-label class="label-color">EMAIL ADDRESS</mat-label>
                        <input class="input-color" formControlName="email" matInput placeholder="Intoduce your email account">
                        <mat-icon class="icon-color" matPrefix>person</mat-icon>
                        <mat-error mat-error>Please, introduce your email</mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="my-3 col-12" appearance="fill">
                        <mat-label class="label-color">PASSWORD</mat-label>
                        <input class="input-color" formControlName="password" matInput placeholder="Introduce your password" type="password" >
                        <mat-icon class="icon-color" matPrefix>lock</mat-icon>
                        <mat-error mat-error>Please, introduce your password</mat-error>
                    </mat-form-field>
    
                    <div class="col-12 my-5 d-flex justify-content-center">
                        <button type="submit" mat-stroked-button color="accent" class="main-button">
                            LOGIN <mat-icon class="icon-color icon-size material-symbols-outlined">arrow_right_alt
                            </mat-icon>
                        </button>
                    </div>
                </form>

                <a class="link-access" routerLink="/change-password">
                    <p>FORGOT<br>PASSWORD?</p>
                </a>

                <hr class="line-access">
                
                <a class="link-access" routerLink="/register">
                    <p>SIGNUP<br>NOW</p>
                </a>

            </div><!-- form_rigth-side -->

        </div><!-- row -->
    </div><!-- container-fluid -->
</div><!-- background -->