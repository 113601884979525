<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->

<div class="create-user-button-container d-flex justify-content-end">
    <button *ngIf="boardDetails$?.displayEditBoardButton" [routerLink]="['edit']" mat-button class="create-user-button d-flex"><mat-icon>edit</mat-icon>
        <div class="d-none d-md-block">&nbsp;&nbsp;EDIT BOARD</div>
    </button>
</div>

<div class="container-fluid ticc-creation-container">

    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb"><span class="single-breadcrumb-link" routerLink="/boards">Boards</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb"><span>{{boardDetails$?.name}}</span></div>
        </div>
    </div>


    <div class="row p-3 m-0">

        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        Board Detail
                    </div>

                    <hr>
                </div>
            </div>
        </div>


        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Overview
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="p-3 information-content">

                <div class="row m-0">
                    <div class="col-12 mb-2">
                        <span class="parameter"><span class="parameter-definition">Name:&nbsp;</span>{{boardDetails$?.name}}</span>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="parameter"><span class="parameter-definition">Site:&nbsp;</span>{{boardDetails$?.siteName}}</span>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="parameter"><span class="parameter-definition">Description:&nbsp;</span>{{boardDetails$?.description}}</span>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="parameter"><span class="parameter-definition">Created At:&nbsp;</span>{{boardDetails$?.createdAt}}</span>
                    </div>
                    <div class="col-12">
                        <span class="parameter"><span class="parameter-definition">Updated At:&nbsp;</span>{{boardDetails$?.updatedAt}}</span>
                    </div>
                </div>
            </mat-card-content>

        </mat-card>



        <mat-tab-group class="col-12 m-0 p-0">

            <mat-tab label="Circuits" class="m-0">

                <div class="row m-0 mt-3">
                    <div class="col-12 p-0">
                        <button [disabled]="!boardDetails$?.enableCreateCircuitButton" [routerLink]="['/boards/' + boardUuid + '/circuits/create']" mat-button class="w-100 cyan-button">
                            <!-- 'boards/' + boardDetails$?.uuid! +  -->
                            NEW CIRCUIT
                        </button>
                    </div>
                </div>


                <mat-card class="card col-12">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">
                            Circuit Management <span class="additional-header-information">Additional info</span>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class=" information-content">

                        <div class="row circuit-container m-0">

                                <div class="col-12 col-lg-6 col-xl-4 pb-3 circuit-container-single-box" *ngFor="let circuit of boardCircuits$">
                                        <div class="col-12 mt-2 mb-3 d-flex circuit-name">
                                            <div class="col-8 d-flex align-items-center">{{circuit.ref}}</div>
                                            <!-- EDIT functional not created yet -->
                                            <button
                                                [disabled]="true || deleteCircuitUuid == circuit.uuid"
                                                [routerLink]="true || deleteCircuitUuid != circuit.uuid ? ['/boards/' + boardUuid + '/circuits/' + circuit.uuid + '/edit'] : null"
                                                mat-button
                                                class="icon-box edit-icon d-flex flex-wrap w-100 justify-content-center">
                                                <mat-icon class="w-100">edit</mat-icon>
                                                <div class="w-100">EDIT</div>
                                            </button>
                                            <button
                                                [disabled]="deleteCircuitUuid == circuit.uuid"
                                                (click)="deleteCircuit(circuit)"
                                                mat-button
                                                class="icon-box delete delete-icon d-flex flex-wrap w-100 justify-content-center">
                                                <mat-icon class="w-100">delete</mat-icon>
                                                <div class="w-100">DELETE</div>
                                            </button>

                                        </div>
                                        <div class="col-12 mb-2 d-flex">
                                            <div class="col-4"><span class="parameter-definition">CircuitUUID:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.uuid}}</span></div>
                                        </div>
                                        <div class="col-12 mb-2 d-flex">
                                            <div class="col-4"><span class="parameter-definition">Protective Device:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.protectiveDevice}}</span></div>
                                        </div>
                                        <div class="col-12 mb-2 d-flex">
                                            <div class="col-4"><span class="parameter-definition">Rating:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.rating}}</span></div>
                                        </div>
                                        <div class="col-12 mb-2 d-flex">
                                            <div class="col-4"><span class="parameter-definition">Description:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.description}}</span></div>
                                        </div>
                                        <div class="col-12 mb-2 d-flex">
                                            <div class="col-4"><span class="parameter-definition">Created At:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.createdAt | date:'dd-MM-YYYY'}}</span></div>
                                        </div>
                                        <div class="col-12 d-flex">
                                            <div class="col-4"><span class="parameter-definition">Updated At:&nbsp;</span></div>
                                            <div class="col-8"><span class="parameter">{{circuit.updatedAt | date:'dd-MM-YYYY'}}</span></div>
                                        </div>
                                </div>
                        </div>
                    </mat-card-content>

                </mat-card>

            </mat-tab>


            <mat-tab label="TICCs" class="m-0 p-0">
                <app-ticcs-list [ticcsArray$]="ticcs$" [pageType]="'boards'" [uuid]="boardDetails$?.uuid!" [siteUuid]="boardDetails$?.siteUuid"></app-ticcs-list>
            </mat-tab>

            <mat-tab label="TAG" class="m-0 p-0">
                <div class="row m-0 mt-3 p-0">
                    <div class="col-12" [ngClass]="{'col-12': !image, 'col-12 col-md-8': image}">
                        <mat-card class="p-0">
                            <mat-card-header class="p-3">
                              <mat-card-title class="m-0">QR Code UUID</mat-card-title>
                            </mat-card-header>

                            <mat-card-content class="p-3" *ngIf="!tagStatusError; else noTag">
                                <div class="w-100" style="text-align: center; margin-bottom: 1rem; font-size: 1.1rem;">
                                    {{tag$?.tagUuid}}
                                </div>
                                <button (click)="disassociateTag()" class="button-center button-disassociate w-100" mat-button style="text-align: center;"><mat-icon>delete</mat-icon> DISASSOCIATE TAG</button>
                            </mat-card-content>

                            <ng-template #noTag>
                                <mat-card-content class="p-3" style="text-align: center !important; font-size: 1.3rem;">
                                    <p style="color: #F61010;">No TAG associated</p>
                                    <p style="font-size: 20px;">Add TAG by UUID</p>
                                    <mat-form-field class="col-12" appearance="outline">
                                        <mat-label>TAG UUID</mat-label>
                                        <input type="text" [(ngModel)]="tagUUID" matInput>
                                    </mat-form-field>
                                    <button mat-button type="submit" class="action-buttons submit w-100" [disabled]="tagUUID == undefined" (click)="associationToTag()" >Add TAG</button>
                                    <p style="font-size: 20px;">Add TAG form QR scan</p>
                                    <button mat-button routerLink="/scanner" [queryParams]="{ lastUrl: 'boards' , uuid:  boardUuid }" class="action-buttons scan w-100"><img
                                            src="web-assets/svg/qrcode.svg"> Scan QR code</button>
                                </mat-card-content>
                            </ng-template>
                        </mat-card>
                    </div>

                    <div class="col-12 col-md-4 tag-image" [ngClass]="{'d-none': !image, 'd-block': image}">
                        <img [src]="image" class="w-100 img-fluid rounded-top" alt="">
                    </div>
                </div>

            </mat-tab>

        </mat-tab-group>


    </div>


    <div class="row m-0">
        <!-- <div class="col-6">
            <button class="w-100 red-button" mat-button [routerLink]="'/sites/' + siteUuid">Cancel</button>
        </div>
        <div class="col-6">
            <button class="w-100 green-button" mat-button (click)="submit()">Create</button>
        </div> -->
    </div>

</div>
