<div class="m-0 p-0">

    <h1 class="dialog-title mx-2 mb-3">{{data?.title ?? "Set check missed recipients"}}</h1>


    <div class="dialog-main-text mx-2 mb-3">

        <div class="module-user-selector">
            <div *ngFor="let user of selectedUsersList">
                <mat-checkbox class="col-12 my-1 d-flex flex-wrap justif-content-between" [(ngModel)]="user.enabled">
                    <span class="col-9 module-user-name">{{user.user.userFullName}}</span>
                    <span class="col-3 module-user-role">{{user.user.userSiteRoleName}}</span>
                </mat-checkbox>
            </div>
        </div>

    </div>
    <!-- <div class="dialog-main-text mx-2 mb-3" *ngIf="data.userFirstName; else deleteDialogUser">
        Are you sure you want to delete user <span class="italic data">"{{data.userFirstName}}&nbsp;{{data.userLastName}}"</span> <br>
        Are you sure?
    </div> -->

    <!-- <ng-template #deleteDialogUser>
      <div class="dialog-main-text mx-2 mb-3" >
          Are you sure you want to delete user <span class="italic data">"{{data.userFullName}}"</span> <br>
          Are you sure?
      </div>
    </ng-template> -->

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button [disabled]="isLoading$ | async" class="col-4 dialog-save-action-button" (click)="submit()" mat-button>       
            <mat-spinner *ngIf="isLoading$ | async" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
            {{(isLoading$ | async) ? '' : 'SAVE'}}
        </button>
    </div>

</div>