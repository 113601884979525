import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { TiccByUuidViewModel, TiccCheckResponseModel } from 'src/app/models/backend-generated-models';
import { SitesService } from 'src/app/services/api/sites.service';
import { TiccsService } from 'src/app/services/api/ticcs.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';
import { UserStateService } from 'src/app/services/state/user-state.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
// import { Breadcrumbs } from 'src/app/shared/literals/breadcrumbs';

@Component({
  selector: 'app-ticcs-detail',
  templateUrl: './ticcs-detail.component.html',
  styleUrls: ['./ticcs-detail.component.scss']
})
export class TiccsDetailComponent implements OnInit, OnDestroy {

  ticcUuid = this.route.snapshot.paramMap.get('uuid');

  checkHistory: TiccCheckResponseModel[] = [];

  /* breadcrumbs!: Breadcrumbs */

  breadcrumbsPageType: string | null = "";
  breadcrumbsUuid: string | null = "";


  ticcInformation: TiccByUuidViewModel | undefined;
  attachmentType: string | undefined;

  constructor(
    private userStateService: UserStateService,
    private sitesStateService: SiteStateService,
    private ticcsService: TiccsService,
    private sitesService: SitesService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.breadcrumbsPageType = localStorage.getItem("breadcrumbs_pageType");
    this.breadcrumbsUuid = localStorage.getItem("breadcrumbs_uuid");

    this.ticcsService.getCheckHistory(this.ticcUuid).subscribe((res) => {
      this.checkHistory = res;
    });

    this.ticcsService.getTiccInformation(this.ticcUuid).subscribe((res) => {
      this.ticcInformation = res;
      if (!this.ticcInformation.schedule) {
        this.snackbar.open('Please set up Schedule', 'ok', {
          panelClass: 'custom-snackbar-error',
          politeness: 'polite',
          duration: 4000
        });
      }
      this.getAttachmentTypeForUrl();
    });

    this.sitesStateService.currentUserSite$.subscribe((res) => {
      this.userStateService.userHasAccess(res.id).subscribe(
        (res) => {
          console.log("has access", res)
        }
      );
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("breadcrumbs_pageType");
    localStorage.removeItem("breadcrumbs_uuid");
  }

  getAttachmentTypeForUrl() {
    if (this.breadcrumbsPageType == 'home') {
      this.attachmentType = "home";
      return;
    }

    switch(this.ticcInformation?.attachmentType) {
      case "Asset": {
        this.attachmentType = "assets";
        break;
      }
      case "Site": {
        this.attachmentType = "sites";
        break;
      }
      case "Board": {
        this.attachmentType = "boards";
        break;
      }
      case "User": {
        this.attachmentType = "users";
        break;
      }
      default: {
        this.attachmentType = "home";
        break;
      }
    }
  }

  onScanClick() {
    if (this.ticcUuid)
      localStorage.setItem("ticcUuid", this.ticcUuid);

    this.router.navigateByUrl('/scanner');
  }
}
