<div class="container-fluid board-creation-container">

    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb" routerLink="/boards"><span class="single-breadcrumb-link">Boards</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb"><span>Create Boards</span></div>
        </div>
    </div>

    <div class="row p-3 m-0">

        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        Create Board
                    </div>

                    <hr>
                </div>
            </div>
        </div>

        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Create Board
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="p-3" [formGroup]="formNewBoard">
                
                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Site</mat-label>
                    <mat-select formControlName="siteUuid">
                        <mat-option *ngFor="let site of sites$ | async" [value]="site.uuid">
                            {{site.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>You must select a site</mat-error>
                </mat-form-field>
                
                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input type="text" matInput formControlName="name">
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>
                
                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input type="text" matInput formControlName="description">
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>

            </mat-card-content>

        </mat-card>
    </div>

    <div class="row m-0">
        <div class="col-6">
            <button class="w-100 center red-button" mat-button routerLink="/boards" >Cancel</button> <!-- [routerLink]="'/sites/' + siteUuid" -->
        </div>
        <div class="col-6">
            <button class="w-100 center green-button" mat-button (click)="submit()">Create</button>
        </div>
    </div>
</div>