<div *ngIf="breadcrumbExist" class="page-top-breadcrumbs">
    <a [routerLink]="breadcrumbUrl">{{breadcrumbTitle}}</a> > Invite to site
</div>

<h1 class="page-top-title">
    Invite to Site
</h1>
<hr class="page-top-title-underline">

<div class="container-fluid">

    <div class="col-12" style="margin-bottom: 1vh;">
        <mat-form-field class="w-100" [floatLabel]="!sites ? 'never' : 'always'">
            <mat-label *ngIf="!sites" class="pl-2 d-flex align-items-center">
                <div class="select-placeholder-container">
                    <span> Sites Loading...</span>
                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
            </mat-label>
            <mat-label *ngIf="sites" class="pl-2 d-flex align-items-center">
                Site invate to:
            </mat-label>
            <mat-select class="invite-to-site-select" placeholder="Choose Site" [(ngModel)]="selectedSite" (ngModelChange)="onSelectSite($event)">
                <mat-option *ngFor="let site of sites" [value]="site.id">
                  {{site.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row users-list">
        <div class="col-12 user-card" *ngFor="let user of users; let i = index">
            <div class="row">
                <div class="col-3 col-sm-2 col-lg-1 col-xl-1">
                    <img [src]="avatarConvert(user.userPhoto) || 'web-assets/images/default/default-user.png'"
                        class="user-image" alt="">
                </div>

                <div class="col-9">
                    <div class="col-12 user-name">{{user?.userFullName}}</div>
                    <div class="col-12 user-email">{{user?.userEmail}}</div>
                </div>

            </div>

            <mat-form-field class="w-100" floatLabel="always">

                <mat-label class="pl-2 d-flex align-items-center">
                </mat-label>
                <mat-select class="invite-to-site-select" placeholder="Role" [(ngModel)]="user.userSiteRole">
                    <mat-option *ngFor="let role of roles | keyvalue" [value]="role">
                        {{role.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-slide-toggle [checked]="user.isCopyTiccs" (change)="toggleCopyTicc(user)">
                Copy User TICCs
            </mat-slide-toggle>

            <div *ngIf="user.isCopyTiccs">
                <mat-form-field class="w-100" floatLabel="always">

                    <mat-label class="pl-2 d-flex align-items-center">
                        Copy form:
                    </mat-label>
                    <mat-select class="invite-to-site-select" placeholder="Choose Site" [(ngModel)]="user.userSiteUUIdForCopyTiccs" (ngModelChange)="onSelectSiteForCopyTicc($event, user)">
                        <mat-option *ngFor="let site of user.sites"
                            (onSelectionChange)="setSiteUUID(site.siteUUID, i, $event)" [value]="site.siteUUID" >
                            {{site.siteName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
        <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
            <button class="col-5 button-center button-disassociate" style="margin: 1vh;" mat-button routerLink="/users" [disabled]="sendInvite_loading" >Cancel</button>
            <button class="col-5 action-buttons submit" style="margin: 1vh;" mat-button
                [disabled]="!canInvite() || sendInvite_loading" 
                (click)="sendInvite()">
                <mat-spinner *ngIf="sendInvite_loading" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
                {{sendInvite_loading ? '' : 'Invite'}}
            </button>
        </div>
    </div>