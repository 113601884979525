import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { AuthService } from 'src/app/services/api/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
  regexPassword : RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  registerForm : UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl("", Validators.required),
    lastName: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    password: new UntypedFormControl("", [Validators.required, Validators.pattern(this.regexPassword)]),
    confirmPassword: new UntypedFormControl("", [Validators.required, Validators.pattern(this.regexPassword)])
  }, {
    validators: [
      this.matchValidator("password", "confirmPassword")
    ]
  },)

  constructor(
    private authService: AuthService,
  ) {
  }

  onSubmit() {
    if(this.registerForm.valid) {
      this.authService.register(this.registerForm.value).subscribe();
    }
  }

  private matchValidator(source: string, target: string): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);
      
      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;

    };
  }
}
