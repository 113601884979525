<!-- <div class="container"> -->
    <div class="row py-3">
        <div class="col-12 py-3 bottom-sheet-title">
            Choose Operation
        </div>
        <div class="col-12 py-3 bottom-sheet-sites-count dialog-data italic">
            number Sites selected
        </div>
        <div class="col-12 py-2 dialog-data italic">
            <button mat-button class="w-100 p-1 bottom-sheet-buttons" (click)="closeBottomSheet('invite-to-site')">Invite to Site</button>
        </div>
        <div class="col-12 py-2 dialog-data italic">
            <button mat-button class="w-100 p-1 bottom-sheet-buttons" (click)="closeBottomSheet('remove-from-site')">Remove from Site</button>
        </div>
        <div class="col-12 py-2 dialog-data italic">
            <button mat-button class="w-100 p-1 bottom-sheet-buttons" [ngStyle]="{'display': userSystemRole == 'user' ? 'none' : 'flex'}" (click)="closeBottomSheet('associate-with-company')">Associate with Company</button>
        </div>
        <div class="col-12 py-2 dialog-data italic">
            <button mat-button class="w-100 p-1 bottom-sheet-buttons" [ngStyle]="{'display': userSystemRole == 'user' ? 'none' : 'flex'}" (click)="closeBottomSheet('disassociate-from-company')">Disassociate from Company</button>
        </div>
    </div>
<!-- </div> -->