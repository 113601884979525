<div class="container-fluid">
    <mat-card class="p-0">
        <mat-card-header class="p-3">
            <mat-card-title class="m-0">Association Type </mat-card-title>
        </mat-card-header>
        <mat-card-content class="p-3">
                <div class="col-12 m-0 p-0">
                    <mat-form-field class="w-100">
                        <mat-label class="pl-2 d-flex align-items-center">
                            TAG Association
                        </mat-label>
                        <mat-select class="invite-to-site-select" [(value)]="selected"
                            (selectionChange)="choiseAssociation(selected)">
                            <mat-option value="site">Site</mat-option>
                            <mat-option value="asset" (onSelectionChange)="isAsset = !isAsset">Asset</mat-option>
                            <mat-option value="board" (onSelectionChange)="isBoard = !isBoard">Board</mat-option>
                            <mat-option value="user">User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-radio-group *ngIf="isAsset" aria-labelledby="example-radio-group-label"
                        class="example-radio-group" [(ngModel)]="radionSelect">
                        <mat-radio-button class="mb-2" value="create">Create new Asset and
                            Associate</mat-radio-button>
                        <mat-radio-button value="clone">Clone existing Asset and Associate</mat-radio-button>
                    </mat-radio-group>
                    <mat-radio-group *ngIf="isBoard" aria-labelledby="example-radio-group-label"
                        class="example-radio-group" [(ngModel)]="radionSelect">
                        <mat-radio-button class="mb-2" value="create">Create new Board and Associate</mat-radio-button>
                        <!-- <mat-radio-button value="clone">Clone existing Board and Associate</mat-radio-button> -->
                    </mat-radio-group>
                </div>
        </mat-card-content>
    </mat-card>
    <div class="col-12 action-buttons-container">
        <div class="row d-flex justify-content-between">
            <div class="col-6 col-md-5 col-xl-3">
                <button mat-button type="submit" routerLink="/" class="action-buttons cancel w-100">Cancel</button>
            </div>
            <div class="col-6 col-md-5 col-xl-3">
                <button mat-button type="submit"
                    *ngIf="radionSelect != 'create' || (selected == 'site' || selected == 'user')  "
                    (click)="submit()" [disabled]="selected == undefined"
                    class="action-buttons submit w-100">Next</button>
                <button mat-button type="submit"
                    *ngIf="radionSelect == 'create' && (selected == 'asset' || selected == 'board') "
                    (click)="submit()" [disabled]="selected == undefined"
                    class="action-buttons submit w-100">Create</button>
            </div>
        </div>
    </div>
</div>