import { HttpClient, HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
	AuthenticatedResponse,
	CreateUserRequestModel,
	ForgotPasswordModel,
	ForgotPasswordRequesModel,
	LoginRequestModel,
} from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { MessagesService } from '../helpers/messages-service.service';
import { UserStateService } from '../state/user-state.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
		private router: Router,
		private errorHandler: ErrorHandlerService,
		private messagesHandler: MessagesService,
		private userStateService: UserStateService
	) { }

	login(body: LoginRequestModel) {
		return this.http
			.post<AuthenticatedResponse>(
				`${environment.apiUrl}Account/login`,
				body
			)
			.pipe(
				tap(response => {
					localStorage.setItem('jwt', response.token as string);
					localStorage.setItem("refreshToken", response.refreshToken as string);
					this.router.navigate(['/']);
				}),

				catchError((error: HttpErrorResponse) => {
					this.errorHandler.handleError(error);

					return throwError(error);
				})
			);
	}

	register(body: CreateUserRequestModel) {
		return this.http
			.post<void>(`${environment.apiUrl}Account/register`, body)
			.pipe(
				tap(() => {
					this.router
						.navigate(['/login'])
						.then((navigated: boolean) => {
							if (navigated) {
								this.messagesHandler.successfullyRegistered();
							}
						});
				}),

				catchError((error: HttpErrorResponse) => {
					this.errorHandler.handleError(error);

					return throwError(error);
				})
			);
	}

	logout() {
		localStorage.removeItem('jwt');
		localStorage.removeItem("refreshToken");
		this.userStateService.currentUserSource.next(null);

		this.router.navigate(['/login']);
	}

	sendResetPasswordLink(body: ForgotPasswordRequesModel) {
		return this.http
			.post(
				`${environment.apiUrl}Account/forgot-password`,
				body
			)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.errorHandler.handleError(error);

					return throwError(error);
				})
			);
	}

	confirmPasswordLink(code: string) {
		return this.http
		.get<HttpResponseBase>(`${environment.apiUrl}Account/forgot-password/${code}`)
		.pipe(
			catchError((error: HttpErrorResponse) => {
				this.errorHandler.handleError(error);
				this.router.navigate(['login']);

				return throwError(error);
			})
		);
	}

	resetPassword(code: string, body: ForgotPasswordModel) {
		return this.http
		.put(`${environment.apiUrl}Account/forgot-password/${code}`, body)
		.pipe(
		  catchError((error: HttpErrorResponse) => {
			this.errorHandler.handleError(error);
			return throwError(error);
		  })
		)
	}

	refreshingTokens(credentials: AuthenticatedResponse) {
		return this.http
			.post<AuthenticatedResponse>(
				`${environment.apiUrl}Account/refresh-token`,
				credentials)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.errorHandler.handleError(error);

					return throwError(error);
				})
			);
	}
}
