<div class="container-fluid container-md ticc-creation-container">


    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb"><span class="single-breadcrumb-link">Assets Type</span> <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb"><span>Edit Asset Type</span></div>
        </div>
    </div>

    <div class="row p-3 m-0">

        <div class="col-12 view-entry">
            <div class="row m-0">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">

                    <div class="title">
                        Edit Asset Type
                    </div>

                </div>
            </div>
        </div>

        <mat-card class="card col-12 mb-0">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Asset Type Management
                    <span class="asset-type-header">{{assetType?.assetTypeName}}</span>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 d-flex" [formGroup]="editAssetType">

                <div class="col-12 mt-3">
                    
                    <mat-form-field class="col-12" appearance="outline">
                        <input formControlName="assetTypeName" type="text" matInput placeholder="Please, introduce asset-type name">
                        <mat-error>This field can not be empty</mat-error>
                    </mat-form-field>

                </div>

                <div class="col-12">
                    
                    <mat-label>Categories</mat-label>

                    <div class="col-12 my-2" *ngFor="let category of modules">
                        <mat-slide-toggle (change)="onChange($event, category)" [checked]="isSavedAsChecked(category)">
                            {{category.moduleName}}
                        </mat-slide-toggle>
                    </div>
                </div>
                
            </mat-card-content>

        </mat-card>
    </div>

    <div class="row m-0 ">
        <div class="col-6">
            <button class="w-100 red-button" routerLink="/admin/asset-types" mat-button>Cancel</button>
        </div>
        <div class="col-6">
            <button class="w-100 green-button" (click)="submit()" mat-button>Update</button> <!--  (click)="submit()" -->
        </div>
    </div>

</div>