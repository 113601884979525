import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SiteBasicViewModel, TiccTypeAttachmentTypes, TiccTypeRequirements } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { ModulesService } from 'src/app/services/api/modules.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-ticc-type',
  templateUrl: './new-ticc-type.component.html',
  styleUrls: ['./new-ticc-type.component.scss']
})
export class NewTiccTypeComponent implements OnInit {

  newTiccTypeForm : UntypedFormGroup = new UntypedFormGroup({
    ticcTypeName: new UntypedFormControl("", Validators.required),
    ticcTypeAttachmentTypes:  new UntypedFormControl(),
    ticcTypeModuleId: new UntypedFormControl(null, Validators.required),
    ticcTypeAllowedAssetTypes:  new UntypedFormArray([]),
    ticcTypeAllowedSites:  new UntypedFormArray([]),
    ticcTypeDescription: new UntypedFormControl("", Validators.required),
    ticcTypeConfirmation: new UntypedFormControl("", Validators.required),
    ticcTypeGuidance: new UntypedFormControl("", Validators.required),
    ticcTypeRequirements: new UntypedFormArray([]),

  })

  snackbarOptions: MatSnackBarConfig = {
    duration: 4000,
  };


  panelOpenState = false;

  assetTypes$ = this.adminService.getAssettypes();

  modulesList$ = this.modulesService.getModulesList();

  sitesBySiteOwner$: [string, any][] | undefined;

  ticcTypeAllowedAssetTypesArr: {assetTypeId: number}[] = [];
  ticcTypeAllowedSitesArr: {siteId: number}[] = [];

  isSitesEnabled = false;
  isAssetsEnabled = false;
  isUserEnabled = false;
  isBoardEnabled = false;

  ticcTypeRequirements: TiccTypeRequirements = {
    attachedFiles: false,
    competentPerson: false,
    responsiblePerson: false,
    legal: false
  }

  ticcTypeAttachmentTypeArr: TiccTypeAttachmentTypes = {
    site: false,
    user: false,
    asset: false,
    board: false
  }

  constructor(
    private adminService: AdminService,
    private siteService: SitesService,
    private modulesService: ModulesService,
    private snackbar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.siteService.getList().subscribe(
      (res : SiteBasicViewModel[]) => {
        // console.log("res", res)
        // console.log(this.groupByProperty(res, 'siteOwner'))
        this.sitesBySiteOwner$ = Object.entries(this.groupByProperty(res, 'siteOwner'));
      }
    )
  }

  groupByProperty(arr: SiteBasicViewModel[], ticcTypeModule: string) {
    return arr.reduce(
      (previousVal: any, currentVal: any) => {
        if (!previousVal[currentVal[ticcTypeModule].fullName]) {
          previousVal[currentVal[ticcTypeModule].fullName] = [];
        }
        previousVal[currentVal[ticcTypeModule].fullName].push(currentVal);
        return previousVal;
      }, {});
  }

  parseDate(date: Date) {
    return date.toLocaleString().split('T')[0];
  }

  updateAllowedSites(siteId: number | undefined) {
    if (siteId) {
      if (this.ticcTypeAllowedSitesArr.some(val => val.siteId === siteId)) {
        this.ticcTypeAllowedSitesArr = this.ticcTypeAllowedSitesArr.filter(obj => obj.siteId !== siteId)
      } else {
        this.ticcTypeAllowedSitesArr?.push({siteId: siteId})
      }
    }
    // console.log(this.ticcTypeAllowedSitesArr)
  }

  updateAllowedAssetTypes(assetTypeId: number | undefined) {
    if (assetTypeId) {
      if (this.ticcTypeAllowedAssetTypesArr.some(val => val.assetTypeId === assetTypeId)) {
        this.ticcTypeAllowedAssetTypesArr = this.ticcTypeAllowedAssetTypesArr.filter(obj => obj.assetTypeId !== assetTypeId)
      } else {
        (this.newTiccTypeForm.get('ticcTypeAllowedAssetTypes') as UntypedFormArray)
        this.ticcTypeAllowedAssetTypesArr?.push({assetTypeId: assetTypeId})
      }
    }
  }

  onSubmit() {
    if(this.newTiccTypeForm.valid) {

      (this.newTiccTypeForm.get('ticcTypeAttachmentTypes') as UntypedFormControl).setValue(this.ticcTypeAttachmentTypeArr);


      this.ticcTypeAllowedAssetTypesArr.forEach(element => {
        (this.newTiccTypeForm.get('ticcTypeAllowedAssetTypes') as UntypedFormArray).push(new UntypedFormControl(element));
      });

      this.ticcTypeAllowedSitesArr.forEach(element => {
        (this.newTiccTypeForm.get('ticcTypeAllowedSites') as UntypedFormArray).push(new UntypedFormControl(element));
      });

      (this.newTiccTypeForm.get('ticcTypeRequirements') as UntypedFormArray).push(new UntypedFormControl(this.ticcTypeRequirements));


      // console.log("full", this.newTiccTypeForm.value)
      this.adminService.setTiccTypes(this.newTiccTypeForm.value).subscribe(() => {
        this.snackbar.open("TICC Type Successfully Created", "Close", this.snackbarOptions);
		    this.router.navigate(['/admin/ticc-types', {ticc_type: this.newTiccTypeForm.get('ticcTypeName')?.value}]);

      });


    } else {
      this.snackbar.open("Empty Fields", "Close", this.snackbarOptions);

      (this.newTiccTypeForm.get('ticcTypeAllowedAssetTypes') as UntypedFormArray).clear();
      (this.newTiccTypeForm.get('ticcTypeAllowedSites') as UntypedFormArray).clear();
      (this.newTiccTypeForm.get('ticcTypeRequirements') as UntypedFormArray).clear();
      this.newTiccTypeForm.reset();
    }
    // console.log("empty", this.newTiccTypeForm.value)
  }
}
