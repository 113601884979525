import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetTypesByIdViewModel, ModulesAdminViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';

@Component({
  selector: 'app-asset-type-edit',
  templateUrl: './asset-type-edit.component.html',
  styleUrls: ['./asset-type-edit.component.scss']
})
export class AssetTypeEditComponent implements OnInit {
  assetType: AssetTypesByIdViewModel | undefined;
  assetTypeUuid = this.route.snapshot.paramMap.get('uuid');

  editAssetType = new UntypedFormGroup({
      assetTypeName: this.fb.control(null, Validators.required),
      assetTypeModules: this.fb.array([])
  });

  selectedCategories: ModulesAdminViewModel[] | undefined = [];
  modules: ModulesAdminViewModel[] | undefined;

  get assetTypeModules() {
    return this.editAssetType.controls["assetTypeModules"] as UntypedFormArray;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private adminSevice: AdminService
  ) { }

  ngOnInit(): void {
    this.adminSevice.getModules().toPromise().then((res) => {
      this.modules = res;
      this.getAssetType();
    });
  }

  getAssetType() {
    this.adminSevice.getAssetTypeByUuid(this.assetTypeUuid as string).subscribe(res => {
      this.assetType = res;
      res.assetTypeModules?.forEach(atm => {
        this.selectedCategories?.push(this.modules?.find(m => m.modileId == atm.moduleId)!);
      })

      this.editAssetType.get('assetTypeName')?.setValue(res.assetTypeName);
    })
  }

  onChange($event: any, category: ModulesAdminViewModel) {
    if ($event.checked) {
      this.selectedCategories?.push(category);
    } else {
      let index = this.selectedCategories?.findIndex(c => c.modileId == category.modileId)!;
      this.selectedCategories?.splice(index, 1);
    }
  }

  isSavedAsChecked(category: ModulesAdminViewModel) {
    return this.assetType?.assetTypeModules?.find(m => m.moduleId == category.modileId) ? true : false;
  }

  submit() {
    this.editAssetType.markAllAsTouched();

    if (!this.editAssetType.valid) return;

    this.selectedCategories?.forEach(category => {
      let assetTypeModule = {
        moduleId: category.modileId
      }

      this.assetTypeModules.push(this.fb.control(assetTypeModule));
    });

    this.adminSevice.editAssetType(this.assetTypeUuid as string, this.editAssetType.value).subscribe(() => {
      this.router.navigate(['admin/asset-types']);
    });
  }
}
