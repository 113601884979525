<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
<div *ngIf="siteDetail$?.displayEditSiteButton" class="fixed-button-container d-flex justify-content-end">
    <button routerLink="edit" mat-button class="fixed-button d-flex"><mat-icon>edit</mat-icon><div class="d-none d-md-block">&nbsp;&nbsp;Edit Site</div></button>
</div>


<div class="page-top-breadcrumbs">
    SITES - DETAIL
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
   Sites Detail
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="container-fluid">

    <mat-card class="p-0">
        <mat-card-header class="p-3">
          <mat-card-title class="m-0">Overview</mat-card-title>
        </mat-card-header>

        <mat-card-content class="p-3">
            <div *ngIf="isLoadingSiteDetails" class="row">
                <div class="col-12 d-flex justify-content-center">
                    <mat-spinner class="spinner-center"></mat-spinner>
                </div>
            </div>
            <div *ngIf="!isLoadingSiteDetails" class="row">
                <div class="col-12 col-md-6 bordered">
                    <span class="italic ligth">Name:</span>&nbsp;<span class="strong">{{siteDetail$?.siteName || "Name not added"}}</span>
                </div>
                <div class="col-12 col-md-6 bordered">
                    <span class="italic ligth">Company:</span>&nbsp;<span class="strong">{{siteDetail$?.siteCompany?.companyName || "Company not added"}}</span>
                </div>
                <div class="col-12 col-md-4 bordered">
                    <span class="italic ligth">Postcode:</span>&nbsp;<span class="strong">{{siteDetail$?.sitePostCode || "Post Code not added"}}</span>
                </div>
                <div class="col-12 col-md-4 bordered">
                    <span class="italic ligth">City:</span>&nbsp;<span class="strong">{{siteDetail$?.siteCity || "City not added"}}</span>
                </div>
                <div class="col-12 col-md-4 bordered">
                    <span class="italic ligth">Address:</span>&nbsp;<span class="strong">{{siteDetail$?.siteAddress || "Address not added"}}</span>
                </div>
                <div class="col-12 bordered">
                    <span class="italic ligth">Notes:</span>&nbsp;<span class="strong">{{siteDetail$?.siteNotes || "Notes not added"}}</span>
                </div>

            </div>
        </mat-card-content>
    </mat-card>

    <mat-tab-group mat-align-tabs="start">

        <mat-tab label="TICCs">
            <app-ticcs-list [ticcsArray$]="ticcs$" [pageType]="'sites'" [uuid]="siteUuid"></app-ticcs-list>
        </mat-tab>

        <mat-tab label="TAG">
            <div class="row m-0 p-0">
                <div class="col-12" [ngClass]="{'col-12': !image, 'col-12 col-md-8': image}">
                    <mat-card class="p-0">
                        <mat-card-header class="p-3">
                            <mat-card-title class="m-0">{{tagStatusError ? 'Add TAG' : 'QR Code UUID' }}
                            </mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="p-3" *ngIf="!tagStatusError; else noTag">
                            <div class="w-100" style="text-align: center; margin-bottom: 1rem; font-size: 1.1rem;">
                                {{tag$?.tagUUID}}
                            </div>
                            <button (click)="disassociateTag()" class="button-center button-disassociate w-100"
                            mat-button style="text-align: center;">
                            <img src="web-assets/svg/qrcode.svg">REMOVE QR
                        </button>
                        </mat-card-content>

                        <ng-template #noTag>
                            <mat-card-content class="p-3" style="text-align: center !important; font-size: 1.3rem;">
                                <p style="color: #F61010;">No TAG associated</p>
                                <p style="font-size: 20px;">Add TAG by UUID</p>
                                <mat-form-field class="col-12" appearance="outline">
                                    <mat-label>TAG UUID</mat-label>
                                    <input type="text" [(ngModel)]="tagUUID" matInput>
                                </mat-form-field>
                                <button mat-button type="submit" class="action-buttons submit w-100" [disabled]="tagUUID == undefined" (click)="associationToTag()">Add TAG</button>
                                <p style="font-size: 20px;">Add TAG form QR scan</p>
                                <button mat-button routerLink="/scanner" [queryParams]="{ lastUrl: 'sites' , uuid:  siteUuid }" class="action-buttons scan w-100"><img
                                        src="web-assets/svg/qrcode.svg"> Scan QR code</button>
                            </mat-card-content>
                        </ng-template>
                    </mat-card>
                </div>

                <div class="col-12 col-md-4 tag-image" [ngClass]="{'d-none': !image, 'd-block': image}">
                    <img [src]="image" class="w-100 img-fluid rounded-top" alt="">
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>

</div>
