import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
/**
 * OrderByPipe 
 * - Sorts an array of objects by a given property and set type of sort (asc/desc)
 * - Usage: {{ array | orderBy: 'property' }} or {{ array | orderBy: 'property': 'asc' }}
 * - Example: {{ [{name: 'John', age: 30}, {name: 'Jane', age: 25}] | orderBy: 'age' }} 
 * - Result: [ {name: 'Jane', age: 25}, {name: 'John', age: 30} ]
 */
export class OrderByPipe implements PipeTransform {

  transform(array: Array<any> | undefined, args: string, type: 'asc' | 'desc' | null = 'asc'): Array<any> {

    if (!array || args === '' || args === undefined) {
      return [];
    }

    array.sort((a: any, b: any) => {
      if (a[args] < b[args]) {
        return -1;
      } else if (a[args] > b[args]) {
        return 1;
      } else {
        return 0;
      }
    });
    if (type === 'desc') {
      array.reverse();
    }
    return array;
  }
}
