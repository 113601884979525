<div class="container-fluid ticc-creation-container">

    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb">Ticc <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb">{{ticcTypeName}} <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb">Edit</div>
        </div>
    </div>

    <div class="col-12 view-entry">
        <div class="row m-0">
            <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <div class="title">
                    TICC Edit
                </div>

                <hr>

                <div class="subtitle">
                    {{ticcTypeName}}
                </div>
            </div>
        </div>
    </div>


    <div class="row p-3 m-0">

        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    TICC Type
                </mat-card-title>
            </mat-card-header>

            <mat-card-content [formGroup]="formTiccEdit" class="p-3 ticc-type-content">

                <mat-form-field class="col-12" appearance="outline">
                    <input type="text" matInput [value]="ticcTypeName" disabled>
                </mat-form-field>


                <div *ngIf="selectedTiccType" class="ticc-type-guidance col-12">
                    {{selectedTiccType.ticcTypeGuidence}}
                </div>

                <div *ngIf="selectedTiccType" class="ticc-type-modules col-12">
                    <mat-chip-list class="row m-0 ticc-type-modules-chiplist">

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': selectedTiccType.ticcTypeRequirements?.legal}">
                            <mat-icon>gavel</mat-icon>
                            &nbsp;Legal</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': selectedTiccType.ticcTypeRequirements?.certificate}">
                            <mat-icon>history_edu</mat-icon>
                            &nbsp;Certificate Required</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': selectedTiccType.ticcTypeRequirements?.responsiblePerson}">
                            <mat-icon>supervisor_account</mat-icon>
                            &nbsp;Responsible Person</mat-chip>

                        <mat-chip class="col-6"
                            [ngClass]="{'active-requirement': selectedTiccType.ticcTypeRequirements?.competentPerson}">
                            <mat-icon>engineering</mat-icon>
                            &nbsp;Competent Person</mat-chip>
                    </mat-chip-list>
                </div>
            </mat-card-content>

        </mat-card>

        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Management Panel
                </mat-card-title>
            </mat-card-header>

            <mat-card-content [formGroup]="formTiccEdit" class="row m-0 d-flex">

                <div class="management-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="ticcAssignedToEnabled"
                        (toggleChange)="formTiccEdit.get('ticcAssignedToEnabled')?.patchValue(!formTiccEdit.get('ticcAssignedToEnabled')?.value)">Assigned
                        To</mat-slide-toggle>
                </div>

                <div class="management-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="ticcTagScanRequiredEnabled"
                        (toggleChange)="formTiccEdit.get('ticcTagScanRequiredEnabled')?.patchValue(!formTiccEdit.get('ticcTagScanRequiredEnabled')?.value)">TAG Scan
                        Require</mat-slide-toggle>
                </div>

                <div class="management-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="ticcObservationsEnabled"
                        (toggleChange)="formTiccEdit.get('ticcObservationsEnabled')?.patchValue(!formTiccEdit.get('ticcObservationsEnabled')?.value)">Observation</mat-slide-toggle>
                </div>

                <div class="management-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="notificationsEnabled"
                        (toggleChange)="formTiccEdit.get('notificationsEnabled')?.patchValue(!formTiccEdit.get('notificationsEnabled')?.value)">Notification</mat-slide-toggle>
                </div>

            </mat-card-content>

        </mat-card>


        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Schedule
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 pt-3" [formGroup]="formSchedule">


                <div class="row m-0 px-1 pb-3 schedule-buttons-container">

                    <div class="col-12 col-md-6 mb-3 mb-md-0 button-simple-box">
                        <button mat-button class="col-12 schedule-buttons"
                            [ngClass]="{'schedule-button-acive': !scheduleButtonToggled}"
                            (click)="scheduleButtonToggled && scheduleButtonToggled = !scheduleButtonToggled; resetTiccCreationForm();">
                            Periodic Task
                        </button>
                    </div>
                    <div class="col-12 col-md-6 button-simple-box">
                        <button mat-button class="col-12 schedule-buttons"
                            [ngClass]="{'schedule-button-acive': scheduleButtonToggled}"
                            (click)="!scheduleButtonToggled && scheduleButtonToggled = !scheduleButtonToggled; resetTiccCreationForm();">
                            Non Periodic Tasks
                        </button>
                    </div>

                </div>


                <div class="row m-0 px-1 py-3 schedule-buttons-container">

                    <div *ngIf="!scheduleButtonToggled; else nonPeriodicTasks">


                        <!-- TIME PERIOD -->
                        <mat-form-field [formGroup]="formTiccEdit" class="col-12" appearance="outline">
                            <mat-label>Time Period</mat-label>
                            <mat-select formControlName="scheduleType" (selectionChange)="changeTimePeriod($event.value)" (opened)="resetTiccCreationForm();">
                                <mat-option *ngFor="let timePeriod of timePeriods; let i = index" [value]="i">
                                    {{timePeriod}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <!-- REPEAT EVERY -->
                        <mat-form-field [formGroup]="formTiccEdit" *ngIf="scheduleType.value !== ''" class="col-12"
                            appearance="outline">
                            <mat-label>Repeat every ({{timePeriods[scheduleType.value]}})</mat-label>
                            <div formGroupName="schedule">
                                <input formControlName="repeatEvery" type="number" matInput min="1" step="1"
                                    placeholder="Introduce {{scheduleType.value}} number">
                            </div>
                        </mat-form-field>



                        <mat-form-field *ngIf="(scheduleType.value === 3) && scheduleType.value !== ''" class="col-12"
                            appearance="outline">
                            <mat-label>Weekday</mat-label>
                            <mat-select [formControl]="formSelectedWeekday">
                                <mat-option *ngFor="let day of checkWeekdays?.days" [value]="day.name">
                                    {{day.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <div *ngIf="(scheduleType.value === 0 || scheduleType.value === 1 || scheduleType.value === 2) && scheduleType.value !== ''"
                            class="col-12 mb-3">
                            <p>Weekdays</p>
                            <span class="row m-0 my-2">
                                <mat-checkbox class="col-12" [checked]="allComplete" [indeterminate]="someComplete()"
                                    color="primary" (change)="setAll($event.checked)">
                                    {{checkWeekdays?.name}}
                                </mat-checkbox>
                            </span>

                            <span class="row m-0">
                                <mat-checkbox class="col-12 my-1 px-3" *ngFor="let day of checkWeekdays?.days"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="day.completed" color="accent"
                                    (ngModelChange)="updateAllComplete()">
                                    &nbsp;&nbsp;{{day.name}}
                                </mat-checkbox>
                            </span>
                        </div>


                        <mat-form-field [formGroup]="formTiccEdit"
                            *ngIf="(scheduleType.value === 0 || scheduleType.value === 1) && scheduleType.value !== ''"
                            class="col-6" appearance="outline">
                            <mat-label>Start Time (o'clock)</mat-label>
                            <div formGroupName="schedule">
                                <input formControlName="startTime" type="time" step="1" matInput
                                    placeholder="Introduce start time">
                            </div>

                        </mat-form-field>

                        <mat-form-field [formGroup]="formTiccEdit" *ngIf="scheduleType.value !== ''" class="col-6"
                            [ngClass]="{'col-12': !(scheduleType.value === 0 || scheduleType.value === 1) }"
                            appearance="outline">
                            <mat-label>End Time (o'clock)</mat-label>
                            <div formGroupName="schedule">
                                <input formControlName="endTime" type="time" step="1" matInput (change)="changePeriodicEndTime($event)"
                                    placeholder="Introduce end time">
                            </div>

                            <mat-error>asdlfkj</mat-error>
                        </mat-form-field>


                        <mat-form-field [formGroup]="formTiccEdit"
                            *ngIf="(scheduleType.value === 4 || scheduleType.value === 5) && scheduleType.value !== ''"
                            class="col-6" appearance="outline">
                            <mat-label>Check Date</mat-label>
                            <div formGroupName="schedule">
                                <input formControlName="checkDate" type="date" value="setInputDate" step="1" matInput
                                    placeholder="Introduce end time">
                            </div>

                            <mat-error>asdlfkj</mat-error>
                        </mat-form-field>
                    </div>

                    <ng-template #nonPeriodicTasks [formGroup]="formTiccEdit">
                        <div formGroupName="schedule">
                            <mat-form-field class="col-12" appearance="outline">
                                <mat-label>Date</mat-label>
                                <input formControlName="checkDate" type="date" matInput
                                    placeholder="Introduce end time">

                            </mat-form-field>

                            <mat-form-field class="col-12" appearance="outline">
                                <mat-label>End Time</mat-label>
                                <input formControlName="endTime" type="time" step="1" matInput (change)="changeNonPeriodicEndTime($event)"
                                    placeholder="Introduce end time">
                            </mat-form-field>
                        </div>
                    </ng-template>

                </div>


            </mat-card-content>

        </mat-card>


        <mat-card class="card col-12" *ngIf="formTiccEdit.get('ticcAssignedToEnabled')?.value">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Assignments
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 d-flex">

                <div class="assignments-panel-items col-12">

                    <div class="assignments-container p-2 my-2">

                        <div class="module-user-selector">
                            <div *ngFor="let user of selectedUsersList">
                                <mat-checkbox class="col-12 my-1 d-flex flex-wrap justif-content-between"
                                    [(ngModel)]="user.enabled">
                                    <span class="col-9 module-user-name">{{user.user.userFullName}}</span>
                                    <span class="col-3 module-user-role">{{user.user.userSiteRoleName}}</span>
                                </mat-checkbox>
                            </div>
                        </div>

                    </div>
                </div>

            </mat-card-content>

        </mat-card>




        <mat-card class="card col-12" *ngIf="formTiccEdit.get('ticcObservationsEnabled')?.value">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Observations
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 d-flex">

                <div class="notifications-panel-items col-12 col-md-6 col-lg-3">

                    <div class="observations-container p-2 my-2"
                        *ngFor="let item of formTiccEdit.get('ticcObservations')?.value; let i = index">
                        <span>{{item?.text}}</span>
                        <button mat-icon-button (click)="deleteObservation(i)">
                            <mat-icon class="delete">delete</mat-icon>
                        </button>
                    </div>

                    <mat-form-field class="col-12 mt-3" appearance="outline">
                        <mat-label>Observation</mat-label>
                        <input #lastObservation type="text" matInput placeholder="Introduce new observation">
                    </mat-form-field>

                    <button mat-button class="col-12 notifications-buttons-reminder"
                        (click)="addObservation(lastObservation)">Add Observation</button>
                </div>

            </mat-card-content>

        </mat-card>



        <mat-card [formGroup]="formTiccEdit" class="card col-12" *ngIf="formTiccEdit.get('notificationsEnabled')?.value">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Notifications
                </mat-card-title>
            </mat-card-header>

            <mat-card-content formGroupName="notificationsData" class="row m-0 d-flex">

                <div class="notifications-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="sendCheckComplete">Send if check created</mat-slide-toggle>

                    <button *ngIf="formTiccEdit.get('notificationsData')?.get('sendCheckComplete')?.value"
                        mat-button class="col-12 notifications-buttons-recipients mt-3"
                        (click)="openDialogRecipients('recipientsForCheckComplete', 'Set check created recipients')">
                        Add Recipients
                    </button>
                </div>

                <div class="notifications-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="sendNotesFilled">Send if "Notes" filled by
                        user</mat-slide-toggle>

                    <button *ngIf="formTiccEdit.get('notificationsData')?.get('sendNotesFilled')?.value" mat-button
                        class="col-12 notifications-buttons-recipients mt-3"
                        (click)="openDialogRecipients('recipientsForNotesFilled', 'Set \'\'Notes\'\' filled by user recipients')">
                        Add Recipients
                    </button>
                </div>

                <div class="notifications-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle (toggleChange)="resetNotificationsValue('recipientsForCheckNotComplete')"
                        formControlName="sendCheckNotComplete">Send if check
                        missed</mat-slide-toggle>

                    <button *ngIf="formTiccEdit.get('notificationsData')?.get('sendCheckNotComplete')?.value"
                        mat-button class="col-12 notifications-buttons-recipients mt-3"
                        (click)="openDialogRecipients('recipientsForCheckNotComplete', 'Set check missed recipients')">
                        Add Recipients
                    </button>

                </div>

                <div class="notifications-panel-items col-12 col-md-6 col-lg-3">
                    <mat-slide-toggle formControlName="sendReminder">Send reminder</mat-slide-toggle>

                    <button *ngIf="formTiccEdit.get('notificationsData')?.get('sendReminder')?.value" mat-button
                        class="col-12 notifications-buttons-recipients mt-3"
                        (click)="openDialogRecipients('recipientsForReminder', 'Set reminder recipients')">
                        Add Recipients
                    </button>




                    <div class="row m-0 mt-3" *ngIf="formTiccEdit.get('notificationsData')?.get('sendReminder')?.value">

                        <div class="col-12">

                            <div class="row m-0 my-3" *ngFor="let reminder of reminderRulesList.controls; let i = index">
                                <div class="row m-0">
                                    <mat-form-field class="ticc-creation-form-field col-5 pb-0" appearance="outline">
                                        <mat-label>Before</mat-label>
                                        <input type="number" [value]="reminder.value.aforehandReminderTimePeriodInUnits"
                                            matInput disabled>
                                    </mat-form-field>

                                    <mat-form-field class="ticc-creation-form-field col-5 pb-0" appearance="outline">
                                        <mat-label>Time Period</mat-label>
                                        <input type="text" [value]="reminder.get('unitOfTime')?.value"
                                            matInput disabled>
                                    </mat-form-field>

                                    <div class="col-2 d-flex justify-content-center">
                                        <button mat-stroked-button (click)="deleteReminder(i)">
                                            <mat-icon>
                                                delete
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <hr class="notifications-hr my-2 mb-4" *ngIf="reminderRulesList.length > 0">

                        <mat-form-field class="col-6" appearance="outline">
                            <mat-label>Before</mat-label>
                            <input #before type="number" matInput placeholder="Input number">

                            <!-- <mat-hint>Hint</mat-hint> -->

                        </mat-form-field>

                        <mat-form-field class="col-6" appearance="outline">

                            <mat-label>Time Period</mat-label>
                            <mat-select #timePeriod>
                                <mat-option *ngFor="let period of timePeriods" [value]="period">{{period}}</mat-option>
                            </mat-select>

                        </mat-form-field>

                        <button mat-button class="col-12 notifications-buttons-reminder"
                            (click)="addReminder(before, timePeriod)">Add Reminder
                        </button>
                    </div>

                </div>

            </mat-card-content>

        </mat-card>

    </div>


    <div class="row m-0">
        <div class="col-6">
            <button class="w-100 red-button" (click)="cancel()" mat-button>Cancel</button>
        </div>
        <div class="col-6">
            <button class="w-100 green-button" mat-button (click)="submit()">
                <mat-spinner *ngIf="isLoading$ | async" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
                    {{(isLoading$ | async) ? '' : 'Update'}}
            </button>
        </div>
    </div>

</div>
