import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PageEvent } from '@angular/material/paginator';
import { Observable, map, mergeMap } from 'rxjs';
import { BoardFilters, BoardListInfo, BoardsAndFilters, CompanyFilterModel, SiteFilterModel } from 'src/app/models/backend-generated-models';
import { BoardsService } from 'src/app/services/api/boards.service';
import { LoadingService } from 'src/app/services/helpers/loading.service';


@Component({
  selector: 'app-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.scss']
})
export class BoardsComponent implements OnInit {
  isLoading$ = this.loadingService.isLoading$;

  globalFilter: HttpParams | undefined;

  selectedGroup: string = "Site(asc)";

  companiesGroupList: { companyName: string; companyId: number; count: number }[] = [];
  sitesGroupList: { siteName: string; siteUuid: string; count: number; }[] = [];


  companiesBoardsList: { companyId: number; boards: Observable<BoardListInfo[]> }[] = [];
  sitesBoardsList: { siteUuid: string; boards: Observable<BoardListInfo[]> }[] = [];

  isFilter = false;
  filter$: BoardFilters | undefined;

  selectionCompanies = new SelectionModel<number | undefined>(true, []);
  selectionSites = new SelectionModel<string | undefined>(true, []);

  obs!: Observable<any>;







  constructor(
    private boardsService: BoardsService,
    private loadingService: LoadingService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (!this.globalFilter) this.globalFilter = new HttpParams();
    this.boardsService.getBoardsList().subscribe((res) => {

      res.updatedFilters?.filteredCompanies?.forEach(element => {
        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
      });

      res.updatedFilters?.filteredSites?.forEach(element => {
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
      })
    })

    let params = new HttpParams()

    params = params.append("PageIndex", 0).append("PageSize", 5)

    this.boardsService.getBoardsList(
      params
    ).subscribe((res) => {
      console.log("response boards list", res)

      this.filter$ = res.updatedFilters;
    });
  }







  /* ==================================================== COMPANIES ==================================================== */
  getCompaniesBoardsList(companyId: number) {
    return this.companiesBoardsList.filter(x => x.companyId == companyId).map(x => x.boards);
  }

  setCompaniesBoardsList(companyId: number) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.companiesBoardsList.map(x => x.companyId).includes(companyId)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5).append("ReturnOnlyGroupWithCompanyId", companyId!);
    this.companiesBoardsList.push({ companyId: companyId, boards: this.boardsService.getBoardsList(filterParams).pipe(map(x => x.boards!)) })
  }

  setCompaniesBoardsListWithPagination(companyId: number, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize).append("ReturnOnlyGroupWithCompanyId", companyId!)

    this.companiesBoardsList[this.companiesBoardsList.map(x => x.companyId).indexOf(companyId)] = {
      companyId: companyId,
      boards: this.boardsService.getBoardsList(filterParams).pipe(map(x => x.boards!))
    }
  }


  /* ==================================================== SITES ==================================================== */
  getSitesBoardsList(siteUuid: string) {
    return this.sitesBoardsList.filter(x => x.siteUuid == siteUuid).map(x => x.boards);
  }
  setSitesBoardsList(siteUuid: string) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.sitesBoardsList.map(x => x.siteUuid).includes(siteUuid)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5).append("ReturnOnlyGroupWithSiteUuid", siteUuid!);

    this.sitesBoardsList.push({ siteUuid: siteUuid, boards: this.boardsService.getBoardsList(filterParams).pipe(map(x => x.boards!)) })
  }

  setSitesBoardsListWithPagination(siteUuid: string, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize).append("ReturnOnlyGroupWithSiteUuid", siteUuid!)

    this.sitesBoardsList[this.sitesBoardsList.map(x => x.siteUuid).indexOf(siteUuid)] = {
      siteUuid: siteUuid,
      boards: this.boardsService.getBoardsList(filterParams).pipe(map(x => x.boards!))
    }
  }

  getMappedCompanies(companies: CompanyFilterModel[]) {
    return companies
      .filter(x => x !== null)
      .map(x => x.id);
  }

  getMappedSites(companies: SiteFilterModel[]) {
    return companies
      .filter(x => x !== null)
      .map(x => x.uuid);
  }


  clearSelectionFilters() {
    this.selectionCompanies.clear();
    this.selectionSites.clear();
    // this.returnListOfSite();
  }


  filter() {

    this.companiesGroupList = [];
    this.sitesGroupList = [];


    if (this.selectionCompanies.selected.length == 0
      && this.selectionSites.selected.length == 0) {

      this.boardsService.getBoardsList().subscribe((res) => {
        this.pushCompaniesAndSitesGroupList(res);
      })
    } else {

      this.globalFilter = new HttpParams();

      this.selectionCompanies.selected.forEach(element => {
        this.globalFilter = this.globalFilter?.append("FilteredCompaniesIds", element!);
      });
      this.selectionSites.selected.forEach(element => {
        this.globalFilter = this.globalFilter?.append("FilteredSitesUuids", element!);
      });

      this.isFilter = true;


      this.boardsService.getBoardsList(this.globalFilter).subscribe((res) => {
        this.pushCompaniesAndSitesGroupList(res);
      })

      let params = new HttpParams();

      params = params.append("PageIndex", 0)
      params = params.append("PageSize", 5)

      this.selectionCompanies.selected.forEach(element => {
        params = params.append("FilteredCompaniesIds", element!);
      });
      this.selectionSites.selected.forEach(element => {
        params = params.append("FilteredSitesUuids", element!);
      });
    }
    /* this.boardsService.getBoardsList(
      params
    ).subscribe((res) => {
    }) */
  }

  private pushCompaniesAndSitesGroupList(boardsAndFilters: BoardsAndFilters) {
    boardsAndFilters.updatedFilters?.filteredCompanies?.forEach(element => {
      var index = this.companiesGroupList.findIndex(x => x.companyId == element.id);
      if (index == -1)
        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
    });

    boardsAndFilters.updatedFilters?.filteredSites?.forEach(element => {
      var index = this.sitesGroupList.findIndex(x => x.siteUuid == element.uuid);
      if (index == -1)
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
    })
  }

  openDeleteDialog(board: BoardListInfo) {
    this.dialog.open(DialogBoardDelete, {
      width: '800px',
      data: board,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((uuid: string) => {
          if(uuid !== null && uuid !== undefined && uuid !== ""){
            return this.boardsService.deleteBoard(uuid);
          }
          return new Observable<object>;
        })
      ).subscribe(
        (res: any) => {
          this.obs = this.obs.pipe(map(e=> e.filter((e: any) => e.uuid !== res.uuid)));
        }
      )
  }

  /* FILTERS - GROUP/BY */


























  filtersList = [
    "some example 1",
    "some example 2",
    "some example 3",
  ]

  mockedBoardsList = [

    {
      boardUUID: "some uuid",
      boardName: "some board name",
      boardDescription: "some board description - long description long description long description long description long description long description long description long description long description long description long description long description",
      boardCircuitsCount: 5,
      boardLastCheck: new Date("2023-04-03T15:35:05.930Z"),
      boardTICCsCount: 8,
    },
    {
      boardUUID: "some uuid",
      boardName: "some board nameti",
      boardDescription: "some board description - long description long description long description long description long description long description long description long description long description long description long description long description",
      boardCircuitsCount: 5,
      boardLastCheck: new Date("2023-04-03T15:35:05.930Z"),
      boardTICCsCount: 8,
    },
    {
      boardUUID: "some uuid",
      boardName: "some board name",
      boardDescription: "some board description - long description long description long description long description long description long description long description long description long description long description long description long description",
      boardCircuitsCount: 5,
      boardLastCheck: new Date("2023-04-03T15:35:05.930Z"),
      boardTICCsCount: 8,
    },
    {
      boardUUID: "some uuid",
      boardName: "some board name",
      boardDescription: "some board description - long description long description long description long description long description long description long description long description long description long description long description long description",
      boardCircuitsCount: 5,
      boardLastCheck: new Date("2023-04-03T15:35:05.930Z"),
      boardTICCsCount: 8,
    },

  ];

}



@Component({
  selector: 'dialog-board-delete',
  templateUrl: 'dialog-board-delete.html',
  styleUrls: ['./boards.component.scss']

})
export class DialogBoardDelete {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogBoardDelete>
  ) { }
}
