import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/api/auth.service';
import { MessagesService } from 'src/app/services/helpers/messages-service.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  loginForm : UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
  })

  constructor(
    private authService: AuthService,
    private messagesService: MessagesService
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (!this.loginForm.valid) return;
    
    this.authService.sendResetPasswordLink(this.loginForm.value).subscribe(res => this.messagesService.forgotPasswordLinkSend())
  }

}
