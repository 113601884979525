<div class="page-top-breadcrumbs">
    ADMIN - TICC TYPE CREATION
</div>

<hr class="page-top-breadcrumbs-underline">

<h1 class="page-top-title">
    Create TICC Type
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->

<div class="container-fluid">

    <form [formGroup]="newTiccTypeForm" (ngSubmit)="onSubmit()" class="row">

        <div class="col-6 px-5 first-column">
            <div class="row">

                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Name</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">

                        <mat-card-content>
                            <mat-form-field class="custom-input" appearance="fill">

                                <mat-label style="color: black">Name</mat-label>
                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>
                                <input type="text" formControlName="ticcTypeName" matInput>

                            </mat-form-field>
                        </mat-card-content>

                    </mat-card>
                </div>


                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Module</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-form-field class="custom-select" appearance="fill">
                              <mat-select formControlName="ticcTypeModuleId" placeholder="SELECT A MODULE" value="2" formControlName="ticcTypeModuleId" ><!-- [(value)]="foods[0].value" -->
                                <mat-option *ngFor="let module of modulesList$ | async" [value]="module.id">
                                  {{module.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                        </mat-card-content>
                    </mat-card>
                </div>


                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Guidance</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-form-field class="custom-input" appearance="fill">

                                <mat-label style="color: black">Guidance</mat-label>
                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>
                                <input type="text" formControlName="ticcTypeGuidance" matInput>

                            </mat-form-field>

                        </mat-card-content>
                    </mat-card>
                </div>



                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Confirmation Text</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-form-field class="custom-input" appearance="fill">

                                <mat-label style="color: black">Confirmation Text</mat-label>
                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>
                                <input type="text" formControlName="ticcTypeConfirmation" matInput>

                            </mat-form-field>

                        </mat-card-content>
                    </mat-card>
                </div>


                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Description</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-form-field class="custom-input" appearance="fill">

                                <mat-label style="color: black">Description</mat-label>
                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>
                                <input type="text" formControlName="ticcTypeDescription" matInput>

                            </mat-form-field>

                        </mat-card-content>
                    </mat-card>
                </div>


                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Requirements</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <div class="row">
                                <div class="col-6 d-flex justify-content-left my-2">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeRequirements!.legal = !ticcTypeRequirements!.legal;">Legal</mat-slide-toggle>
                                </div>
                                <div class="col-6 d-flex justify-content-left my-2">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeRequirements!.attachedFiles = !ticcTypeRequirements!.attachedFiles;">Certificate</mat-slide-toggle>
                                </div>
                                <div class="col-6 d-flex justify-content-left my-2">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeRequirements!.responsiblePerson = !ticcTypeRequirements!.responsiblePerson;">Responsible Person</mat-slide-toggle>
                                </div>
                                <div class="col-6 d-flex justify-content-left my-2">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeRequirements!.competentPerson = !ticcTypeRequirements!.competentPerson;">Competent Person</mat-slide-toggle>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>


        <div class="col-6 px-5 second-column">
            <div class="row">


                <div class="col-12 col-md-12 sections">
                    <mat-card class="title-section">
                        <mat-card-title>Attachment Types</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <div class="row">
                                <div class="col-3 d-flex justify-content-center">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeAttachmentTypeArr!.site = !ticcTypeAttachmentTypeArr!.site; isSitesEnabled = !isSitesEnabled">Sites</mat-slide-toggle>
                                </div>
                                <div class="col-3 d-flex justify-content-center">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeAttachmentTypeArr!.asset = !ticcTypeAttachmentTypeArr!.asset; isAssetsEnabled = !isAssetsEnabled">Assets</mat-slide-toggle>
                                </div>
                                <div class="col-3 d-flex justify-content-center">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeAttachmentTypeArr!.user = !ticcTypeAttachmentTypeArr!.user;">User</mat-slide-toggle>
                                </div>
                                <div class="col-3 d-flex justify-content-center">
                                    <mat-slide-toggle color="primary" (toggleChange)="ticcTypeAttachmentTypeArr!.board = !ticcTypeAttachmentTypeArr!.board;">Board</mat-slide-toggle>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>
                </div>



                <div class="col-12 col-md-12 sections" *ngIf="isSitesEnabled">
                    <mat-card class="title-section">
                        <mat-card-title>Sites</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-accordion>
                                <mat-expansion-panel class="custom-expansion-panel" *ngFor="let site of sitesBySiteOwner$" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        {{site[0]}}
                                    </mat-expansion-panel-header>

                                    <div class="container">
                                        <div class="row">
                                            <div *ngFor="let siteName of site[1]" class="col-12 col-md-6 my-2 d-flex align-items-center">
                                                <mat-checkbox (change)="updateAllowedSites(siteName.id)">
                                                    {{siteName.name}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>

                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="col-12 col-md-12 sections" *ngIf="isAssetsEnabled">
                    <mat-card class="title-section">
                        <mat-card-title>Asset Types</mat-card-title>
                    </mat-card>
                    <mat-card class="body-section">
                        <mat-card-content>

                            <mat-accordion>
                                <mat-expansion-panel class="custom-expansion-panel" *ngFor="let assetType of assetTypes$ | async" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>

                                        <mat-panel-title>
                                                {{assetType.assetTypeName}}
                                        </mat-panel-title>

                                    </mat-expansion-panel-header>

                                    <div class="container">
                                        <div class="row">
                                            <div *ngFor="let module of assetType.assetTypeModules" class="col-12 col-md-6 my-2 d-flex align-items-center">
                                                <mat-checkbox (change)="updateAllowedAssetTypes(module.moduleId)">
                                                    {{module.moduleName}}
                                                </mat-checkbox>

                                            </div>
                                        </div>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>

                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>


        <div class="col-12 action-buttons-container">
            <div class="row d-flex justify-content-between">

                <div class="col-6 col-md-5 col-xl-3">
                    <button mat-button type="submit" routerLink="/admin/ticc-types" class="action-buttons cancel w-100">CANCEL</button>
                </div>

                <div class="col-6 col-md-5 col-xl-3">
                    <button mat-button type="submit" class="action-buttons submit w-100">CREATE TICC TYPE</button>
                </div>
            </div>
        </div>

    </form>

</div>
