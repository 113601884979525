import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import {  AssetTypesFullInfoViewModel, SiteAdminViewModel, AssingmentRoleRequestModel, TiccTypesAdminViewModel, TiccTypesResponseModels, UsersAdminViewModel, CompanyBasicViewModel, CreateCompanyRequest, CreateParentCompanyRequest, PagedUserViewModel, TagsBaseViewModel, CreateTagsBatchesResponseModel, AssetTypesByIdViewModel, ModulesAdminViewModel, CreateAssetTypeResponseModel, EditAssetTypesRequestModel, ForgotPasswordModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  errorHandler: any;

  constructor(
    private http: HttpClient
  ) {
  }

  createParentCompany(body: CreateParentCompanyRequest) {
    return this.http
    .post<number>(`${environment.apiUrl}admin/parent-companies`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getParentCompaniesList() {
    return this.http
    .get<CompanyBasicViewModel[]>(`${environment.apiUrl}admin/parent-companies/list`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  enableOrDisableUser (userUUID: string, action: number) {
    return this.http.put(`${environment.apiUrl}admin/Users/${userUUID}/enable`, {isActive: action})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }


  changeSystemRole (userUUID: string, body: AssingmentRoleRequestModel) {
    return this.http.put(`${environment.apiUrl}admin/Users/${userUUID}/system-role`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  deleteUser (userUUID: string | undefined) {
    console.log("log from service", userUUID);
    return this.http.delete(`${environment.apiUrl}admin/Users/${userUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  changeUserPassword (userUUID: string | undefined, body: ForgotPasswordModel) : Observable<any> {

    if (!userUUID) {
      return of(null);
    }

    return this.http.put(`${environment.apiUrl}admin/Users/${userUUID}/password`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAllUsers (): Observable<UsersAdminViewModel[]> {
    return this.http.get<UsersAdminViewModel[]>(`${environment.apiUrl}admin/Users`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getPagedUsers (page: number, search: string): Observable<PagedUserViewModel> {
    return this.http.get<PagedUserViewModel>(`${environment.apiUrl}admin/Users?page=${page}&search=${search}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAssettypes (): Observable<AssetTypesFullInfoViewModel[]> {
    return this.http.get<AssetTypesFullInfoViewModel[]>(`${environment.apiUrl}admin/asset-types`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getTiccTypes (): Observable<TiccTypesAdminViewModel[]>{
    return this.http.get<TiccTypesAdminViewModel[]>(`${environment.apiUrl}admin/ticc-types`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  setTiccTypes (body: TiccTypesResponseModels) {
    return this.http.post(`${environment.apiUrl}admin/ticc-types`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  deleteTiccType (ticcTypeId: number | undefined) {
    return this.http.delete(`${environment.apiUrl}admin/ticc-types/${ticcTypeId}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  deleteSite(siteUUID: string | undefined) {
    console.log("this deleteSite api is being called")
    return this.http.delete(`${environment.apiUrl}admin/Sites/${siteUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getSites (): Observable<SiteAdminViewModel> {
    return this.http.get<SiteAdminViewModel>(`${environment.apiUrl}admin/Sites`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  setSitesEnabledOrDisabled (status: boolean, siteUUId: string) {
    return this.http.put(`${environment.apiUrl}admin/Sites/${siteUUId}/enable`, {Enabled : status})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  reactivateSite(siteUUId: string) {
    return this.http.put(`${environment.apiUrl}admin/Sites/${siteUUId}/reactivate`, {Enabled : status})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAllTags() {
    return this.http.get<TagsBaseViewModel[]>(`${environment.apiUrl}admin/Tags`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  createTags(body: CreateTagsBatchesResponseModel) {
    return this.http.post(`${environment.apiUrl}admin/Tags/batches`, body).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  deleteAssetType(assetTypeUuid: string | undefined) {
    return this.http.delete(`${environment.apiUrl}admin/asset-types/${assetTypeUuid}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAssetTypeByUuid(assetTypeUuid: string | undefined) {
    return this.http.get<AssetTypesByIdViewModel>(`${environment.apiUrl}admin/asset-types/${assetTypeUuid}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  createAssetType(newAssetType: CreateAssetTypeResponseModel) {
    return this.http.post(`${environment.apiUrl}admin/asset-types`, newAssetType).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }

  editAssetType(assetTypeUuid: string, editAssetType: EditAssetTypesRequestModel) {
    return this.http.put(`${environment.apiUrl}admin/asset-types/${assetTypeUuid}`, editAssetType).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }

  getModules() {
    return this.http.get<ModulesAdminViewModel[]>(`${environment.apiUrl}admin/modules`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }
}
