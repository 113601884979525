import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteFullInfoViewModel } from 'src/app/models/backend-generated-models';
import { AssetsService } from 'src/app/services/api/assets.service';
import { SitesService } from 'src/app/services/api/sites.service';

@Component({
  selector: 'app-asset-clone',
  templateUrl: './asset-clone.component.html',
  styleUrls: ['./asset-clone.component.scss']
})
export class AssetCloneComponent implements OnInit {

  assetTypeList$ = this.assetsService.getAssetTypeList();

  sitesList$ = this.sitesService.getSiteFullList();

  assetUUID = this.route.snapshot.paramMap.get('uuid');
  assetName: string | undefined;
  siteName: string | undefined;
  assetTypeId: number | undefined;
  assetTypeName: string | undefined;
  
  constructor(
    private sitesService: SitesService,
    private assetsService: AssetsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.assetsService.getAssetByUUID(this.assetUUID).subscribe((res) => {
      this.assetName = res.assetName;
      this.siteName = res.assetSiteName;
      this.assetTypeId = res.assetTypeID;
      this.assetTypeName = res.assetTypeName;

      this.formAssetClone = new UntypedFormGroup({
        assetName: this.fb.control(res.assetName),
        assetDescription: this.fb.control(res.assetDescription),
        assetTypeId: this.fb.control(res.assetTypeID),
        assetSiteUuid: this.fb.control(res.assetSiteUuid),
        cloneFromAssetUuid: this.fb.control(this.assetUUID),
        assetTypeName: this.fb.control({value: this.assetTypeName, disabled: true}),
        assetSiteName: this.fb.control({value: this.siteName, disabled: true})
      });
    });
  }
  
  formAssetClone = new UntypedFormGroup({
    assetTypeId: this.fb.control(null),
    assetName: this.fb.control(null),
    assetDescription: this.fb.control(null),
    assetSiteUuid: this.fb.control(null),
    cloneFromAssetUuid: this.fb.control(null),
    assetTypeName: this.fb.control(null),
    assetSiteName: this.fb.control(null)
  });
  
  submit() {
    this.formAssetClone.markAllAsTouched();

    if (!this.formAssetClone.valid) return;

    this.assetsService.cloneAsset(this.formAssetClone.value).subscribe(() => {
      this.router.navigate(['assets']);
    })
  }

}
