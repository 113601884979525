import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModuleBasicViewModel } from 'src/app/models/backend-generated-models';
import { ModulesService } from 'src/app/services/api/modules.service';
import SwiperCore, { Navigation } from "swiper";

const ICON_LIST: any = {
  'Car maintance': 'dashboard',
  'Electrical': 'power',
  'Fire': 'local_fire_department',
  'Mechanical': 'precision_manufacturing',
  'Waste & Water': 'water_drop',
  'COVID-19': 'coronavirus',
  'Health and  Safety': 'health_and_safety',
  'Routine Checks': 'check_circle',
  'Waste': 'delete',
  'Pest Control': 'pest_control',
  "Ivan's CATEGORY": 'image',
}

SwiperCore.use([Navigation]);
@Component({
  selector: 'app-modules-carrousel',
  templateUrl: './modules-carrousel.component.html',
  styleUrls: ['./modules-carrousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModulesCarrouselComponent implements OnInit {

  swiperConfiguration = {
    navigation: true,
    breakpoints: {
      0: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 5
      },
      992: {
        slidesPerView: 6
      },
      1200: {
        slidesPerView: 7
      },
      1500: {
        slidesPerView: 8
      },
      1800: {
        slidesPerView: 9
      },
      2100: {
        slidesPerView: 10
      },
      2400: {
        slidesPerView: 11
      },
    }
  }

  modulesList$ : ModuleBasicViewModel[] | any;

  constructor(
    private modulesService: ModulesService
  ) {}
  
  ngOnInit(): void {
    this.modulesList$ = this.modulesService.getModulesList()
  }

  checkIcon(element: string) {
    return ICON_LIST[element]
  }
}
