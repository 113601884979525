<div class="background"><!-- background-image -->
    <div class="container-fluid">
        <div class="row my-0 mx-0">

            <div class="d-sm-inline d-none col-12 col-sm-4 py-5 logotype-container">
                <div class="col-10 offset-1 col-sm-8 offset-sm-2 xy-5">
                    <img class="logotype" src="../../../web-assets/logotype/Logo-01.png" alt="">
                </div>
            </div><!-- logotype_left-side -->

            <div class="col-12 col-sm-8 py-5 darker-background">

                <span class="d-sm-none">
                    <img class="mb-4 logotype" src="../../../web-assets/logotype/Logo-01.png" alt="">
                </span><!-- logotype-mobile -->

                <div class="col-12 d-flex justify-content-center">
                    <u class="mat-headline header-color mt-4 mb-3">USER REGISTER</u>
                </div>

                <form class="px-lg-5" [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                    <mat-form-field class="my-3 col-12 col-sm-4" appearance="fill">
                        <mat-label class="label-color">FIRST NAME</mat-label>
                        <input class="input-color" formControlName="firstName" matInput placeholder="Introduce your name">
                        <mat-icon class="icon-color" matPrefix>person</mat-icon>
                        <mat-error mat-error>Please, introduce your name</mat-error>
                    </mat-form-field>

                    <mat-form-field class="my-3 col-12 col-sm-7 offset-sm-1" appearance="fill">
                        <mat-label class="label-color">LAST NAME</mat-label>
                        <input class="input-color" formControlName="lastName" matInput placeholder="Intoduce your surename">
                        <mat-icon class="icon-color" matPrefix>person</mat-icon>
                        <mat-error mat-error>Please, introduce your surename</mat-error>
                    </mat-form-field>

                    <mat-form-field class="my-3 col-12" appearance="fill">
                        <mat-label class="label-color">EMAIL ADDRESS</mat-label>
                        <input class="input-color" formControlName="email" matInput placeholder="Intoduce your email account">
                        <mat-icon class="icon-color" matPrefix>mail</mat-icon>
                        <mat-error mat-error>Please, introduce your email</mat-error>
                    </mat-form-field>

                    <mat-form-field class="my-3 col-12" appearance="fill">
                        <mat-label class="label-color">PASSWORD</mat-label>
                        <input class="input-color" formControlName="password" matInput placeholder="Introduce your password" type="password" >
                        <mat-icon class="icon-color" matPrefix>lock</mat-icon>
                        <mat-error *ngIf="!registerForm.value.password" mat-error>Please, introduce your password</mat-error>
                        <mat-error *ngIf="!registerForm.value.password.pattern" mat-error>Must contain minimum eight characters, at least one letter, one number and one special character</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="my-3 col-12" appearance="fill">
                        <mat-label class="label-color">REPEAT PASSWORD</mat-label>
                        <input class="input-color" matInput formControlName="confirmPassword" name="confirmPassword" type="password" required>
                        <mat-icon class="icon-color" matPrefix>lock</mat-icon>
                        <mat-error *ngIf="!registerForm.value.confirmPassword">This field is required</mat-error>
                        <mat-error *ngIf="registerForm.value.password !== registerForm.value.confirmPassword">Passwords does not match</mat-error>
                    </mat-form-field>
    
                    <div class="col-12 my-5 d-flex justify-content-center">
                        <button type="submit" mat-stroked-button color="accent" class="main-button">
                            REGISTER <mat-icon class="icon-color icon-size material-symbols-outlined">arrow_right_alt
                            </mat-icon>
                        </button>
                    </div>
                </form>


                <hr class="line-access mb-5">
                
                <a class="link-access" routerLink="/login">
                    <p>ALREADY HAVE<br> ACCOUNT? <br> <STRONG>LOGIN</STRONG></p>
                </a>

            </div><!-- form_rigth-side -->

        </div><!-- row -->
    </div><!-- container-fluid -->
</div><!-- background -->