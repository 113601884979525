import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {  UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { CompaniesViewModel } from 'src/app/models/backend-generated-models';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
  selector: 'app-sites-edit',
  templateUrl: './sites-edit.component.html',
  styleUrls: ['./sites-edit.component.scss']
})
export class SitesEditComponent implements OnInit {

  siteUUID = this.route.snapshot.paramMap.get('siteUuid');

  siteName: string | undefined;
  currentNewCompanyName: string | undefined;
  prevParentId: number| undefined;

  useExistingCompany: boolean = true;

  formSiteEdit: UntypedFormGroup = new UntypedFormGroup({
    siteName: new UntypedFormControl('', [Validators.maxLength(30), Validators.required]),
    siteCity: new UntypedFormControl('', Validators.required),
    siteAddress: new UntypedFormControl(0),
    sitePostCode: new UntypedFormControl(0),
    siteCompany: new UntypedFormGroup(
      {
        companyId: new UntypedFormControl(0),
        companyName: new UntypedFormControl(null, Validators.maxLength(20)),
        parentCompanyId: new UntypedFormControl(0),
        newCompanyName: new UntypedFormControl(null, [Validators.maxLength(20), Validators.required]),
      }
    ),
    siteNotes: new UntypedFormControl(0),
  });

  selectedParanetId?: number;
  selectedCompanyId?: number;

  parentCompaniesList: CompaniesViewModel[] | undefined;

  userState$ = this.userStateService.currentUser$.pipe(map(x => x?.role));

  siteParentCompanyName: string | undefined;

  companiesNested: CompaniesViewModel[] | undefined;

  currentParentCompanyCompanies: CompaniesViewModel[] | undefined;

  siteEditing_loading: boolean = false;

  @ViewChild('parentSelect') parentSelect: any;

  constructor(
    private userStateService: UserStateService,
    private sitesService: SitesService,
    private companiesService: CompaniesService,
    private route: ActivatedRoute,
    private router: Router,
    public matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    Promise.all([
      this.sitesService.getSiteDetailByUUID(this.siteUUID).toPromise(),
      this.companiesService.getCompaniesList().toPromise(),
    ]).then((res) => {
      this.initSiteData(res[0]);
      this.initCompaniesList(res[1] as CompaniesViewModel[]);
    })
  }

  setCurrentParentCompanyCompanies() {
    this.formSiteEdit.get('siteCompany')?.get('parentCompanyId')?.patchValue(this.selectedParanetId);
    this.siteParentCompanyName = this.companiesNested?.find(x => x.companyId == this.selectedParanetId)?.companyName;

    this.currentParentCompanyCompanies = [];
    this.companiesNested?.forEach(element => {
      if (element.parentCompanyId == this.selectedParanetId) {
        this.currentParentCompanyCompanies?.push(element);
      }
    });
  }

  confirmParentCompanyChange(event: boolean) {
    !event ? null : this.matDialog.open(DialogRenameCompanyConfirmation, {
      width: '600px',
      panelClass: 'dialog-box'
    }).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.setCurrentParentCompanyCompanies();
        this.prevParentId = this.selectedParanetId;
      } else {
        this.selectedParanetId = this.prevParentId;
      }
      this.parentSelect.close();
    })
  }

  OnSwitchCompanyUseOrCreate(): void
  {
    this.useExistingCompany = !this.useExistingCompany;
    if (this.useExistingCompany) {
      this.formSiteEdit.get("siteCompany")?.get("newCompanyName")?.disable();
      this.formSiteEdit.get("siteCompany")?.get("companyId")?.enable();
      this.formSiteEdit.get('siteCompany')?.get('companyId')?.patchValue(this.selectedCompanyId);
      this.formSiteEdit.get('siteCompany')?.get('newCompanyName')?.patchValue(null);
      this.formSiteEdit.get('siteCompany')?.get('parentCompanyId')?.clearValidators();
      this.currentNewCompanyName = this.formSiteEdit.get('siteCompany')?.get('newCompanyName')?.value;
    } else {
      this.formSiteEdit.get("siteCompany")?.get("newCompanyName")?.enable();
      this.formSiteEdit.get("siteCompany")?.get("companyId")?.disable();
      this.formSiteEdit.get("siteCompany")?.get("newCompanyName")?.patchValue(this.currentNewCompanyName);
      this.formSiteEdit.get('siteCompany')?.get('companyId')?.patchValue(null);
      this.formSiteEdit.get('siteCompany')?.get('parentCompanyId')?.addValidators(Validators.required);
    }
    this.formSiteEdit.get('siteCompany')?.get('parentCompanyId')?.updateValueAndValidity();
  }

  submit() {
    if (!this.formSiteEdit.valid || this.siteEditing_loading) return;

    this.siteEditing_loading = true;

    this.sitesService.editSite(this.siteUUID, this.formSiteEdit.value).subscribe((res) => {
       console.log("Site successfully updated", res)
       this.router.navigate(['sites'])
      }, (err) =>  {
        console.log("Site update failed", err);
        this.siteEditing_loading = false;
      } ,
      () => this.siteEditing_loading = false
    )
  }

  private initSiteData(siteData: any) {
    if (!siteData) return;

    this.siteName = siteData.siteName;
    this.formSiteEdit.get('siteName')?.patchValue(siteData.siteName);
    this.formSiteEdit.get('siteCity')?.patchValue(siteData.siteCity);
    this.formSiteEdit.get('siteAddress')?.patchValue(siteData.siteAddress);
    this.formSiteEdit.get('sitePostCode')?.patchValue(siteData.sitePostCode);
    this.formSiteEdit.get('siteCompany')?.get('companyName')?.patchValue(siteData.siteCompany?.companyName);
    this.formSiteEdit.get('siteCompany')?.get('companyId')?.patchValue(siteData.siteCompany?.companyId);
    this.formSiteEdit.get('siteCompany')?.get('parentCompanyId')?.patchValue(siteData.siteCompany?.parentCompanyId || siteData.siteCompany?.companyId);
    this.formSiteEdit.get("siteCompany")?.get("newCompanyName")?.disable();
    this.formSiteEdit.get('siteNotes')?.patchValue(siteData.siteNotes);

    this.selectedCompanyId = siteData.siteCompany?.parentCompanyId != undefined ? siteData.siteCompany?.companyId : undefined;
    this.selectedParanetId = siteData.siteCompany?.parentCompanyId || siteData.siteCompany?.companyId;

    this.siteParentCompanyName = siteData.siteCompany?.parentCompanyName || siteData.siteCompany?.companyName;

    this.prevParentId = siteData.siteCompany?.parentCompanyId;
  }
  
  private initCompaniesList(conpanies: CompaniesViewModel[]){
    this.companiesNested = conpanies;
    this.parentCompaniesList = this.companiesNested?.filter(x => x.parentCompanyId == null && x.parentCompanyName == null);
    if (this.selectedParanetId) {
      this.setCurrentParentCompanyCompanies();
    }
  }
}



@Component({
  selector: 'dialog-rename-company-confirmation',
  templateUrl: 'dialog-rename-company-confirmation.html',
  styleUrls: ['./sites-edit.component.scss']
})
export class DialogRenameCompanyConfirmation {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string | undefined,
    public dialogRef: MatDialogRef<DialogRenameCompanyConfirmation>
  ) {
  }
}
