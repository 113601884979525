import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { concatMap, EMPTY, mergeMap, Subscription, throwError } from 'rxjs';
import { BoardDetails, CircuitResumeModel, SiteResponseModel, SiteTagResponseModel, TagResponseModel } from 'src/app/models/backend-generated-models';
import { BoardsService } from 'src/app/services/api/boards.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';
import { DialogCircuitDeleteConfirmation } from '../circuits/dialog-circuit-delete-confirmation/dialog-circuit-delete-confirmation';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-board-detail',
  templateUrl: './board-detail.component.html',
  styleUrls: ['./board-detail.component.scss']
})
export class BoardDetailComponent implements OnInit {

  boardUuid = this.route.snapshot.paramMap.get('uuid');
  boardDetails$: BoardDetails | undefined;
  boardCircuits$: CircuitResumeModel[] | undefined;
  ticcs$ = this.boardsService.getTiccs(this.boardUuid);

  siteUuid = this.route.snapshot.paramMap.get('uuid');

  tagStatusError = false;

  tag$: TagResponseModel | undefined

  tagImage$!: string;

  image: SafeUrl | undefined;

  currentDialog: Subscription | undefined;
  tagUUID: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private sitesService: SitesService,
    private boardsService: BoardsService,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorHandlerService,
    public dialog: MatDialog,
    private tagsService: TagsService
  ) { }


  ngOnInit(): void {

    this.boardsService.getBoard(this.boardUuid).subscribe((res) => {
      this.boardDetails$ = res;
    });

    this.boardsService.getCircuits(this.boardUuid).subscribe((res) => {
      this.boardCircuits$ = res;
    });

    this.boardsService.getBoardTagInformation(this.boardUuid)
      .pipe(
        concatMap((tag) => {
          this.tag$ = tag;
          return this.boardsService.getCurrentTagImage(tag.tagUuid);

        }
        )
      )
      .subscribe(
        {
          next: (blobImage) => {
            this.tagStatusError = false;

            let parsedBlobImage = URL.createObjectURL(blobImage);

            this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          },
          error: (error: HttpErrorResponse) => {

            this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }

            this.errorHandler.handleError(error);

            return throwError(error);
          }
        }
      )
  }

  disassociateTag() {
    if (this.tag$?.tagUuid) {
      this.boardsService.deleteTagAssociation(this.tag$.tagUuid).subscribe(
        () => {
          this.image = undefined;
          this.tagStatusError = true
          this.tag$ = undefined;
        }
      )
    }
  }

  associationToTag() {
    this.tagsService.setAssociationForTag(this.tagUUID as string, { tagableType: 'App\\Models\\Board', tagableUuid: this.boardUuid as string }).pipe(
      concatMap((tag) => {
        this.tag$ = {
          tagUuid: tag
        };
        return this.boardsService.getCurrentTagImage(tag);
      }
      )).subscribe(
        {
          next: (blobImage) => {
            this.tagStatusError = false;

            let parsedBlobImage = URL.createObjectURL(blobImage);

            this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          },
          error: (error: HttpErrorResponse) => {

            this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }

            this.errorHandler.handleError(error);

            return throwError(error);
          }
        }
      )
  }

  deleteCircuitUuid: string | undefined;

  deleteCircuit(circuit: CircuitResumeModel) {

    if (!circuit || !circuit.uuid) return;

    var deletedCircuitUUID = circuit.uuid;

    return this.dialog.open(DialogCircuitDeleteConfirmation, {
      width: '450px',
      data: circuit
    },)
      .afterClosed()
      .pipe(
        mergeMap((circuitUUID) => {
          console.log("circuit uuid", circuitUUID);
          this.deleteCircuitUuid = circuitUUID;
          if (!circuitUUID) this.currentDialog?.unsubscribe();
          return this.boardsService.deleteCircuit(this.boardUuid!, circuitUUID);
        })
      )
      .subscribe((res) => {
        this.boardCircuits$ = this.boardCircuits$?.filter((circuit) => circuit.uuid !== deletedCircuitUUID);
        this.currentDialog?.unsubscribe()
      })
  }
}
