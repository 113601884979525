<div class="dialog">

    <h1 class="dialog-title mx-2 mb-3">Disassociate from company</h1>
  
    <span class="w-100 users-count">{{usersBulkArr?.length}} users selected</span>
  
    <hr>
    
    <div class="dialog-main-text mx-2 mb-3">
        <!-- Are you sure you want to delete use rX<br>
        Are you sure? -->
        Are you sure that you want to <span class="italic dialog-data-orange">disassociate</span> selected user from their current companies?
    </div>
  
    
    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button class="col-4 dialog-blue-action-button" (click)="submit()" [matDialogClose]="true" mat-button>DELETE</button>
    </div>
    
  </div>