<mat-sidenav-container>
    <mat-sidenav class="pt-2" position="end" #sidenav closed class="py-4" [fixedInViewport]="options.value.fixed"
        [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">

        <div class="row m-0 mb-4">
            <div class="col-9">
                <p class="m-0 account-header">{{currentUserData?.name}}&nbsp;{{currentUserData?.surname}}</p>
                <p class="account-email">{{currentUserData?.email}}</p>
            </div>

            <div [routerLink]="['/users/' + currentUserData?.userUUId]" class="col-3 user-profile">
                <div class="account-image" [ngStyle]="{backgroundImage: 'url(web-assets/avatars/' + userList?.photo + ')'}">
                </div>
            </div>
        </div>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="home" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Dashboard</div>
            <div class="col-2">
                <mat-icon>bar_chart</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="sites" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Sites</div>
            <div class="col-2">
                <mat-icon>store</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="boards" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Boards</div>
            <div class="col-2">
                <mat-icon>assignment</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="assets" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Assets</div>
            <div class="col-2">
                <mat-icon>image</mat-icon>
            </div>
        </a>

        <!-- <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="modules" (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Modules</div>
            <div class="col-2">
                <mat-icon>layers</mat-icon>
            </div>
        </a> -->

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="reports" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Reports</div>
            <div class="col-2">
                <mat-icon>report</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="my-files" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                My Files</div>
            <div class="col-2">
                <mat-icon>folder_open</mat-icon>
            </div>
        </a>

        <a *ngIf="(currenUserRole$ | async) === 'company manager' && checkSiteRoles()" class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="users" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Users</div>
            <div class="col-2">
                <mat-icon>group</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 bottom-whitespace mat-sidenav-links" routerLink="my-files" (click)="logout()">
            <div class="col-10 mat-sidenav-links-text">
                Logout</div>
            <div class="col-2">
                <mat-icon>logout</mat-icon>
            </div>
        </a>

    </mat-sidenav>

    <mat-sidenav-content [formGroup]="options">

        <app-menu-mobile (togglerEvent)="togglerEventHandler(sidenav)"></app-menu-mobile>

        <router-outlet></router-outlet>

    </mat-sidenav-content>

</mat-sidenav-container>