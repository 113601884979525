<div class="dialog">

    <h1 class="dialog-title mx-2 mb-3">Remove from Site</h1>

    <div class="dialog-main-text mx-2 mb-3">
        Are you sure you want to remove user <span class="italic data">"{{data.userFullName}}"</span> <br> from site
    </div>

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button class="col-4 dialog-delete-action-button" [matDialogClose]="true" mat-button>REMOVE</button>
    </div>

</div>