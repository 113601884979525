import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { SiteBasicViewModel, TiccCheckEventForDashboard } from 'src/app/models/backend-generated-models';
import { DashboardService } from '../api/dashboard.service';

@Injectable({
	providedIn: 'root',
})
export class SiteStateService {

	currentUserSiteSource = new BehaviorSubject<SiteBasicViewModel>({} as SiteBasicViewModel);
	currentUserSite$ = this.currentUserSiteSource.asObservable();


	/* LEFT THIS CODE AS A REFERENCE - WILL REMOVE IN THE FUTURE */
	/* currentDashboardSource = new BehaviorSubject<Observable<TiccCheckEventForDashboard[]> | undefined>(undefined);
	currentDashboard$ = this.currentDashboardSource;
	*/

}
