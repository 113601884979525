<div class="dialog">

  <h1 class="dialog-title mx-2 mb-3">User Delete</h1>

  
  <div class="dialog-main-text mx-2 mb-3" *ngIf="data.userFirstName; else deleteDialogUser">
      Are you sure you want to delete user: <span class="italic data">"{{data.userFirstName}}&nbsp;{{data.userLastName}}"?</span> <br>
  </div>

  <ng-template #deleteDialogUser>
    <div class="dialog-main-text mx-2 mb-3" >
        Are you sure you want to delete user: <span class="italic data">"{{data.userFullName}}"?</span> <br>
    </div>
  </ng-template>
  
  <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
      <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>No</button>
      <button class="col-4 dialog-delete-action-button" [matDialogClose]="data.userUUID" mat-button>Yes</button>
  </div>
  
</div>