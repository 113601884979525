import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MyFilesService } from 'src/app/services/api/myfiles.service';
import { SitesService } from 'src/app/services/api/sites.service';

@Component({
  selector: 'app-my-files',
  templateUrl: './my-files.component.html',
  styleUrls: ['./my-files.component.scss']
})
export class MyFilesComponent implements OnInit {

  sitesList$ = this.sitesService.getList();

  selectedSite: any;
  siteFolderSharedUrlSafe: any;

  isLoadingUrl: boolean = false;

  constructor(
    private myFilesService: MyFilesService,
    private sitesService: SitesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  onSiteChange(selectedSite: any){
      this.isLoadingUrl = true;
      this.siteFolderSharedUrlSafe = undefined

      this.myFilesService.getOrCreateSiteFolderSharedUrl(selectedSite.id).subscribe((response: any) => {
        this.navigateToUrl(response.siteFolderSharedUrl);
      },
      (error) => {},
        () => {this.isLoadingUrl = false}
      );
  }

  private navigateToUrl(url: string) {
    window.open(url, "_blank");
    this.siteFolderSharedUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
