<!-- ------------------------------------ LOGGED ------------------------------------ -->
<ng-container (window:resize)="onResize($event)" *ngIf="(loggedIn$ | async) ; else notLoggedIn">

    <!-- ------------------------------------ MOBILE ------------------------------------ -->
    <ng-container *ngIf="isMobile ; else notMobile">
        
        <!-- ------------------------------------ ADMIN ------------------------------------ -->
        <ng-container *ngIf="(isUserAdmin$ | async); else notAdmin">
            <app-admin-sidenav></app-admin-sidenav>
        </ng-container>
        
        <!-- ------------------------------------ NOT-ADMIN ------------------------------------ -->
        <ng-template #notAdmin>
            <app-sidenav></app-sidenav>
        </ng-template>

    </ng-container>
    

    <!-- ------------------------------------ DESKTOP ------------------------------------ -->
    <ng-template #notMobile>

        <!-- ------------------------------------ ADMIN ------------------------------------ -->
        <ng-container *ngIf="(isUserAdmin$ | async); else notAdmin">
            <app-admin-menu-desktop></app-admin-menu-desktop>
        </ng-container>
        
        <!-- ------------------------------------ NOT-ADMIN ------------------------------------ -->
        <ng-template #notAdmin>
            <app-menu-desktop></app-menu-desktop>
        </ng-template>

        <router-outlet></router-outlet>

    </ng-template>

</ng-container>


<!-- ------------------------------------ NOT LOGGED ------------------------------------ -->
<ng-template #notLoggedIn>

    <router-outlet></router-outlet>

</ng-template>