<div class="dialog">

  <h1 class="dialog-title d-flex justify-content-center mx-2 mb-3">Change Password for: <span class="italic data">"{{data?.userFirstName}}&nbsp;{{data?.userLastName}}"</span></h1>

  <form [formGroup]="formResetPassword" (ngSubmit)="onSubmit()">

    <mat-form-field class="my-2 col-12" appearance="fill">
        <mat-label class="label-color">EMAIL ADDRESS</mat-label>
        <input class="input-color" formControlName="email" matInput placeholder="Intoduce your email account">
        <mat-icon class="icon-color" matPrefix>alternate_email</mat-icon>
        <mat-error mat-error>Please, introduce your email</mat-error>
    </mat-form-field>

    <mat-form-field class="my-2 col-12" appearance="fill">
        <mat-label class="label-color">NEW PASSWORD</mat-label>
        <input [type]="showPassword ? 'text' : 'password'" class="input-color"  formControlName="newPassword" matInput placeholder="Intoduce your password">
        <mat-icon class="icon-color" matPrefix>lock</mat-icon>
        <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
        <mat-error *ngIf="!formResetPassword.value.newPassword" mat-error>Please, introduce your password</mat-error>
        <mat-error *ngIf="!formResetPassword.value.newPassword.pattern" mat-error>Must contain minimum eight characters, at least one letter, one number and one special character</mat-error>
    </mat-form-field>

    <mat-form-field class="my-2 col-12" appearance="fill">
        <mat-label class="label-color">PASSWORD CONFIRMATION</mat-label>
        <input [type]="showConfirmPassword ? 'text' : 'password'" class="input-color" formControlName="newConfirmPassword" matInput placeholder="Repeat your password">
        <mat-icon class="icon-color" matPrefix>lock</mat-icon>
        <mat-icon matSuffix (click)="toggleConfirmPasswordVisibility()">{{showConfirmPassword?'visibility_off':'visibility'}}</mat-icon>
        <mat-error *ngIf="!formResetPassword.value.newConfirmPassword">This field is required</mat-error>
        <mat-error *ngIf="formResetPassword.value.newPassword !== formResetPassword.value.newConfirmPassword">Passwords does not match</mat-error>
    </mat-form-field>

    <div class="d-flex justify-content-center ">
      <button type="button" mat-stroked-button color="warn" class="main-button text-uppercase mx-2" (click)="onNoClick()">
        Cancel
      </button>

        <button type="submit" mat-stroked-button color="accent" class="main-button text-uppercase mx-2">
          Update
        </button>
    </div>
  </form>

</div>