import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PageEvent } from '@angular/material/paginator';
import { map, mergeMap, Observable, Subscription } from 'rxjs';
import { AssetFilters, AssetTypeFilterModel, AssetsAndFilters, AssetsBaseViewModel, CompanyFilterModel, SiteFilterModel } from 'src/app/models/backend-generated-models';
import { AssetsService } from 'src/app/services/api/assets.service';
import { LoadingService } from 'src/app/services/helpers/loading.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoading$ = this.loadingService.isLoading$;

  // here we can return only the asset group name instead of companyId

  globalFilter: HttpParams | undefined;

  selectedGroup: string = "Site(asc)";

  companiesGroupList: { companyName: string; companyId: number; count: number }[] = [];
  sitesGroupList: { siteName: string; siteUuid: string; count: number; }[] = [];
  assetTypesGroupList: { assetTypeName: string; assetTypeUuid: string; count: number; }[] = [];

  companiesAssetsList: { companyId: number; assets: Observable<AssetsBaseViewModel[]> }[] = [];
  sitesAssetsList: { siteUuid: string; assets: Observable<AssetsBaseViewModel[]> }[] = [];
  assetTypesAssetsList: { assetTypeUuid: string; assets: Observable<AssetsBaseViewModel[]> }[] = [];

  isFilter = false;
  filter$: AssetFilters | undefined;

  selectionCompanies = new SelectionModel<number | undefined>(true, []);
  selectionSites = new SelectionModel<string | undefined>(true, []);
  selectionAssetTypes = new SelectionModel<string | undefined>(true, []);


  /* ==================================================== COMPANIES ==================================================== */
  getCompaniesAssetsList(companyId: number) {
    return this.companiesAssetsList.filter(x => x.companyId == companyId).map(x => x.assets);
  }
  setCompaniesAssetsList(companyId: number) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.companiesAssetsList.map(x => x.companyId).includes(companyId)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5).append("ReturnOnlyGroupWithCompanyId", companyId!);
    this.companiesAssetsList.push({ companyId: companyId, assets: this.assetsService.getAssetsList(filterParams).pipe(map(x => x.assets!)) })
  }

  setCompaniesAssetsListWithPagination(companyId: number, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize).append("ReturnOnlyGroupWithCompanyId", companyId!)

    this.companiesAssetsList[this.companiesAssetsList.map(x => x.companyId).indexOf(companyId)] = {
      companyId: companyId,
      assets: this.assetsService.getAssetsList(filterParams).pipe(
        map(
          x => x.assets!)
      )
    }
  }


  /* ==================================================== SITES ==================================================== */
  getSitesAssetsList(siteUuid: string) {
    return this.sitesAssetsList.filter(x => x.siteUuid == siteUuid).map(x => x.assets);
  }
  setSitesAssetsList(siteUuid: string) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.sitesAssetsList.map(x => x.siteUuid).includes(siteUuid)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5).append("ReturnOnlyGroupWithSiteUuid", siteUuid!);

    this.sitesAssetsList.push({ siteUuid: siteUuid, assets: this.assetsService.getAssetsList(filterParams).pipe(map(x => x.assets!)) })
  }

  setSitesAssetsListWithPagination(siteUuid: string, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize).append("ReturnOnlyGroupWithSiteUuid", siteUuid!)

    this.sitesAssetsList[this.sitesAssetsList.map(x => x.siteUuid).indexOf(siteUuid)] = {
      siteUuid: siteUuid,
      assets: this.assetsService.getAssetsList(filterParams).pipe(
        map(
          x => x.assets!)
      )
    }
  }
  deleteAsset() {

  }


  /* ==================================================== ASSET TYPES ==================================================== */
  getAssetTypesAssetsList(assetTypeUuid: string) {
    return this.assetTypesAssetsList.filter(x => x.assetTypeUuid == assetTypeUuid).map(x => x.assets);
  }
  setAssetTypesAssetsList(assetTypeUuid: string) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    if (this.assetTypesAssetsList.map(x => x.assetTypeUuid).includes(assetTypeUuid)) return;

    let filterParams = this.globalFilter?.append("PageIndex", 0).append("PageSize", 5).append("ReturnOnlyGroupWithAssetTypeUuid", assetTypeUuid!);

    this.assetTypesAssetsList.push({ assetTypeUuid: assetTypeUuid, assets: this.assetsService.getAssetsList(filterParams).pipe(map(x => x.assets!)) })
  }

  setAssetTypesAssetsListWithPagination(assetTypeUuid: string, event: PageEvent) {
    if (!this.globalFilter) this.globalFilter = new HttpParams();

    let filterParams = this.globalFilter?.append("PageIndex", event.pageIndex).append("PageSize", event.pageSize).append("ReturnOnlyGroupWithAssetTypeUuid", assetTypeUuid!)

    this.assetTypesAssetsList[this.assetTypesAssetsList.map(x => x.assetTypeUuid).indexOf(assetTypeUuid)] = {
      assetTypeUuid: assetTypeUuid,
      assets: this.assetsService.getAssetsList(filterParams).pipe(
        map(
          x => x.assets!)
      )
    }
  }




  /* getAssetArray(observable: { companyId: number; observable: Observable<AssetsAndFilters | undefined>}[]): Observable<AssetsBaseViewModel[] | >[] {
    return observable.map(x => x.observable).map(x => x.pipe(map(x => x?.assets)))
  } */

  constructor(
    private assetsService: AssetsService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
  ) { }

  exec(event: PageEvent) {
    console.log("changed", event)

    let params = new HttpParams();

    params = params.append("PageIndex", event.pageIndex)
    params = params.append("PageSize", event.pageSize)

    this.selectionCompanies.selected.forEach(element => {
      params = params.append("FilteredCompaniesIds", element!);
    });
    this.selectionSites.selected.forEach(element => {
      params = params.append("FilteredSitesUuids", element!);
    });
    this.selectionAssetTypes.selected.forEach(element => {
      params = params.append("FilteredAssetTypesUuids", element!);
    });

    this.assetsService.getAssetsList(

      params

    ).subscribe((res) => {
      console.log("after change", res)
      this.assets = res;
    })
  }

  ngOnInit(): void {
    if (!this.globalFilter) this.globalFilter = new HttpParams();
    this.assetsService.getAssetsList().subscribe((res) => {

      res.updatedFilters?.filteredCompanies?.forEach(element => {

        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count!/* .pipe(map(x => x.assets)) */ })
      });

      res.updatedFilters?.filteredSites?.forEach(element => {
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
      })

      res.updatedFilters?.filteredAssetTypes?.forEach(element => {
        this.assetTypesGroupList.push({ assetTypeName: element.name!, assetTypeUuid: element.uuid!, count: element.count! })
      })
    })

    let params = new HttpParams()

    params = params.append("PageIndex", 0).append("PageSize", 5)

    this.assetsService.getAssetsList(
      params
    ).subscribe((res) => {
      console.log("response asset list", res)

      if (res.pageInfo?.totalCount) this.paginatorLength = res.pageInfo?.totalCount;
      this.assets = res;
      this.filter$ = res.updatedFilters;
    });

  }

  @ViewChildren('assets') things: QueryList<any> | undefined;

  assets: AssetsAndFilters | undefined;
  paginatorLength = 100;


  search: string | undefined;


  assetsList: [string, any][] | undefined

  currentSiteId: number | undefined;

  currentDialog: Subscription | undefined;


  ngAfterViewInit() {
    if (this.things) {
      this.things.changes.subscribe(() => {
        this.ngForRendered();
      })
    }
  }

  ngForRendered() {
    let assetScrollPositionItem = localStorage.getItem('assetScrollPosition');
    if (assetScrollPositionItem !== null) {
      this.document.documentElement.scrollTop = parseInt(assetScrollPositionItem);
      localStorage.removeItem('assetScrollPosition')
    }
  }

  ngOnDestroy(): void {
    localStorage.setItem('assetScrollPosition', (this.document.documentElement.scrollTop).toString());
    this.search ? localStorage.setItem('assetSearch', this.search) : localStorage.setItem('assetSearch', "")
  }

  deleteAction(asset: AssetsBaseViewModel, deletedFrom: string, item: string | number) {

    return this.dialog.open(DialogDeleteConfirmation, {
      width: '450px',
      data: asset
    },)
      .afterClosed()

      .pipe(
        mergeMap((assetUUID) => {
          console.log("asset uuid", assetUUID)
          if (!assetUUID) this.currentDialog?.unsubscribe();
          return this.assetsService.deleteAsset(assetUUID);
        })
      )
      .subscribe((res) => {
        console.log("delted", res)

        switch (deletedFrom) {
          case 'company':
            this.setCompaniesAssetsList(parseInt(item.toString()));
            break;

          case 'site':
            this.setSitesAssetsList(item.toString());
            break;

          case 'assetType':
            this.setAssetTypesAssetsList(item.toString());
            break;
        }


        this.currentDialog?.unsubscribe()
      })

  }

  /* FILTERS - GROUP/BY */

  // sitesModels$: { assetCreatedAt: Date | undefined; assetDescription: string | undefined; assetId: number | undefined; assetIsTagAssociated: boolean | undefined; assetLastCheckCompleted: Date | undefined; assetName: string | undefined; assetTiccCount: number | undefined; assetTypeModules: AssetTypeModules[] | undefined; assetTypeName: string | undefined; assetUpdatedAt: Date | undefined; assetUuid: string | undefined; }[] | undefined




  getMappedCompanies(companies: CompanyFilterModel[]) {
    return companies
      .filter(x => x !== null)
      .map(x => x.id);
  }

  getMappedSites(companies: SiteFilterModel[]) {
    return companies
      .filter(x => x !== null)
      .map(x => x.uuid);
  }

  getMappedAssetTypes(companies: AssetTypeFilterModel[]) {
    return companies
      .filter(x => x !== null)
      .map(x => x.uuid);
  }

  clearSelectionFilters() {
    this.selectionCompanies.clear();
    this.selectionSites.clear();
    this.selectionAssetTypes.clear();
    // this.returnListOfSite();
  }


  filter() {

    this.companiesGroupList = [];
    this.sitesGroupList = [];
    this.assetTypesGroupList = [];


    if (this.selectionCompanies.selected.length == 0
      && this.selectionSites.selected.length == 0
      && this.selectionAssetTypes.selected.length == 0) {


      this.assetsService.getAssetsList().subscribe((res) => {

        res.updatedFilters?.filteredCompanies?.forEach(element => {
          this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
        });

        res.updatedFilters?.filteredSites?.forEach(element => {
          this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
        })

        res.updatedFilters?.filteredAssetTypes?.forEach(element => {
          this.assetTypesGroupList.push({ assetTypeName: element.name!, assetTypeUuid: element.uuid!, count: element.count! })
        })
      })
    }

    this.globalFilter = new HttpParams();

    this.selectionCompanies.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredCompaniesIds", element!);
    });
    this.selectionSites.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredSitesUuids", element!);
    });
    this.selectionAssetTypes.selected.forEach(element => {
      this.globalFilter = this.globalFilter?.append("FilteredAssetTypesUuids", element!);
    });

    this.isFilter = true;


    this.assetsService.getAssetsList(this.globalFilter).subscribe((res) => {

      res.updatedFilters?.filteredCompanies?.forEach(element => {
        this.companiesGroupList.push({ companyName: element.name!, companyId: element.id!, count: element.count! })
      });

      res.updatedFilters?.filteredSites?.forEach(element => {
        this.sitesGroupList.push({ siteName: element.name!, siteUuid: element.uuid!, count: element.count! })
      })

      res.updatedFilters?.filteredAssetTypes?.forEach(element => {
        this.assetTypesGroupList.push({ assetTypeName: element.name!, assetTypeUuid: element.uuid!, count: element.count! })
      })

    })

    let params = new HttpParams();

    params = params.append("PageIndex", 0)
    params = params.append("PageSize", 5)

    this.selectionCompanies.selected.forEach(element => {
      params = params.append("FilteredCompaniesIds", element!);
    });
    this.selectionSites.selected.forEach(element => {
      params = params.append("FilteredSitesUuids", element!);
    });
    this.selectionAssetTypes.selected.forEach(element => {
      params = params.append("FilteredAssetTypesUuids", element!);
    });

    this.assetsService.getAssetsList(
      params
    ).subscribe((res) => {
      if (res.pageInfo?.totalCount) this.paginatorLength = res.pageInfo?.totalCount;
      this.assets = res;
    })
  }

  /* FILTERS - GROUP/BY */
}


@Component({
  selector: 'dialog-delete-confirmation',
  templateUrl: 'dialog-delete-confirmation.html',
  styleUrls: ['./assets.component.scss']

})
export class DialogDeleteConfirmation {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AssetsBaseViewModel | undefined,
    public dialogRef: MatDialogRef<DialogDeleteConfirmation>,
  ) { }
}
