<zxing-scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>

  <section class="results" *ngIf="qrResultString">
    <div>
      <small>Result</small>
      <strong>QR Code found</strong>
    </div>
    <button mat-icon-button (click)="clearResult()">&times;</button>
  </section>

  <div class="row d-flex justify-content-between">
    <div class="col-6 col-md-5 col-xl-3">
        <button mat-button type="submit"  (click)="cancel()" class="action-buttons cancel w-100">Cancel</button>
    </div>
    <div class="col-6 col-md-5 col-xl-3">
        <button mat-button type="submit" (click)="submit()" [disabled]="qrResultString == undefined" class="action-buttons submit w-100">Next</button>
    </div>
</div>