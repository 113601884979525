<div class="dialog">
    <h1 class="dialog-title mx-2 mb-3">Add New Users</h1>

    <div class="dialog-main-text mx-2 mb-3">
        <!-- Are you sure you want to remove user <span class="italic data">"{{data[0].userFullName}}"</span> <br> from site
        <div *ngFor="let item of data">
            {{item.userFullName}}
        </div> -->
        <div class="row">
            <div class="col-12 dialog-data italic">
                <!-- {{usersSites?.userUUID?.length}} users selected -->
            </div>

            <div class="col-12 add-users-form" [formGroup]="newUsersForm">
                <cdk-virtual-scroll-viewport itemSize="50" class="scrollbar-box" formArrayName="users">
                    <!-- <div *ngFor="let item of usersSites$.userUUID | async">
                        {{item.userName}}
                    </div> -->
                    <!-- <div *ngFor="let item of usersSites?.userUUID">
                        {{item?.userName}}
                    </div> -->

                    <div *ngFor="let item of users.controls; let i = index" class="scrollbar-items">

                        <div class="row user-row" [formGroupName]="i">

                            <div class="col-12 col-md-6">
                                <mat-form-field class="w-100" floatLabel="always">
                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>person</mat-icon>&nbsp;
                                        Name
                                    </mat-label>
                                    <input formControlName="userFirsName" class="dialog-input" type="text"
                                        placeholder="Insert User Name" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6">
                                <mat-form-field class="w-100" floatLabel="always">
                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>person</mat-icon>&nbsp;
                                        Surname
                                    </mat-label>
                                    <input formControlName="userLastName" class="dialog-input" type="text" matInput
                                        placeholder="Insert User Surname">
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="w-100" floatLabel="always">
                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>mail</mat-icon>&nbsp;
                                        Email
                                    </mat-label>
                                    <input formControlName="usersEmail" class="dialog-input" type="text"
                                        placeholder="Insert User Email" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6">
                                <mat-form-field class="w-100" floatLabel="always">

                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>apartment</mat-icon>&nbsp;
                                        Site
                                    </mat-label>

                                    <mat-select formControlName="userSiteUUID" class="invite-to-site-select"
                                        placeholder="Select Site Name">
                                        <mat-option *ngFor="let site of sitesList$ | async" [value]="site.siteUUID">
                                            {{site.siteName}}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6">
                                <mat-form-field class="w-100" floatLabel="always">

                                    <mat-label class="pl-2 d-flex align-items-center">
                                        <mat-icon>supervisor_account</mat-icon>&nbsp;
                                        Site Role
                                    </mat-label>
                                    <mat-select formControlName="userSiteRole" class="invite-to-site-select"
                                        placeholder="Select Site Role">

                                        <mat-option *ngFor="let role of roles | keyvalue" [value]="role.value">
                                            {{role.value}}
                                        </mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-12 d-flex justify-content-center">
                                <button (click)="removeUser(i)" class="user-delete-bulk-button" mat-button>
                                    <mat-icon>
                                        delete
                                    </mat-icon>
                                </button>
                            </div>



                        </div>

                        <hr class="add-user-separator">


                        <div *ngIf="i === (users.length - 1)" class="col-12">
                            <button (click)="addNewUser()" mat-button class="add-user-button">
                                <mat-icon>add</mat-icon>&nbsp;ADD OTHER USER
                            </button>
                        </div>

                    </div>

                    <div class="row">
                        <div *ngIf="users.length == 0" class="col-12 my-2">
                            <button (click)="addNewUser()" mat-button class="add-user-button">
                                <mat-icon>add</mat-icon>&nbsp;ADD USER
                            </button>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>

    <div class="row" [formGroup]="newUsersForm">
        <div class="col-12 users-activation-imediate">
            <mat-checkbox formControlName="activateNewUsersImmediately">Activate new users inmediately</mat-checkbox>
        </div>
    </div>

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button class="col-4 dialog-blue-action-button" (click)="submit()" mat-button [disabled]="users.length === 0 || !newUsersForm.valid"><!-- [disabled]="users.length === 0" -->
            INVITE
        </button><!-- (click)="submit()"  -->
    </div>

</div>