import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { Observable, map } from 'rxjs';
import { SiteTiccDataItem, ReportDataCollectionViewModelOfSiteTiccDataItem } from 'src/app/models/backend-generated-models';
import { UsersTiccDataItem, ReportDataCollectionViewModelOfUsersTiccDataItem } from 'src/app/models/backend-generated-models';
import { BoardTiccDataItem, ReportDataCollectionViewModelOfBoardTiccDataItem } from 'src/app/models/backend-generated-models';
import { AssetTiccDataItem, ReportDataCollectionViewModelOfAssetTiccDataItem } from 'src/app/models/backend-generated-models';
import { ReportsService } from 'src/app/services/api/reports.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})



export class ReportsComponent implements OnInit {
  siteTableItems: SiteTiccDataItem[] = [];
  usersTableItems: UsersTiccDataItem[] = [];
  boardsTableItems: BoardTiccDataItem[] = [];
  assetsTableItems: AssetTiccDataItem[] = [];

  exportColumnsSite: any[] = [
    {field: "siteName", header: "Site Name"},
    {field: "ticcName", header: "TICC"},
    {field: "checkDeadline", header: "Check Deadline"},
    {field: "checkedAt", header: "Checked At"},
    {field: "checkedBy", header: "Checked By"},
    {field: "checkNotes", header: "Notes"},
    {field: "checkStatus", header: "Status"}
  ];

  exportColumnsUsers: any[] = [
    {field: "siteName", header: "Site Name"},
    {field: "userName", header: "User Name"},
    {field: "ticcName", header: "TICC"},
    {field: "checkDeadline", header: "Check Deadline"},
    {field: "checkedAt", header: "Checked At"},
    {field: "checkedBy", header: "Checked By"},
    {field: "checkNotes", header: "Notes"},
    {field: "checkStatus", header: "Status"}
  ];

  exportColumnsBoard: any[] = [
    {field: "siteName", header: "Site Name"},
    {field: "boardName", header: "Board Name"},
    {field: "ticcName", header: "TICC"},
    {field: "checkDeadline", header: "Check Deadline"},
    {field: "checkedAt", header: "Checked At"},
    {field: "checkedBy", header: "Checked By"},
    {field: "checkNotes", header: "Notes"},
    {field: "checkStatus", header: "Status"}
  ];

  exportColumnsAssets: any[] = [
    {field: "siteName", header: "Site Name"},
    {field: "assetName", header: "Asset Name"},
    {field: "assetType", header: "Asset Type"},
    {field: "ticcName", header: "TICC"},
    {field: "checkDeadline", header: "Check Deadline"},
    {field: "checkedAt", header: "Checked At"},
    {field: "checkedBy", header: "Checked By"},
    {field: "checkNotes", header: "Notes"},
    {field: "checkStatus", header: "Status"}
  ];

  loading: boolean = true;
  displayEmpty: boolean = true;
  globalFilter: HttpParams | undefined;
  incPaginationRequest: {
    PortionSize: number;
    PreviousPortionSize: number;
    DisplayEmpty: boolean;
  } | undefined

  constructor(
    private reportsService: ReportsService
  ) {
   }

  switchEmptyData(displayEmpty: boolean)
  {
    this.displayEmpty = displayEmpty;
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.incPaginationRequest = {
      PortionSize: 500,
      PreviousPortionSize: 0,
      DisplayEmpty: this.displayEmpty
    }
    let filterParams= new HttpParams({ fromObject: this.incPaginationRequest });
    this.reportsService.GetSitesReport(filterParams).subscribe( x => this.loadFirstPortionSite(x));
    this.reportsService.GetUsersReport(filterParams).subscribe( x => this.loadFirstPortionUsers(x));
    this.reportsService.GetBoardsReport(filterParams).subscribe( x => this.loadFirstPortionBoards(x));
    this.reportsService.GetAssetsReport(filterParams).subscribe( x => this.loadFirstPortionAssets(x));
  }

  loadFirstPortionSite(response: ReportDataCollectionViewModelOfSiteTiccDataItem): void{
    this.appendTableRecordsSite(response.items);

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0),
      DisplayEmpty: this.displayEmpty
    }
    let filterParams= new HttpParams({ fromObject: this.incPaginationRequest });

    this.reportsService.GetSitesReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsSite(x));

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0) + Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      DisplayEmpty: this.displayEmpty
    }
    filterParams= new HttpParams({ fromObject: this.incPaginationRequest });
    this.reportsService.GetSitesReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsSite(x));
  }

  appendTableRecordsSite(records: SiteTiccDataItem[]): void {
    records.forEach(i => {
      if (i.checkDeadline != null && i.checkDeadline != undefined) {
        i.checkDeadline = new Date(i.checkDeadline);
      }
      if (i.checkedAt != null && i.checkedAt != undefined) {
        i.checkedAt = new Date(i.checkedAt);
      }
    });

    this.siteTableItems = this.siteTableItems.concat(records);
    this.loading = false;
  }

  loadFirstPortionUsers(response: ReportDataCollectionViewModelOfUsersTiccDataItem): void{
    this.appendTableRecordsUsers(response.items);

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0),
      DisplayEmpty: this.displayEmpty
    }
    let filterParams= new HttpParams({ fromObject: this.incPaginationRequest });

    this.reportsService.GetUsersReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsUsers(x));

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0) + Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      DisplayEmpty: this.displayEmpty
    }
    filterParams= new HttpParams({ fromObject: this.incPaginationRequest });
    this.reportsService.GetUsersReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsUsers(x));
  }

  appendTableRecordsUsers(records: SiteTiccDataItem[]): void {
    records.forEach(i => {
      if (i.checkDeadline != null && i.checkDeadline != undefined) {
        i.checkDeadline = new Date(i.checkDeadline);
      }
      if (i.checkedAt != null && i.checkedAt != undefined) {
        i.checkedAt = new Date(i.checkedAt);
      }
    });

    this.usersTableItems = this.usersTableItems.concat(records);
    this.loading = false;
  }

  loadFirstPortionBoards(response: ReportDataCollectionViewModelOfBoardTiccDataItem): void{
    this.appendTableRecordsBoards(response.items);

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0),
      DisplayEmpty: this.displayEmpty
    }
    let filterParams= new HttpParams({ fromObject: this.incPaginationRequest });

    this.reportsService.GetBoardsReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsBoards(x));

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0) + Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      DisplayEmpty: this.displayEmpty
    }
    filterParams= new HttpParams({ fromObject: this.incPaginationRequest });
    this.reportsService.GetBoardsReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsBoards(x));
  }

  appendTableRecordsBoards(records: BoardTiccDataItem[]): void {
    records.forEach(i => {
      if (i.checkDeadline != null && i.checkDeadline != undefined) {
        i.checkDeadline = new Date(i.checkDeadline);
      }
      if (i.checkedAt != null && i.checkedAt != undefined) {
        i.checkedAt = new Date(i.checkedAt);
      }
    });

    this.boardsTableItems = this.boardsTableItems.concat(records);
    this.loading = false;
  }

  loadFirstPortionAssets(response: ReportDataCollectionViewModelOfAssetTiccDataItem): void{
    this.appendTableRecordsAssets(response.items);

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0),
      DisplayEmpty: this.displayEmpty
    }
    let filterParams= new HttpParams({ fromObject: this.incPaginationRequest });

    this.reportsService.GetAssetsReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsAssets(x));

    this.incPaginationRequest = {
      PortionSize: Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      PreviousPortionSize: (response.incrementalPageInfo?.portionSize || 0) + Math.round((response.incrementalPageInfo?.itemsLeft || 0)/2),
      DisplayEmpty: this.displayEmpty
    }
    filterParams= new HttpParams({ fromObject: this.incPaginationRequest });
    this.reportsService.GetAssetsReport(filterParams).pipe(map(i => i.items)).subscribe( x => this.appendTableRecordsAssets(x));
  }

  appendTableRecordsAssets(records: AssetTiccDataItem[]): void {
    records.forEach(i => {
      if (i.checkDeadline != null && i.checkDeadline != undefined) {
        i.checkDeadline = new Date(i.checkDeadline);
      }
      if (i.checkedAt != null && i.checkedAt != undefined) {
        i.checkedAt = new Date(i.checkedAt);
      }
    });

    this.assetsTableItems = this.assetsTableItems.concat(records);
    this.loading = false;
  }

  clear(table: Table) {
      table.clear();
  }

}
