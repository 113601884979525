import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministratorGuard } from './guards/administrator.guard';
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permission.guard';
import { AdminModulesComponent } from './views/admin/admin-modules/admin-modules.component';
import { AdminSitesManagementComponent } from './views/admin/admin-sites-management/admin-sites-management.component';
import { AdminTagsComponent } from './views/admin/admin-tags/admin-tags.component';
import { AdminUserManagementComponent } from './views/admin/admin-user-management/admin-user-management.component';
import { AssetTypeCreateComponent } from './views/admin/asset-types/asset-type-create/asset-type-create.component';
import { AssetTypeEditComponent } from './views/admin/asset-types/asset-type-edit/asset-type-edit.component';
import { AssetTypesComponent } from './views/admin/asset-types/asset-types.component';
import { NewTiccTypeComponent } from './views/admin/ticc-types/new-ticc-type/new-ticc-type.component';
import { TiccTypesComponent } from './views/admin/ticc-types/ticc-types.component';
import { AssetCreateComponent } from './views/assets/asset-create/asset-create.component';
import { AssetsDetailComponent } from './views/assets/assets-detail/assets-detail.component';
import { AssetsComponent } from './views/assets/assets.component';
import { BoardDetailComponent } from './views/boards/board-detail/board-detail.component';
import { BoardsComponent } from './views/boards/boards.component';
import { CircuitEditComponent } from './views/boards/circuits/circuit-edit/circuit-edit.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { ModulesComponent } from './views/modules/modules.component';
import { MyFilesComponent } from './views/my-files/my-files.component';
import { NewPasswordComponent } from './views/new-password/new-password.component';
import { RegisterComponent } from './views/register/register.component';
import { ReportsComponent } from './views/reports/reports.component';
import { SitesCreationComponent } from './views/sites/sites-creation/sites-creation.component';
import { SitesDetailComponent } from './views/sites/sites-detail/sites-detail.component';
import { SitesEditComponent } from './views/sites/sites-edit/sites-edit.component';
import { SitesComponent } from './views/sites/sites.component';
import { CheckCreateComponent } from './views/ticcs/check-create/check-create.component';
import { CheckDetailComponent } from './views/ticcs/check-detail/check-detail.component';
import { TiccsCreateComponent } from './views/ticcs/ticcs-create/ticcs-create.component';
import { TiccsDetailComponent } from './views/ticcs/ticcs-detail/ticcs-detail.component';
import { TiccsEditComponent } from './views/ticcs/ticcs-edit/ticcs-edit.component';
import { InviteToSiteComponent } from './views/users/invite-to-site/invite-to-site.component';
import { UserDetailComponent } from './views/users/user-detail/user-detail.component';
import { UserEditComponent } from './views/users/user-detail/user-edit/user-edit.component';
import { UsersComponent } from './views/users/users.component';
import { TagAssociationComponent, TagAssociationForAssetComponent, TagAssociationForBoardComponent, TagAssociationForSiteComponent, TagAssociationForUserComponent } from './views/tags/tag-association/tag-association.component';
import { ScannerComponent } from './views/scanner/scanner.component';
import { AssetCloneComponent } from './views/assets/asset-clone/asset-clone.component';
import { BoardCreateComponent } from './views/boards/board-create/board-create.component';
import { AssetEditComponent } from './views/assets/asset-edit/asset-edit.component';
import { QRComponent } from './views/qr/qr.component';
import { CircuitCreateComponent } from './views/boards/circuits/circuit-create/circuit-create.component';
import { BoardEditComponent } from './views/boards/board-edit/board-edit.component';

const routes: Routes = [

  /* ------------------------------------ PUBLIC ------------------------------------ */
  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'register',
    component: RegisterComponent
  },

  {
    path: 'change-password',
    component: ForgotPasswordComponent
  },

  {
    path: 'forgot-password',
    component: NewPasswordComponent
  },

  /* {
    path: 'forgot-password',
    children: [
      {
        path: '?code',
        component: NewPasswordComponent,
        pathMatch: 'full'
      }
    ]
  }, */

  /* {
    path: 'Accounts',
    children: [
      {
        path: 'forgot-password',
        children: [
          {
            path: ':uuid',
            component: NewPasswordComponent
          }
        ]
      }
    ]
  }, */
  /* ------------------------------------ END-PUBLIC ------------------------------------ */


  /* ------------------------------------ PRIVATE ------------------------------------ */
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'ticcs',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':uuid',
        component: TiccsDetailComponent,
      },
      {
        path: ':uuid',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'checks',
            children: [
              {
                path: 'create',
                component: CheckCreateComponent
              },
              {
                path: ':uuid',
                component: CheckDetailComponent
              }
            ]
          },
          {
            path: 'edit',
            component: TiccsEditComponent
          }
        ]
      }
    ]
  },

  {
    path: 'checks',
    children: [
      {
        path: ':uuid',
        component: CheckDetailComponent
      }
    ]
  },

  /*
      {
        path: 'ticc',
        children: [
          {
            path: ':uuid',
            children: [
              {
                path: 'check',
                component: CheckCreationComponent,
                canActivate: [AuthGuard],
              }
            ]
          }
        ]
      }, */






  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuard, /* PermissionGuard */],
    data: { role: ["Site Owner", "Employee"] }
  },


  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    // pathMatch: 'full',
  },

  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'USERS' },
    children: [
      {
        path: 'invite',
        pathMatch: 'full',
        component: InviteToSiteComponent
      },
      {
        path: ':userUUID',
        pathMatch: 'full',
        component: UserDetailComponent,
        data: { breadcrumb: 'DETAIL' },
      },
      {
        path: ':userUUID',
        children: [
          {
            path: 'edit',
            data: { breadcrumb: 'EDIT' },
            component: UserEditComponent
          },
          {
            path: 'sites/:siteUuid/ticcs/create',
            component: TiccsCreateComponent
          }
        ]
      },
    ]
  },

  {
    path: 'sites',
    component: SitesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sites',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        component: SitesCreationComponent
      },
      {
        path: ':siteUuid',
        pathMatch: 'full',
        component: SitesDetailComponent,
      },
      {
        path: ':siteUuid',
        children: [
          {
            path: 'edit',
            component: SitesEditComponent
          },
          {
            path: 'ticcs',
            children: [
              {
                path: 'create',
                component: TiccsCreateComponent
              }
            ]
          },
          {
            path: 'overview',
            component: SitesDetailComponent
          }
        ]
      }
    ]
  },

  {
    path: 'boards',
    component: BoardsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'boards',
    children: [
      {
        path: 'create',
        component: BoardCreateComponent,
      },
      {
        path: ':uuid',
        component: BoardDetailComponent,
        pathMatch: 'full',
      },
      {
        path: ':uuid',
        children: [
          {
            path: 'circuits',
            children: [
              {
                path: 'create',
                component: CircuitCreateComponent
              },
              {
                path: ':uuid',
                children: [
                  {
                    path: 'edit',
                    component: CircuitEditComponent
                  }
                ]
              }
            ]
          },
          {
            path: 'edit',
            component: BoardEditComponent
          },
          {
            path: 'ticcs',
            children: [
              {
                path: 'create',
                children: [
                  {
                    path: ':siteUuid',
                    component: TiccsCreateComponent
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  },

  {
    path: 'assets',
    component: AssetsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'assets',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        component: AssetCreateComponent
      },
      {
        path: ':uuid',
        pathMatch: 'full',
        component: AssetsDetailComponent,
      },
      {
        path: ':uuid',
        children: [
          {
            path: 'edit',
            component: AssetEditComponent
          },
          {
            path: 'clone',
            component: AssetCloneComponent
          },
          {
            path: 'ticcs',
            children: [
              {
                path: 'create',
                children: [
                  {
                    path: ':siteUuid',
                    component: TiccsCreateComponent
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: 'modules',
    component: ModulesComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'my-files',
    component: MyFilesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tags/:tagUUID/association',
    component: TagAssociationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tags/:tagUUID/association',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'site',
        component: TagAssociationForSiteComponent
      },
      {
        path: 'user',
        component: TagAssociationForUserComponent
      },
      {
        path: 'asset',
        component: TagAssociationForAssetComponent
      },
      {
        path: 'board',
        component: TagAssociationForBoardComponent
      }
    ]
  },

  {
    path: 'qr/:qr',
    component: QRComponent
  },
  {
    path: 'scanner',
    component: ScannerComponent,
    canActivate: [AuthGuard],
  },
  /* ------------------------------------ END-PRIVATE ------------------------------------ */


  /* ------------------------------------ ADMIN ------------------------------------ */
  {
    path: 'admin',
    canActivate: [AuthGuard, AdministratorGuard],
    children: [
      {
        path: 'site-management',
        component: AdminSitesManagementComponent,
      },
      {
        path: 'user-management',
        component: AdminUserManagementComponent,
      },

      {
        path: 'asset-types',
        component: AssetTypesComponent,
        pathMatch: 'full'
      },

      {
        path: 'asset-types',
        children: [
          {
            path: 'create',
            component: AssetTypeCreateComponent,
          }
        ]
      },

      {
        path: 'asset-types',
        children: [
          {
            path: ':uuid/edit',
            component: AssetTypeEditComponent,
          }
        ]
      },
      {
        path: 'ticc-types',
        component: TiccTypesComponent,
        pathMatch: 'full',
      },
      {
        path: 'ticc-types',
        children: [
          {
            path: 'create',
            component: NewTiccTypeComponent,
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'modules',
        component: AdminModulesComponent,
      },
      {
        path: 'tags',
        component: AdminTagsComponent,
      },

    ],
  },
  /* ------------------------------------ END-ADMIN ------------------------------------ */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
