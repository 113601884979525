import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs';
import { CompaniesViewModel } from 'src/app/models/backend-generated-models';
import { IExtractedUserFromJWT } from 'src/app/models/user.model';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';
//import { DialogAsignCompany } from '../../sites/sites.component';

@Component({
  selector: 'dialog-asign-to-company',
  templateUrl: 'dialog-asign-to-company.component.html',
  styleUrls: ['./dialog-asign-to-company.component.scss']

})
export class DialogAsignToCompanyComponent implements OnInit {
  switchCompany: boolean = true;

  isUserAdmin$ = this.userStateService.isUserAdmin;

  userRole$ = this.userStateService.currentUser$.pipe(map(x => x?.role))
  userRole:string | undefined;

  companiesList: CompaniesViewModel[] | undefined;
  parentCompaniesList: CompaniesViewModel[] | undefined;
  companiesForSelect: CompaniesViewModel[] | undefined;
  selectedParentCompany: CompaniesViewModel | null = null;

  formNewCompany: UntypedFormGroup = new UntypedFormGroup({
    companyName: new UntypedFormControl(null),
    parentCompanyId: new UntypedFormControl('')
  });

  formSelectedCompany: UntypedFormGroup = new UntypedFormGroup({
    companyId: new UntypedFormControl(null),
    parentCompanyId: new UntypedFormControl('')
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,

    private sitesService: SitesService,
    private usersService: UserService,
    private userStateService: UserStateService,
    private companiesService: CompaniesService,
    private jwtHelper: JwtHelperService,
    public dialogRef: MatDialogRef<DialogAsignToCompanyComponent>
  ) { }

  userCompany: string | undefined;
  userParentCompanyId: number | undefined;

  companiesCurrent: CompaniesViewModel[] | undefined;

  pageType: string = this.data.type;

  ngOnInit(): void {
    this.userStateService.currentUser$.pipe(map(x => x?.role)).subscribe(res => {
      this.userRole = res;
      if(this.userRole == 'administrator'){
        this.formSelectedCompany.get('companyId')?.disable();
      }
    });   

    this.companiesService.getCompaniesList().subscribe(res => {
      this.companiesList = res;
      this.parentCompaniesList = this.companiesList?.filter(x => x.parentCompanyId == null && x.parentCompanyName == null);
      this.companiesForSelect = this.companiesList;
    });
    
    this.usersService.getUserInformation((this.jwtHelper.decodeToken(localStorage.getItem("jwt")!) as IExtractedUserFromJWT).UUId).subscribe(res => {
      this.userCompany = res.userCompany?.companyName;
      this.userParentCompanyId = res.userCompany?.parentCompanyId || res.userCompany?.companyId;
      this.formNewCompany.controls['parentCompanyId'].setValue(this.userParentCompanyId);

      // this.companiesService.getCompaniesList()
      //   .pipe(
      //     /* Filter by current user parent company */
      //     map(x => x.filter(x => x.parentCompanyId == this.userParentCompanyId))
      //   ).subscribe(res => {
      //     this.companiesCurrent = res
      //   })
    })

  }

  onParentChange(obj: any){
    if(obj.value != null){
      this.companiesForSelect = [];
      this.selectedParentCompany = this.companiesList?.find(x => x.companyId == obj.value) ?? null;
      if(this.selectedParentCompany != null){
        this.companiesForSelect?.push(this.selectedParentCompany);
      }
      let childCompanies = this.companiesList?.filter(x => x.parentCompanyId == obj.value);
      if(childCompanies != null){
        this.companiesForSelect = this.companiesForSelect?.concat(childCompanies);
      }
      this.formSelectedCompany.get('companyId')?.enable();
    }
    if(this.userRole == 'administrator' && obj.value == null){
      this.formSelectedCompany.get('companyId')?.disable();
    }
  }

  submit() {

    if(this.data.type == 'sites'){
      if (this.switchCompany == true) {
        ((this.data.array) as string[])?.forEach(siteUUID => {
          if(this.formSelectedCompany.get('companyId')?.value){
            this.sitesService
            .associateCompanyForSite(siteUUID, { companyId: this.formSelectedCompany.get('companyId')?.value })
            .subscribe(res => { console.log("subscribed", res) })
          } else {
            this.sitesService
            .associateCompanyForSite(siteUUID, { companyId: this.formSelectedCompany.get('parentCompanyId')?.value })
            .subscribe(res => { console.log("subscribed", res) })
          }
        })
      } else {
        this.companiesService.createCompany(this.formNewCompany.value).subscribe((res: any) => {
          ((this.data.array) as string[])?.forEach(siteUUID => {
            this.sitesService.associateCompanyForSite(siteUUID, { companyId: res }).subscribe(res => { console.log("subscribed", res) })
          })
        })
      }
    }

    if(this.data.type == 'users'){

      if (this.switchCompany == true) {
        if(this.formSelectedCompany.get('companyId')?.value){
          this.usersService.associateCompanyForUsers({
            companyToAssociate: this.formSelectedCompany.get('companyId')?.value,
            usersToAssociate: ((this.data.array) as string[])
          })
          .subscribe(res => { console.log("subscribed", res) })
        } else {
          this.usersService.associateCompanyForUsers({
            companyToAssociate: this.formSelectedCompany.get('parentCompanyId')?.value,
            usersToAssociate: ((this.data.array) as string[])
          })
          .subscribe(res => { console.log("subscribed", res) })
        }
        
      } else {
        this.companiesService.createCompany(this.formNewCompany.value).subscribe((res: any) => {
          this.usersService
            .associateCompanyForUsers({
              companyToAssociate: res,
              usersToAssociate: ((this.data.array) as string[])
            })
            .subscribe(res => { console.log("subscribed", res) })
        });
      }

    }
    
  }
}
