import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CheckStatus, SiteResponseModel, TiccByUuidViewModel, TiccCheckExtendedViewModel } from 'src/app/models/backend-generated-models';
import { TiccsService } from 'src/app/services/api/ticcs.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { Location } from '@angular/common';
import { MyFilesService } from 'src/app/services/api/myfiles.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/helpers/loading.service';

export interface Observations {
  text: string,
  isChecked: boolean
}

@Component({
  selector: 'app-check-create',
  templateUrl: './check-create.component.html',
  styleUrls: ['./check-create.component.scss']
})
export class CheckCreateComponent implements OnInit, OnDestroy {
  ticcUuid = this.route.snapshot.paramMap.get('uuid');

  newCheck: UntypedFormGroup = new UntypedFormGroup({
    status: new UntypedFormControl(),
    checkAttachmentsFileIDs: new UntypedFormArray([]),
    notes: new UntypedFormControl(""),
    checkObservationsData: new UntypedFormControl(''),
    ticcUUID: new UntypedFormControl(this.ticcUuid),
    checkEventUuid: new UntypedFormControl(localStorage.getItem("checkEventUuid"), { nonNullable: true }),
  });

  availableOptions = [CheckStatus.Compliant, CheckStatus.NonCompliant];

  selected = CheckStatus.Compliant;

  isConfirmed: boolean = false;

  notesEnabled: boolean = false;

  eventsWarnigMessage: string | undefined;
  isEventsWarnig: boolean = false;

  observationData = JSON.parse('{"score": "3", "questions": [{"key": "The flor is clean", "value": "on"}, {"key": "The air is fresh", "value": "on"}]}');

  private win: any
  private port: any

  pickedFiles: any[] = [];

  ticcInformation: TiccCheckExtendedViewModel | undefined;
  ticcInformationTable: TiccByUuidViewModel | undefined;

  site: SiteResponseModel | undefined;

  selectedFiles!: string[];
  selFiles!: FileList | null;
  formData: FormData | undefined;

  observations: Observations[] = [];
  token?: string;
  selectedFilesOneDriveIds: string[] = [];
  pickerData: any;


  attachmentsSelector = "myFiles";
  creatingCheck = false;

  attachmentType: string | undefined;
  breadcrumbsPageType: string | null = "";

  get canCreateCheck() {
    if (this.creatingCheck) return false;

    if (this.ticcInformationTable?.ticcRequirements?.certificate)
      if (!this.selectedFiles?.length && !this.selectedFilesOneDriveIds?.length) return false;

    return !((this.isConfirmed == this.isEventsWarnig) && !this.isConfirmed || this.isEventsWarnig)
  } 

  constructor(
    private ticcsService: TiccsService,
    private sitesService: SitesService,
    private siteStateService: SiteStateService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private myFilesService: MyFilesService

  ) {

    this.ticcsService.getCheckInformationByTicc(this.ticcUuid).subscribe((res) => {
      console.log("ticc information", res)
      this.ticcInformation = res;

      res.ticcObservations?.forEach(element => {
        this.observations.push({ text: element, isChecked: false })
      })

      // this.newCheck.get('notes')?.patchValue(res.ticcTypeConfirmationText)
    }, error => {
      this.eventsWarnigMessage = error.error.Message;
      this.isEventsWarnig = true;
    })

    this.ticcsService.getTiccInformation(this.ticcUuid).subscribe((res) => {
      this.ticcInformationTable = res;
      console.log("information table", res)

      this.getAttachmentTypeForUrl();

      res.observarions?.forEach(element => {
        this.observations.push({ text: element.text!, isChecked: false })
      })

      this.sitesService.getSiteDetailByUUID(this.ticcInformationTable?.siteUuid!).subscribe((res) => {
        this.site = res as SiteResponseModel;
      })

    });
  }
  ngOnDestroy(): void {
    localStorage.removeItem("checkEventUuid");
    localStorage.removeItem("breadcrumbs_pageType");

    window.removeEventListener("message", this.messageEventListener);
  }

  ngOnInit(): void {
    this.breadcrumbsPageType = localStorage.getItem("breadcrumbs_pageType");

    this.myFilesService.getToken().subscribe(rest => this.token = rest.accessToken );
    this.myFilesService.getPickerData().subscribe(rest => this.pickerData = rest );
  }

  getAttachmentTypeForUrl() {
    if (this.breadcrumbsPageType == 'home') {
      this.attachmentType = "home";
      return;
    }

    switch(this.ticcInformationTable?.attachmentType) {
      case "Asset": {
        this.attachmentType = "assets";
        break;
      }
      case "Site": {
        this.attachmentType = "sites";
        break;
      }
      case "Board": {
        this.attachmentType = "boards";
        break;
      }
      case "User": {
        this.attachmentType = "users";
        break;
      }
      default: {
        this.attachmentType = "home";
        break;
      }
    }
  }

  goBack() {
    this.location.back();
  }


  @ViewChild('files') filesInput: any | undefined;


  removeFile(fileIndex: number) {
    this.selectedFiles.splice(fileIndex, 1);

    // this.filesInput?.nativeElement.files.splice(fileIndex, 1);

    /* console.log("files", this.filesInput.nativeElement.files)
    if (!this.filesInput.nativeElement.files) return */

    // Array.from(this.filesInput.nativeElement.files).splice(fileIndex, 1)
    /* this.filesInput?.files.forEach(element => {

    }); */

    // console.log("files", this.filesInput.nativeElement.files)
    // this.filesInput.splice()
    // this.filesInput



  }

  removeLaunchPickerFile(LaunchPickerFileIndex: number) {
    var id = this.pickedFiles[LaunchPickerFileIndex].id;

    this.pickedFiles.splice(LaunchPickerFileIndex, 1);
    this.selectedFilesOneDriveIds.splice(this.selectedFilesOneDriveIds.indexOf(id), 1);
  }

  getCheckStatusLabel(option: CheckStatus) {
    switch (option) {
      case CheckStatus.Compliant:
        return "Compliant";
      case CheckStatus.NonCompliant:
        return "Non Compliant";
      default:
        throw new Error("Unsupported option");
    }
  }

  fileSelectionChanged(event: Event) {
    if (!this.selectedFiles) this.selectedFiles = [];

    const element = event.currentTarget as HTMLInputElement

    this.selFiles = element.files;

    let fileList: FileList | null = element.files;

    if (fileList) {
      for (let itm in fileList) {

        let item: File = fileList[itm]

        if ((itm.match(/\d+/g) != null) && (!this.selectedFiles.includes(item['name']))) {
          this.selectedFiles.push(item['name']);
        }
      }
    }
  }

  async submit() {

    if(this.canCreateCheck == false) return;

    console.log("1")
    if (!this.ticcInformationTable?.siteUuid) return;

    // DELETE 'SCORE' DATA WE GET FROM OBSERVABLES
    console.log("2")
    if (this.observationData.score) delete this.observationData.score;

    // FILE UPLOADING
    console.log("3")

    this.creatingCheck = true;

    (this.newCheck.get('checkAttachmentsFileIDs') as UntypedFormArray).clear()

    await this.uploadFiles();
    await this.copyCheckAttachment()
    await this.createNewCheck(this.newCheck.value);

    this.creatingCheck = false;
  }

  private createNewCheck(newCheck: any) {
    console.log("form 1", newCheck);

    this.newCheck.patchValue({ 'status': this.selected })
    this.newCheck.patchValue({ 'checkEventUuid': this.ticcInformation?.ticcCheckEventUuid })
    this.newCheck.patchValue({ 'checkObservationsData': JSON.stringify(this.observations) })

    return this.ticcsService.postNewCheck(this.newCheck.value).toPromise().then((res) => {
      localStorage.removeItem("checkEventUuid");
      console.log("resonse from backend", res);
      this.router.navigate(['ticcs', this.ticcUuid]);
    })
  }

  private copyCheckAttachment() {
    console.log("copyCheckAttachment");

    if (!(this.selectedFilesOneDriveIds.length > 0)) return;

    console.log(this.selectedFilesOneDriveIds)
    return this.ticcsService.copyCheckAttachment(this.selectedFilesOneDriveIds,this.ticcInformationTable?.siteUuid as string)
      .toPromise().then( copyedAttachmentsIds => {
      console.log("copy file copyedAttachmentsIds", copyedAttachmentsIds);
      copyedAttachmentsIds?.forEach((element: any) => {
        if (element) {
          (this.newCheck.get('checkAttachmentsFileIDs') as UntypedFormArray).push(new UntypedFormControl(element));
        }
      });
    });
  }

  private uploadFiles() {
    console.log("uploadFiles");

    if (!this.selFiles) return Promise.resolve(
      console.log("no files")
    );

    let selectedFilesArray = Array.from(this.selFiles!);

    var promises: any[] = [];

    selectedFilesArray.forEach((file: any) => {
      let fd = new FormData();
      fd.append('File', file)
      fd.append('SiteUuid', this.ticcInformationTable?.siteUuid!)

      promises.push(
          this.ticcsService.uploadCheckAttachment(fd).toPromise().then(
          (res) => {
            console.log("update file res", res);

            (this.newCheck.get('checkAttachmentsFileIDs') as UntypedFormArray).push(new UntypedFormControl(res));
          }
        )
      );
    });

    return Promise.all(promises);
  }

  async launchPicker() {
    this.win = window.open("", "Picker", "width=800,height=600");

    let options = JSON.parse(JSON.stringify(this.pickerData.params));
    options.entry.oneDrive.files.folder = this.pickerData.params.entry.oneDrive.files.folder.replace("{siteName}", this.site?.siteId + '-' + this.site?.siteName as string);
    options.messaging.origin = environment.url;
    options.authentication = {};
    console.log(options)

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(options),
    });

    const url = this.combine(this.pickerData.baseUrl, `_layouts/15/FilePicker.aspx?${queryString}`);

    const form = this.win.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");
    this.win?.document.body.append(form);

    const input = document.createElement("input");
    input.setAttribute("type", "hidden")
    input.setAttribute("name", "access_token");
    input.setAttribute("value", this.token as string);
    form.appendChild(input);

    form.submit();

    window.addEventListener("message", this.messageEventListener);
  }

  private messageEventListener = (event: any) => {
    if (event.source && event.source === this.win) {
      const message = event.data;

      console.log(this.pickerData?.params?.messaging?.channelId)
      if (message.type === "initialize" && message.channelId === this.pickerData?.params?.messaging?.channelId as string) {
        this.port = event.ports[0];
        this.port.addEventListener("message", this.messageListener.bind(this));
        this.port.start();

        this.port.postMessage({
          type: "activate",
        });
      }
    }
  }

  private async messageListener(message: MessageEvent) {
    var token = this.token;
    switch (message.data.type) {
      case "notification":
        console.log(`notification: ${message.data}`);
        break;

      case "command":
        this.port?.postMessage({
          type: "acknowledge",
          id: message.data.id,
        });

        const command = message.data.data;

        switch (command.command) {
          case "authenticate":
            if (typeof token !== "undefined" && token !== null) {
              this.port?.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "token",
                  token,
                }
              });
            } else {
              console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
            }

            break;

          case "close":
            this.win?.close();
            break;

          case "pick":
            console.log("pick", command.items);
            command.items.forEach((itemToAdd: any) => {
              let itemExists = false;
              let itemToAddString = JSON.stringify(itemToAdd);

              for (let i = 0; i < this.pickedFiles.length; i++) {
                if (JSON.stringify(this.pickedFiles[i]) === itemToAddString) {
                  itemExists = true;
                  break;
                }
              }

              if (!itemExists) {
                this.pickedFiles.push(itemToAdd);
                this.selectedFilesOneDriveIds.push(itemToAdd.id);
              }

            });

            console.log(this.pickedFiles);
            this.port?.postMessage({
              type: "result",
              id: message.data.id,
              data: {
                result: "success",
              },
            });

            this.win?.close();
            break;

          default:
            console.warn(`Unsupported command: ${JSON.stringify(command)}`);
            this.port?.postMessage({
              result: "error",
              error: {
                code: "unsupportedCommand",
                message: command.command
              },
              isExpected: true,
            });
            break;
        }
        break;
    }
  }

  private combine(baseUrl: string, path: string): string {
    if (baseUrl.endsWith("/") && path.startsWith("/")) {
      return baseUrl + path.substring(1);
    } else if (!baseUrl.endsWith("/") && !path.startsWith("/")) {
      return baseUrl + "/" + path;
    } else {
      return baseUrl + path;
    }
  }
}
