<!-- Role {{userRole$ | async}} -->

<div [ngSwitch]="userRole$ | async">

    <!-- ADMINISTRATOR -->
    <div *ngSwitchCase="'administrator'" class="dialog">
        <mat-card class="p-0 card">
            <mat-card-header class="p-3 site-creation-company">
                <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                    <span class="w-100 sites-dialog-title">Assign to Company</span>
                    <span class="w-100 sites-count">{{this.data.array?.length}} {{this.pageType}} selected</span>

                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="p-3">
                <div class="row">
                    <div class="col-12 assign-to-company">
                        <div class="row">
                            <div class="col-12 assign-to-company-options my-3 px-2 d-flex justify-content-center">
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div>
                        </div>

                        <div class="col-12">

                            <div [formGroup]="formSelectedCompany" *ngIf="switchCompany; else newCompany">
                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Parent Company
                                        </mat-card-title>
                                    </mat-card-header>


                                    <mat-card-content><!-- TODO: [ngClass]="{'select-not-admin': isUserAdmin$}" -->
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Select Parent Company</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select
                                                formControlName="parentCompanyId" (selectionChange)="onParentChange($event)"><!--  [disabled]="isUserAdmin$" -->
                                                <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                                    [value]="parentCompany.companyId">
                                                    {{parentCompany.companyName}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>



                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Select Company</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select *ngIf="selectedParentCompany" [(ngModel)]="selectedParentCompany.companyId" formControlName="companyId"><!--  [disabled]="isUserAdmin$" -->
                                                <mat-option *ngFor="let company of companiesForSelect"
                                                    [value]="company.companyId"
                                                    [ngClass]="company.companyName === selectedParentCompany.companyName ? 'parentOption' : 'childOption'">
                                                    {{company.companyName}}
                                                </mat-option>
                                            </mat-select>

                                            <mat-select *ngIf="!selectedParentCompany" formControlName="companyId">
                                                <mat-option *ngFor="let company of companiesForSelect"
                                                    [value]="company.companyId">
                                                    {{company.companyName}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </div>


                            <ng-template #newCompany [formGroup]="formNewCompany">

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Parent Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content><!-- TODO: [ngClass]="{'select-not-admin': isUserAdmin$}" -->
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Select Parent Company</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select
                                                formControlName="parentCompanyId"><!--  [disabled]="isUserAdmin$" -->
                                                <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                                    [value]="parentCompany.companyId">
                                                    {{parentCompany.companyName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>



                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Add Company Name</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <input matInput type="text" formControlName="companyName">
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </ng-template>
                        </div>

                        <div class="col-12 py-3 dialog-text">
                            With this action you are going to assign all the selected {{this.pageType}} to the chosen company.
                        </div>
                    </div>
                </div>

            </mat-card-content>


            <div class="col-12">
                <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
                    <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
                    <button class="col-4 dialog-blue-action-button" (click)="submit()" [matDialogClose]="true"
                        mat-button>ASSIGN</button>
                </div>
            </div>


        </mat-card>
    </div>










    <!-- COMPANY MANAGER -->
    <div *ngSwitchCase="'company manager'" class="dialog">
        <mat-card class="p-0 card">
            <mat-card-header class="p-3 site-creation-company">
                <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                    <span class="w-100 sites-dialog-title">Assign to Company</span>
                    <span class="w-100 sites-count">{{this.data.array?.length}} {{this.pageType}} selected</span>

                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="p-3">
                <div class="row">
                    <div class="col-12 assign-to-company">
                        <div class="row">
                            <div class="col-12 assign-to-company-options my-3 px-2 d-flex justify-content-center">
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div [formGroup]="formSelectedCompany" *ngIf="switchCompany; else newCompany">
                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Parent Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content
                                        class="select-not-admin">
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">{{userCompany}}</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select [disabled]="true"></mat-select>
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Select Company</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select formControlName="companyId">
                                                <mat-option *ngFor="let company of companiesForSelect"
                                                    [value]="company.companyId">
                                                    {{company.companyName}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <ng-template #newCompany [formGroup]="formNewCompany">

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Parent Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content
                                        class="select-not-admin">
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">{{userCompany}}</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select [disabled]="true"></mat-select>
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Add Company Name</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <input matInput type="text" formControlName="companyName">
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </ng-template>
                        </div>

                        <div class="col-12 py-3 dialog-text">
                            With this action you are going to assign all the selected {{this.pageType}} to the chosen company.
                        </div>
                    </div>
                </div>

            </mat-card-content>

            <div class="col-12">
                <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
                    <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
                    <button class="col-4 dialog-blue-action-button" (click)="submit()" [matDialogClose]="true"
                        mat-button>ASSIGN</button>
                </div>
            </div>

        </mat-card>
    </div>











    <!-- USER -->
    <div *ngSwitchCase="'user'" class="dialog">
        <mat-card class="p-0 card">
            <mat-card-header class="p-3 site-creation-company">
                <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                    <span class="w-100 sites-dialog-title">Assign to Company</span>
                    <span class="w-100 sites-count">{{this.data.array?.length}} {{this.pageType}} selected</span>

                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="p-3">
                <div class="row">
                    <div class="col-12 assign-to-company">
                        <div class="row">
                            <div class="col-12 assign-to-company-options my-3 px-2 d-flex justify-content-center">
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div [formGroup]="formSelectedCompany" *ngIf="switchCompany; else newCompany">

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Select Company</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <mat-select formControlName="companyId">
                                                <mat-option *ngFor="let company of companiesForSelect"
                                                    [value]="company.companyId">
                                                    {{company.companyName}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <ng-template #newCompany [formGroup]="formNewCompany">

                                <mat-card class="my-3">
                                    <mat-card-header>
                                        <mat-card-title>
                                            Company
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content>
                                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">
                                            <mat-label style="color: black">Add Company Name</mat-label>

                                            <span matPrefix>
                                                <mat-icon class="icon-color">
                                                    description
                                                </mat-icon>
                                            </span>

                                            <input matInput type="text" formControlName="companyName">
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-card>
                            </ng-template>
                        </div>

                        <div class="col-12 py-3 dialog-text">
                            With this action you are going to assign all the selected {{this.pageType}} to the chosen company.
                        </div>
                    </div>
                </div>

            </mat-card-content>

            <div class="col-12">
                <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
                    <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
                    <button class="col-4 dialog-blue-action-button" (click)="submit()" [matDialogClose]="true"
                        mat-button>ASSIGN</button>
                </div>
            </div>

        </mat-card>
    </div>



</div>
