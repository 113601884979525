<mat-drawer-container class="mb-5 sidenav-general-container" autosize>
    <mat-drawer #drawer class="sidenav-content-container d-flex flex-wrap" mode="over" fullscreen>
        <div class="col-12 d-flex align-items-center filter-header">
            <div class="col-8 offset-1 filters-title">
                Apply Filters
            </div>

            <div class="col-2 filters-count-container d-flex flex-wrap">
                <span class="filters-count">{{selectionCompanies.selected.length +
                    selectionSites.selected.length}}</span>
                <span class="filters-text w-100">FILTERS</span>
            </div>
        </div>

        <div class="col-12">
            <mat-accordion class="filters-accordion" multi>
                <mat-expansion-panel class="w-100">

                    <mat-expansion-panel-header>
                        <div class="col-10 d-flex align-items-center">
                            <mat-panel-title class="user-title">
                                <mat-icon>apartment</mat-icon>&nbsp;
                                Companies
                            </mat-panel-title>
                        </div>
                        <div class="col-2">
                            <div class="count-expansion-pannel">
                                {{selectionCompanies.selected.length}}
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row" *ngFor=" let company of filter$?.filteredCompanies; let i = index">

                        <div class="col-12 py-2">
                            <mat-checkbox class="example-margin"
                                (change)="selectionCompanies.toggle(getMappedCompanies(filter$?.filteredCompanies!)[i])"
                                [checked]="selectionCompanies.isSelected(getMappedCompanies(filter$?.filteredCompanies!)[i])">
                                <span *ngIf="company != null">
                                    {{company.name}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="col-12">
            <mat-accordion class="filters-accordion" multi>
                <mat-expansion-panel class="w-100">

                    <mat-expansion-panel-header>
                        <div class="col-10 d-flex align-items-center">
                            <mat-panel-title class="user-title">
                                <mat-icon>corporate_fare</mat-icon>&nbsp;
                                Sites
                            </mat-panel-title>
                        </div>
                        <div class="col-2">
                            <div class="count-expansion-pannel">
                                {{selectionSites.selected.length}}
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row m-0" *ngFor=" let company of filter$?.filteredSites; let i = index">
                        <div class="col-12 py-2">
                            <mat-checkbox class="example-margin"
                                (change)="selectionSites.toggle(getMappedSites(filter$?.filteredSites!)[i])"
                                [checked]="selectionSites.isSelected(getMappedSites(filter$?.filteredSites!)[i])">
                                <span *ngIf="company != null">
                                    {{company.name}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="row m-0">
            <div class="col-6 filter-button-container">
                <button mat-button class="filter-button cancel-filter-button"
                    (click)="drawer.toggle();clearSelectionFilters();">
                    CANCEL
                </button>
            </div>
            <div class="col-6 filter-button-container" (click)="filter();drawer.toggle();">
                <button mat-button class="filter-button apply-filter-button">
                    APPLY FILTERS
                </button>
            </div>
        </div>


    </mat-drawer>



    <!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
    <div class="create-board-button-container d-flex justify-content-end">
        <button routerLink="/boards/create" mat-button class="fixed-button d-flex"><mat-icon>add_circle</mat-icon>
            <div class="d-none d-md-block">&nbsp;&nbsp;NEW BOARD</div>
        </button>
    </div>

    <div class="page-top-breadcrumbs">
        BOARDS
    </div>

    <hr class="page-top-breadcrumbs-underline">

    <h1 class="page-top-title">
        Boards
    </h1>
    <!-- ---------------------------------------------------------- FILTERS - GROUP BY ---------------------------------------------------------- -->
    <div class="row m-0 d-flex justify-content-center">
        <div class="col-5 col-md-6 col-lg-3 filter-button-container">
            <button (click)="drawer.toggle()" mat-button class="open-filter-button open-filtering">
                <mat-icon>tune</mat-icon>&nbsp;
                Filter
            </button>
        </div>
        <div class="col-7 col-md-6 col-lg-3 filter-button-container">
            <button mat-button class="open-filter-button open-grouping" [matMenuTriggerFor]="grouping">
                <mat-icon>sort</mat-icon>&nbsp;
                Group By {{selectedGroup}}
            </button>
            <mat-menu #grouping="matMenu">
                <button mat-menu-item (click)="selectedGroup = 'Company(asc)'"><mat-icon>trending_up</mat-icon>A-Z
                    Company asc</button>
                <button mat-menu-item (click)="selectedGroup = 'Site(asc)'"><mat-icon>trending_up</mat-icon>A-Z
                    Site asc</button>
                <button mat-menu-item (click)="selectedGroup = 'Company(desc)'"><mat-icon>trending_down</mat-icon>Z-A
                    Company desc</button>
                <button mat-menu-item (click)="selectedGroup = 'Site(desc)'"><mat-icon>trending_down</mat-icon>Z-A Site
                    desc</button>
            </mat-menu>
        </div>
    </div>


    <div *ngIf="selectionCompanies.selected.length > 0 || selectionSites.selected.length > 0"
        class="row m-0 filter-count-container">
        <mat-chip-list class="d-flex justify-content-center">
            <mat-chip *ngIf="selectionCompanies.selected.length > 0">
                <div class="filter-count-circle px-2 me-2">
                    {{selectionCompanies.selected.length}}
                </div>
                Companies
            </mat-chip>

            <mat-chip *ngIf="selectionSites.selected.length > 0">
                <div class="filter-count-circle px-2 me-2">
                    {{selectionSites.selected.length}}
                </div>
                Sites
            </mat-chip>
        </mat-chip-list>
    </div>



    <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">

        <div *ngIf="selectedGroup == 'Company(asc)' || selectedGroup == 'Company(desc)'"
            class="row p-0 m-0 content w-100">

            <mat-accordion class="boards-container">
                <mat-expansion-panel (opened)="setCompaniesBoardsList(company.companyId)" hideToggle
                    [ngClass]="{'mt-3': company.companyId}"
                    *ngFor="let company of companiesGroupList; first as isFirst;" [expanded]="isFirst">
                    <mat-expansion-panel-header *ngIf="company.companyId != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>

                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{company.companyName}}</div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{company.count}} boards</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getCompaniesBoardsList(company.companyId)"
                        class="row circuit-container m-0">

                        <div class="col-12 col-lg-6 col-xl-4 pb-3 d-flex circuit-container-single-box" [class.not-display]="isLoading$ | async"
                            *ngFor="let board of item | async">

                            <div class="col-9">
                                <div class="col-12 mt-3 mb-5 d-flex board-name">
                                    <div class="col-10 d-flex align-items-center">{{board.name}}</div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Description:&nbsp;</span>
                                    </div>
                                    <div class="col-8"><span class="parameter">{{board.description}}</span></div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Circuits:&nbsp;</span></div>
                                    <div class="col-8"><span class="parameter">{{board.circuitsCount}}</span></div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Last Check:&nbsp;</span></div>
                                    <div class="col-8"><span class="parameter">{{board.lastCheck |
                                            date:'dd-MM-YYYY'}}</span></div>
                                </div>
                            </div>

                            <div class="col-2 offset-1 d-flex align-items-center flex-wrap">

                                <div class="col-12 single-action-button">
                                    <button [routerLink]="['/boards/' + board.uuid]" mat-button class="w-100">
                                        <mat-icon class="color-blue">visibility</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 single-action-button">
                                    <button mat-button (click)="openDeleteDialog(board)" class="w-100">
                                        <mat-icon class="color-red">delete</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 single-action-button">
                                    <span class="color-grey d-flex flex-wrap justify-content-center align-items-center">
                                        <mat-icon class="">check_circle</mat-icon>
                                        &nbsp;{{board.circuitsCount}}&nbsp;ticcs
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <mat-paginator [length]="company.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page"
                        (page)="setCompaniesBoardsListWithPagination(company.companyId, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>

        </div>



        <div *ngIf="selectedGroup == 'Site(asc)' || selectedGroup == 'Site(desc)'" class="row p-0 m-0 content w-100">

            <mat-accordion class="boards-container">
                <mat-expansion-panel (opened)="setSitesBoardsList(site.siteUuid)" hideToggle
                    [ngClass]="{'mt-3': site.siteUuid}" *ngFor="let site of sitesGroupList; first as isFirst" [expanded]="isFirst">
                    <mat-expansion-panel-header *ngIf="site.siteUuid != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>

                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{site.siteName}}</div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{site.count}} boards</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getSitesBoardsList(site.siteUuid)" class="row circuit-container m-0">

                        <div class="col-12 col-lg-6 col-xl-4 pb-3 d-flex circuit-container-single-box" [class.not-display]="isLoading$ | async"
                            *ngFor="let board of item | async">

                            <div class="col-9">
                                <div class="col-12 mt-3 mb-5 d-flex board-name">
                                    <div class="col-10 d-flex align-items-center">{{board.name}}</div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Description:&nbsp;</span>
                                    </div>
                                    <div class="col-8"><span class="parameter">{{board.description}}</span></div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Circuits:&nbsp;</span></div>
                                    <div class="col-8"><span class="parameter">{{board.circuitsCount}}</span></div>
                                </div>

                                <div class="col-12 mb-2 d-flex">
                                    <div class="col-4"><span class="parameter-definition">Last Check:&nbsp;</span></div>
                                    <div class="col-8"><span class="parameter">{{board.lastCheck |
                                            date:'dd-MM-YYYY'}}</span></div>
                                </div>
                            </div>

                            <div class="col-2 offset-1 d-flex align-items-center flex-wrap">

                                <div class="col-12 single-action-button">
                                    <button [routerLink]="['/boards/' + board.uuid]" mat-button class="w-100">
                                        <mat-icon class="color-blue">visibility</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 single-action-button">
                                    <button mat-button (click)="openDeleteDialog(board)" class="w-100">
                                        <mat-icon class="color-red">delete</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 single-action-button">
                                    <span class="color-grey d-flex flex-wrap justify-content-center align-items-center">
                                        <mat-icon class="">check_circle</mat-icon>
                                        &nbsp;{{board.circuitsCount}}&nbsp;ticcs
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <mat-paginator [length]="site.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page" (page)="setSitesBoardsListWithPagination(site.siteUuid, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>

        </div>


    </cdk-virtual-scroll-viewport>