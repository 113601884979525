import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { ModuleBasicViewModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) { }

  getModulesList () {
    return this.http.get<ModuleBasicViewModel[]>(`${environment.apiUrl}Modules`).pipe(
      /* tap(
        (response) => {
          console.log(response)
        }
      ), */
      catchError((error: HttpErrorResponse) => {
        
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }
  
}
