<!-- <section class="example-section">
    <mat-checkbox class="example-margin">Check me!</mat-checkbox>
    <mat-checkbox class="example-margin" [disabled]="true">Disabled</mat-checkbox>
</section> -->

<!-- <section class="example-section">
    <span class="example-list-section">
        <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
            {{task.name}}
        </mat-checkbox>
    </span>
    <span class="example-list-section">
        <ul style="list-style: none">
            <li *ngFor="let subtask of task.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                    (ngModelChange)="updateAllComplete()">
                    {{subtask.name}}
                </mat-checkbox>
            </li>
        </ul>
    </span>
</section> -->

<mat-accordion>
    <mat-expansion-panel id="employees" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title>
                <mat-checkbox (click)="$event.stopPropagation();" (change)="trigger(this)">
                    &nbsp;Employees
                </mat-checkbox>
            </mat-panel-title>

        </mat-expansion-panel-header>


        <div class="container">
            <div class="row">
                <!-- <div *ngFor="let item of data">
                    {{item.fullName}}
                </div> -->

                <div *ngFor="let item of data.assignedTo" class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    {{item.fullName}}
                </div>

                <!-- <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div> -->
                

            </div>
        </div>

    </mat-expansion-panel>
    <!-- <mat-expansion-panel id="employees" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title>
                <mat-checkbox></mat-checkbox>
                &nbsp;Employees
            </mat-panel-title>

        </mat-expansion-panel-header>


        <div class="container">
            <div class="row">

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>
                

            </div>
        </div>

    </mat-expansion-panel>

    <mat-expansion-panel id="service-providers" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title>
                <mat-checkbox></mat-checkbox>
                &nbsp;Service&nbsp;Providers
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container">
            <div class="row">

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>
            </div>
        </div>

    </mat-expansion-panel>
    
    <mat-expansion-panel id="site-managers" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title>
                <mat-checkbox></mat-checkbox>
                &nbsp;Site&nbsp;Managers
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container">
            <div class="row">

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>
            </div>
        </div>

    </mat-expansion-panel>
    
    <mat-expansion-panel id="site-owners" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title>
                <mat-checkbox></mat-checkbox>
                &nbsp;Site&nbsp;Owners
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container">
            <div class="row">

                <div class="col-12 col-md-6 my-2 d-flex align-items-center">
                    <mat-checkbox></mat-checkbox> 
                    <div class="circle-image"></div>
                    Jacob Wathever Wathever
                </div>
            </div>
        </div>

    </mat-expansion-panel> -->
</mat-accordion>