import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from "@angular/forms";
import { ThemePalette } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

export interface keyable {
  [key: string]: any  
}

export interface IAssignedTo {
  fullName: string,
  email: string,
  photo: string
}

@Component({
  selector: 'app-modal-dashboard',
  templateUrl: './modal-dashboard.component.html',
  styleUrls: ['./modal-dashboard.component.scss']
})
export class ModalDashboardComponent implements OnInit {

  panelOpenState = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private ticcService : TiccService
    ) {
    }
    

    // myData = this.data.
  ngOnInit(): void {
    console.log("Modal data", this.data)
    // throw new Error('Method not implemented.');
  
  }


    task: Task = {
      name: 'Indeterminate',
      completed: false,
      color: 'primary',
      subtasks: [
        {name: 'Primary', completed: false, color: 'primary'},
        {name: 'Accent', completed: false, color: 'accent'},
        {name: 'Warn', completed: false, color: 'warn'},
      ],
    };
  
    allComplete: boolean = false;
  
    updateAllComplete() {
      this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    }
  
    someComplete(): boolean {
      if (this.task.subtasks == null) {
        return false;
      }
      return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
    }
  
    setAll(completed: boolean) {
      this.allComplete = completed;
      if (this.task.subtasks == null) {
        return;
      }
      this.task.subtasks.forEach(t => (t.completed = completed));
    }

    trigger(event : any) {
      console.log("trigger", event)

    }
}


