import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { AssetTypeModuleViewModel, AssetTypesFullInfoViewModel } from 'src/app/models/backend-generated-models';
import { AdminService } from 'src/app/services/api/admin.service';
import { mergeMap, of } from 'rxjs';

@Component({
  selector: 'app-asset-types',
  templateUrl: './asset-types.component.html',
  styleUrls: ['./asset-types.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetTypesComponent{

  assetTypeData$ = this.adminSevice.getAssettypes();

  displayedColumns = ['assetTypeName', 'assetTypeModules', 'assetCount', 'createdAt', 'updatedAt', 'actions'];

  constructor(
    private adminSevice: AdminService,
    public dialog: MatDialog,
  ) { }

  parseDate (date : Date) {
    return date.toLocaleString().split(',')[0];
  }

  deleteAssetType(element: AssetTypesFullInfoViewModel, assetTypeUuid: string){
    return this.dialog.open(DialogDeleteAssetTypeCompanyConfirmation, {
      width: '450px',
      data: element
    },)
      .afterClosed()
      .pipe(
        mergeMap((assetTypeUuid) => {
          if (assetTypeUuid) this.adminSevice.deleteAssetType(assetTypeUuid).subscribe();
          return of(assetTypeUuid);
        })
      )
      .subscribe((res) => {
        console.log("delted", res)
      })

  }

}

@Component({
  selector: 'dialog-delete-asset-type-confirmation',
  templateUrl: 'dialog-delete-asset-type-confirmation.html',
  styleUrls: ['./asset-types.component.scss']
})
export class DialogDeleteAssetTypeCompanyConfirmation {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AssetTypesFullInfoViewModel | undefined,
    public dialogRef: MatDialogRef<DialogDeleteAssetTypeCompanyConfirmation>
  ) {
  }
}
