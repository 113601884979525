import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AdministratorGuard } from '../guards/administrator.guard';

@Directive({
  selector: '[appIsAdministrator]'
})
export class IsAdministratorDirective implements OnInit{

  @Input() isAdminElement = false;

  constructor(
    private element: ElementRef,
    private adminGuard: AdministratorGuard
    ) {}

  ngOnInit(): void {
    this.adminGuard.canActivate().subscribe(res => {
      // is admin
      if (res) {
        if (this.isAdminElement) this.element.nativeElement.style.display = "";
        else this.element.nativeElement.style.display = "none";
      } 
      // not admin
      else {
        if (this.isAdminElement) this.element.nativeElement.style.display = "none";
        else this.element.nativeElement.style.display = "";
      }
    })
  }
}
