import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AdministratorGuard } from 'src/app/guards/administrator.guard';
import { UserBasicViewModel } from 'src/app/models/backend-generated-models';
import { IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/api/auth.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
  selector: 'app-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss']
})
export class AdminSidenavComponent implements OnInit {

  options = this.formBuilder.group({
    bottom: 0,
    fixed: false,
    top: 0,
  });

  userList!: [UserBasicViewModel] | any;

	currentUserData: IUser | null | undefined

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private userStateService: UserStateService,

  ) {
    this.userService.getModulesList().subscribe(data => {
      this.userList = data as [UserBasicViewModel];
      console.log("user list", this.userList)
    })
  }

  ngOnInit(): void {
		this.userStateService.currentUser$.subscribe(
			res => this.currentUserData = res
		)
  }

  togglerEventHandler(sidenav: any) {
    sidenav.toggle()
  }

  logout() {
    this.auth.logout();
  }
}
