import { Component, Inject, } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'dialog-circuit-delete-confirmation',
  templateUrl: 'dialog-circuit-delete-confirmation.html'

})
export class DialogCircuitDeleteConfirmation {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogCircuitDeleteConfirmation>
  ) { }
}