<button *ngIf="isUrlEmpty() && pageType != 'users'" [routerLink]="['/' + pageType + '/' + uuid + '/ticcs/create/' + siteUuid]" class="mt-3 button-center button-new-ticc w-100" mat-button style="text-align: center;">NEW TICC</button>

<cdk-virtual-scroll-viewport [itemSize]="50" class="container-fluid scrollbar-box">
    <div class="row p-0 m-0 content" *cdkVirtualFor="let ticc of (!filterBySiteUuid ? ticcsModelsSubject$ : ticcsModelsSubject$ | async); let i = index">
        <div class="item col-7 col-lg-2 ticc-name">
            {{ticc.ticcTypeName}}
        </div>

        <!-- DELETED ON LG -->
        <div class="d-flex d-lg-none col-5 action-buttons">
            <button [routerLink]="['/ticcs/' + ticc.ticcUUID]" mat-button class="icon-box view d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">visibility</mat-icon>
                <div class="w-100">VIEW</div>
            </button>
            <button
                [disabled]="!ticc.enableEditButton"
                [routerLink]="ticc.enableEditButton ? ['/ticcs/' + ticc.ticcUUID + '/edit'] : null"
                [queryParams]="{ lastUrl: pageType , uuid:  uuid }"
                mat-button
                class="icon-box edit d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">edit</mat-icon>
                <div class="w-100">EDIT</div>
            </button>
            <button
                [disabled]="!ticc.enableDeleteButton"
                (click)="openDeleteDialog(ticc, i)"
                mat-button
                class="icon-box delete d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">delete</mat-icon>
                <div class="w-100">DELETE</div>
            </button>
        </div>


        <div class="item col-12 col-lg-1 modules">
            <mat-chip-list>
                <mat-chip>{{ticc.ticcModule}}</mat-chip>
            </mat-chip-list>
        </div>

        <div class="item col-12 col-lg-4 requirements">
            <div class="row">

                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.legal}">
                        <mat-icon class="circle-icon" mat-icon>
                            gavel
                        </mat-icon>
                    </div>
                </div>
                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.certificate}">
                        <mat-icon class="circle-icon" mat-icon>
                            history_edu
                        </mat-icon>
                    </div>
                </div>
                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.responsiblePerson}">
                        <mat-icon class="circle-icon" mat-icon>
                            supervisor_account
                        </mat-icon>
                    </div>
                </div>
                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.competentPerson}">
                        <mat-icon class="circle-icon" mat-icon>
                            engineering
                        </mat-icon>
                    </div>
                </div>
                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.tagScan}">
                        <mat-icon class="circle-icon" mat-icon>
                            qr_code
                        </mat-icon>
                    </div>
                </div>
                <div class="col-2">
                    <div class="requirement-icon" [ngClass]="{'active-requirement': ticc.ticcRequirements?.observations}">
                        <mat-icon class="circle-icon" mat-icon>
                            receipt_long
                        </mat-icon>
                    </div>
                </div>

            </div>
        </div>

        <div class="item col-6 col-lg-1"><span class="italic ligth">Created At:</span><br>{{ticc.ticcCreatedAt}}</div>
        <div class="item col-6 col-lg-1"><span class="italic ligth">Updated At:</span><br>{{ticc.ticcUpdatedAt}}</div>
        <div class="item col-6 col-lg-1"><span class="italic ligth">Last Check:</span><br>{{ticc.lastCheckAt}}</div>
        <div class="item col-6 col-lg-1 assigned" *ngIf="ticc.ticcAssignedTo">Assigned</div>

        <div class="d-none d-lg-flex col-2 action-buttons">
            <button (click)="onViewClick(ticc.ticcUUID)" mat-button class="icon-box view d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">visibility</mat-icon>
                <div class="w-100">VIEW</div>
            </button>
            <button
                [disabled]="!ticc.enableEditButton"
                [routerLink]="ticc.enableEditButton ? ['/ticcs/' + ticc.ticcUUID + '/edit'] : null"
                [queryParams]="{ lastUrl: pageType , uuid:  uuid }"
                mat-button
                class="icon-box edit d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">edit</mat-icon>
                <div class="w-100">EDIT</div>
            </button>
            <button
                [disabled]="!ticc.enableDeleteButton"
                (click)="openDeleteDialog(ticc, i)"
                mat-button
                class="icon-box delete d-flex flex-wrap w-100 justify-content-center">
                <mat-icon class="w-100">delete</mat-icon>
                <div class="w-100">DELETE</div>
            </button>
        </div>

    </div>
</cdk-virtual-scroll-viewport>
