<mat-toolbar color="secondary" class="py-5">
 
    <div class="container-fluid m-0 p-0">
        <div class="row m-0 p-0">
            <div class="col-1">
                <button (click)="toggleModules()" mat-icon-button class="example-icon float-start"
                    aria-label="Example icon-button with menu icon">
                    <mat-icon>apps</mat-icon>
                </button>
            </div>

            <div class="col-8 offset-1 menu-logo-mobile">

                <!-- <div class="col-5">
                    <button class="menu-dropdowns" mat-button [matMenuTriggerFor]="companies">Companies</button>
                    <mat-menu #companies="matMenu">
                        <button *ngFor="let site of sitesList$ | async" mat-menu-item>
                            {{site.company}}
                        </button>
                    </mat-menu>
                </div>

                <div class="col-2 d-flex justify-content-center">
                    <div class="header-logo-image">
                    </div>
                </div>

                <div class="col-5">
                    <button
                        class="menu-dropdowns selectors"
                        mat-button
                        [matMenuTriggerFor]="sites">
                            {{ (currentSite$ | async)!.name || "Sites" }}
                    </button>
                    <mat-menu #sites="matMenu">
                        <button
                            *ngFor="let site of sitesList$ | async"
                            mat-menu-item
                            (click)="changeSite(site)"
                            class="main-menu-selector"
                            >
                            <span class="main-menu-selector-sitename">{{ site.name }}</span>
                            <br class="my-2">
                            <div *ngIf="site.city">
                                <mat-icon class="mx-0 main-menu-selector-city">pin_drop</mat-icon>
                                {{site.city}}
                            </div>
                        </button>
                    </mat-menu>
                </div> -->
            </div>

            <div class="col-1">
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>notifications</mat-icon>
                </button>
                <button (click)="toggleSidebar()" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>
    </div>

</mat-toolbar>

<app-admin-modules-carrousel *ngIf="areModulesDisplayed"></app-admin-modules-carrousel>