import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { DashboardEventsAndPagination } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) { }



  getTodayTiccsList(siteUuid: string | undefined, params: HttpParams) {
    return this.http.get<DashboardEventsAndPagination>(`${environment.apiUrl}Dashboard/${siteUuid}/today`, { params: params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getFutureTiccsList(siteUuid: string | undefined, params: HttpParams) {
    return this.http.get<DashboardEventsAndPagination>(`${environment.apiUrl}Dashboard/${siteUuid}/future`, { params: params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

}
