import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, mergeMap, Observable, of } from 'rxjs';
import { TiccViewModel } from 'src/app/models/backend-generated-models';
import { TiccsService } from 'src/app/services/api/ticcs.service';

@Component({
  selector: 'app-ticcs-list',
  templateUrl: './ticcs-list.component.html',
  styleUrls: ['./ticcs-list.component.scss']
})
export class TiccsListComponent implements OnInit {

  @Input() ticcsArray$ : Observable<TiccViewModel[]> = new Observable<TiccViewModel[]>();
  @Input() pageType : string = "";
  @Input() uuid : string | null = "";
  @Input() siteUuid : string | undefined = "";

  @Input() filterBySiteUuid : boolean = false;

  ticcs$: [string, [any]][] | undefined;
  ticcsModels$: any | undefined;

  ticcsModelsSubject$ = new BehaviorSubject<TiccViewModel[]>([])

  constructor(
    private ticcsService: TiccsService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.ticcsArray$.subscribe(res => {
      this.showNewTiccList(res);
    });
  }

  public showNewTiccList(newTiccs: TiccViewModel[]) {
    this.ticcsModelsSubject$.next(this.ticcsModels$ = newTiccs.map(p => {
      return {
        ticcUUID: p.ticcUUID,
        ticcTypeName: p.ticcTypeName,
        ticcModule: p.ticcModule,
        ticcAssignedTo: p.ticcAssignedTo,
        ticcRequirements: p.ticcRequirements,
        lastCheckAt: p.lastCheckAt,
        ticcCreatedAt: p.ticcCreatedAt,
        ticcUpdatedAt: p.ticcUpdatedAt,
        siteUUID: p.siteUUID,
        enableEditButton: p.enableEditButton,
        enableDeleteButton: p.enableDeleteButton,
      }
    }));
  }

  public isUrlEmpty() : boolean {
    if(this.pageType == "" || this.uuid == ""){
      return false;
    }
    else return true;
  }

  openDeleteDialog(ticc: TiccViewModel, i: number) {

    if (!ticc.enableDeleteButton) return;

    this.dialog.open(DialogTiccDelete, {
      width: '800px',
      data: ticc,
      panelClass: 'dialog-box'
    },)
      .afterClosed()
      .pipe(
        mergeMap((ticcUUID) => {
          if (ticcUUID) return this.ticcsService.deleteTicc(ticcUUID)
          return of(ticcUUID)
        }),
        catchError((error) => {
          return error
        })
      ).subscribe(
        (res: any) => {
          if (res.uuid === ticc.ticcUUID) {
            this.ticcsModels$.splice(i, 1);
            this.ticcsModelsSubject$.next(this.ticcsModels$);
          }
        }
      )
  }

  onViewClick(ticcUUID: string) {
    localStorage.setItem("breadcrumbs_pageType", this.pageType);
    localStorage.setItem("breadcrumbs_uuid", this.uuid!);
    this.router.navigate(['/ticcs/' + ticcUUID]);
  }
}

@Component({
  selector: 'dialog-ticc-delete',
  templateUrl: 'dialog-ticc-delete.html',
  styleUrls: ['./ticcs-list.component.scss']
})
export class DialogTiccDelete {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | undefined,
    public dialogRef: MatDialogRef<DialogTiccDelete>
  ) { }
}
