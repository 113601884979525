import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { concatMap, Subject } from 'rxjs';
import { UserBasicViewModel } from 'src/app/models/backend-generated-models';
import { IExtractedUserFromJWT, IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/api/auth.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { UserService } from 'src/app/services/api/user.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';
import { UserStateService } from 'src/app/services/state/user-state.service';

@Component({
	selector: 'app-admin-menu-desktop',
	templateUrl: './admin-menu-desktop.component.html',
	styleUrls: ['./admin-menu-desktop.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMenuDesktopComponent implements OnInit{
	areModulesDisplayed: boolean = false;
	newUserName = new Subject<string>();

	currentSite$ = this.siteStateService.currentUserSite$;

	currentUserData: IUser | null | undefined
	userPhoto: any;
	
	constructor(
		private siteStateService: SiteStateService,
		private sitesService: SitesService,
		private auth: AuthService,
		private userStateService: UserStateService,
		private userService : UserService,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {
		this.userService.getModulesList().subscribe(res => {
			const userBasicData: UserBasicViewModel = res as UserBasicViewModel;
			
			this.userService.getUserPhoto(userBasicData?.photo as string).subscribe(
				{
				  next: (blobImage) => {
		  
					let parsedBlobImage = URL.createObjectURL(blobImage);
		  
					this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
				  }
				}
			  );
		})
		
		this.userStateService.currentUser$.subscribe(
			res => { this.currentUserData = res;  }
		)

		this.userService.getUserName().subscribe(name => {
			this.newUserName.next(name);
		  });
	}

	changeSite(site: { id: number }) {

		this.sitesService.setCurrentSite(site.id).pipe(
			concatMap(() => {
				return this.sitesService.getCurrentSite()
			})
		).subscribe((res) => {
			console.log("Current Site", res);
			this.siteStateService.currentUserSiteSource.next(res);
		})

	}

	toggleModules() {
		this.areModulesDisplayed = !this.areModulesDisplayed;
	}

	logout() {
		this.auth.logout();
	}
}
