import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UserStateService } from './services/state/user-state.service';
import { AppInsightsLoggerService } from './services/logging/app-insights-logger.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    title = 'TiCCBox.Compliance';

    loggedIn$

    events: string[] = [];

    carrouselModulesActive: boolean = false;

    opened: boolean = false;

    public isMobile: boolean = false;

    responsiveSize: number = 768;

    isUserAdmin$ = this.userStateService.isUserAdmin;

    options = this.formBuilder.group({
        bottom: 0,
        fixed: true,
        top: 0,
      });


    constructor(
        private userStateService: UserStateService,
        private formBuilder: UntypedFormBuilder,
        private logger: AppInsightsLoggerService,
    ) {
        this.loggedIn$ = this.userStateService.isLoggedIn$;
        logger = new AppInsightsLoggerService();
    }

    ngOnInit(): void {
        if (window.innerWidth >= this.responsiveSize) {
            this.isMobile = false
        } else {
            this.isMobile = true
        }
    }

    onResize(event: any) {
        if (event.target.innerWidth >= this.responsiveSize) {
            this.isMobile = false
        } else {
            this.isMobile = true
        }
        console.log("mobile", this.isMobile)
    }
}
