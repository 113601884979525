<div class="container-fluid ticc-creation-container">

    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb" routerLink="/boards"><span class="single-breadcrumb-link">Boards</span>
                <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb">Board Name <mat-icon>chevron_right</mat-icon></div>
            <div class="single-breadcrumb"><span>New Circuit</span></div>
        </div>
    </div>


    <div class="row p-3 m-0">

        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        New Circuit
                    </div>

                    <hr>
                </div>
            </div>
        </div>


        <mat-card class="card col-12">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Create Circuit&nbsp;
                    <span class="title-aditional">Aditional info</span>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content [formGroup]="formNewCircuit" class="p-3">

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Circuit Reference</mat-label>
                    <input formControlName="referenceName" type="text" matInput>
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Protective Device</mat-label>
                    <input formControlName="protectiveDevice" type="text" matInput>
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Rating</mat-label>

                    <mat-select formControlName="rating">
                        <mat-option *ngFor="let rating of circuitRating | keyvalue" [value]="rating.value">
                            {{rating.key}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Please, select a rating</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input formControlName="description" type="text" matInput>
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label>Comments</mat-label>
                    <input formControlName="comments" type="text" matInput>
                    <mat-error>This field can not be empty</mat-error>
                </mat-form-field>

            </mat-card-content>

        </mat-card>
    </div>


    <div class="row m-0">
        <div class="col-6">
            <button class="w-100 red-button" [routerLink]="['/boards/' + boardUuid]" mat-button>Cancel</button>
        </div>
        <div class="col-6">
            <button class="w-100 green-button" (click)="submit()" mat-button>Create</button>
        </div>
    </div>

</div>