<mat-drawer-container class="mb-5 sidenav-general-container" autosize>
    <mat-drawer #drawer class="sidenav-content-container d-flex flex-wrap" mode="over" fullscreen>
        <div class="col-12 d-flex align-items-center filter-header">
            <div class="col-8 offset-1 filters-title">
                Apply Filters
            </div>

            <div class="col-2 filters-count-container d-flex flex-wrap">
                <span class="filters-count">{{selectionCompanies.selected.length + selectionAssetTypes.selected.length +
                    selectionSites.selected.length}}</span>
                <span class="filters-text w-100">FILTERS</span>
            </div>
        </div>

        <div class="col-12">
            <mat-accordion class="filters-accordion" multi>
                <mat-expansion-panel class="w-100">

                    <mat-expansion-panel-header>
                        <div class="col-10 d-flex align-items-center">
                            <mat-panel-title class="user-title">
                                <mat-icon>apartment</mat-icon>&nbsp;
                                Companies
                            </mat-panel-title>
                        </div>
                        <div class="col-2">
                            <div class="count-expansion-pannel">
                                {{selectionCompanies.selected.length}}
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row" *ngFor=" let company of filter$?.filteredCompanies; let i = index">

                        <div class="col-12 py-2">
                            <mat-checkbox class="example-margin"
                                (change)="selectionCompanies.toggle(getMappedCompanies(filter$?.filteredCompanies!)[i])"
                                [checked]="selectionCompanies.isSelected(getMappedCompanies(filter$?.filteredCompanies!)[i])">
                                <span *ngIf="company != null">
                                    {{company.name}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="col-12">
            <mat-accordion class="filters-accordion" multi>
                <mat-expansion-panel class="w-100">

                    <mat-expansion-panel-header>
                        <div class="col-10 d-flex align-items-center">
                            <mat-panel-title class="user-title">
                                <mat-icon>corporate_fare</mat-icon>&nbsp;
                                Sites
                            </mat-panel-title>
                        </div>
                        <div class="col-2">
                            <div class="count-expansion-pannel">
                                {{selectionSites.selected.length}}
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row m-0" *ngFor=" let company of filter$?.filteredSites; let i = index">
                        <div class="col-12 py-2">
                            <mat-checkbox class="example-margin"
                                (change)="selectionSites.toggle(getMappedSites(filter$?.filteredSites!)[i])"
                                [checked]="selectionSites.isSelected(getMappedSites(filter$?.filteredSites!)[i])">
                                <span *ngIf="company != null">
                                    {{company.name}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="col-12">
            <mat-accordion class="filters-accordion" multi>
                <mat-expansion-panel class="w-100">

                    <mat-expansion-panel-header>
                        <div class="col-10 d-flex align-items-center">
                            <mat-panel-title class="user-title">
                                <mat-icon>perm_media</mat-icon>&nbsp;
                                Asset Types
                            </mat-panel-title>
                        </div>
                        <div class="col-2">
                            <div class="count-expansion-pannel">
                                {{selectionAssetTypes.selected.length}}
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row m-0" *ngFor=" let company of filter$?.filteredAssetTypes; let i = index">

                        <div class="col-12 py-2">
                            <mat-checkbox class="example-margin"
                                (change)="selectionAssetTypes.toggle(getMappedAssetTypes(filter$?.filteredAssetTypes!)[i])"
                                [checked]="selectionAssetTypes.isSelected(getMappedAssetTypes(filter$?.filteredAssetTypes!)[i])">
                                <span *ngIf="company != null">
                                    {{company.name}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="row m-0">
            <div class="col-6 filter-button-container">
                <button mat-button class="filter-button cancel-filter-button"
                    (click)="drawer.toggle();clearSelectionFilters();">
                    CANCEL
                </button>
            </div>
            <div class="col-6 filter-button-container" (click)="filter();drawer.toggle();">
                <button mat-button class="filter-button apply-filter-button">
                    APPLY FILTERS
                </button>
            </div>
        </div>


    </mat-drawer>



    <!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->
    <div class="asset-page-container d-flex justify-content-end">
        <!-- <input placeholder="Search Asset" class="fixed-input d-flex" type="text" [(ngModel)]="search"> -->
        <button routerLink="/assets/create" mat-button class="fixed-button d-flex"><mat-icon>add_circle</mat-icon>
            <div class="d-none d-md-block">&nbsp;&nbsp;NEW ASSET</div>
        </button>
    </div>

    <div class="page-top-breadcrumbs">

        <!-- <div class="row pt-1 m-0"> -->
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb">Assets <!-- <mat-icon>chevron_right</mat-icon> -->
            </div>
        </div>
        <!-- </div> -->

    </div>

    <!-- <hr class="page-top-breadcrumbs-underline"> -->

    <h1 class="page-top-title">
        Assets
    </h1>



    <!-- ---------------------------------------------------------- FILTERS - GROUP BY ---------------------------------------------------------- -->
    <div class="row m-0 d-flex justify-content-center">
        <div class="col-5 col-md-6 col-lg-3 filter-button-container">
            <button (click)="drawer.toggle()" mat-button class="open-filter-button open-filtering">
                <mat-icon>tune</mat-icon>&nbsp;
                Filter
            </button>
        </div>
        <div class="col-7 col-md-6 col-lg-3 filter-button-container">
            <button mat-button class="open-filter-button open-grouping" [matMenuTriggerFor]="grouping">
                <mat-icon>sort</mat-icon>&nbsp;
                Group By {{selectedGroup}}
            </button>
            <mat-menu #grouping="matMenu">
                <button mat-menu-item (click)="selectedGroup = 'Company(asc)'"><mat-icon>trending_up</mat-icon>A-Z
                    Company asc</button>
                <button mat-menu-item (click)="selectedGroup = 'Site(asc)'"><mat-icon>trending_up</mat-icon>A-Z
                    Site asc</button>
                <button mat-menu-item (click)="selectedGroup = 'Asset Type(asc)'"><mat-icon>trending_up</mat-icon>A-Z
                    Asset Types asc</button>
                <button mat-menu-item (click)="selectedGroup = 'Company(desc)'"><mat-icon>trending_down</mat-icon>Z-A
                    Company desc</button>
                <button mat-menu-item (click)="selectedGroup = 'Site(desc)'"><mat-icon>trending_down</mat-icon>Z-A Site
                    desc</button>
                <button mat-menu-item (click)="selectedGroup = 'Asset Type(desc)'"><mat-icon>trending_down</mat-icon>Z-A
                    Asset
                    Types desc</button>
            </mat-menu>
        </div>
    </div>


    <div *ngIf="selectionCompanies.selected.length > 0 || selectionSites.selected.length > 0 || selectionAssetTypes.selected.length > 0"
        class="row m-0 filter-count-container">
        <mat-chip-list class="d-flex justify-content-center">
            <mat-chip *ngIf="selectionCompanies.selected.length > 0">
                <div class="filter-count-circle px-2 me-2">
                    {{selectionCompanies.selected.length}}
                </div>
                Companies
            </mat-chip>

            <mat-chip *ngIf="selectionSites.selected.length > 0">
                <div class="filter-count-circle px-2 me-2">
                    {{selectionSites.selected.length}}
                </div>
                Sites
            </mat-chip>

            <mat-chip *ngIf="selectionAssetTypes.selected.length > 0">
                <div class="filter-count-circle px-2 me-2">
                    {{selectionAssetTypes.selected.length}}
                </div>
                Asset
                Types
            </mat-chip>
        </mat-chip-list>
    </div>


    <!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
    <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">



        <div *ngIf="selectedGroup == 'Company(asc)' || selectedGroup == 'Company(desc)'"
            class="row p-0 m-0 content w-100">

            <mat-accordion class="assets-container">
                <mat-expansion-panel (opened)="setCompaniesAssetsList(company.companyId)" hideToggle class="mt-3"
                    *ngFor="let company of companiesGroupList; first as isFirst" [expanded]="isFirst && company.companyId != null"> 
                    <mat-expansion-panel-header *ngIf="company.companyId != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>

                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{company.companyName}}</div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{company.count}} assets</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getCompaniesAssetsList(company.companyId)" >
                        <div class="col-12 py-2 detail-panel" *ngFor="let asset of item | async" [class.not-display]="isLoading$ | async">

                            <div class="row mx-0 my-2">

                                <div class="col-9 col-md-3 asset-title">
                                    {{asset.assetName}}
                                </div>

                                <div class="col-3  d-md-none justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'company', company.companyId)" mat-button
                                        class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 col-md-6">
                                    <mat-chip-list>
                                        <mat-chip class="asset-module"
                                            *ngFor="let module of (asset.assetTypeModules ? asset.assetTypeModules.slice(0,4): []); let i = index">

                                            <ng-container *ngIf="i != 3; else dots">{{module.moduleName |
                                                uppercase}}</ng-container>
                                            <ng-template #dots>...</ng-template>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                                <div class="col-3 d-none d-md-flex justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'company', company.companyId)" mat-button
                                        class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-12">
                                    <span class="parameter"><span
                                            class="parameter-definition">Description:&nbsp;</span><span
                                            class="strong">{{asset.assetDescription}}</span></span>
                                </div>
                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-8">
                                    <span class="parameter">
                                        <span class="parameter-definition">Last Check: &nbsp;</span>
                                        <span class="strong">{{!asset.assetLastCheckCompleted ? "'No check performed'" : asset.assetLastCheckCompleted | date:'dd-MM-yyyy'}}</span>
                                    </span>
                                </div>

                                <div class="col-4 d-flex justify-content-end align-items-center strong">
                                    <mat-icon>check_circle</mat-icon>&nbsp;
                                    {{asset.assetTiccCount}}&nbsp;ticcs
                                </div>
                            </div>

                        </div>
                    </div>

                    <mat-paginator [length]="company.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page"
                        (page)="setCompaniesAssetsListWithPagination(company.companyId, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>

        </div>



        <div *ngIf="selectedGroup == 'Site(asc)' || selectedGroup == 'Site(desc)'" class="row p-0 m-0 content w-100">

            <mat-accordion class="assets-container">
                <mat-expansion-panel (opened)="setSitesAssetsList(site.siteUuid)" hideToggle class="mt-3"
                    *ngFor="let site of sitesGroupList; first as isFirst" [expanded]="isFirst"> 
                    <mat-expansion-panel-header *ngIf="site.siteUuid != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>

                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{site.siteName}}</div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{site.count}} assets</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getSitesAssetsList(site.siteUuid)">
                        <div class="col-12 py-2 detail-panel" *ngFor="let asset of item | async"  [class.not-display]="isLoading$ | async">

                            <div class="row mx-0 my-2">

                                <div class="col-9 col-md-3 asset-title">
                                    {{asset.assetName}}
                                </div>

                                <div class="col-3  d-md-none justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'site', site.siteUuid)" mat-button
                                        class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 col-md-6">
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let module of (asset.assetTypeModules ? asset.assetTypeModules.slice(0,4): []); let i = index">

                                            <ng-container *ngIf="i != 3; else dots">{{module.moduleName |
                                                uppercase}}</ng-container>
                                            <ng-template #dots>...</ng-template>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                                <div class="col-3 d-none d-md-flex justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'site', site.siteUuid)" mat-button
                                        class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-12">
                                    <span class="parameter"><span
                                            class="parameter-definition">Description:&nbsp;</span><span
                                            class="strong">{{asset.assetDescription}}</span></span>
                                </div>
                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-8">
                                    <span class="parameter">
                                        <span class="parameter-definition">Last Check: &nbsp;</span>
                                        <span class="strong">{{!asset.assetLastCheckCompleted ? "'No check performed'" : asset.assetLastCheckCompleted | date:'dd-MM-yyyy'}}</span>
                                    </span>
                                </div>

                                <div class="col-4 d-flex justify-content-end align-items-center strong">
                                    <mat-icon>check_circle</mat-icon>&nbsp;
                                    {{asset.assetTiccCount}}&nbsp;ticcs
                                </div>
                            </div>

                        </div>
                    </div>

                    <mat-paginator [length]="site.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page" (page)="setSitesAssetsListWithPagination(site.siteUuid, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>

        </div>




        <div *ngIf="selectedGroup == 'Asset Type(asc)' || selectedGroup == 'Asset Type(desc)'"
            class="row p-0 m-0 content w-100">

            <mat-accordion class="assets-container">
                <mat-expansion-panel (opened)="setAssetTypesAssetsList(assetType.assetTypeUuid)" hideToggle class="mt-3"
                    *ngFor="let assetType of assetTypesGroupList; first as isFirst" [expanded]="isFirst"> 
                    <mat-expansion-panel-header *ngIf="assetType.assetTypeUuid != null" class="d-flex p-0">

                        <div class="col-8 d-flex flex-wrap align-items-center">
                            <div class="col-1">
                                <mat-icon class="p-2">circle</mat-icon>

                            </div>

                            <div class="col-10">
                                <div class="p-2 expansion-header-center-title">{{assetType.assetTypeName}}</div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="d-flex justify-content-end">
                                <div class="site-box d-flex p-2 expansion-header-rigth">
                                    <div class="site">{{assetType.count}} assets</div>
                                </div>
                                <mat-icon class="p-2">expand_more</mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div *ngIf="isLoading$ | async" class="col-12 h-100 d-flex justify-content-center">
                        <mat-spinner class="my-auto"></mat-spinner>
                    </div>

                    <div *ngFor="let item of getAssetTypesAssetsList(assetType.assetTypeUuid)">
                        <div class="col-12 py-2 detail-panel" *ngFor="let asset of item | async" [class.not-display]="isLoading$ | async">
                            <!-- [ngClass]="{'hidden': (search && !(search && assetIncludesSearch(asset?.assetName)))}" -->

                            <div class="row mx-0 my-2">

                                <div class="col-9 col-md-3 asset-title">
                                    {{asset.assetName}}
                                </div>

                                <div class="col-3  d-md-none justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'assetType', assetType.assetTypeUuid)"
                                        mat-button class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                                <div class="col-12 col-md-6">
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let module of (asset.assetTypeModules ? asset.assetTypeModules.slice(0,4): []); let i = index">

                                            <ng-container *ngIf="i != 3; else dots">{{module.moduleName |
                                                uppercase}}</ng-container>
                                            <ng-template #dots>...</ng-template>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                                <div class="col-3 d-none d-md-flex justify-content-end navigation-buttons">

                                    <button [routerLink]="['/assets/' + asset.assetUuid]" mat-button
                                        class="mx-3 blue-color">
                                        <mat-icon>visibility</mat-icon>
                                    </button>

                                    <button [routerLink]="['/assets/' + asset.assetUuid + '/clone']" mat-button class="mx-3 green-color">
                                        <mat-icon>library_add</mat-icon>
                                    </button>

                                    <button (click)="deleteAction(asset, 'assetType', assetType.assetTypeUuid)"
                                        mat-button class="mx-3 red-color">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>

                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-12">
                                    <span class="parameter"><span
                                            class="parameter-definition">Description:&nbsp;</span><span
                                            class="strong">{{asset.assetDescription}}</span></span>
                                </div>
                            </div>


                            <div class="row mx-0 my-2">
                                <div class="col-8">
                                    <span class="parameter">
                                        <span class="parameter-definition">Last Check: &nbsp;</span>
                                        <span class="strong">{{!asset.assetLastCheckCompleted ? "'No check performed'" : asset.assetLastCheckCompleted | date:'dd-MM-yyyy'}}</span>
                                    </span>
                                </div>

                                <div class="col-4 d-flex justify-content-end align-items-center strong">
                                    <mat-icon>check_circle</mat-icon>&nbsp;
                                    {{asset.assetTiccCount}}&nbsp;ticcs
                                </div>
                            </div>

                        </div>
                    </div>

                    <mat-paginator [length]="assetType.count" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        aria-label="Select page"
                        (page)="setAssetTypesAssetsListWithPagination(assetType.assetTypeUuid, $event)">
                    </mat-paginator>

                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </cdk-virtual-scroll-viewport>

</mat-drawer-container>