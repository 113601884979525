import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

    // Subject emitting the breadcrumb hierarchy 
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]); 
    readonly breadcrumbs$ = this._breadcrumbs$.asObservable(); 
 
    constructor(private router: Router) { 
      this.router.events.pipe( 
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end 
        filter((event) => event instanceof NavigationEnd) 
      ).subscribe(event => { 
        // Construct the breadcrumb hierarchy 
        const root = this.router.routerState.snapshot.root; 
        const breadcrumbs: Breadcrumb[] = []; 
        this.addBreadcrumb(root, [], breadcrumbs); 
   
        // Emit the new hierarchy 
        this._breadcrumbs$.next(breadcrumbs); 
      }); 
    } 
   
    private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) { 
      if (route) { 
        // Construct the route URL 
        const routeUrl = parentUrl.concat(route.url.map(url => url.path)); 
   
        // Add an element for the current route part 
        if (route.data['breadcrumb']) { 
          const breadcrumb = { 
            label: this.getLabel(route.data), 
            url: '/' + routeUrl.join('/') 
          }; 
          breadcrumbs.push(breadcrumb); 
        } 
   
        // Add another element for the next route part 
        this.addBreadcrumb(route?.firstChild, routeUrl, breadcrumbs); 
      } 
    } 
   
    private getLabel(data: Data) { 
      // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data 
      return typeof data?.['breadcrumb'] === 'function' ? data['breadcrumb'](data) : data['breadcrumb']; 
    } 
}


@Component({ 
  selector: 'app-breadcrumb', 
  templateUrl: './breadcrumb.component.html', 
  styleUrls: ['./breadcrumb.component.scss'], 
}) 
export class BreadcrumbComponent { 
  breadcrumbs$: Observable<Breadcrumb[]>; 
 
  constructor(private readonly breadcrumbService: BreadcrumbService) { 
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$; 
  } 
} 