import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { find, map } from 'rxjs';
import { BasicTiccTypeViewModel, SiteUserModel, TiccScheduleType } from 'src/app/models/backend-generated-models';
import { SitesService } from 'src/app/services/api/sites.service';
import { TiccsService } from 'src/app/services/api/ticcs.service';
import { LoadingService } from 'src/app/services/helpers/loading.service';
import { AsyncPipe } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export interface ScheduleCheckWeekdays {
  name: string;
  completed: boolean;
  days: {
    name: string;
    completed: boolean;
  }[];
}

/* export interface Reminder {
  before: string,
  timePeriod: string
} */

@Component({
  selector: 'app-ticcs-create',
  templateUrl: './ticcs-create.component.html',
  styleUrls: ['./ticcs-create.component.scss']
})
export class TiccsCreateComponent implements OnInit {

  requirements = {
    legal: true,
    certificateRequired: true,
    responsiblePerson: true,
    competentPerson: false
  }

  enumScheduleType = TiccScheduleType;

  timePeriods = [
    "Minutes",
    "Hours",
    "Days",
    "Weeks",
    "Months",
    "Years",
  ];

  pTimePerion: number | undefined;
  pEndTime: string | null = null;
  nonpEndTime: string | null = null;

  months = [
    { month: "January", days: 31 },
    { month: "February", days: 28 },
    { month: "March", days: 31 },
    { month: "April", days: 30 },
    { month: "May", days: 31 },
    { month: "June", days: 30 },
    { month: "July", days: 31 },
    { month: "August", days: 31 },
    { month: "September", days: 30 },
    { month: "October", days: 31 },
    { month: "November", days: 30 },
    { month: "December", days: 31 }
  ]

  siteUuid = this.route.snapshot.paramMap.get('siteUuid');
  ticcableType = "site"
  ticcableTypeUrl: string | undefined;
  ticcableUuid = this.route.snapshot.paramMap.get('uuid');

  checkWeekdays: ScheduleCheckWeekdays = {
    name: 'All',
    completed: false,
    days: [
      { name: 'Sunday', completed: false },
      { name: 'Monday', completed: false },
      { name: 'Tuesday', completed: false },
      { name: 'Wednesday', completed: false },
      { name: 'Thursday', completed: false },
      { name: 'Friday', completed: false },
      { name: 'Saturday', completed: false },
    ],
  };


  formTiccCreation = this.fb.group({
    ticcTypeId: this.fb.control(null),
    ticcableType: this.fb.control(this.ticcableType),
    ticcableUuid: this.fb.control(this.ticcableUuid),
    siteUuid: this.fb.control(this.siteUuid),
    scheduleType: this.fb.control(null),

    schedule: this.fb.group({
      startTime: this.fb.control('00:00:00'),
      endTime: this.fb.control(null),
      repeatEvery: this.fb.control(undefined),
      weekdays: this.fb.group({
        sunday: this.fb.control(false),
        monday: this.fb.control(false),
        tuesday: this.fb.control(false),
        wednesday: this.fb.control(false),
        thursday: this.fb.control(false),
        friday: this.fb.control(false),
        saturday: this.fb.control(false),
      }),
      checkDate: this.fb.control(null)
    }),

    ticcObservationsEnabled: this.fb.control(false),
    ticcObservations: this.fb.array([]),
    ticcAssignedToEnabled: this.fb.control(false),
    ticcAssignedTo: this.fb.array([]),
    ticcTagScanRequiredEnabled: this.fb.control(false),
    notificationsEnabled: this.fb.control(false),
    notificationsData: this.fb.group({
      sendReminder: this.fb.control(false),
      sendCheckComplete: this.fb.control(false),
      sendCheckNotComplete: this.fb.control(false),
      sendNotesFilled: this.fb.control(false),

      reminderRules: this.fb.array([
        /* new FormGroup({
          unitOfTime: new FormControl(''),
          aforehandReminderTimePeriodInUnits: new FormControl('')
        }) */
      ]),

      recipientsForReminder: this.fb.array([]),
      recipientsForCheckComplete: this.fb.array([]),
      recipientsForCheckNotComplete: this.fb.array([]),
      recipientsForNotesFilled: this.fb.array([]),

      /* recipientsForCheckComplete: this.fb.group({
        id: this.fb.control(null),
        enabled: this.fb.control(false)
      }),

      recipientsForCheckNotComplete: this.fb.group({
        id: this.fb.control(null),
        enabled: this.fb.control(false)
      }),

      recipientsForNotesFilled: this.fb.group({
        id: this.fb.control(null),
        enabled: this.fb.control(false)
      }), */

    }),
  });

  formReminderRules = this.fb.group({

  })

  changeTimePeriod(timePerion: number) {
    this.pTimePerion = timePerion;
  }

  changePeriodicEndTime(event: any) {
    this.pEndTime = event.target.value;
  }

  changeNonPeriodicEndTime(event: any) {
    this.nonpEndTime = event.target.value;
  }

  resetTiccCreationForm() {
    this.formTiccCreation.controls['schedule'].patchValue(
      this.fb.group(
        {
          endTime: this.fb.control(null),
          startTime: this.fb.control('00:00:00'),
          repeatEvery: this.fb.control(undefined),
          weekdays: this.fb.group({
            sunday: this.fb.control(false),
            monday: this.fb.control(false),
            tuesday: this.fb.control(false),
            wednesday: this.fb.control(false),
            thursday: this.fb.control(false),
            friday: this.fb.control(false),
            saturday: this.fb.control(false),
          }),
          checkDate: this.fb.control(null)
        }
      )
    );

    if (!this.scheduleButtonToggled) {
      this.formTiccCreation.get('scheduleType')?.patchValue(this.pTimePerion);
      this.formTiccCreation.get('schedule')?.get('endTime')?.patchValue(this.pEndTime);
    } else {
      this.formTiccCreation.get('schedule')?.get('endTime')?.patchValue(this.nonpEndTime);
    }
  }



  formSchedule = this.fb.group({
    timePeriod: this.fb.control(''),
    repetition: this.fb.control(null),
    startTime: this.fb.control(null),
    endTime: this.fb.control(null),
    weekdays: this.fb.control('')
  })

  formScheduleNonPeriodic = this.fb.group({
    date: this.fb.control(''),
    endTime: this.fb.control(null),
  })


  formSelectedWeekday = this.fb.control('');

  formMonthSelector = this.fb.control('');

  maxDaysMonth: number | 0;

  selectedUsersList: { user: SiteUserModel; enabled: boolean; }[] = []

  isLoading$ = this.loadingService.isLoading$;

  constructor(
    private fb: UntypedFormBuilder,
    private ticcsService: TiccsService,
    private sitesService: SitesService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private snackbar: MatSnackBar,
    private asyncPipe: AsyncPipe

  ) {
    this.maxDaysMonth = 0;
  }

  ngOnInit(): void {
    const currentUrl = this.router.parseUrl(this.router.url);
    this.ticcableTypeUrl = currentUrl.root.children['primary'].segments[0].toString();
    let uuid = currentUrl.root.children['primary'].segments[1].toString();
    if (!this.ticcableTypeUrl || this.ticcableTypeUrl == "") {
      this.ticcableTypeUrl = 'site';
      this.ticcableUuid = uuid;
      this.formTiccCreation.get('ticcableUuid')?.patchValue(uuid);
      this.formTiccCreation.get('ticcableType')?.patchValue('site');
    }

    if (this.ticcableTypeUrl == "sites") {
      this.ticcableType = "site";
      this.ticcableUuid = uuid;
      this.formTiccCreation.get('ticcableUuid')?.patchValue(uuid);
      this.formTiccCreation.get('ticcableType')?.patchValue('site');
    }

    if (this.ticcableTypeUrl == "assets") {
      this.ticcableType = "asset";
      this.ticcableUuid = uuid;
      this.formTiccCreation.get('ticcableUuid')?.patchValue(uuid);
      this.formTiccCreation.get('ticcableType')?.patchValue('asset');
    }

    if (this.ticcableTypeUrl == "users") {
      this.ticcableType = "user";
      this.ticcableUuid = uuid;
      this.formTiccCreation.get('ticcableUuid')?.patchValue(uuid);
      this.formTiccCreation.get('ticcableType')?.patchValue('user');
    }

    if (this.ticcableTypeUrl == "boards") {
      this.ticcableType = "board";
      this.siteUuid = this.route.snapshot.paramMap.get('siteUuid');
      this.ticcableUuid = uuid;
      this.formTiccCreation.get('ticcableUuid')?.patchValue(uuid);
      this.formTiccCreation.get('siteUuid')?.patchValue(this.siteUuid);
      this.formTiccCreation.get('ticcableType')?.patchValue('board');
    }

    this.sitesService.getSiteUsersList(this.siteUuid!).subscribe((res) => {

      res.forEach(user => {
        console.log("each element", user)
        this.selectedUsersList?.push(
          {
            user,
            enabled: false
          })
      })
    })

    console.log(this.route.snapshot.paramMap.get('uuid'))
  }

  cancel() {
    if (!this.ticcableTypeUrl || !this.ticcableUuid) return history.back();

    this.router.navigate([this.ticcableTypeUrl, this.ticcableUuid])
  }
  // reminderList: Reminder[] = [];

  addReminder(aforehandReminderTimePeriodInUnits: HTMLInputElement, unitOfTime: MatSelect) {
    console.log("bef", aforehandReminderTimePeriodInUnits.value)

    if (!unitOfTime.value || !aforehandReminderTimePeriodInUnits.value) return

    // console.log("enum", )
    console.log("enum value", unitOfTime.value, TiccScheduleType[unitOfTime.value as keyof typeof TiccScheduleType])

    const reminders = this.fb.group({
      unitOfTime: this.fb.control(TiccScheduleType[unitOfTime.value as keyof typeof TiccScheduleType]),
      aforehandReminderTimePeriodInUnits: this.fb.control(parseInt(aforehandReminderTimePeriodInUnits.value))
    })



    this.reminderRulesList.push(
      reminders
    );

    unitOfTime.value = "";
    aforehandReminderTimePeriodInUnits.value = "";

    console.log("rem", this.reminderRulesList)
  }

  deleteReminder(reminderIndex: number) {
    this.reminderRulesList.removeAt(reminderIndex);
  }

  setMonth() {
    console.log("month", this.formMonthSelector.value)

    console.log("current days", this.months.find(x => x.month == this.formMonthSelector.value)?.days)

    this.maxDaysMonth = this.months.find(x => x.month == this.formMonthSelector.value)?.days || 0;
  }




  resetNotificationsValue(notificationType: string) {
    (this.formTiccCreation.get('notificationsData')?.get(notificationType.toString()) as UntypedFormArray)
      .clear();
  }

  openDialogRecipients(notificationType: string, title: string) {
    this.dialog.open(DialogRecipientsSelector, {
      width: '600px',
      data: {
        siteUuid: this.siteUuid,
        recipientsForCheckComplete: this.formTiccCreation.get('notificationsData')?.get(notificationType)?.value,
        title: title
      }
    })
      .afterClosed()
      .subscribe(
        (recipients: { uuid: string; enabled: boolean; }[]) => {
          if(!recipients) return

          this.resetNotificationsValue(notificationType);
          recipients?.forEach((recipient) => {
            (this.formTiccCreation.get('notificationsData')?.get(notificationType.toString()) as UntypedFormArray).push(
              this.fb.group({
                uuid: this.fb.control(recipient.uuid),
                enabled: this.fb.control(recipient.enabled)
              })
            );
          });


          // this.formTiccCreation.get('notificationsData')?.get('recipientsForReminder')?.patchValue(res);
          // console.log("form b", this.formTiccCreation.get('notificationsData')?.get('recipientsForReminder'));
          console.log("form", this.formTiccCreation.value)
        })

    // this.formTiccCreation.get('notificationsData')?.get('recipientsForCheckNotComplete')


  }


  selectedTiccType: BasicTiccTypeViewModel | undefined;

  setTiccTypeValue(e: number) {
    console.log("any", e)

    this.ticcTypesList$.pipe(map(x => x.find(x => x.id == e))).subscribe((res) => { console.log("found", res); this.selectedTiccType = res; })
  }

  formDayRepeat = this.fb.control('');


  get scheduleType() {
    return this.formTiccCreation.controls['scheduleType'] as UntypedFormControl;
  }

  get ticcObservations() {
    return this.formTiccCreation.controls["ticcObservations"] as UntypedFormArray;
  }

  get reminderRulesList() {
    return this.formTiccCreation.get('notificationsData')?.get('reminderRules') as UntypedFormArray;
  }
  minDate = moment(new Date()).format('YYYY-MM-DD')



  // SWITCH BUTTONS
  switchAssignedTo = false;
  switchTagScanRequire = false;
  switchObservation = false;
  switchNotification = false;

  scheduleButtonToggled = false;

  // observationsList: string[] = [];
  // observationsList = this.fb.array(this.fb.control(''));

  allComplete: boolean = false;


  ticcTypesList$ = this.ticcsService.getTiccTypesList();

  /* OBSERVATIONS */

  addObservation(lastObservation: HTMLInputElement) {
    // let observationControl = this.fb.control(observation);

    if (!lastObservation.value || this.ticcObservations.controls.some(x => x.value == lastObservation.value)) return

    this.ticcObservations.push(new UntypedFormGroup({ text: new UntypedFormControl(lastObservation.value) }));
    console.log("creation", this.formTiccCreation.value)

    lastObservation.value = "";
  }

  deleteObservation(observationIndex: number) {
    this.ticcObservations.removeAt(observationIndex);
  }

  /* END - OBSERVATIONS */



  /* CHECKBOX */

  updateAllComplete() {
    this.allComplete = this.checkWeekdays.days != null && this.checkWeekdays.days.every((t: { completed: any; }) => t.completed);
  }

  someComplete(): boolean {
    if (this.checkWeekdays.days == null) {
      return false;
    }
    return this.checkWeekdays.days.filter((t: { completed: any; }) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.checkWeekdays.days == null) {
      return;
    }
    this.checkWeekdays.days.forEach((t: { completed: boolean; }) => (t.completed = completed));
  }

  /* END - CHECKBOX */


  submit() {
    if (!this.scheduleButtonToggled && !this.formTiccCreation.get('scheduleType')?.value && this.formTiccCreation.get('scheduleType')?.value != 0) {
      this.snackbar.open('Time Period is required', 'ok', {
        panelClass: 'custom-snackbar-error',
        politeness: 'polite',
        duration: 4000
      });
      return;
    }

    if (this.asyncPipe.transform(this.isLoading$)) return;

    /* TIME PERDIOD SET FOR NON PERIODIC TASKS */
    if (this.scheduleButtonToggled) {
      this.formTiccCreation.get('scheduleType')?.patchValue(6)
    }

    /* ADD SCHEDULE WEEKDAYS CHECKBOX TO POST MODEL */
    (Object.keys((this.formTiccCreation.get('schedule')?.get('weekdays') as UntypedFormGroup).value).forEach((v: any, i) => {
      this.formTiccCreation.get('schedule')?.get('weekdays')?.get(v)?.patchValue(this.checkWeekdays.days[i].completed)
    }));



    /* FULFILL WITH SELECTED WEEKDAY - ONLY FOR WEEKS TIME PERIOD */
    if (this.formSelectedWeekday.value) {
      this.formTiccCreation.get('schedule')?.get('weekdays')?.get((this.formSelectedWeekday.value).toLowerCase())?.patchValue(true)
    }



    let weekdays = this.checkWeekdays.days.filter(x => x.completed).map(x => x.name);


    /* TODO: add weekdays to formSchedule */
    this.formSchedule.controls['weekdays'].patchValue(weekdays)

    console.log("weekdays: ", weekdays);

    console.log("single weekday: ", this.formSelectedWeekday.value);


    // console.log("main form: ", this.formTiccCreation.value);
    console.log("schedule form: ", this.formSchedule.value);

    console.log("assignments list: ", this.selectedUsersList);



    /* STORE SELECTED USERS IN ASSIGNMENTS */
    if (this.switchAssignedTo)
      this.selectedUsersList.filter(((x) => x.enabled == true)).map(x => x.user.userUUID).forEach(user => {
        (this.formTiccCreation.get('ticcAssignedTo') as UntypedFormArray).push(new UntypedFormControl({ userUuid: user }))
      });

    // (this.formTiccCreation.get('ticcAssignedTo') as FormArray).push(users)

    // set to checkDate time from endTime or startTime to fix bug with utc time
    if (this.formTiccCreation?.get('schedule')?.get('checkDate')?.value != null) {
      var date = this.formTiccCreation?.get('schedule')?.get('checkDate')?.value as string;

      var time = this.formTiccCreation?.get('schedule')?.get('endTime')?.value 
        ?? this.formTiccCreation?.get('schedule')?.get('startTime')?.value;

      if (time == null) 
        time = "00:00:00";

      var checkDate = date.slice(0, 10) + "T" + time;

      this.formTiccCreation?.get('schedule')?.get('checkDate')?.patchValue(checkDate);
    }

    console.log("final form", this.formTiccCreation.value);


    this.ticcsService.createTicc(this.formTiccCreation.value).subscribe((res) => {
      console.log("ticc created successfully", res)
      this.router.navigate([this.ticcableTypeUrl, this.ticcableUuid])
    })
  }


}


@Component({
  selector: 'dialog-recipients-selector',
  templateUrl: 'dialog-recipients-selector.html',
  styleUrls: ['../../admin/admin-user-management/admin-user-management.component.scss', './ticcs-create.component.scss']

})
export class DialogRecipientsSelector {

  selectedUsersList: { user: SiteUserModel; enabled: boolean; }[] = []
  // siteUsers$ = this.sitesService.getSiteUsersList(this.siteUUID);

  isLoading$ = this.loadingService.isLoading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { siteUuid: string, recipientsForCheckComplete: any[], title: string },
    private sitesService: SitesService,
    public dialogRef: MatDialogRef<DialogRecipientsSelector>,
    private loadingService: LoadingService,
    private asyncPipe: AsyncPipe
  ) { }

  ngOnInit(): void {
    this.sitesService.getSiteUsersList(this.data.siteUuid).subscribe((res) => {
      this.initUsers(res);
    })
  }

  initUsers(users: SiteUserModel[]) {
    this.selectedUsersList = [];

    users.forEach(user => {
      const obj = this.data.recipientsForCheckComplete?.find(obj => obj.uuid == user.userUUID);
      this.selectedUsersList?.push(
      {
        user,
        enabled: obj?.enabled ?? false
      })
    })
  }

  submit() {
    if (this.asyncPipe.transform(this.isLoading$)) return;

    this.dialogRef.close(this.selectedUsersList.map(x => {
      return {
        uuid: x.user.userUUID,
        enabled: x.enabled
      }
    }))
  }

}
