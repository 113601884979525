import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
// import { Location } from '@angular/common';
import { TiccsService } from 'src/app/services/api/ticcs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, mergeMap, Observable, tap, zip } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { CheckAttachmentViewModel, CheckDetailsModel, CheckStatus } from 'src/app/models/backend-generated-models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LoadingService } from 'src/app/services/helpers/loading.service';

@Component({
  selector: 'app-check-detail',
  templateUrl: './check-detail.component.html',
  styleUrls: ['./check-detail.component.scss']
})
export class CheckDetailComponent implements OnInit, OnDestroy {
  isLoading$ = this.loadingService.isLoading$;

  observations = [
    {
      observation: "something",
      completed: false
    }
  ];

  checkUuid: string | null = this.route.snapshot.paramMap.get('uuid');

  sscheckAttachment: CheckAttachmentViewModel | undefined;

  attachments = [];

  attachmentFileList: CheckAttachmentViewModel[] | undefined = [];

  checkDetails: CheckDetailsModel | undefined;

  attachmentType: string | undefined;
  breadcrumbsPageType: string | null = "";

  constructor(
    private route: ActivatedRoute,
    // private location: Location,
    private ticcsService: TiccsService,
    public sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.breadcrumbsPageType = localStorage.getItem("breadcrumbs_pageType");

    this.ticcsService.getCheckInformation(this.checkUuid).pipe(
      concatMap((res) => {
        let observablesList: Observable<CheckAttachmentViewModel>[] = [];
        res.checkAttachments?.forEach(element => {
          observablesList.push(this.ticcsService.getCheckAttachments(element));
        })
        console.log(observablesList);
        this.checkDetails = res;
        this.getAttachmentTypeForUrl();
        return observablesList;
      })
    ).subscribe((res) => {
      res.forEach(element => {
        this.attachmentFileList?.push(element);
      })
    })
  }

  ngOnDestroy(): void {
    localStorage.removeItem("breadcrumbs_pageType");
  }

  getAttachmentTypeForUrl() {
    if (this.breadcrumbsPageType == 'home') {
      this.attachmentType = "home";
      return;
    }

    switch(this.checkDetails?.ticcAttachmentType) {
      case "Asset": {
        this.attachmentType = "assets";
        break;
      }
      case "Site": {
        this.attachmentType = "sites";
        break;
      }
      case "Board": {
        this.attachmentType = "boards";
        break;
      }
      case "User": {
        this.attachmentType = "users";
        break;
      }
      default: {
        this.attachmentType = "home";
        break;
      }
    }
  }

  getDownloadUrl(base64String: string | undefined) {
    if (!base64String) return;
    const blob = this.base64toBlob(base64String);

    const url = window.URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  base64toBlob(base64String: string): Blob {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/octet-stream' });
  }

  /* getCheckStatus(status: number | undefined) {
    if (!status) return;

    return CheckStatus[Compliant].replace(/([A-Z])/g, ' $1').trim();
  } */

  openCheckDeleteDialog(data: CheckDetailsModel | undefined) {
    this.dialog.open(DialogCheckDelete, {
      width: '800px',
      data: data,
      panelClass: 'dialog-box'
    })
    .afterClosed().subscribe()
  }

  isImg(contentType: string | undefined) {
    let isTypeImg: boolean = false;
    if (contentType?.includes("jpeg") || contentType?.includes("jpg") || contentType?.includes("png"))
      isTypeImg = true;

    return isTypeImg;
  }
}


@Component({
  selector: 'dialog-check-delete',
  templateUrl: 'dialog-check-delete.html',
  styleUrls: ['./check-detail.component.scss']
})
export class DialogCheckDelete {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CheckDetailsModel | undefined,
    public dialogRef: MatDialogRef<DialogCheckDelete>,
    private ticcsService: TiccsService,
    private router: Router,
  ) { }

  deleteCheck() {
    this.ticcsService.deleteCheck(this.data?.uuid).subscribe((res) => {
      // console.log("deleted", res);
      this.dialogRef.close();
      this.router.navigate(['/ticcs/' + this.data?.ticcUuid]);
    })


    // this.dialogRef.close()
  }
}
