import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { BasicTiccTypeViewModel, CheckAttachmentViewModel, CheckDetailsModel, CreateTiccRequestModel, EditTiccRequestModel, TiccByUuidViewModel, TiccCheckExtendedViewModel, TiccCheckResponseModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TiccsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService

  ) { }

  deleteCheck(checkUuid: string | undefined) {
    return this.http
    .delete(`${environment.apiUrl}Checks/${checkUuid}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }
  
  getCheckAttachments(attachmentId: number) {
        return this.http
          .get<CheckAttachmentViewModel>(`${environment.apiUrl}Checks/attachments/${attachmentId}`)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handleError(error);
    
              return throwError(error);
            })
          );
  }

  getCheckInformation(checkUuid: string | null) {
    return this.http
      .get<CheckDetailsModel>(`${environment.apiUrl}Checks/${checkUuid}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  editTicc(ticcUuid: string | null, body: EditTiccRequestModel) {
    console.log("body", body)
    return this.http
      .put(`${environment.apiUrl}Ticcs/${ticcUuid}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  createTicc(body: CreateTiccRequestModel) {
    console.log("body", body)
    return this.http
      .post(`${environment.apiUrl}Ticcs`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }
  
  getTiccInformation(ticcUUID: string | null) {
    return this.http
    .get<TiccByUuidViewModel>(`${environment.apiUrl}Ticcs/${ticcUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getCheckHistory(ticcUUID: string | null) {
    return this.http
    .get<TiccCheckResponseModel[]>(`${environment.apiUrl}Ticcs/${ticcUUID}/checks`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }


  getTiccTypesList() {
    return this.http
      .get<BasicTiccTypeViewModel[]>(`${environment.apiUrl}ticc-types/list`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }


  uploadCheckAttachment(body: object) {
    console.log("body", body)
    return this.http
      .post(`${environment.apiUrl}Checks/attachments`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  copyCheckAttachment(body: string[], siteUuid: string) {
    console.log("body", body)
    return this.http
      .post<string[]>(`${environment.apiUrl}Checks/attachments-copy/${siteUuid}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }


  getCheckInformationByTicc(param: string | null /* ticcUUID */) {
    return this.http
      .get<TiccCheckExtendedViewModel>(`${environment.apiUrl}Ticcs/${param}/check`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }


  postNewCheck(body: object) {
    return this.http
      .post(`${environment.apiUrl}Checks`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  deleteTicc(ticcUUID: string /* ticcUUID */) {
    return this.http
    .delete(`${environment.apiUrl}Ticcs/${ticcUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }



}
