<div class="dialog">

    <h1 class="dialog-title mx-2 mb-3">Delete TiCC</h1>
  
    
    <div class="dialog-main-text mx-2 mb-3">
        Are you sure that you want to delete TICC: <span class="italic data">"{{data.ticcTypeName}}"</span> <br>
    </div>
    
    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>No</button>
        <button class="col-4 dialog-delete-action-button" [matDialogClose]="data.ticcUUID" mat-button>Yes</button>
    </div>
    
 </div>
