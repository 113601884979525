<!-- ---------------------------------------------------------- STICKY ---------------------------------------------------------- -->

<div *ngIf="breadcrumbExist" class="page-top-breadcrumbs">
    <a [routerLink]="breadcrumbUrl">{{breadcrumbTitle}}</a> > {{userDetails$?.userFirstName}}
    {{userDetails$?.userLastName}}
</div>

<div class="fixed-button-container d-flex justify-content-end" *ngIf="isEditButton && !isLoadingUserDetails">
    <button routerLink="edit" mat-button class="fixed-button d-flex"><mat-icon>edit</mat-icon>
        <div class="d-none d-md-block">&nbsp;&nbsp;Edit User</div>
    </button>
</div>

<!-- <div class="page-top-breadcrumbs">
    USER DETAIL
</div> -->

<!-- <hr class="page-top-breadcrumbs-underline"> -->

<h1 class="page-top-title">
    User Detail
</h1>

<!-- ---------------------------------------------------------- CONTENT ---------------------------------------------------------- -->
<div class="container-fluid">

    <mat-card class="p-0">
        <mat-card-header class="p-3">
            <mat-card-title class="m-0">Information</mat-card-title>
        </mat-card-header>

        <mat-card-content class="p-3">
            <div *ngIf="isLoadingUserDetails" class="row">
                <div class="col-12 d-flex justify-content-center">
                    <mat-spinner class="spinner-center"></mat-spinner>
                </div>
            </div>
            <div *ngIf="!isLoadingUserDetails" class="row">
                <div class="col-12 col-md-1 user-image-container">
                    <img [src]="avatarConvert(userDetails$?.userPhoto) || 'web-assets/images/default/default-user.png'"
                        class="user-image" alt="">
                </div>

                <div class="col-12 col-md-11 row">
                    <div class="col-12 col-md-6 row">
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Name:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{((userDetails$?.userFirstName ?
                                    (userDetails$?.userFirstName + " ") : "") + userDetails$?.userLastName) || "Name not added"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Email:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{userDetails$?.userEmail || "Email not added"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Company:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{userDetails$?.userCompany?.companyName || "Company not added"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 row">
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">System Role:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{userDetails$?.userSystemRole || "No System Role" |
                                    titlecase}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Created Date:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{userDetails$?.createdAt || "No value"}}</span>
                            </div>
                        </div>
                        <div class="col-12 pt-1 pb-2 row">
                            <div class="col-4">
                                <span class="ligth break-word">Updated Date:</span>
                            </div>
                            <div class="col-8">
                                <span class="strong break-word">{{userDetails$?.updatedAt || "No value"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="TICCs">
            <div class="row justify-content-between p-0 ml-0 mt-0 mr-0 mb-2">
                <div class="mt-4 col-12 col-md-4">
                    <mat-form-field class="w-100" [floatLabel]="isLoadingUserDetails ? 'never' : 'always'">

                        <mat-label *ngIf="isLoadingUserDetails">
                                <div class="select-placeholder-container">
                                    <span>Loading...</span>
                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div>
                        </mat-label>
                        <mat-label *ngIf="!isLoadingUserDetails" class="pl-2 d-flex align-items-center">
                            Site
                        </mat-label>

                        <mat-select class="invite-to-site-select" [(ngModel)]="selectedSite"
                            (ngModelChange)="onSiteSelected()" placeholder="Select Site Name">
                            <mat-option *ngFor="let site of userDetails$?.userSites" [value]="site.id">
                                {{site.siteName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mt-4 col-12 col-md-4">
                    <button [disabled]="selectedSite == ''" [routerLink]="['/users/' + userUuid + '/sites/' + selectedSite + '/ticcs/create']"
                        class="button-center button-new-ticc w-100" mat-button style="text-align: center;">NEW
                        TICC</button>
                </div>
            </div>
            <app-ticcs-list [ticcsArray$]="ticcs$" [pageType]="'users'" [uuid]="userUuid" [siteUuid]="selectedSite || ''" [filterBySiteUuid]="true"></app-ticcs-list>
        </mat-tab>

        <mat-tab label="TAG">
            <div class="row m-0 p-0">
                <div class="col-12" [ngClass]="{'col-12': !image, 'col-12 col-md-8': image}">
                    <mat-card class="card p-0">
                        <mat-card-header class="p-3">
                            <mat-card-title class="m-0">{{tagStatusError ? 'Add TAG' : 'QR Code UUID' }}
                            </mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="p-3" *ngIf="!tagStatusError; else noTag">
                            <div class="w-100" style="text-align: center; margin-bottom: 1rem; font-size: 1.1rem;">
                                {{tag$?.tagUUID}}
                            </div>
                            <button (click)="disassociateTag()" class="button-center button-disassociate w-100"
                                mat-button style="text-align: center;">
                                <img src="web-assets/svg/qrcode.svg">REMOVE QR
                            </button>
                        </mat-card-content>

                        <ng-template #noTag>
                            <mat-card-content class="p-3" style="text-align: center !important; font-size: 1.3rem;">
                                <p style="color: #F61010;">No TAG associated</p>
                                <p style="font-size: 20px;">Add TAG by UUID</p>
                                <mat-form-field class="col-12" appearance="outline">
                                    <mat-label>TAG UUID</mat-label>
                                    <input type="text" [(ngModel)]="tagUUID" matInput>
                                </mat-form-field>
                                <button mat-button type="submit" class="action-buttons submit w-100" [disabled]="tagUUID == undefined" (click)="associationToTag()" >Add TAG</button>
                                <p style="font-size: 20px;">Add TAG form QR scan</p>
                                <button mat-button routerLink="/scanner" [queryParams]="{ lastUrl: 'users' , uuid:  userUuid }" class="action-buttons scan w-100"><img
                                        src="web-assets/svg/qrcode.svg"> Scan QR code</button>
                            </mat-card-content>
                        </ng-template>
                    </mat-card>
                </div>
                <div class="col-12 col-md-4 tag-image" [ngClass]="{'d-none': !image, 'd-block': image}">
                    <img [src]="image" class="w-100 img-fluid rounded-top" alt="">
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
