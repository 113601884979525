
<div class="row py-3">
    <div class="col-12 py-3 bottom-sheet-title">
        Choose Operation
    </div>
    <div class="col-12 py-3 bottom-sheet-sites-count dialog-data italic">
        {{data}} Sites selected
    </div>
    <div class="col-12 py-2 dialog-data italic">
        <button mat-button class="w-100 p-1 bottom-sheet-buttons" (click)="closeBottomSheet('assign-to-company')">
            Assign to Company
        </button>
    </div>
    <div class="col-12 py-2 dialog-data italic">
        <button mat-button class="w-100 p-1 bottom-sheet-buttons"(click)="closeBottomSheet('disassociate-from-company')">
            Disassociate from Company
        </button>
    </div>
</div>