<!-- <h1 class="title mx-2 mb-3">Restriction Dialog</h1>

<div class="main-text mx-2 mb-3">
    You are going to delete <br>
    <span class="italic data">"{{data}}"</span> site<br>
    System should keep this site DEACTIVATED for 30 days.<br>
    Please approve this action"
</div>

<mat-dialog-actions class="row mx-2 d-flex justify-content-center modal-action-buttons">
  <button class="col-4" mat-button [mat-dialog-close]="false">CANCEL</button>
    <button class="col-4" mat-button [mat-dialog-close]="true">YES</button>
</mat-dialog-actions> -->




<div class="dialog">

  <h1 class="dialog-title mx-2 mb-3">Restriction Dialog</h1>

  <div class="dialog-main-text mx-2 mb-3">
      You are going to delete <span class="italic dialog-data">"{{data.name}}"</span> site. <br>
      System should keep this site DEACTIVATED for {{parseDate(data.time)}} days. <br>
      Please approve this action.
  </div>
  
  <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
      <button class="col-4 dialog-red-action-button" mat-button [mat-dialog-close]="false">CANCEL</button>
      <button class="col-4 dialog-delete-action-button" mat-button [mat-dialog-close]="true">DELETE</button>
  </div>
  
</div>