import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AssetResponseModel, AssetsAndFilters, AssetsBaseViewModel, AssetSelectedSiteModel, AssetTypesViewModel, AssetUpdateModel, CloneAssetRequestModel, CreateAssetRequestModel, TagResponseModel, TiccViewModel, UserTagResponseModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) {}


  filterForAsset() {
    return this.http.get<[]>(`${environment.apiUrl}Filters`, {
      params:
      {
        param: "companies&sites&asset_types"
      }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }


  getAssetsList(params?: HttpParams) {
    params = params?.append("IncludeNullCompanies", false)

    return this.http
    .get<AssetsAndFilters>(`${environment.apiUrl}assets`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  createAsset(body: CreateAssetRequestModel) {
    return this.http
    .post(`${environment.apiUrl}assets`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  editAsset(assetUuid: string ,body: AssetUpdateModel) {
    return this.http
    .put(`${environment.apiUrl}assets/${assetUuid}`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }


  cloneAsset(body: CloneAssetRequestModel) {
    return this.http
    .post(`${environment.apiUrl}assets/clone`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAssetTypeList() {
    return this.http
    .get<AssetTypesViewModel[]>(`${environment.apiUrl}asset-types/list`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAssetByUUID(assetUUID: string | null) {
    return this.http
      .get<AssetResponseModel>(`${environment.apiUrl}assets/${assetUUID}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }


  deleteAsset(assetUUID: string | undefined) {
    return this.http
    .delete(`${environment.apiUrl}assets/${assetUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getAssetTiccsInformation(assetUUID: string | null) {
    return this.http
      .get<TiccViewModel[]>(`${environment.apiUrl}assets/${assetUUID}/ticcs`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getAssetTagInformation(assetUUID: string | null) {
    return this.http
      .get<TagResponseModel>(`${environment.apiUrl}assets/${assetUUID}/tag`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getCurrentTagImage(param: string | undefined) {
    console.log("param", `${environment.apiUrl}Tags/${param}/qr`)
    return this.http
      .get(`${environment.apiUrl}Tags/${param}/qr`, { responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  deleteTagAssociation(param: string /* tagUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Tags/${param}/association`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getAssetForSiteList(siteUUID: string | undefined) {
    return this.http
    .get<AssetSelectedSiteModel[]>(`${environment.apiUrl}assets/list?siteUuid=${siteUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

}
