import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsLoggerService } from './app-insights-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlingService extends ErrorHandler {

constructor(private appInsightsService: AppInsightsLoggerService) {
  super();
}

  override handleError(error: Error) {
    this.appInsightsService.logException(error); // Manually log exception
  }

}
