import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsService } from 'src/app/services/api/assets.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: './asset-create.component.html',
  styleUrls: ['./asset-create.component.scss']
})
export class AssetCreateComponent implements OnInit {

  assetTypeList$ = this.assetsService.getAssetTypeList();

  sitesList$ = this.sitesService.getSiteFullList();
  previousUrl?: string | null;

  constructor(
    private sitesService: SitesService,
    private assetsService: AssetsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tagsService: TagsService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.previousUrl = queryParams.get('associationTo');
    });
  }

  formAssetCreation = this.fb.group({
    assetSiteUuid: this.fb.control(null, Validators.required),
    assetName: this.fb.control(null, Validators.required),
    assetDescription: this.fb.control(null, Validators.required),
    assetTypeId: this.fb.control(null, Validators.required)
  });

  submit() {
    this.formAssetCreation.markAllAsTouched();

    if (!this.formAssetCreation.valid) return;

    this.assetsService.createAsset(this.formAssetCreation.value).subscribe((res: any) => {
      if (this.previousUrl) {
        this.tagsService.setAssociationForTag(this.previousUrl, { tagableType: 'App\\Models\\Asset', tagableUuid: res?.assetUuid }).subscribe();
      }
      this.router.navigate(['assets']);
    })
  }
}
