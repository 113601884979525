
<div class="dialog">
    <div class="d-flex align-items-center mx-2 mb-3">
        <h1 class="dialog-title mb-0 mx-2">Remove from Site</h1>
        <mat-icon style="color: white;"
            matTooltipPosition="after" 
            matTooltip="We remove the user from the sites that you will leave in the form">
                info
        </mat-icon>
    </div>



    <div class="dialog-main-text mx-2 mb-3">
        <!-- Are you sure you want to remove user <span class="italic data">"{{data[0].userFullName}}"</span> <br> from site
        <div *ngFor="let item of data">
            {{item.userFullName}}
        </div> -->
        <div class="row">
            <div class="col-12 dialog-data italic">
                {{removeFromSiteUsers?.length}} users selected
            </div>

            <div class="col-12">
                <cdk-virtual-scroll-viewport itemSize="50" class="scrollbar-box">
                    <div *ngFor="let user of removeFromSiteUsers; let i = index" class="scrollbar-items">
                        <!-- index {{data[i].userUUID}} -->
                        <div class="row">
                            <div class="col-3 user-image">
                                some
                            </div>
                            <div class="col-9">
                                <div class="col-12 user-name">{{user.userName}}</div>
                                <div class="col-12 d-flex">
                                    <div class="col-7 current-sites-text">Current Sites</div>
                                    <div class="col-5 current-sites-text-count">{{user.userSites?.length}}</div>
                                </div>
                            </div>

                        </div>

                        <div class="row site-box my-2" *ngFor="let site of user.userSites">
                            <div class="col-12 col-md-5 site-box-items">{{site.siteName || "No Sitename"}}</div>
                            <div class="col-12 col-md-3 site-box-items"><mat-icon>pin_drop</mat-icon>{{site.siteCity || "No Location"}}</div>
                            <div class="col-12 col-md-3 site-box-items"><mat-icon>account_circle</mat-icon>{{site.siteRole || "No Role"}}</div>
                            <div class="col-12 col-md-1 site-box-items" (click)="removeSiteForUser(site, user);"><mat-icon>disabled_by_default</mat-icon></div>
                            <!-- <ng-template #test>
                                <div class="col-12 col-md-1 site-box-items" (click)="removeSiteForUser(site, data[i].userUUID)" ><mat-icon>chec_box</mat-icon></div>
                            </ng-template> -->
                        </div>

                        <!-- {{item}} -->
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>

        </div>

    </div>

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" (click)="cancel()" mat-button mat-dialog-close>CANCEL</button>
        <button class="col-4 dialog-delete-action-button" (click)="remove()" mat-button>REMOVE</button>
    </div>

</div>
