export const environment = {
  production: true,
  url: 'https://compliance.ticcbox.com/',
  apiUrl: 'https://ticcbox-compliance-api.azurewebsites.net/api/',
  allowedDomain: 'ticcbox-compliance-api.azurewebsites.net',
  appInsights: {
    instrumentationKey: '73c5ebe2-5fcf-462e-b5b9-f0a2c42fb7eb',
    connectionString: 'InstrumentationKey=73c5ebe2-5fcf-462e-b5b9-f0a2c42fb7eb;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/'
  }
};
