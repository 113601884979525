import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DashboardEventsAndPagination, SiteBasicViewModel, TiccCheckEventForDashboard } from 'src/app/models/backend-generated-models';
import { DashboardService } from 'src/app/services/api/dashboard.service';
import { SitesService } from 'src/app/services/api/sites.service';
import { SiteStateService } from 'src/app/services/state/site-state.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { LoadingService } from 'src/app/services/helpers/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DashboardComponent {

  isLoading$ = this.loadingService.isLoading$;

  sitesList: SiteBasicViewModel[] | null = null;

  selectedSiteUuid: string | undefined;

  showFutureTiccs: boolean = false;
  futureTiccsCount: number | undefined = 1000;

  todayNearestTiccsEnabled = false;
  futureNearestTiccsEnabled = true;

  dashboardTodayPageSize = 0;
  dashboardTodayPageIndex = 0;
  dashboardTodayPageLength = 0;

  dashboardFuturePageSize = 0;
  dashboardFuturePageIndex = 0;
  dashboardFuturePageLength = 0;

  dashboardTodayList: TiccCheckEventForDashboard[] = [];
  dashboardFutureList: { checkDate: string; eventList: TiccCheckEventForDashboard[]; }[] | undefined;


  constructor(
    private sitesService: SitesService,
    private dashboardService: DashboardService,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {
		this.sitesService.getList().subscribe(res => this.sitesList = res);
	}

  createCheck(check: TiccCheckEventForDashboard) {
    localStorage.setItem("breadcrumbs_pageType", "home");
    if (check.ticcInfo?.newScanningIsRequired) {
      this.router.navigate(['/scanner']);
    } else {
      localStorage.setItem("checkEventUuid", check?.eventUuid!.toString());
      this.router.navigate(['/ticcs/' + check.ticcInfo?.ticcUuid + '/checks/create']);
    }
  }


  /* ===================================================================== TODAY TICCS ===================================================================== */
  todayTiccsChangeNearestTiccsCheckbox() {
    this.todayNearestTiccsEnabled = !this.todayNearestTiccsEnabled;

    let params = new HttpParams().append("SiteUuid", this.selectedSiteUuid!).append("PageSize", 5).append("PageIndex", 0).append("pendingEventsMustBeOnlyNearest", this.todayNearestTiccsEnabled);

    this.dashboardService.getTodayTiccsList(this.selectedSiteUuid!, params).subscribe((dashboardTodayList: DashboardEventsAndPagination) => {
      this.dashboardTodayList = this.sortByCheckTimeAscendant(dashboardTodayList.eventsList!);
      this.dashboardTodayPageSize = dashboardTodayList.pageInfo?.pageSize!;
      this.dashboardTodayPageIndex = dashboardTodayList.pageInfo?.pageIndex!;
      this.dashboardTodayPageLength = dashboardTodayList.pageInfo?.totalCount!;
    })
  }

  todayTiccsChangeIndex(event: PageEvent) {
    let params = new HttpParams().append("SiteUuid", this.selectedSiteUuid!).append("PageSize", event.pageSize).append("PageIndex", event.pageIndex).append("pendingEventsMustBeOnlyNearest", this.todayNearestTiccsEnabled);

    this.dashboardService.getTodayTiccsList(this.selectedSiteUuid!, params).subscribe((dashboardTodayList: DashboardEventsAndPagination) => {
      console.log("Today", dashboardTodayList)
      console.log("Today Sort", this.sortByCheckTimeAscendant(dashboardTodayList.eventsList!))
      this.dashboardTodayList = this.sortByCheckTimeAscendant(dashboardTodayList.eventsList!);
    })
  }



  /* ===================================================================== FUTURE TICCS ===================================================================== */
  futureTiccsChangeIndex(event: PageEvent) {
    let params = new HttpParams().append("SiteUuid", this.selectedSiteUuid!).append("PageSize", event.pageSize).append("PageIndex", event.pageIndex).append("pendingEventsMustBeOnlyNearest", this.futureNearestTiccsEnabled);

    this.dashboardService.getFutureTiccsList(this.selectedSiteUuid!, params).subscribe((dashboardFutureList: DashboardEventsAndPagination) => {
      console.log("future", dashboardFutureList)
      this.sortByCheckDateAscendant(dashboardFutureList.eventsList!);
    })
  }

  futureTiccsChangeNearestTiccsCheckbox() {

    this.futureNearestTiccsEnabled = !this.futureNearestTiccsEnabled;
    let params = new HttpParams().append("SiteUuid", this.selectedSiteUuid!).append("PageSize", this.futureTiccsCount!).append("PageIndex", 0).append("pendingEventsMustBeOnlyNearest", this.futureNearestTiccsEnabled);


    this.dashboardService.getFutureTiccsList(this.selectedSiteUuid!, params).subscribe((dashboardFutureList: DashboardEventsAndPagination) => {
      console.log("future", dashboardFutureList)

      this.dashboardFutureList = undefined;
      this.sortByCheckDateAscendant(dashboardFutureList.eventsList!);
    })
  }

  loadFutureTiccs() {
    let params = new HttpParams().append("SiteUuid", this.selectedSiteUuid!).append("PageSize", this.futureTiccsCount!).append("PageIndex", 0).append("pendingEventsMustBeOnlyNearest", this.futureNearestTiccsEnabled);
    this.dashboardService.getFutureTiccsList(this.selectedSiteUuid!, params).subscribe((res) => {
      console.log("lot of data", res)
      this.sortByCheckDateAscendant(res.eventsList!);
    })
  }




  siteChanged(siteUuid: string, event: any) {

    if (!event.isUserInput) return;

    this.selectedSiteUuid = siteUuid;

    this.showFutureTiccs = false;



    /* ===================================================================== TODAY TICCS ===================================================================== */

    let params = new HttpParams().append("SiteUuid", siteUuid).append("PageSize", 5).append("PageIndex", 0).append("pendingEventsMustBeOnlyNearest", this.todayNearestTiccsEnabled);
    this.dashboardService.getTodayTiccsList(siteUuid, params)
      .subscribe((dashboardTodayList: DashboardEventsAndPagination) => {
        this.dashboardTodayPageSize = dashboardTodayList.pageInfo?.pageSize!;
        this.dashboardTodayPageIndex = dashboardTodayList.pageInfo?.pageIndex!;
        this.dashboardTodayPageLength = dashboardTodayList.pageInfo?.totalCount!;

        console.log("log ticcs for today", dashboardTodayList)

        this.dashboardTodayList = this.sortByCheckTimeAscendant(dashboardTodayList.eventsList!);
      });

  }



  /* ===================================================================== SORT ===================================================================== */

  sortByCheckTimeAscendant(dashboard: TiccCheckEventForDashboard[]) {
    return dashboard.sort((a, b) => new Date(a.checkEventTime!).getTime()! - new Date(b.checkEventTime!).getTime()!)
  }

  sortByCheckDateAscendant(dashboard: TiccCheckEventForDashboard[] | undefined) {
    this.dashboardFutureList = [];
    dashboard?.forEach((element: TiccCheckEventForDashboard) => {

      let fullDate = (new Date(element.checkEventTime!).getDate() + "." + (new Date(element.checkEventTime!).getMonth()! + 1) + "." + new Date(element.checkEventTime!).getFullYear()).toString();
      const dashboardFutureItem = this.dashboardFutureList?.find(x => x.checkDate === fullDate);

      if (!dashboardFutureItem) {
        this.dashboardFutureList?.push({
            checkDate: fullDate,
            eventList: [element]
          });
      } else {
        const eventItem = dashboardFutureItem.eventList.some(e => e.eventUuid === element.eventUuid);
        if (!eventItem) dashboardFutureItem.eventList.push(element);
      }
    });
  }

  onTitleClick(ticcUUID: string) {
    localStorage.setItem("breadcrumbs_pageType", "home");
    this.router.navigate(['/ticcs/' + ticcUUID]);
  }

  onCheckVisibilityClick(checkUuid: string) {
    localStorage.setItem("breadcrumbs_pageType", "home");
    this.router.navigate(['/checks/' + checkUuid]);
  }
}

