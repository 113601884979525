import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concatMap, EMPTY, Observable, take, throwError } from 'rxjs';
import { TiccViewModel, UserInfoViewModel, UserTagResponseModel } from 'src/app/models/backend-generated-models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/helpers/error-handler.service';
import { UserService } from 'src/app/services/api/user.service';
import { UserStateService } from 'src/app/services/state/user-state.service';
import { PhotoConvertService } from 'src/app/services/helpers/photo-convert';
import { TiccsListComponent } from '../../ticcs/ticcs-list/ticcs-list.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TagsService } from 'src/app/services/api/tags.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  tagStatusError = false;

  userUuid = this.route.snapshot.paramMap.get('userUUID');

  userDetails$: UserInfoViewModel | undefined;

  tag$: UserTagResponseModel | undefined

  tagImage$!: string;

  image: SafeUrl | undefined;
  isEditButton?: boolean;
  ticcsModels$: any | undefined;
  selectedSite: string | null = "";
  tagUUID: string | undefined;

  ticcs$: Observable<TiccViewModel[]> = new Observable<TiccViewModel[]>();

  breadcrumbExist = false;
  breadcrumbTitle: string = "";
  breadcrumbUrl: string = "";
  userPhoto?: SafeUrl;

  @ViewChild(TiccsListComponent) ticcsComponent: TiccsListComponent | undefined;


  isLoadingUserDetails = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private userStateService: UserStateService,
    private errorHandler: ErrorHandlerService,
    private photoConvert: PhotoConvertService,
    private tagsService: TagsService
  ) { }

  ngOnInit(): void {

    this.userStateService.currentUser$.subscribe((res) => {
      this.isEditButton = this.userUuid == res?.userUUId ? true : false;
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      const previousUrl = queryParams.get('lastUrl');

      if (previousUrl === "users") {
        this.breadcrumbExist = true;
        this.breadcrumbTitle = "USERS"
        this.breadcrumbUrl = "/users"
      }
      if (previousUrl === "admin-user-manager") {
        this.breadcrumbExist = true;
        this.breadcrumbTitle = "Admin User Manager"
        this.breadcrumbUrl = "/admin/user-management"
      }

    });

    this.isLoadingUserDetails = true;
    this.userService.getUserInformation(this.userUuid).subscribe((res) => {
      this.isLoadingUserDetails = false;
      this.userDetails$ = res;
      this.selectedSite = res.userSites && res.userSites[0].id ? res.userSites[0].id : "";
      if (this.selectedSite.length > 0) this.onSiteSelected();
    }, (error) => {
      this.isLoadingUserDetails = false;
    });

    this.userService.getUserTagInformation(this.userUuid)
      .pipe(
        concatMap((tag) => {
          this.tag$ = tag;
          return this.userService.getCurrentTagImage(tag.tagUUID);

        }
        )
      )
      .subscribe(
        {
          next: (blobImage) => {
            this.tagStatusError = false;

            let parsedBlobImage = URL.createObjectURL(blobImage);

            this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
          },
          error: (error: HttpErrorResponse) => {

            this.tagStatusError = true;

            if (error.status === 400) {
              return EMPTY
            }

            this.errorHandler.handleError(error);

            return throwError(error);
          }
        }
      )
  }

  onSiteSelected(): void{
    this.userService.getUserTiccsInformation(this.userUuid, this.selectedSite).subscribe(res => {
      if(this.ticcsComponent){
        this.ticcsComponent.showNewTiccList(res);
      }
    })
  }

  disassociateTag() {
    if (this.tag$?.tagUUID) {
      this.userService.deleteTagAssociation(this.tag$.tagUUID).subscribe(
        () => {
          this.image = undefined;
          this.tagStatusError = true
          this.tag$ = undefined;
        }
      )
    }
  }

  avatarConvert(photo: any) {
    if (photo == null) {
      return null;
    }
    return this.photoConvert.photoConvert(photo);
  }

  associationToTag() {
    this.tagsService.setAssociationForTag(this.tagUUID as string, { tagableType: 'App\\Models\\Auth\\User', tagableUuid: this.userUuid as string }).pipe(
      concatMap((tag) => {
        this.tag$ = tag;
        return this.userService.getCurrentTagImage(tag);
      }
    )).subscribe(
      {
        next: (blobImage) => {
          this.tagStatusError = false;

          let parsedBlobImage = URL.createObjectURL(blobImage);

          this.image = this.sanitizer.bypassSecurityTrustUrl(parsedBlobImage);
        },
        error: (error: HttpErrorResponse) => {

          this.tagStatusError = true;

          if (error.status === 400) {
            return EMPTY
          }

          this.errorHandler.handleError(error);

          return throwError(error);
        }
      }
    )
  }
}
