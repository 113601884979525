import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";

import {Router} from "@angular/router";
import { UserStateService } from '../services/state/user-state.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private userStateService: UserStateService, private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(
        (response: HttpErrorResponse) => {
          console.log("Response => ", response);

          if (response.status === 401) {
            localStorage.clear();
            this.userStateService.currentUserSource.next(null);
            this.router.navigate(['/login']);
          }

          return throwError(response);
        }
      )
    );
  }
}
