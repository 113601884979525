
<div class="page-top-breadcrumbs">
    NEW SITE
</div>

<hr class="page-top-breadcrumbs-underline">

<div class="container-fluid" style="min-width: 0"> 
    <div class="row">     
        <div class="col-12 view-entry">
            <div class="row">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <div class="title">
                        Create Site
                    </div>
                </div>
            </div>
        </div>

        <form class="row" [ngSwitch]="userSystemRole" [formGroup]="formSiteCreation"><!-- (submit)="submit()" -->


            <!-- GROUPED -->
            <div class="col-6" *ngIf="!isMobile">
                <div class="col-12">
                    <mat-card class="p-0 card">
                        <mat-card-header class="p-3">
                            <mat-card-title class="m-0">
                                Site Name
                            </mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="p-3">
                            <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Add Site Name</mat-label>

                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>

                                <input matInput type="text" formControlName="siteName">
                            </mat-form-field>
                        </mat-card-content>

                    </mat-card>
                </div>

                <div class="col-12">
                    <mat-card class="p-0 card">
                        <mat-card-header class="p-3">
                            <mat-card-title class="m-0">Address</mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="p-3">
                            <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Add Site Address</mat-label>

                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>

                                <input matInput type="text" formControlName="siteAddress">
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="col-12 col-md-6" *ngIf="isMobile">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">
                            Site Name
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="siteName">
                        </mat-form-field>
                    </mat-card-content>

                </mat-card>
            </div>











            <!-- ADMINISTRATOR -->
            <div class="col-12 col-md-6" *ngSwitchCase="'administrator'">
                <mat-card class="p-0 card"><!-- [formGroup]="formSelectedCompany"  -->

                    <mat-card-header class="p-3 site-creation-company">
                        <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                            Company
                            <div>
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div>
                        </mat-card-title>
                    </mat-card-header>


                    <mat-card-content *ngIf="switchCompany; else newCompany" formGroupName="siteCompany" class="p-3"><!--  [formGroup]="formSelectedCompany"  -->
                        <span>Parent Company</span>
                        <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Parent Company</mat-label>

                            <!-- TODO: -->
                            <mat-select [(value)]="selectedParanetId" (selectionChange)="setCurrentParentCompanyCompanies();"><!-- formControlName="parentCompanyId" --> <!--  [disabled]="isUserAdmin$" -->
                                <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                    [value]="parentCompany.companyId">
                                    {{parentCompany.companyName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <hr class="separator">

                        <span>Company</span>
                        <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Select Company</mat-label>

                            <mat-select formControlName="companyId">
                                <mat-option *ngFor="let company of currentParentCompanyCompanies"
                                    [value]="company.companyId">
                                    {{company.companyName}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </mat-card-content>


                    <ng-template formGroupName="siteCompany" #newCompany>
                        <mat-card-content class="p-3">


                            <span>Parent Company</span>
                            <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Parent Company</mat-label>

                                <mat-select formControlName="parentCompanyId">
                                    <mat-option *ngFor="let parentCompany of parentCompaniesList"
                                        [value]="parentCompany.companyId">
                                        {{parentCompany.companyName}}
                                    </mat-option>
                                </mat-select>


                            </mat-form-field>

                            <hr class="separator">

                            <span>New Company Name</span>
                            <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Add Company Name</mat-label>

                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>


                                <input matInput type="text" formControlName="companyName">
                                <!-- <input matInput type="text" formControlName="companyName"> -->
                            </mat-form-field>
                        </mat-card-content>
                    </ng-template>
                </mat-card>
            </div>















            <!-- COMPANY MANAGER -->
            <div class="col-12 col-md-6" *ngSwitchCase="'company manager'">
                <mat-card class="p-0 card">

                    <mat-card-header class="p-3 site-creation-company">
                        <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                            Company
                            <div>
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div>
                        </mat-card-title>
                    </mat-card-header>


                    <!-- --------------------- EXISTING COMPANY --------------------- -->
                    <mat-card-content class="p-3" *ngIf="switchCompany; else newCompany">
                        <span>Parent Company</span>
                        <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">{{userCompany}}</mat-label>

                            <mat-select [disabled]="true"></mat-select>
                        </mat-form-field>

                        <hr class="separator">

                        <span>Company</span>
                        <mat-form-field formGroupName="siteCompany" class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Select Company</mat-label>

                            <mat-select formControlName="companyId"><!--  [disabled]="isUserAdmin$" -->
                                <mat-option *ngFor="let company of currentParentCompanyCompanies"
                                    [value]="company.companyId">
                                    {{company.companyName}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </mat-card-content>


                    <!-- --------------------- NEW COMPANY --------------------- -->
                    <ng-template #newCompany>
                        <mat-card-content class="p-3">


                            <span>Parent Company</span>
                            <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">{{userCompany}}</mat-label>

                                <mat-select [disabled]="true"></mat-select>


                            </mat-form-field>

                            <hr class="separator">

                            <span>New Company Name</span>
                            <mat-form-field formGroupName="siteCompany" class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Add Company Name</mat-label>

                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>


                                <input matInput type="text" formControlName="companyName">
                            </mat-form-field>
                        </mat-card-content>
                    </ng-template>
                </mat-card>
            </div>






            <!-- USER -->
            <div class="col-12 col-md-6" *ngSwitchCase="'user'">
                <mat-card class="p-0 card">

                    <mat-card-header class="p-3 site-creation-company">
                        <mat-card-title class="m-0 d-flex flex-wrap justify-content-between">
                            Company
                            <!-- <div>
                                <span class="company" [ngClass]="{'selected-company': switchCompany}">EXISTING
                                    COMPANY</span>
                                &nbsp;&nbsp;<mat-slide-toggle (toggleChange)="switchCompany = !switchCompany"
                                    class="m-0 p-0"></mat-slide-toggle>&nbsp;&nbsp;
                                <span class="company" [ngClass]="{'selected-company': !switchCompany}">NEW
                                    COMPANY</span>
                            </div> -->
                        </mat-card-title>
                    </mat-card-header>


                    <!-- --------------------- EXISTING COMPANY --------------------- -->
                    <mat-card-content class="p-3"><!--  *ngIf="switchCompany; else newCompany" -->

                        <span>Parent Company</span>
                        <mat-form-field class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">{{userCompany}}</mat-label>

                            <mat-select [disabled]="true"></mat-select>
                        </mat-form-field>

                        <hr class="separator">

                        <span>Company</span>
                        <mat-form-field formGroupName="siteCompany" class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Select Company</mat-label>

                            <mat-select formControlName="companyId">
                                <mat-option *ngFor="let company of currentParentCompanyCompanies"
                                    [value]="company.companyId">
                                    {{company.companyName}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </mat-card-content>


                    <!-- --------------------- NEW COMPANY --------------------- -->
                    <!-- <ng-template #newCompany>
                        <mat-card-content class="p-3">

                            <span>New Company Name</span>
                            <mat-form-field formGroupName="siteCompany" class="page-form-field mb-4 mt-1 w-100 custom-input" appearance="fill">

                                <mat-label style="color: black">Add Company Name</mat-label>

                                <span matPrefix>
                                    <mat-icon class="icon-color">
                                        description
                                    </mat-icon>
                                </span>


                                <input matInput type="text" formControlName="companyName">
                            </mat-form-field>
                        </mat-card-content>
                    </ng-template> -->
                </mat-card>
            </div>




            <div class="col-12 col-md-6" *ngIf="isMobile">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Address</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site Address</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="siteAddress">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12 col-md-6">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">Post Code</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site Post Code</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="sitePostCode">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12 col-md-6">
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0">City</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site City</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <input matInput type="text" formControlName="siteCity">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-12"><!--  [ngClass]="{'col-12': !switchCompany, 'col-6': switchCompany}" -->
                <mat-card class="p-0 card">
                    <mat-card-header class="p-3">
                        <mat-card-title class="m-0 p-0">
                            Notes
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-3">
                        <mat-form-field class="page-form-field w-100 custom-input" appearance="fill">

                            <mat-label style="color: black">Add Site Notes</mat-label>

                            <span matPrefix>
                                <mat-icon class="icon-color">
                                    description
                                </mat-icon>
                            </span>

                            <textarea matInput type="text" formControlName="siteNotes"></textarea>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>
            </div>


            <div class="col-12 action-buttons-container">
                <div class="row d-flex justify-content-between">

                    <div class="col-6 col-md-5 col-xl-3">
                        <button mat-button type="submit" routerLink="/sites" [disabled]="siteCreating_loading"
                            class="action-buttons cancel w-100">CANCEL</button>
                    </div>

                    <div class="col-6 col-md-5 col-xl-3">
                        <button (click)="submit()" mat-button class="action-buttons submit w-100" [disabled]="siteCreating_loading">
                            <mat-spinner *ngIf="siteCreating_loading" class="my-1" style="display: inline-flex;" [diameter]="28" [strokeWidth]="4" ></mat-spinner>
                            {{siteCreating_loading ? '' : 'CREATE SITE'}}
                        </button><!--  type="submit"  -->
                    </div>
                </div>
            </div>


        </form>

    </div>
</div>