<div class="container-fluid container-md ticc-creation-container">


    <div class="row m-0">
        <div class="col-12 breadcrumbs-container">
            <div class="single-breadcrumb"><span class="single-breadcrumb-link">Assets</span>
                <mat-icon>chevron_right</mat-icon>
                <span class="single-breadcrumb-link">{{assetName}}</span>
                <mat-icon>chevron_right</mat-icon>
            </div>
            <div class="single-breadcrumb"><span>Edit Asset</span></div>
        </div>
    </div>

    <div class="row p-3 m-0">

        <div class="col-12 view-entry">
            <div class="row m-0">
                <div class="col-10 offset-1  col-md-6 offset-md-3  col-lg-4 offset-lg-4">

                    <div class="title">
                        Edit Asset
                    </div>

                    <hr>

                </div>
            </div>
        </div>

        <mat-card class="card col-12 mb-0">
            <mat-card-header class="p-3">
                <mat-card-title class="m-0">
                    Edit Asset {{assetName}}
                </mat-card-title>
            </mat-card-header>

            <mat-card-content class="row m-0 d-flex" [formGroup]="formAssetEdit">

                <div class="col-12 mb-1 mb-md-2">
                    <mat-form-field class="col-12 mt-3" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input formControlName="assetName" type="text" matInput
                            placeholder="Please, introduce asset name">
                        <mat-error>This field can not be empty</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field class="col-12 mt-3" appearance="outline">
                        <mat-label>Asset Type</mat-label>
                        <input formControlName="assetTypeName" type="text" matInput>
                    </mat-form-field>
                </div>

                <div class="col-12 mb-1 mb-md-2">
                    <mat-form-field class="col-12 mt-3" appearance="outline">
                        <mat-label>Site</mat-label>
                        <input formControlName="showedSiteName" type="text" matInput>
                    </mat-form-field>
                </div>

                <div class="col-12 mb-1 mb-md-2">

                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Description</mat-label>
                        <input formControlName="assetDescription" type="text" matInput
                            placeholder="Please, introduce asset description">
                        <mat-error>This field can not be empty</mat-error>
                    </mat-form-field>

                </div>

                <!-- <div class="col-12">

                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Asset Type</mat-label>

                        <mat-select formControlName="assetTypeId">
                            <mat-option *ngFor="let assetType of assetTypeList$ |async"  [value]="assetType.assetTypeID">
                                {{assetType.assetTypeName}}
                            </mat-option>

                        </mat-select>
                        <mat-error>Please, select asset type</mat-error>
                    </mat-form-field>

                </div> -->


            </mat-card-content>

        </mat-card>
    </div>

    <div class="row m-0 ">
        <div class="col-6">
            <button class="w-100 red-button" routerLink="/assets/{{assetUUID}}" mat-button>Cancel</button>
        </div>
        <div class="col-6">
            <button class="w-100 green-button" (click)="submit()" mat-button>Update</button>
        </div>
    </div>

</div>