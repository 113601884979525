import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, catchError, throwError } from 'rxjs';
import { AssingmentRoleRequestModel, AssociateUserToCompanyModel, DissociateUserToCompanyModel, InviteForUserRequest, ListOfUsersRemoveFromSite, NewUsersModel, TiccViewModel, UserFilters, UserInfoViewModel, UsersAndFilters, UsersViewModel, UserTagResponseModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { AuthService } from './auth.service';



/* TODO: */

export interface UsersViewModelForSiteRemove {
  userUUID: UsersWithSelectedSites[]
}

export interface UsersUUIDSiteRemove {
  userUUID: string[]
}

export interface UsersWithSelectedSites {
  userName?: string | undefined;
  userUuid?: string | undefined;
  userPhotoURL?: string | undefined;
  userSites?: UserSitesViewModel[];
}

/* TODO: */
export interface UserSitesViewModel {
  siteCity?: string | undefined;
  siteName?: string | undefined;
  siteRole?: string | undefined;
  siteUUID?: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userNameSubject = new BehaviorSubject<string>('');
  private userPhotoSubject: Subject<any> = new Subject<any>();
  private usersToInviteList: UsersViewModel[] | undefined;

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService
  ) { }

  disassociateUsersFromCompany(body: DissociateUserToCompanyModel | undefined) {
    return this.http
    .put(`${environment.apiUrl}Users/dissociate-company`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  getUsersListByGroup(params?: HttpParams) {
    return this.http
    .get<UsersViewModel[] | undefined>(`${environment.apiUrl}Users/newListByGroup`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  getUsersListFilter(params?: HttpParams) {
    return this.http
    .get<UserFilters | undefined>(`${environment.apiUrl}Users/newListFilters`, {params:params})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  addNewUsers(body: NewUsersModel) {
    return this.http
      .post(`${environment.apiUrl}Users`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }

  inivteUsersToSiteBulk(body: InviteForUserRequest) {
    return this.http
      .post(`${environment.apiUrl}Users/invite`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }

  getUsersSites(/* userUUID: string | undefined, */ body: string[] | undefined) {
    console.log("inside service", body)
    return this.http
      .post<{ userUUID: object[] }>(`${environment.apiUrl}Users/get-sites`, { UserUuids: body })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }


  assingmentCompanyRole(userUUID: string | undefined, body: AssingmentRoleRequestModel) {
    return this.http
      .put(`${environment.apiUrl}admin/Users/${userUUID}/system-role`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }

  activateOrDeactivateUser (userUUID: string | undefined, isActive: number | undefined) {
    return this.http.put(`${environment.apiUrl}Users/${userUUID}/enable`, {isActive: isActive})
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  removeUsersFromSitesBulk(userUuids: ListOfUsersRemoveFromSite | undefined, siteUUID: string | undefined/* , body: UsersUUIDSiteRemove */) {
    console.log("deleting");

    return this.http
      .put(`${environment.apiUrl}Users/${siteUUID}/sites-remove`, { userUuids: userUuids })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }

  removeUserFromSite(userUUID: string | undefined) {
    // console.log("inside service", userUUID)
    return this.http
      .delete(`${environment.apiUrl}Users/${userUUID}/site`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error)
          return throwError(error);
        })
      )
  }

  getUserSites(body: ListOfUsersRemoveFromSite | undefined) {
    console.log("user array asd", body)
    return this.http
      .post<UsersViewModelForSiteRemove>(`${environment.apiUrl}Users/get-sites`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      )
  }

  editUser(userUUID: string | null, body: string) {
    return this.http
      .put(`${environment.apiUrl}Users/${userUUID}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  setUserRole(userUUID: string | undefined, role: string) {
    return this.http
      .put(`${environment.apiUrl}Users/${userUUID}/role`, { userSiteRole: role })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getCurrentTagImage(param: string | undefined) {
    console.log("param", `${environment.apiUrl}Tags/${param}/qr`)
    return this.http
      .get(`${environment.apiUrl}Tags/${param}/qr`, { responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  deleteTagAssociation(param: string /* tagUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Tags/${param}/association`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getUserTagInformation(userUUID: string | null) {
    return this.http
      .get<UserTagResponseModel>(`${environment.apiUrl}Users/${userUUID}/tag`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getUserTiccsInformation(userUUID: string | null, siteUUID: string | null) {
    return this.http
      .get<TiccViewModel[]>(`${environment.apiUrl}Users/${userUUID}/ticcs?siteUuid=${siteUUID}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getUserInformation(userUUID: string | null | undefined) {
    if(userUUID == undefined) {
      this.authService.logout();
    }
    return this.http
      .get<UserInfoViewModel>(`${environment.apiUrl}Users/${userUUID}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return throwError(error);
        })
      );
  }

  getModulesList() {
    return this.http.get(`${environment.apiUrl}Users/info`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  getAllUsers() {
    return this.http.get<UsersViewModel[]>(`${environment.apiUrl}Users`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  filterForUser() {
    return this.http.get<[]>(`${environment.apiUrl}Filters`, {
      params:
      {
        param: "companies&sites&roles"
      }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }

  associateCompanyForUsers(body: AssociateUserToCompanyModel | undefined) {
    return this.http
    .put(`${environment.apiUrl}Users/associate-company`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  setNewUserName(name : string) {
    this.userNameSubject.next(name);
  }

  getUserName() {
    return this.userNameSubject.asObservable();
  }

  upload(formData: any, uuid: string) {
    return this.http.post(`${environment.apiUrl}Users/${uuid}/image`, formData,  { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    )
  }

  getUserPhoto(uuid: string)  {
    return this.http
    .get(`${environment.apiUrl}Photos/${uuid}?folder=users`, { responseType: 'blob' })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }

  setUsersForInvite(users: UsersViewModel[]) {
    this.usersToInviteList = users;
    localStorage.setItem("users invite", JSON.stringify(this.usersToInviteList));
  }

  getUsersForInvite() {
    const data = localStorage.getItem("users invite");
    return  data ? JSON.parse(data) : [];
  }
}
