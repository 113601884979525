export interface IUser {
    nameIdentifier: string;
    name: string;
    surname: string;
    email: string;
    userUUId: string;
    role: string;
    siteRoles: ISiteRole[];
}

export interface IExtractedUserFromJWT {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
    SiteRoles: string;
    UUId: string;
    exp: number;
    iss: string;
    aud: string;
}

export enum RoleNameEnum {
    SiteOwner = 'SiteOwner',
    SiteManager = 'SiteManager',
    Employee = 'Employee'
}

export const Employee = "Employee";
export const SiteManager = "Site Manager";
export const SiteOwner = "Site Owner";
export const ServiceProvider = "Service Provider";

export enum UserSiteRoles {
    "Employee" = 1,
    "Site Manager" = 2,
    "Site Owner" = 3,
    "Service Provider" = 4
};

export const getListUserSiteRoles = () => {
    return Object.keys(UserSiteRoles).slice(Object.keys(UserSiteRoles).length / 2);
}

export interface ISiteRole {
    RoleName: RoleNameEnum;
    RoleId: number;
    SiteId: number;
}

export class User implements IUser {
    nameIdentifier: string;
    name: string;
    surname: string;
    email: string;
    userUUId: string;
    role: string;
    siteRoles: ISiteRole[];

    constructor(tokenUser: IExtractedUserFromJWT) {
        this.nameIdentifier = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        this.name = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        this.surname = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"];
        this.email = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        // this.userUuid = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uuid"];
        this.role = tokenUser["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        this.siteRoles = JSON.parse(tokenUser.SiteRoles) as ISiteRole[]
        this.userUUId = tokenUser.UUId;
    }
}
