<div class="dialog">

    <h1 class="dialog-title mx-2 mb-3">User <span *ngIf="data.userIsActive === 0">Activate</span><span *ngIf="data.userIsActive === 1">Deactivate</span></h1>
    
    <div class="dialog-main-text mx-2 mb-3" >
        Are you sure you want to <span *ngIf="data.userIsActive === 0">activate</span><span *ngIf="data.userIsActive === 1">deactivate</span> user <span class="italic data">"{{data.userFullName}}"</span> <br>
        Are you sure?
    </div>

    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button mat-dialog-close>CANCEL</button>
        <button *ngIf="data.userIsActive === 0" class="col-4 dialog-delete-action-button" [matDialogClose]="true" mat-button>ACTIVATE</button>
        <button *ngIf="data.userIsActive === 1" class="col-4 dialog-delete-action-button" [matDialogClose]="true" mat-button>DEACTIVATE</button>
    </div>
    
  </div>