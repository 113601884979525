import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, take, throwError } from 'rxjs';
import { AssociateCompanyForSelectedSitesRequest, AssociateCompanyForSiteRequest, CreateOrEditSiteRequestModel, SiteBasicViewModel, SiteFullInfoViewModel, SiteResponseModel, SiteTagResponseModel, TiccViewModel, DissociateSitesToCompanyModel, SiteUserModel } from 'src/app/models/backend-generated-models';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  /* 
  Fill Parent company selector
    Call: GET api/admin/parent-companies/list
  Associate with Company "Submit"
    Call: PUT api/sites/associate-company
  New Company "Submit"
    Call: POST api/companies
    Call: PUT api/sites/associate-company
 */

  getSiteUsersList(siteUUID: string | undefined) {
    return this.http
    .get<SiteUserModel[]>(`${environment.apiUrl}Sites/${siteUUID}/users`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }


  disassociateCompanyFromSite(body: DissociateSitesToCompanyModel) {
    return this.http
    .put(`${environment.apiUrl}Sites/dissociate-company`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  associateCompanyForSite(siteUUID: string, body: AssociateCompanyForSiteRequest) {
    return this.http
    .put(`${environment.apiUrl}Sites/${siteUUID}/associate-company`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }


  associateCompany(body: AssociateCompanyForSelectedSitesRequest) {
    return this.http
    .put(`${environment.apiUrl}Sites/associate-company`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  editSite(siteUUID: string | null, body: CreateOrEditSiteRequestModel) {
    return this.http
    .put(`${environment.apiUrl}Sites/${siteUUID}`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    )
  }

  deleteTagAssociation(param: string /* tagUUID */) {
    return this.http
      .delete(`${environment.apiUrl}Tags/${param}/association`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }


  /* getSite(siteUUID: string) {
    return this.http
    .get<SiteTiccResponseModel[]>(`${environment.apiUrl}sites/${siteUUID}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
 */
  getTiccInformation(param: string | null /* siteUUID */) {
    return this.http
      .get<TiccViewModel[]>(`${environment.apiUrl}Sites/${param}/ticcs`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getCurrentTagImage(param: string | undefined) {
    console.log("param", `${environment.apiUrl}Tags/${param}/qr`)
    return this.http
      .get(`${environment.apiUrl}Tags/${param}/qr`, { responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getCurrentTagBySiteUUID(param: string | null /* siteUUID */) {
    return this.http
      .get<SiteTagResponseModel>(`${environment.apiUrl}Sites/${param}/tag`)
      .pipe(
        map((res) => {
          console.log("map", res)
          return res;
        }),

      );
  }

  getSiteDetailByUUID(siteUUID: string | null) {
    return this.http
      .get<SiteResponseModel>(`${environment.apiUrl}Sites/${siteUUID}`)
      .pipe(
        take(1),
        /* map(data => {
          return data
        }), */
        // tap(x1 => this.subject.next(x1))
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      )
  }

  getSiteFullList() {
    return this.http
      .get<SiteFullInfoViewModel[]>(`${environment.apiUrl}Sites`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getList(action : "read" | "manage" = "read" ) {
    return this.http
      .get<SiteBasicViewModel[]>(`${environment.apiUrl}Sites/list?action=${action}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  getCurrentSite() {
    return this.http
      .get<SiteBasicViewModel>(`${environment.apiUrl}Sites/current`)
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  setCurrentSite(siteId: number) {
    return this.http
      .post(`${environment.apiUrl}Sites/current`, null, {
        params: new HttpParams().append('siteId', siteId)
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  createNewSite(body: CreateOrEditSiteRequestModel) {
    return this.http
      .post(`${environment.apiUrl}Sites`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        })
      );
  }

  filterForSite() {
    return this.http.get<[]>(`${environment.apiUrl}Filters`, {
      params:
      {
        param: "companies&cities&owners"
      }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
}
