import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, map } from 'rxjs';
import { AuthService } from 'src/app/services/api/auth.service';
import { MessagesService } from 'src/app/services/helpers/messages-service.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  regexPassword: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  formResetPassword = this.fb.group({
    email: this.fb.control('', [Validators.required, Validators.email]),
    newPassword: this.fb.control('', [Validators.required, Validators.pattern(this.regexPassword)]),
    newConfirmPassword: this.fb.control('', [Validators.required, Validators.pattern(this.regexPassword)]),

  }, {
    validators: [
      this.matchValidator("newPassword", "newConfirmPassword")/*  */
    ]
  },)

  code: string | null | undefined;

  constructor(
    private authService: AuthService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private messageService: MessagesService,
  ) { }

  ngOnInit(): void {
    this.aRoute.queryParamMap.pipe(map(x => x.get('code'))).subscribe((res: string | null) => {
      // console.log("query", res)

      this.code = res;
      this.authService.confirmPasswordLink(res as string).subscribe()
    }
    )
  }

  private matchValidator(source: string, target: string): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;

    };
  }

  onSubmit() {
    if (!this.formResetPassword.valid) return;

    this.authService.resetPassword(this.code!, this.formResetPassword.value).subscribe(() => {
      this.router.navigate(['/login']);
      this.messageService.passwordSuccessfullyReset();
    })

  }


}
