<h1 class="page-top-title">
    My Files
</h1>

<div class="col-12">
    <mat-form-field class="col-12" appearance="outline">
        <mat-label>Select Site</mat-label>
        <mat-select [(value)]="selectedSite" (selectionChange)="onSiteChange(selectedSite)" [disabled]="isLoadingUrl">
            <mat-option *ngFor="let site of sitesList$ | async" [value]="site">
                {{site.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="siteFolderSharedUrlSafe === undefined && isLoadingUrl" class="col-12 mt-5 d-flex justify-content-center">
    <mat-spinner class="my-auto"></mat-spinner>
</div>

<iframe *ngIf="siteFolderSharedUrlSafe !== undefined" name="myframe" width="100%" height="350px" [src]="siteFolderSharedUrlSafe"></iframe>
