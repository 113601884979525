<mat-sidenav-container>
    <mat-sidenav class="pt-2" position="end" #sidenav closed [fixedInViewport]="options.value.fixed"
        [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">

        <div class="row m-0 mb-4">
            <div class="col-9">
                <p class="m-0 account-header">{{currentUserData?.name}}&nbsp;{{currentUserData?.surname}}</p>
                <p class="account-email">{{currentUserData?.email}}</p>
            </div>

            <div [routerLink]="['/users/' + currentUserData?.userUUId]" class="col-3 user-profile">
                <div class="account-image" [ngStyle]="{backgroundImage: 'url(web-assets/avatars/' + userList?.photo + ')'}">
                </div>
            </div>
        </div>

        <p class="mt-3 section-header">USER AREA</p>
        <hr class="mb-4">

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="home" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Dashboard</div>
            <div class="col-2">
                <mat-icon>incomplete_circle</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="sites" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Sites</div>
            <div class="col-2">
                <mat-icon>store</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="boards" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Boards</div>
            <div class="col-2">
                <mat-icon>assignment</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="assets" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Assets</div>
            <div class="col-2">
                <mat-icon>image</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="reports" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Reports</div>
            <div class="col-2">
                <mat-icon>report</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="my-files" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                My Files</div>
            <div class="col-2">
                <mat-icon>folder_open</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="users" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Users</div>
            <div class="col-2">
                <mat-icon>group</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" (click)="logout()">
            <div class="col-10 mat-sidenav-links-text">
                Logout</div>
            <div class="col-2">
                <mat-icon>logout</mat-icon>
            </div>
        </a>

        <p class="mt-5 section-header">USER AREA</p>

        <hr class="mb-4">


        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="admin/site-management"
            (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Sites Managemenet</div>
            <div class="col-2">
                <mat-icon>corporate_fare</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="admin/user-management"
            (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                User Managemenet</div>
            <div class="col-2">
                <mat-icon>manage_accounts</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="admin/asset-types"
            (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Asset Types</div>
            <div class="col-2">
                <mat-icon>perm_media</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="admin/ticc-types"
            (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                TICC Types</div>
            <div class="col-2">
                <mat-icon>done_all</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 mat-sidenav-links" routerLink="admin/modules" (click)="togglerEventHandler(sidenav)"
            routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                Modules</div>
            <div class="col-2">
                <mat-icon>layers</mat-icon>
            </div>
        </a>

        <a class="row m-0 px-4 py-2 bottom-whitespace mat-sidenav-links" routerLink="admin/tags"
            (click)="togglerEventHandler(sidenav)" routerLinkActive="mat-sidenav-links-active">
            <div class="col-10 mat-sidenav-links-text">
                TAGs</div>
            <div class="col-2">
                <mat-icon>qr_code</mat-icon>
            </div>
        </a>

    </mat-sidenav>

    <mat-sidenav-content [formGroup]="options">

        <app-admin-menu-mobile (togglerEvent)="togglerEventHandler(sidenav)"></app-admin-menu-mobile>

        <router-outlet></router-outlet>

    </mat-sidenav-content>

</mat-sidenav-container>
