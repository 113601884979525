<div class="dialog">
    <h1 class="dialog-title mx-2 mb-3">Edit Company</h1>
  
    <div class="dialog-main-text mx-2 mb-3">
        Are you sure that you want to change <span class="dialog-data">PARENT COMPANY</span>?<br>
        This will affect all associated pages.
    </div>
    
    <div mat-dialog-actions class="row mx-2 d-flex justify-content-center dialog-action-buttons">
        <button class="col-4 dialog-red-action-button" mat-button [mat-dialog-close]="false">CANCEL</button>
        <button class="col-4 dialog-blue-action-button" mat-button [mat-dialog-close]="true">OK</button>
    </div>
</div>